
import { httpGetAsync } from "../../../utilities/TZMRequest";

export class PainelGerencialAAPService {


    listar  = async (params) => {
        return httpGetAsync("/painel-gerencial-aap/listar", params);
    }

    atualizar  = async () => {
        return httpGetAsync("/painel-gerencial-aap/atualizar");
    }

    listarDetalhe  = async (params) => {
        return httpGetAsync("/painel-gerencial-aap/listarDetalhe", params);
    }
}