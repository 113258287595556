import classNames from "classnames";
import React from "react";
import { httpGet } from "../../utilities/TZMRequest";
import { toTimestamp } from "../common/TZMFormatter";

export class TZMMenubarInfo extends React.Component {

	constructor() {
		super();
		this.state = {
			now: Date.now(),
			localNow: Date.now()
		};
	}

	componentDidMount() {
		this.getNow();
		this.timer = setInterval(this.updateNow, 1000);
		this.rebootTimer = setInterval(this.getNow, 3600000);
	}

	componentWillUmount() {
		clearInterval(this.timer);
		clearInterval(this.rebootTimer);
	}

	timer = null;
	rebootTimer = null;

	getNow = () => {
		httpGet("/public/agora").then((response) => {
			this.setState({now: Date.parse(response)});
		});
	}

	updateNow = () => {
		this.setState({now: this.state.now + Date.now() + 10 - this.state.localNow, localNow: Date.now()});
	}

	zeroPad(num, places) {
		var zero = places - num.toString().length + 1;
		return Array(+(zero > 0 && zero)).join("0") + num;
	}

	render() {
		let classes = classNames({
			"ui-tzm-menubar-info": true
		});
		if (this.state.now) {
			return (
				<div className={classes}>
					<span className="fa fa-clock" style={{marginRight: "4px"}} />{toTimestamp(this.state.now)}
					<span className="ui-tzm-dashboard-countdown-symbol">&#xf110;</span>{this.zeroPad(this.props.countdown, 2)}
				</div>
			);
		} else return null;
	}

}
