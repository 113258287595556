import React, {Component} from "react";

export class Toggle extends Component {

	handleClick = () => {
		if (this.props.onChange) {
			this.props.onChange({name: this.props.name, index: this.props.index, value: this.props.value === this.props.left.value ? this.props.right.value : this.props.left.value});
		}
	}

	render() {
		return (
			<div className="toggle-container" {...this.props} onClick={this.handleClick}>
				<label className="ui-input-label" style={{marginBottom: "-2px"}}>{this.props.label}</label>
				<div className="toggle">
					<div className={`toggle-bullet ${this.props.right.value === this.props.value ? "right" : ""}`}>
						<i className={`fa fa-${this.props.value === this.props.left.value ? this.props.left.icon : this.props.right.icon}`}/>
					</div>
					<div style={{clear: "both"}}/>
				</div>
			</div>
		);
	}

}

