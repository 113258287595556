import React from "react";

/** Retorna os emails, transformados em link, separados por ponto-e-vírgula */
export function asEmail(emails) {
	if (emails) {
		return <div>{emails.split(";").map((email) => <span className="fake-link ui-tzm-email-link" href={`mailto:${email.trim()}`}>{email.trim()}</span>)}</div>;
	}
	return emails;
}

