import React from "react";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMTable from "../../../components/table/TZMTable";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import TZMShortcut from "../../../utilities/TZMShortcut";
import {IntegracaoService} from "../../servicos/IntegracaoService";

export class LogIntegracao extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			visible: true,
			logs: props.logs
		};
	}

	fechar = () => {
		this.setState({visible: false});
	}

	columns = [
		<TZMColumn header="Status" style={{width: "5em"}} body={IntegracaoService.columnStatus} />,
		<TZMColumn header="Versão" field="versao" style={{width: "5em"}} />,
		<TZMColumn header="Manutenção" field="tipoManutencao" style={{width: "8em"}} />,
		<TZMColumn header="Mensagem" field="mensagemErro" style={{width: "*"}} />,
	];

	render() {
		return (
			<TZMDialog style={{width: "800px"}} visible={this.state.visible} modal header="Registros de Integração" {...this.props}>
				<div className="ui-g">
					<TZMTable value={this.state.logs}>{this.columns}</TZMTable>
				</div>
				<TZMPanelFooter>
					<TZMButton secondary label="Fechar" icon="fas fa-close" onClick={this.fechar} />
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible}
					onEscape={this.fechar}
				/>
			</TZMDialog>
		);
	}

}
