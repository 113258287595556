import moment from "moment";
import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import TZMShortcut from "../../../utilities/TZMShortcut";
import TZMUserData from "../../../utilities/TZMUserData";
import { BudgetService } from "../../servicos/BudgetService";
import { EditarBudget } from "./EditarBudget";
import { Fetch } from "../../../utilities/Fetch";

const title = "Budgets";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class Budget extends React.Component {

    state = {
        vendedor: "",
        budgets: []
    };

    componentDidMount() {
		if (TZMUserData.isAutenticado()) {
            Fetch.Get("/ping");
            moment.locale('pt-BR');
		} else {
			window.location.replace("/");		
		}
	}

    budgetService = new BudgetService();

	listarBudgets = () => {
		this.budgetService.listar(`vendedor=nome~${this.state.vendedor}&sort=referencia`).then((budgets) => this.setState({budgets}));
	}

	alternarOcultos = () => {
		this.setState({desativados: !this.state.desativados});
	}

	editarBudget = (budget) => {
		ModalRealm.showDialog(<EditarBudget vendedor={budget.vendedor} onModalClose={this.listarBudgets} />);
	}

	criarBudget = () => {
		this.editarBudget({
            meta: 0,
            referencia: moment().format("YYYY-MM-DD"),
            vendedor: null
        });
	}

	excluirBudget = (budget) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
			this.budgetService.excluir(budget).then(this.listarBudgets);
		}} question="Tem certeza de que deseja excluir este budget?" />);
	}

	columns = [
		<TZMColumn header="Vendedor" key={1} style={{width: "*"}} body={(budget) => budget.vendedor.nome} />,
        <TZMColumn header="Referência" key={2} style={{width: "*"}} body={(budget) => moment(budget.referencia).format("MMMM - YYYY")} />,
        <TZMColumn header="Budget (M²)" key={3} style={{width: "*"}} body={(budget) => budget.unidade} />,
        <TZMColumn header="Budget (R$)" key={4} style={{width: "*"}} body={(budget) => budget.moeda} />,
		<TZMColumn header="Ações" key={5} style={{width: "6em", textAlign: "center"}} body={(budget) => {
			return (
				<div>
					{TZMUserData.hasRole("____BUDG_E") ? <span title="Editar Budget" className="fa fa-edit ui-tzm-icon" onClick={() => this.editarBudget(budget)} /> : null}
					{TZMUserData.hasRole("____BUDG_D") ? <span title="Remover Budget" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirBudget(budget)} /> : null}
				</div>
			);
		}} />
    ];
    
    handleChange = (event) => this.setState({[event.name]: event.target.value});

    render() {
        return (
            <div>
                <TZMMainContent>
                    <div className="ui-g">
                        <div className="ui-g-12">
                            <TZMPanel header={title}>
                                <TZMForm>
                                    <div className="ui-g">
                                        <div className="ui-g-12">
                                            <TZMTextField name="vendedor" autoFocus onChange={this.handleChange} label="Vendedor" placeholder="Nome ou e-mail" value={this.state.vendedor} />
                                        </div>
                                    </div>
                                </TZMForm>
                                <TZMPanelFooter>
                                    <TZMButton title={this.state.desativados ? "Ocultar Desativados" : "Exibir Desativados"} style={{float: "left"}} onClick={this.alternarOcultos} secondary icon={this.state.desativados ? "fas fa-eye": "fas fa-low-vision"} />
                                    {TZMUserData.hasRole("____BUDG_C") ? <TZMButton success icon="fas fa-plus" label="Novo Budget" onClick={this.criarBudget} /> : null}
                                    <TZMButton icon="fas fa-search" label="Procurar" onClick={this.listarBudgets} />
                                </TZMPanelFooter>
                            </TZMPanel>
                            <div style={{height: "8px"}} />
                            <TZMPanel header="Resultado da Pesquisa">
                                <TZMTable value={this.state.budgets} paginator rows={50} children={this.columns} />
                            </TZMPanel>
                        </div>
                    </div>
                    <TZMShortcut active={TZMShortcut.modalCount === 0}
                        onEnter={this.listarBudgets}
                        onCtrlH={this.alternarOcultos}
                        onCtrlN={this.criarBudget}
                    />
                    <span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
                    <ModalRealm />
                </TZMMainContent>
            </div>
        );
    }

}
