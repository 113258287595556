import React, {Component} from "react";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import TZMShortcut from "../../../utilities/TZMShortcut";
import {ProdutoService} from "../../servicos/ProdutoService";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import {FamiliaService} from "../../servicos/FamiliaService";
import TZMMensagens from "../../../components/dialog/TZMMensagens";
import {ModalRealm} from "../../../components/modal/ModalRealm";

export class SelecionarFamilia extends Component {

	produtoService = new ProdutoService();

	familiaService = new FamiliaService();

	state = {
		visible: true,
		produtos: this.props.produtos,
		familias: []
	};

	salvarProdutos = () => {
		if (this.state.familia?.id) {
			const {produtos} = this.state;
			produtos.forEach(p => p.familia = this.state.familia);
			this.salvarProduto(produtos);
		} else {
			ModalRealm.showDialog(<TZMMensagens mensagens={["É obrigatório selecionar uma família."]}/>);
		}
	}

	salvarProduto = (produtos, index = 0) => {
		this.produtoService.completar(produtos[index]).then(produto => {
			produto.familia = produtos[index].familia;
			this.produtoService.salvar(produto).then(() => {
				++index;
				if (produtos.length > index) {
					this.salvarProduto(produtos, index);
				} else {
					if (this.props.onModalClose) {
						this.props.onModalClose();
					}
					this.onClose();
				}
			});
		});
	}

	handleClear = (event) => {
		this.setState({familia: null});
	}

	handleSelect = (event) => {
		this.setState({familia: event.value});
	}

	listarFamilias = async (event) => {
		await this.familiaService.autoCompletarFamilia({descricao: event.query, sort: "descricao"}).then(familias => this.setState({familias}));
	}

	onClose = () => this.setState({visible: false});

	render() {
		return (
			<TZMDialog style={{width: "400px"}} visible={this.state.visible} modal header="Família" onHide={this.onClose} {...this.props}>
				<TZMPanel>
					<div className="ui-g">
						<div className="ui-g-12 ui-fluid">
							<TZMAutoComplete onClear={this.handleClear}
											 onSelect={this.handleSelect}
											 onChange={this.handleSelect}
											 suggestions={this.state.familias}
											 dropdown
											 completeMethod={this.listarFamilias}
											 name="familia"
											 field="descricao"
											 label="Tipo"
											 placeholder="Descrição"
											 value={this.state.familia}/>
						</div>
					</div>
				</TZMPanel>
				<TZMPanelFooter>
					<TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvarProdutos}/>
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onClose}/>
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onCtrlS={this.salvarProdutos} onEscape={this.onClose}/>
			</TZMDialog>
		);
	}

}
