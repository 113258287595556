import { Fetch } from "../../utilities/Fetch";
import { httpGet, httpGetAsync } from "../../utilities/TZMRequest";
const empresa = process.env.REACT_APP_EMPRESA;

export class TipoMovimentoService {

	autoCompletar(query) {
		return Fetch.GetJSON(`/tipos-movimento?tipomovimento=${query}&page=0&size=10`);
	}

	listar(params) {
		return httpGet("/tipos-movimento", params);
	}

	 listarCombobox = async () =>{		
		if (empresa === "COLACRIL") {
			return await httpGetAsync("/tipos-movimento/list", {empresa: 'AAP'});
		} else if (empresa === "RRE") {			
			return await httpGetAsync("/tipos-movimento/list", {empresa: empresa});
		}

	}
}
