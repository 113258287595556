import { Fetch } from "../../utilities/Fetch";
import { httpGet, httpGetAsync } from "../../utilities/TZMRequest";

export class BobinaService {

	autoCompletar(query) {
		return Fetch.GetJSON(`/bobinas?bobina=${query}&size=10&page=0&sort=descricao`);
	}

	listar(params) {
		return httpGet("/bobinas", params);
	}

	listarCortesClienteProduto = async (params) => {
        return await httpGetAsync("/bobinas/listar-cortes-cliente-produto", params, true);
    }

	preencherNulos(itemPedido) {
		if (!itemPedido.produto) {
			itemPedido.produto = { pk: {}, descricao: " " };
		}
		if (!itemPedido.bobina) {
			itemPedido.bobina = { quantidadeGrades: 0, cortado: false, largura: 0, comprimento: 0 };
		} else {
			itemPedido.bobina.m2 = itemPedido.bobina.largura * itemPedido.bobina.comprimento / 1000;
		}
		if (!itemPedido.bobina.m2Corte) {
			itemPedido.bobina.m2Corte = [];
		}
		if (!itemPedido.bobina.cortes) {
			itemPedido.bobina.quantidadeGrades = 1;
			itemPedido.bobina.cortes = [{ grade: 0, numero: 0, largura: 0, subtotal: 0, m2: 0 }];
		} else {
			let quantidadeGrades = 0;
			itemPedido.bobina.cortes = itemPedido.bobina.cortes.sort((a, b) => a.grade - b.grade);
			itemPedido.bobina.cortes.forEach((corte) => {
				if (corte.totalM2CompradoGrade) {
					itemPedido.bobina.m2Corte[corte.grade - 1] = corte.totalM2CompradoGrade;
				}
				if (quantidadeGrades < corte.grade) {
					quantidadeGrades = corte.grade;
				}
			});
			
			itemPedido.bobina.quantidadeGrades = quantidadeGrades;
		}
		itemPedido.bobina.cortes = itemPedido.bobina.cortes.sort((a, b) => a.itemId - b.itemId);
		return itemPedido;
	}

	recalcularGrades = (itemPedido) => {
		itemPedido.bobina.m2 = itemPedido.bobina.comprimento * itemPedido.bobina.largura / 1000;
		itemPedido.bobina.cortes.forEach((corte) => {
			corte.aproveitamento = corte.largura * corte.numero;
			corte.totalBobinas = corte.numero * this.calcularTiradasGrade(corte.grade-1, itemPedido);
			corte.m2 = corte.aproveitamento * (itemPedido.bobina.comprimento / 1000) * this.calcularTiradasGrade(corte.grade-1, itemPedido);
			itemPedido.bobina.cortes.forEach((corte, index) => {
				corte.subtotal = itemPedido.bobina.largura - itemPedido.bobina.cortes.filter((item) => item.grade === corte.grade).slice(0, index + 1).map((item) => item.aproveitamento).reduce((a, b) => a + b, 0);
			});
		});
		return itemPedido;
	}

	calcularTiradasGrade = (index,itemPedido) => {
		let value = Math.ceil(itemPedido.bobina.m2Corte[index] / itemPedido.bobina.m2);
		return value ? value : 0;
	}

}
