import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import { LogLantekService } from "../../servicos/LogLantekService";
import TZMTable from "../../../components/table/TZMTable";
import { Column } from "primereact/components/column/Column";
import { toDate } from "../../../components/common/TZMFormatter";
import TZMCalendar from "../../../components/common/TZMCalendar";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMPanel from "../../../components/panel/TZMPanel";

export class VisualizarLogLantek extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			visible: true,
            logLantek: props.logLantek,           
		};
        this.logLantekService = new LogLantekService();
	}

	onHide = () => this.setState({visible: false});	

	columns = [
		<Column filter={true} sortable header="Sequencia" key="sequencial" field="sequencial" style={{width: "8em", textAlign: "center"}} />,		
		<Column sortable field="dataEntrega" key="dataEntrega" style={{ width: "6.3em", textAlign: "center" }} body={this.dateFormat} header="Entrega" />,
		<Column filter={true} sortable header="Pedido" key="pedidoId" field="pedidoId" style={{width: "10em", textAlign: "center"}} />,
		<Column sortable header="Item" key="itemId" field="itemId" style={{width: "8em", textAlign: "center"}} />,
		<Column sortable header="Cliente" key="clienteId" field="clienteId" style={{width: "8em", textAlign: "center"}} />,
		<Column filter={true} sortable header="Produto" key="produtoId" field="produtoId" style={{width: "10em", textAlign: "center"}} />,
		<Column filter={true} sortable  header="Ordem Produção" key="ordemProducao" field="ordemProducao" style={{width: "8em", textAlign: "center"}} />,
		<Column header="Descrição" key="descricaoGrade" field="descricaoGrade" style={{width: "20em", textAlign: "center"}} />,
		<Column filter={true} sortable  header="Largura" key="largura" field="largura" style={{width: "8em", textAlign: "center"}} />,
		<Column filter={true} sortable  header="Comprimento" key="comprimento" field="comprimento" style={{width: "8em", textAlign: "center"}} />		
	];

	dateFormat(rowData, column) {
		let campo = rowData[column.field];
		if (campo !== null) {
			return toDate(campo);
		}
		return null;
	}

    render() {
		return (
			<TZMDialog style={{width: "1400px"}} visible={this.state.visible} modal header="Integração Lantek" onHide={this.onHide} {...this.props}>
				<TZMPanel>
					<div className="ui-g">
						<div className="ui-g-3">
							<TZMTextField  disabled value={this.state.logLantek.codigo} label="Código" />
						</div>
						<div className="ui-g-3">
							<TZMCalendar  disabled={true} name="inicio" showTime={true} label="Gerado Em" value={this.state.logLantek.registro} />
						</div>
						<div className="ui-g-3">
							<TZMTextField  disabled value={this.state.logLantek.geradoPor} label="Gerado Por" />
						</div>						
						<div className="ui-g-3">
							<TZMTextField  disabled value={this.state.logLantek.forma} label="Forma" />
						</div>
					</div>
					<div className="ui-g">							
						<div className="ui-g-12">
							<TZMTable  value={this.state.logLantek.itens}  paginator rows={15} >
								{this.columns}
							</TZMTable>
						</div>
					</div>
				</TZMPanel>
				<TZMPanelFooter>					
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onHide} />
				</TZMPanelFooter>				
			</TZMDialog>
		);
	}
}
