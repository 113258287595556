import React from "react";
import FontAwesome from "react-fontawesome";

export default class TZMIcon extends React.Component {

	render() {
		return (
			<FontAwesome {...this.props} />
		);
	}

}
