import { httpGet, httpGetAsync } from "../../utilities/TZMRequest";
export class VwProdutoService {
	
	autoCompletar = async (params) => {
		return await httpGetAsync('/vw-produtos/listar-simples', params,false);
	}

	listarSimples = async (params) => {
		return await httpGetAsync('/vw-produtos/listar-simples', params,true);
	}

	listarSimplesListaPreco(params) {		
		return httpGet('/vw-produtos/listar-simples-lista-preco', params, false);
	}

	listar(params) {
		return httpGet("/vw-produtos", params);
	}

	listarMateriaPrima = async (query) =>{
		return await httpGetAsync("/vw-produtos/listar-materia-prima", {produto: query}, true);
	}

	completarProduto = async (params) =>{
		return await httpGetAsync("/vw-produtos/completar-produto", params, true);
	}

	listarProdutosCliente = async (params) => {		
		return await httpGetAsync('/vw-produtos/listar-simples-lista-preco', params, true);
	}

}
 