import moment from "moment";
import React from "react";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMButton from "../../../components/common/TZMButton";
import TZMRadioButton from "../../../components/common/TZMRadioButton";
import TZMDialog from "../../../components/dialog/TZMDialog";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import { BobinaService } from "../../servicos/BobinaService";
import { VwClienteService } from "../../servicos/VwClienteService";
import { VwPedidoService } from "../../servicos/VwPedidoService";
import EditarPedido from "./EditarPedido";

export default class ClonarPedido extends React.Component {

	constructor() {
		super();
		this.state = {
			visible: true,
			manterCliente: true,
            modoCopia: 'cabecalho',
            params: {
				cliente: { pk: {}, descricaoAmigavel: "" },
			},
		};
	}   
    
    vwClienteService = new VwClienteService();
    vwPedidoService = new VwPedidoService();
    bobinaService = new BobinaService();

	fecharPainel = () => {
		this.setState({visible: false});
	}

    cloneProperties = (pedido) => {        
        pedido.status = "RASCUNHO";
        pedido.sistema = "TZM";
        pedido.pedidoCloneId = pedido.pk.pedidoId;
        pedido.pedidoId = null;
        pedido.seuPedido = null;
        pedido.pedidoCliente = null;
        pedido.confirmadoEm = null;
        pedido.clienteRetira = false;
        pedido.condicaoPagamento = pedido.cliente.condicaoPagamento;
        pedido.criadoPor = null;
        pedido.operador = null;
        pedido.gestor = null;
        pedido.versao = 0;
        let date = new Date();
        pedido.dataPedido = moment(date).format("YYYY-MM-DD");
        pedido.dataInclusao = moment(date).format("YYYY-MM-DD");
        pedido.dataEntrega = moment(date).add(3, 'days').format("YYYY-MM-DD");
        pedido.dataPedidoCliente = moment(date).format("YYYY-MM-DD");
        pedido.dataMinimaFaturamento = null;
        pedido.dataPrevisaoFaturamento = null;
        if (pedido.id != null) pedido.id = null;
        if (pedido.pk != null) pedido.pk = {};
        if (pedido.itens != null) {
            pedido.itens = pedido.itens.filter(item => item.produto.posicao === 'ATIVO').sort((a, b) => (a.pk && b.pk ? a.pk.itemId - b.pk.itemId : a.itemId - b.itemId ));
            pedido.itens.forEach(item => {
                item.versao = 0;
                item.status = null;
                if (item.id != null) item.id = null;
                if (item.pk != null) {
                    item.itemId = item.pk.itemId;
                    item.pk = {};
                }				
                if (item.bobina != null) {
                    item.bobina.versao = 0;
                    if (item.bobina.id != null) item.bobina.id = null;
                    if (item.bobina.pk != null) item.bobina.pk = {};
                    if (item.bobina.cortes) item.bobina.cortes = item.bobina.cortes.filter(c => !c.ponta && c.grade > 0);
                    if (item.bobina.cortes != null) item.bobina.cortes.forEach(corte => {
                        corte.versao = 0;
                        if (corte.id != null) corte.id = null;
                        if (corte.pk != null) {
                            corte.itemId = corte.pk.itemId;
                            corte.pk = {};
                        }
                    });
                    
                    item = this.bobinaService.preencherNulos(item);
                    item = this.bobinaService.recalcularGrades(item);
                    item.ordensProducao = [];
                    item.statusOs = null;
                }
            });
        }
        if (pedido.textoInterno != null) {
            pedido.textoInterno.versao = 0;
            pedido.textoInterno.forEach(texto => {
                if (texto.id != null) texto.id = null;
                if (texto.pk != null) texto.pk = {};
            });
        }
        if (pedido.textoEspecifico != null) {
            pedido.textoEspecifico.versao = 0;
            pedido.textoEspecifico.forEach(texto => {
                if (texto.id != null) texto.id = null;
                if (texto.pk != null) texto.pk = {};
            });
        }
        if (pedido.imagem != null) {
            if (pedido.imagem.id != null) pedido.imagem.id = null;
        }
        ModalRealm.showDialog(<EditarPedido pedido={pedido} key={Math.random()} onSave={(pedido) => {
            if (this.props.onSave) {
                this.props.onSave(pedido);
            }
        }} />);
    }

    clonarPedido = async () => {
        switch (this.state.modoCopia) {
            case 'completo':                
                await this.vwPedidoService.clonar({pk: this.props.pedido.pk}).then((result) => this.cloneProperties(result)) 
                this.fecharPainel();
                break;
            case 'cabecalho':                
                await this.vwPedidoService.completar(this.props.pedido.pk).then((result) => {result.itens = []; this.cloneProperties(result)});
                this.fecharPainel();
                break;
            case 'completoSemCliente':                     
                if(!!this.state.params.cliente.pk.clienteId) {
                    await this.vwPedidoService.clonar({pk: this.props.pedido.pk, clientPk: this.state.params.cliente.pk}).then((result) => this.cloneProperties(result)) 
                    this.fecharPainel();
                }
                break;
            default:
                break;
        }
    }

	onYes = () => {
        this.clonarPedido();
	}

    clearAutoComplete = (event) => {
        let params = this.state.params;
        params[event.name] = { pk: {}, [event.field]: "" };
        this.setState({ params });
    }

    selectAutoComplete = (event) => {
        let params = this.state.params;
        params[event.name] = event.value;
        this.setState({ params });
    }

    listClientes = async (event) => {
        await this.vwClienteService.autoComplete({cliente: event.query,posicao: 'ATIVO', vendedor: 'true', sort: 'razao'}).then((listClientes) => this.setState({listClientes}));
    }

    queryAutoComplete = (event) => {		
        console.log(this.state.params.cliente.pk)
        let params = this.state.params;
        params[event.name][event.field] = event.value;
        this.setState({ params });
    }

	render() {
		return (
			<TZMDialog header="Cópia" visible={this.state.visible} modal>				 
				<div style={{fontSize: '1.3rem'}}  className="ui-question-message">{"Selecione uma das opções de cópia: "}</div>		
				<div className='radioGroup'>
                    <TZMRadioButton value='cabecalho' checked={this.state.modoCopia === 'cabecalho'}  onChange={(e) => this.setState({modoCopia: e.value})} label='Copiar apenas o cabeçalho' id='CCA' />
                    <TZMRadioButton value='completo' checked={this.state.modoCopia === 'completo'} onChange={(e) => this.setState({modoCopia: e.value})} label='Copiar pedido completo'  id='CCO'/>
                    <TZMRadioButton value='completoSemCliente' checked={this.state.modoCopia === 'completoSemCliente'} onChange={(e) => this.setState({modoCopia: e.value})} label='Copiar pedido completo e alterar o cliente' id='CCOSC'/>
                </div>

                { this.state.modoCopia === 'completoSemCliente' && <div className="choiceAutocomplete">
                    <TZMAutoComplete 
                        itemTemplate={this.clienteTemplate} 
                        onClear={this.clearAutoComplete} 
                        name="cliente"
                        field="descricaoAmigavel" 
                        onSelect={this.selectAutoComplete} 
                        suggestions={this.state.listClientes}
                        completeMethod={this.listClientes} 
                        placeholder="Razão social, fantasia ou CNPJ" 
                        onChange={this.queryAutoComplete}
                        value={this.state.params.cliente.descricaoAmigavel} 
                        label="Cliente" 
                    />                  
                </div> }
				<TZMPanelFooter>
					<TZMButton icon={ `fas fa-copy`} label={'Copiar'} onClick={this.onYes} disabled={this.state.modoCopia === 'completoSemCliente' && !this.state.params.cliente.pk.clienteId} />
				</TZMPanelFooter>
			</TZMDialog>
		);
	}

}