
import moment from 'moment';
import { Column } from 'primereact/components/column/Column';
import { DataTable } from 'primereact/components/datatable/DataTable';
import React from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import TZMAutoComplete from '../../../components/common/TZMAutoComplete';
import TZMButton from '../../../components/common/TZMButton';
import TZMCalendar from "../../../components/common/TZMCalendar";
import TZMCombobox from "../../../components/common/TZMCombobox";
import TZMForm from "../../../components/common/TZMForm";
import { toCurrency, toCurrencyFormatterPrecision, toDate, toPercent } from "../../../components/common/TZMFormatter";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from '../../../components/modal/ModalRealm';
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import { Fetch } from '../../../utilities/Fetch';
import Templates from '../../../utilities/Templates';
import TZMUserData from "../../../utilities/TZMUserData";
import { UF } from '../../../utilities/UF';
import UsuarioTemplate from "../usuario/UsuarioTemplate";
import { VwClienteService } from "../../servicos/VwClienteService";
import { RegionalService } from '../../servicos/RegionalService';
import { UsuarioService } from '../../servicos/UsuarioService';
import { VendedorService } from "../../servicos/VendedorService";
import "./PainelAnaliseVendas.css";
import { AnaliseService } from '../../servicos/AnaliseService';
import { PedidoUtils } from '../../aap/pedido/PedidoUtils';
import { TZMCurrency } from '../../../components/common/TZMCurrency';
import { ClienteUtils } from '../cliente/ClienteUtils';
import { VwPedidoService } from '../../servicos/VwPedidoService';
import EditarPedido from "../../aap/pedido/EditarPedido";

const VERDE =  "#008000";
const VERMELHO = "#FF0000";
const AMARELO = "#FFD700";

const title = "Análise de Vendas";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export default class PainelAnaliseVendas extends React.Component {

	constructor(props) {
		super(props);
		moment.locale('pt-BR');
		this.state = {
			vendas: [],
			loading: false,
			activeIndex: 0,
			windowSize: 0,
			params: Object.assign({}, this.defaultParams),
			fixedSeller: false,
			fixedGerente: false,
			fixedSupervisor: false,
			filtrarGrupo: false,
			regional: {
				regional:null,
				vendedor: null, 
				gerente: null,
				supervisor: null,
			},			
			grafico: [],
			listClientes: []
		};		
		this.analiseService = new AnaliseService();
		this.vendedorService = new VendedorService();
		this.vwClienteService = new VwClienteService();		
		this.usuarioService = new UsuarioService();
		this.regionalService = new RegionalService();
		this.vwPedidoService = new VwPedidoService();
	}

	defaultParams = {
		dataPedido: [null, null],
		numero: "",
		uf: "",
		cliente: {pk: {}, descricaoAmigavel: ""}
	};

	rangeDiario() {
		let date = new Date();
		let firstDay = moment(date).format("YYYY-MM-DD");
		return [firstDay, firstDay];
	}

	componentDidMount() {
		if (TZMUserData.isAutenticado()) {
			Fetch.Get("/ping");	
			let usuario = TZMUserData.getPrincipal();		
			let regional = this.state.regional;
			
			switch (usuario.nivel) {
				case TZMUserData.REPRESENTANTE:
					if (usuario.vendedor) {
						regional.vendedor = usuario.vendedor;
						this.setState({regional, fixedSeller: true});
					}
					break; 
				case TZMUserData.GERENTE:										
					regional.gerente = usuario;										
					this.setState({regional, fixedGerente: true});
				break;
				case TZMUserData.SUPERVISOR:
					regional.supervisor = usuario;
					this.setState({regional, fixedSupervisor: true});
				break;			
			default:
				break;
			}
			this.resizeGraphs();
			window.addEventListener("resize", this.resizeGraphs);
			this.handleListGrafico();
		} else {
			window.location.replace("/pedidos");
		} 
	}
	
	resizeGraphs = () => {
		this.setState({windowSize: window.innerHeight - 94});
	}

	handleUpdate = (key, event) => {
		this.setState({params: {...this.state.params, [key]: event.target.value}});
	}

	dateFormat(rowData, column) {
		return toDate(rowData[column.field]);
	}

	toCurrency(rowData, column) {
		return <div style={{textAlign: "right"}}>{toCurrency(rowData[column.field])}</div>
	}

	cliente(rowData) {
		return <div>
			<div style={{textAlign: "left", marginTop: "2px"}}>{rowData.razaoSocial}</div>
			<div style={{textAlign: "left"}}>{rowData.nomeFantasia}</div>
			<div style={{textAlign: "left", marginBottom: "2px"}}>{rowData.cpfCnpjFormatado}</div>
		</div>
	}

	vendedor(rowData) {
		return <div>
			<div style={{textAlign: "center", marginTop: "2px"}}>{rowData.vendedorId} - {rowData.vendedorNome}</div>
		</div>
	}

	toPercent(rowData, column) {
		return <div style={{textAlign: "right"}}>{toPercent(rowData[column.field])}</div>
	}

	mesFormat(rowData, column) {
		return moment(rowData[column.field]).format("DD/MM/YYYY");
	}

	toggleGrupo = () => { 
		this.setState({filtrarGrupo: !this.state.filtrarGrupo});
    }

	getParams(values) {       
		let params = Object.assign({}, this.state.params);
		let regional = Object.assign({}, this.state.regional);
		
		Object.keys(params).forEach(k => params[k] != null && typeof params[k] === "string" ? params[k] = params[k].trim() : null);  
		params.filtrarGrupo = this.state.filtrarGrupo ? "true" : "false";

		if (params.cliente) {
			if (params.cliente.pk.clienteId) {
				params.cliente = params.cliente.pk;
			} else {
				params.cliente = null;
			}
		}
		if (regional.vendedor && regional.vendedor.vendedorId) {
			params.vendedor = regional.vendedor.vendedorId.vendedorId.toString();
		}		
		
		if (regional.regional) {
			params.regional = regional.regional.id; 
		}

		if (regional.gerente) {
			params.gerente = regional.gerente.id; 
		}
		if (regional.supervisor) {
			params.supervisor = regional.supervisor.id;			
		}
		
		if (values) {
			params[values] = values;
		}		
		return params;
	}

	handleListGrafico = () => {
		this.analiseService.listarGrafico(this.getParams()).then((response) => {		
			this.setState({grafico: response});}
		);
	}   

	handleList = () => {	
		let params = this.getParams();
		this.analiseService.listarGrafico(params).then((graficos) => {		
			this.analiseService.analiseVendas(params).then((analises) => {			 
				this.setState({vendas:analises, grafico: graficos});
			});
		});
	}	

	clienteTemplate(cliente) {
		return <div><i className={`fa fa-${cliente.posicao === "ATIVO" ? "check-circle" : "minus-circle"}`} /> {cliente.pk.clienteId} - {cliente.razaoSocial}</div>;
	} 

	listarGerentes = (event) => {
		this.usuarioService.autoCompletar({usuario: event.query,regional: TZMUserData.GERENTE,sort:'nome'}).then((gerentes) => this.setState({gerentes}));
	}

	listarSupervisores = (event) => {		
		let supervisores = [];
		let usuario = TZMUserData.getPrincipal();		
		switch (usuario.nivel) {
			case TZMUserData.GERENTE:
				supervisores = usuario.regionais.filter((regional) => regional.supervisor && regional.supervisor.nome.toLowerCase().includes(event.query.toLowerCase()));
				supervisores = supervisores.map(r => r.supervisor);
				supervisores = supervisores.filter((v, i, a) => a.findIndex(w => w.id === v.id) === i);				
				this.setState({supervisores})	
			break;			
		default:			
			this.usuarioService.autoCompletar({usuario: event.query,regional: TZMUserData.SUPERVISOR, sort:'nome'}).then((supervisores) => this.setState({supervisores}));
			break;
		}
	}

	listarVendedores = async (event) => {
		let vendedores = [];	
		let usuario = TZMUserData.getPrincipal();			
		switch (usuario.nivel) {
			case TZMUserData.REPRESENTANTE:
				if (usuario.vendedor) {					
				}
				break;
			case TZMUserData.GERENTE:				
				usuario.regionais.forEach((regional) => {
					vendedores = vendedores.concat(regional.vendedores.filter((vendedor) => 
					vendedor.nome.toLowerCase().includes(event.query.toLowerCase()) ||  
					vendedor.descricaoAmigavel.toLowerCase().includes(event.query.toLowerCase())));
				});
				vendedores = vendedores.filter((v, i, a) => a.findIndex(w => w.id === v.id) === i);
				this.setState({vendedores})
			break;
			case TZMUserData.SUPERVISOR:
				if (usuario.regionais) {
					usuario.regionais.forEach((regional) => {
						vendedores = vendedores.concat(regional.vendedores.filter((vendedor) => 
						vendedor.nome.toLowerCase().includes(event.query.toLowerCase()) ||  
						vendedor.descricaoAmigavel.toLowerCase().includes(event.query.toLowerCase())));
					});
					vendedores = vendedores.filter((v, i, a) => a.findIndex(w => w.id === v.id) === i);
					this.setState({vendedores})				
				} else {
					this.setState({vendedores});
				}	
			break;			
		default:
			await this.vendedorService.autoCompletar({vendedor: event.query, sort:'nome'}).then((vendedores) => this.setState({vendedores}));
			break;
		}
	}

	listarRegionais = async (event) => {
		let regionais = [];
		let usuario = TZMUserData.getPrincipal();				
		switch (usuario.nivel) {
			case TZMUserData.REPRESENTANTE:
				if (usuario.vendedor) {					
				}
				break;
			case TZMUserData.GERENTE:	
				regionais = usuario.regionais.filter((regional) => regional.descricao.toLowerCase().includes(event.query.toLowerCase()));				
				this.setState({regionais})
			break;
			case TZMUserData.SUPERVISOR:
				if (usuario.regionais) {
					regionais = usuario.regionais.filter((regional) => regional.descricao.toLowerCase().includes(event.query.toLowerCase()));
					this.setState({regionais})				
				} else {
					this.setState({regionais});
				}	
			break;			
		default:
			await this.regionalService.autoCompletar({descricao: event.query, sort: "descricao"}).then((regionais) => this.setState({regionais}));
			break;
		}
	}

	listClientes = async (event) => {
        await this.vwClienteService.autoComplete({cliente: event.query, sort: 'razao'}).then((listClientes) => this.setState({listClientes}));
    }

	queryAutoComplete = (event) => {       
		const params = this.state.params;
		params[event.name][event.field] = event.value;
		this.setState({params});
	}


	selectAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name] = event.value;
		this.setState({params});
	}

	clearAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name] = { pk: {}, [event.field]: "" };
		this.setState({params});
	}

	handleUpdateParams = (event, field, index) => {
		let params = this.state.params;
		params[field][index] = event.target.value;
		this.setState({params});
	}

	formatNumero(numero) {
		return new Intl.NumberFormat('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(Number(numero));
	}

	COLORS = [VERDE ,AMARELO, VERMELHO];
	RADIAN = Math.PI / 180;
	renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {
		return (`${(percent * 100).toFixed(2)}%`);
	};

	onPieClick = (data, index) => {			
		let params = Object.assign({}, {indicador: data.indicador},this.getParams());
		this.analiseService.analiseVendas(params).then((response) => {			
			this.setState({vendas: response});
		});
	}

	renderSuggestion = ()  => {
		let usuario = TZMUserData.getPrincipal();
		switch (usuario.nivel) {
			case TZMUserData.REPRESENTANTE:
				break;
			case TZMUserData.GERENTE:										
				return (<div className="ui-g-12 ui-g-nopad">
						<div className="ui-g-12 ui-fluid">
							<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.regionais} dropdown completeMethod={this.listarRegionais} name="regional" field="descricao" 
								label="Regional" onUnselect={this.handleUnselect} placeholder="Descrição" value={this.state.regional.regional} />
						</div>
						<div className="ui-g-12 ui-fluid">							
							<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.gerentes} dropdown completeMethod={this.listarGerentes} name="gerente" field="descricaoAmigavel" 
								disabled={this.state.fixedGerente} label="Gerente" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.gerente} />
							
						</div>
						<div className="ui-g-12 ui-fluid">
							<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.supervisores} dropdown completeMethod={this.listarSupervisores} name="supervisor" field="descricaoAmigavel" 
								disabled={this.state.fixedSupervisor} label="Supervisor" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.supervisor} />
						</div>
					</div>
				);
			case TZMUserData.SUPERVISOR:
				return (
					<div className="ui-g-12 ui-g-nopad">
						<div className="ui-g-12 ui-fluid">
							<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.regionais} dropdown completeMethod={this.listarRegionais} name="regional" field="descricao" 
								label="Regional" onUnselect={this.handleUnselect} placeholder="Descrição" value={this.state.regional.regional} />
						</div>

						<div className="ui-g-12 ui-fluid">
							<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.supervisores} dropdown completeMethod={this.listarSupervisores} name="supervisor" field="descricaoAmigavel" 
								disabled={this.state.fixedSupervisor} label="Supervisor" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.supervisor} />
						</div>	
					</div>
				);	
		default:
			return (
				<div className="ui-g-12 ui-g-nopad">
					<div className="ui-g-12 ui-fluid">
						<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
							suggestions={this.state.regionais} dropdown completeMethod={this.listarRegionais} name="regional" field="descricao" 
							label="Regional" onUnselect={this.handleUnselect} placeholder="Descrição" value={this.state.regional.regional} />
					</div>
					<div className="ui-g-12 ui-fluid">							
						<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
							suggestions={this.state.gerentes} dropdown completeMethod={this.listarGerentes} name="gerente" field="descricaoAmigavel" 
							disabled={this.state.fixedGerente} label="Gerente" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.gerente} />
						
					</div>
					<div className="ui-g-12 ui-fluid">
						<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
							suggestions={this.state.supervisores} dropdown completeMethod={this.listarSupervisores} name="supervisor" field="descricaoAmigavel" 
							disabled={this.state.fixedSupervisor} label="Supervisor" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.supervisor} />
					</div>
				</div>
			);			
		}		
	}

	handleUnselect = (event) => {
		let regional = this.state.regional;
		if (!regional[event.name]) {
			regional[event.name] = [];
		}
		regional[event.name] = regional[event.name].filter((e) => e.id !== event.value.id);
		this.setState({regional});
	}
	
	handleClear = (event) => {
		let regional = this.state.regional;
		regional[event.name] = null;
		this.setState({regional});
	}

	handleSelect = (event) => {		
		let regional = this.state.regional;		
		regional[event.name] = event.value;
		this.setState({regional});
	}

	customTooltip = ({ active, payload, label }) => {
        if (active && payload !== null) {
          return (
            <div className="style1">
                <div className="label" style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} >
					{`${payload[0].name}`} :  {`${payload[0].payload.quantidade}`}					
                </div>                      
            </div>
          );
        }
      
        return null;
    };

	showValorUnitario = (rowData) => {        
        let color = null;
        switch (rowData.indicador) {
            case  PedidoUtils.VERDE():		
                color = VERDE;
                break;
            case  PedidoUtils.AMARELO():					
                color = AMARELO;
                break;
            case  PedidoUtils.VERMELHO():					
                color = VERMELHO;
                break;
            default:
                break;
        }
        return (
            <div key={TZMUserData.getIdGenerator()} className="campo-descontos cmp-vlrunit-prtc">
                <div key={TZMUserData.getIdGenerator()} title={rowData.mensagem} className="campo-valunit">
                    <TZMCurrency value={toCurrencyFormatterPrecision(rowData.valorUnitario)} disabled />
                    <i className="fa fa-balance-scale" style={{color}} title={rowData.mensagem}  />
                </div>
            </div>
        );
    }

	vendedor = (rowData) => {
		return <div>
			<div style={{textAlign: "center", marginTop: "2px"}}>{rowData.vendedorId} - {rowData.vendedorNome}</div>
		</div>
	}

	selectPedido = (pedidoId) => {
        this.vwPedidoService.completar({pedidoId: pedidoId, empresaFilialId: 27300001}).then((response) => {
            ModalRealm.showDialog(<EditarPedido key={TZMUserData.getIdGenerator()}  pedido={response}  />);
        });
	}

	columns = [
		<Column sortable header="Razão Social" key="razaoSocial" field="razaoSocial" style={{width: "*"}} body={(rowData) => ClienteUtils.clienteTipoNome(rowData)}/>,
		<Column sortable  header="Nome Fantasia" key="nomeFantasia" field="nomeFantasia" style={{width: "15em"}} />,
		<Column sortable  body={this.vendedor} field="vendedorNome" key="vendedorNome" header="Vendedor" style={{width: "25em", textAlign: "center"}} />,
		<Column 
			sortable  
			key="dataPedido" 
			style={{ width: "8em"}} 
			field="dataPedido" 
			header="Data do Pedido" 
			body={(rowData) => {
				return <div style={{ textAlign: "center" }}>{toDate(rowData["dataPedido"])}</div>;
			}} 
		/>,
		<Column sortable  key="pedidoId" style={{ textAlign: "center", width: "6em" }} field="pedidoId" header="Pedido" />,
		<Column 
			key="itemId" 
			style={{ textAlign: "center", width: "4em" }} 
			field="itemId" 
			header="Item" 
		/>,
		<Column 
			sortable  
			key="valorUnitarioTabela" 
			style={{ width: "8em" }} 
			field="valorUnitarioTabela" 
			header="Vlr. Unitário da Lista de Preço" 
			body={(rowData) => {
				return <div style={{ textAlign: "right" }}>{toCurrencyFormatterPrecision(rowData["valorUnitarioTabela"])}</div>;
			}} 
		/>,
		<Column 
			sortable  
			key="valorUnitario" 
			style={{ width: "9em" }} 
			field="valorUnitario" 
			header="Vlr. Unitário Praticado" 
			body={this.showValorUnitario} 
		/>,
		<Column sortable  key="quantidadeComprada" style={{ textAlign: "center", width: "6em" }} field="quantidadeComprada" header="Quantidade" />,
		<Column 
			sortable  
			key="percentualIcms" 
			style={{ width: "4em " }} 
			field="percentualIcms" 
			header="ICMS %" 
			body={(rowData) => {
				return <div style={{ textAlign: "right" }}>{toPercent(rowData["percentualIcms"])}</div>;
			}} 
		/>,
		<Column 
			sortable 
			key="valorTotalItem" 
			style={{ width: "9em" }} 
			field="valorTotalItem" 
			header="Valor Total" 
			body={(rowData) => {
				return <div style={{ textAlign: "right" }}>{toCurrencyFormatterPrecision(rowData["valorTotalItem"])}</div>;
			}} 
		/>,
		<Column style={{ width: "5em", textAlign: "center" }} key="acoes" header="Ações" body={(rowData) => {                                                                
			return (
				<div>												
					<span key={0} title="Visualizar Pedido" className="fa fa-desktop ui-tzm-icon" onClick={() => this.selectPedido(rowData.pedidoId)} />                                        
				</div>
			);
		}} />

	]

	render() {		
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-7">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">
									<div className="ui-g-4">
										<TZMCalendar label="Data do Pedido" placeholder="Inicial" onChange={(event) => this.handleUpdateParams(event, "dataPedido", 0)} value={this.state.params.dataPedido[0]} />
									</div>
									<div className="ui-g-4">
										<TZMCalendar label="&nbsp;" placeholder="Final" onChange={(event) => this.handleUpdateParams(event, "dataPedido", 1)} value={this.state.params.dataPedido[1]} />
									</div>
									<div className="ui-g-1">
										<TZMCombobox value={this.state.params.uf} label="UF" onChange={(event) => this.handleUpdate("uf", event)}>{UF.list}</TZMCombobox>
									</div>
									<div className="ui-g-12 ui-g-nopad" >
										<div className="ui-g-11">
											<TZMAutoComplete itemTemplate={this.clienteTemplate} onClear={this.clearAutoComplete} name="cliente"
												field="descricaoAmigavel" onSelect={this.selectAutoComplete} suggestions={this.state.listClientes}
												completeMethod={this.listClientes} placeholder="Razão social, fantasia ou CNPJ" onChange={this.queryAutoComplete}
												value={this.state.params.cliente.descricaoAmigavel} label="Cliente" />                                        
										</div>
										<div className="ui-g-1 center-button">
											<TZMButton id="warehouse1" title={this.state.filtrarGrupo ? "Filtrar apenas o cliente selecionado" : "Filtrar todos os clientes do mesmo Grupo Empresarial"}
													style={{ float: "left", color: this.state.filtrarGrupo ? '#4CAF50' : '#607D8B' }} onClick={this.toggleGrupo} secondary="true"
													icon={this.state.filtrarGrupo ?  "fas fa-users " : "fas fa-user-tie"} />								
										</div>    
									</div>
									{this.renderSuggestion()}
									<div className="ui-g-12">
										<span className="ui-fluid">
											<TZMAutoComplete itemTemplate={Templates.vendedorTemplateOmega} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
													suggestions={this.state.vendedores} dropdown completeMethod={this.listarVendedores} name="vendedor" field="descricaoAmigavel" 
													disabled={this.state.fixedSeller} label="Vendedor" placeholder="Código Omega, E-mail ou Nome" onUnselect={this.handleUnselect} 
													value={this.state.regional.vendedor} />
										</span>	
									</div>
								</div>
								<TZMPanelFooter>
									<TZMButton icon="fa fa-search" label="Procurar" className="ui-button-primary" onClick={this.handleList} />
									{/* <TZMButton icon={this.state.loading ? `fa fa-spin fa-circle-notch` : `fa fa-print`} disabled={this.state.loading} label="Imprimir" className="ui-button-primary" onClick={this.imprimir} /> */}
									{/* <Button icon={this.state.loading ? `fa fa-spin fa-circle-notch` : `fa fa-print`} disabled={this.state.loading} label="CSV" className="ui-button-primary" onClick={this.csv} />  */}
								</TZMPanelFooter>

							</TZMForm>
						</TZMPanel>

					</div>

					<div className="ui-g-5">
						<TZMPanel >
							<ResponsiveContainer height={Math.round(this.state.windowSize) / 2.1} width="100%">
								<PieChart onMouseEnter={this.onPieEnter} >
									<Pie data={this.state.grafico} animationEasing='ease-out' dataKey='quantidade'
										nameKey='descricao'
										isAnimationActive={true}
										legendType='square'
										onClick={this.onPieClick}
										label={this.renderCustomizedLabel} fill="#8884d8">
										{this.state.grafico.map((entry, index) => 
											<Cell key={Math.random()} fill={this.COLORS[entry.ordenador-1 % this.COLORS.length]} />
										)}
									</Pie>
									<Tooltip content={this.customTooltip} />
									<Legend layout='vertical' align='right' verticalAlign='middle' />
								</PieChart>
							</ResponsiveContainer>
						</TZMPanel>
					</div>
					<div className="ui-g-12">
						<TZMPanel>
							<DataTable emptyMessage="Nenhum registro encontrado" value={this.state.vendas} 
								rows={30} rowsPerPageOptions={[30, 60, 100]} paginator={true} pageLinkSize={10} >
									{this.columns}
							</DataTable>
						</TZMPanel>
					</div>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}

}
