import { Fetch } from "../../utilities/Fetch";
import { httpGetAsync } from "../../utilities/TZMRequest";

export class PainelCACService {

	pedidoOperador() {
		return Fetch.GetJSON("/painel-cac/operador");
	}
	
	pedidoVendedor() {
		return Fetch.GetJSON("/painel-cac/vendedor");
	}

	monitorTotais() {
		return Fetch.GetJSON("/painel-cac/monitor-totais");
	}

	detalhesOperador  = async (operador) => {
		return  httpGetAsync(`/painel-cac/operador/detalhe/${operador}`);
	}

	detalhesVendedor  = async (vendedor) => {
		return  httpGetAsync(`/painel-cac/vendedor/detalhe/${vendedor}`);
	}

	detalhesBloqueado  = async () => {
		return  httpGetAsync("/painel-cac/detalhes-bloqueado");
	}

}
