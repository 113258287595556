import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMForm from "../../../components/common/TZMForm";
import "./Transportadora";
import { TransportadoraService } from "../servicos/TransportadoraService";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMUserData from "../../../utilities/TZMUserData";

export class EditarTransportadora extends React.Component {

	constructor(props) {
		super();		
		this.state = {
			visible: true,			
			transportadora: props.transportadora
		};		
		this.transportadoraService = new TransportadoraService();
	}	

	onHide = () => this.setState({visible: false});

	handleChange = (event) => {
		let transportadora = this.state.transportadora;
		transportadora[event.name] = event.value;
		this.setState({transportadora});
	}

	salvar = () => {
		this.transportadoraService.salvar(this.state.transportadora).then((transportadora) => {
			if (this.props.onModalClose) this.props.onModalClose(transportadora);
			this.onHide();
		});
	}

    render() {
		return (
			<TZMDialog style={{width: "1000px"}} visible={this.state.visible} modal header="Transportadora" onHide={this.onHide} {...this.props}>
				<TZMPanel>
					<TZMForm>
						<div className="ui-g">
							<div className="ui-g-2">
								<TZMTextField label="Código" disabled name="transportadoraId" value={this.state.transportadora.vwTransportadora.pk.fornecedorId}  />
							</div>								
							<div className="ui-g-6">
								<TZMTextField label="Razão Social" disabled name="razaoSocial" value={this.state.transportadora.razaoSocial}  />
							</div>
							<div className="ui-g-5">
								<TZMTextField label="Nome Fantasia" disabled name="nomeFantasia" value={this.state.transportadora.nomeFantasia}  />
							</div>
							<div className="ui-g-3">
								<TZMTextField label="CNPJ" disabled name="cpfCnpjFormatado" value={this.state.transportadora.cnpjFormatado}  />
							</div>
							<div className="ui-g-2">
								<TZMTextField label="Inscrição Estadual" disabled name="inscricaoEstadual" value={this.state.transportadora.vwTransportadora.inscricaoEstadual}  />
							</div>
							<div className="ui-g-2">
							</div>
							<div className="ui-g-4">
								<TZMTextField label="Logradouro" disabled name="logradouro" value={this.state.transportadora.vwTransportadora.logradouro}  />									
							</div>
							<div className="ui-g-2">
								<TZMTextField label="Complemento" disabled name="complemento" value={this.state.transportadora.vwTransportadora.complemento}  />									
							</div>
							<div className="ui-g-2">
								<TZMTextField label="CEP" disabled name="cep" value={this.state.transportadora.vwTransportadora.cep}  />									
							</div>
							<div className="ui-g-2">
								<TZMTextField label="Bairro" disabled name="bairro" value={this.state.transportadora.vwTransportadora.bairro}  />									
							</div>
							<div className="ui-g-4">
								<TZMTextField label="Cidade" disabled name="cidade" value={this.state.transportadora.vwTransportadora.localidade}  />									
							</div>
							<div className="ui-g-2">
								<TZMTextField label="Estado" disabled name="estado" value={this.state.transportadora.vwTransportadora.estado}  />									
							</div>
							<div className="ui-g-2">
								<TZMTextField label="Contato" disabled name="contato" value={this.state.transportadora.vwTransportadora.contato}  />									
							</div>
							<div className="ui-g-4"/>
							<div className="ui-g-2">
								<TZMTextField label="Telefone 1" disabled name="telefone1" value={this.state.transportadora.vwTransportadora.telefone1}  />									
							</div>
							<div className="ui-g-2">
								<TZMTextField label="Telefone 2" disabled name="telefone2" value={this.state.transportadora.vwTransportadora.telefone2}  />									
							</div>
							<div className="ui-g-2">
								<TZMTextField label="Telefone 3" disabled name="telefone3" value={this.state.transportadora.vwTransportadora.telefone3}  />									
							</div>
						</div>

					</TZMForm>	
				</TZMPanel>
				<TZMPanelFooter>
					{TZMUserData.hasRole("FRETE_TRANS_E") ?  <TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvar} /> : null}
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onHide} />
				</TZMPanelFooter>				
			</TZMDialog>
		);
	}

}
