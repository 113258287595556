import React from "react";
import TZMButton from "../components/common/TZMButton";
import TZMIcon from "../components/common/TZMIcon";
import TZMDialog from "../components/dialog/TZMDialog";
import TZMPanelFooter from "../components/panel/TZMPanelFooter";
import TZMTabPanel from "../components/panel/TZMTabPanel";
import TZMTabView from "../components/panel/TZMTabView";
import TZMShortcut from "../utilities/TZMShortcut";
import "./Pages.css";



export default class CheatSheet extends React.Component {

	constructor() {
		super();
		this.state = {
			visible: true
		};
	}

	fecharCheatSheet = () => {
		this.setState({visible: false});
	}

	selectTabIndex = (index) => {
		this.setState({selectedTab: index});
	}

	render() {
		return (
			<TZMDialog visible={this.state.visible} style={{width: "800px"}}modal={true} header="Tabela de Atalhos" {...this.props}>
				<TZMTabView activeIndex={this.state.selectedTab}>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Atalhos Gerais</span>}>
						<div className="ui-g">
							<div className="ui-g-1 ui-g-np ui-g-ct"><TZMIcon name="search" /></div>
							<div className="ui-g-2 ui-g-np">
								<div className="ui-sidebar-link-cheatsheet" style={{color: "#FFFFFF", backgroundColor: "#2399e5", width: "55px"}}>Enter</div>
							</div>
							<div className="ui-g-3 ui-g-np">Procurar registros</div>
							<div className="ui-g-6 ui-g-np">Executa a busca padrão da tela selecionada</div>
							<div className="ui-g-1 ui-g-np ui-g-ct"><TZMIcon name="plus" /></div>
							<div className="ui-g-2 ui-g-np">
								<div className="ui-sidebar-link-cheatsheet" style={{color: "#FFFFFF", backgroundColor: "#EF6C00"}}>N</div>
								<div className="ui-sidebar-link-cheatsheet" style={{color: "#FFFFFF", backgroundColor: "#EF6C00"}}>Alt</div>
								<div className="ui-sidebar-link-cheatsheet" style={{color: "#FFFFFF", backgroundColor: "#EF6C00"}}>Ctrl</div>
							</div>
							<div className="ui-g-3 ui-g-np">Criar novo registro</div>
							<div className="ui-g-6 ui-g-np">Adiciona um novo registro para edição</div>
							<div className="ui-g-1 ui-g-np ui-g-ct"><TZMIcon name="folder-open" /></div>
							<div className="ui-g-2 ui-g-np">
								<div className="ui-sidebar-link-cheatsheet" style={{color: "#FFFFFF", backgroundColor: "#03A9F4"}}>0</div>
								<div style={{float: "right", margin: "0 4px", color: "#9E9E9E"}}><TZMIcon name="ellipsis-h" /></div>
								<div className="ui-sidebar-link-cheatsheet" style={{color: "#FFFFFF", backgroundColor: "#03A9F4"}}>1</div>
								<div className="ui-sidebar-link-cheatsheet" style={{color: "#FFFFFF", backgroundColor: "#03A9F4"}}>Alt</div>
							</div>
							<div className="ui-g-3 ui-g-np">Alterna abas</div>
							<div className="ui-g-6 ui-g-np">Alterna entre as abas numeradas de um diálogo</div>
							<div className="ui-g-1 ui-g-np ui-g-ct"><TZMIcon name="save" /></div>
							<div className="ui-g-2 ui-g-np">
								<div className="ui-sidebar-link-cheatsheet" style={{color: "#FFFFFF", backgroundColor: "#4cae4c"}}>S</div>
								<div className="ui-sidebar-link-cheatsheet" style={{color: "#FFFFFF", backgroundColor: "#4cae4c"}}>Ctrl</div>
							</div>
							<div className="ui-g-3 ui-g-np">Salvar registro</div>
							<div className="ui-g-6 ui-g-np">Grava o registro do painel selecionado</div>
							<div className="ui-g-1 ui-g-np ui-g-ct"><TZMIcon name="close" /></div>
							<div className="ui-g-2 ui-g-np">
								<div className="ui-sidebar-link-cheatsheet" style={{color: "#000000", backgroundColor: "#ffffff"}}>Esc</div>
							</div>
							<div className="ui-g-3 ui-g-np">Fechar diálogo</div>
							<div className="ui-g-6 ui-g-np">Encerra o painel que estiver aberto no momento</div>
							<div className="ui-g-1 ui-g-np ui-g-ct"><TZMIcon name="eye" /></div>
							<div className="ui-g-2 ui-g-np">
								<div className="ui-sidebar-link-cheatsheet" style={{color: "#000000", backgroundColor: "#ffffff"}}>H</div>
								<div className="ui-sidebar-link-cheatsheet" style={{color: "#000000", backgroundColor: "#ffffff"}}>Ctrl</div>
							</div>
							<div className="ui-g-3 ui-g-np">Alternar desativados</div>
							<div className="ui-g-6 ui-g-np">Liga/desliga a visualização de registros desativados</div>
							<div className="ui-g-1 ui-g-np ui-g-ct"><TZMIcon name="sign-out-alt" /></div>
							<div className="ui-g-2 ui-g-np">
								<div className="ui-sidebar-link-cheatsheet" style={{color: "#FFFFFF", backgroundColor: "#d9534f"}}>Q</div>
								<div className="ui-sidebar-link-cheatsheet" style={{color: "#FFFFFF", backgroundColor: "#d9534f"}}>Ctrl</div>
							</div>
							<div className="ui-g-3 ui-g-np">Sair do sistema</div>
							<div className="ui-g-6 ui-g-np">Executa a saída (logout) do sistema</div>
						</div>
					</TZMTabPanel>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Atalhos de Funções</span>}>
						<div className="ui-g">
							<div className="ui-g-1 ui-g-np">
								<div className="ui-sidebar-link-cheatsheet" style={{color: "#000000", backgroundColor: "#ffffff"}}>F1</div>
							</div>
							<div className="ui-g-2 ui-g-np">Menu principal</div>
							<div className="ui-g-1 ui-g-np">
								<div className="ui-sidebar-link-cheatsheet" style={{color: "#000000", backgroundColor: "#ffffff"}}>F1</div>
								<div className="ui-sidebar-link-cheatsheet" style={{color: "#000000", backgroundColor: "#ffffff"}}>Ctrl</div>
							</div>
							<div className="ui-g-2 ui-g-np">Tabela de Atalhos</div>
							<div className="ui-g-1 ui-g-np">
								<div className="ui-sidebar-link-cheatsheet" style={{color: "#000000", backgroundColor: "#ffffff"}}>F2</div>
							</div>							
							<div className="ui-g-2 ui-g-np">Pedidos</div>
						</div>
					</TZMTabPanel>
				</TZMTabView>
				<TZMPanelFooter>
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.fecharCheatSheet} />
				</TZMPanelFooter>
				<TZMShortcut
					active={this.state.visible}
					onEscape={this.fecharCheatSheet}
					onAlt1={() => this.selectTabIndex(0)}
					onAlt2={() => this.selectTabIndex(1)}
				/>
			</TZMDialog>
		);
	}

}
