import { httpGetAsync , httpPost} from "../../../utilities/TZMRequest";

export class AcompanhamentoDiarioNovoService {

    salvarAcompanhamentoDiarioConfig  = async (data) => {
        console.log(data)
        return httpPost("/acompanhamento-config/emails", data);
    }

    acompanhamentoDiarioConfig  = async (params) => {
        return httpGetAsync("/acompanhamento-config/emails/singleResult", params);
    }
}