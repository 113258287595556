import React from "react";
import TZMTextFieldPainel from "../../../components/common/TZMTextFieldPainel";
import TZMMainContent from "../../../components/main/TZMMainContent";
import TZMTabView from "../../../components/panel/TZMTabView";
import { TZMMenubarInfo } from "../../../components/menubar/TZMMenubarInfo";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import { Fetch } from "../../../utilities/Fetch";
import TZMUserData from "../../../utilities/TZMUserData";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMColumnPainel from "../../../components/table/TZMColumnPainel";
import { toCurrency, toDecimal,  toDecimalMathRoundNull} from "../../../components/common/TZMFormatter";
import { Tooltip } from "primereact/components/tooltip/Tooltip";
import TZMTable from "../../../components/table/TZMTable";
import { PainelCACDetalheNovo } from "./PainelCACDetalheNovo";
import { PainelCACNovoService } from "../servicos/PainelCACNovoService";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;
const title = "Painel CAC";

export default class PainelCACNovo extends React.Component {

	constructor() {
		super();
		this.state = {
			countdown: 0, 
			familiasMoeda:[],
			familiasVolume:[],
			operadores:[],
			vendedores:[],
			painelCac:{
				budgetAtingido:0,
				forecastAtingido: 0,
				budgetAtingidoLocal:0,
				budgetAtingidoExportacao:0,
				budgetAtingidoOfficePimaco:0,
				budgetAtingidoIntercompany:0
			}
		};
		this.painelCACNovoService = new PainelCACNovoService();
		this.reloadPreview = this.reloadPreview.bind(this);
	}

	monthFormatter = new Intl.DateTimeFormat("pt-BR", { month: "long" });

	monthName = (monthIndex) => {
		let now = new Date(); 
		now.setMonth(now.getMonth() + monthIndex);
		return this.monthFormatter.format(now);
	}

	reloadInterval = null;

	componentDidMount() {
		if (TZMUserData.isAutenticado()) {
			Fetch.Get("/ping");
			this.realoadInterval = setInterval(this.reloadPreview, 1000);
		}
	}

	componentWillUnmount() {
		clearInterval(this.realoadInterval);
	}

	async reloadPreview() {
		if (this.state.countdown > 1) {
			this.setState({ countdown: this.state.countdown - 1 });
		} else {
			this.setState({ countdown: 60 });			
			await this.painelCACNovoService.familiasVolume().then((response) => {
				this.setState({ familiasVolume: response });
			});

			await this.painelCACNovoService.familiasMoeda().then((response) => {
				this.setState({ familiasMoeda: response });
			});

			await this.painelCACNovoService.operadores().then((response) => {
				this.setState({ operadores: response });
			});	

			await this.painelCACNovoService.vendedores().then((response) => {
				this.setState({ vendedores: response });
			});	

			await this.painelCACNovoService.lancado().then((response) => {
				this.setState({ painelCac: response });
			});
		}
	}

	operadores = [
		<TZMColumnPainel body={(rowData, column) => 
			<div style={{ cursor: "pointer" ,fontSize: "25px", fontWeight: "bold" }} onClick={() => this.detalhesOperador(rowData.operador)}>
				{rowData["operador"]}
			</div>} field="operador" key="operador" style={{ width: "*" }} header={<span style={{ fontSize: "20px" }}>Pedidos por Operador</span>} />,
		<TZMColumnPainel body={(rowData, column) => <div style={{ textAlign: "right", fontSize: "25px", fontWeight: "bold" }}>{toDecimal(Math.round(rowData["quantidade"]))}</div>} field="quantidade" key="volume" style={{ width: "10em" }} header={<span style={{ fontSize: "20px" }}>Volume m2</span>} />,
		<TZMColumnPainel body={(rowData, column) => <div style={{ textAlign: "right", fontSize: "25px", fontWeight: "bold" }}>{rowData["precoMedio"].toCurrency()}</div>} field="precoMedio" key="precoMedio" header={<div id="preco" children="Preço Médio" style={{ fontSize: "20px" }} />} style={{ width: "10em" }} />
	];

	vendedores = [
		<TZMColumnPainel body={(rowData, column) =>
			<div title={`Código do Vendedor: ` + rowData["vendedorId"]}  onClick={() => this.detalhesVendedor(rowData.vendedorId, rowData.nome)} style={{ cursor: "pointer" , fontSize: "25px", fontWeight: "bold" }}>
				{rowData["nome"]}
			</div>}
			field="nome" key="nome" header={<span style={{ fontSize: "20px" }} > Pedidos por Vendedor</span>} style={{ width: "*" }} />,

		<TZMColumnPainel body={(rowData, column) => <div style={{ textAlign: "right", fontSize: "25px", fontWeight: "bold" }}>{toDecimal(Math.round(rowData["quantidade"]))}</div>} field="quantidade" key="volume" header={<span style={{ fontSize: "20px" }}>Volume m2</span>} style={{ width: "10em" }} />,
	];

	detalhesVendedor = (vendedorId, vendedorNome) => {
		this.painelCACNovoService.detalhesVendedor(vendedorId).then((response) => {
            let lista = response;
			ModalRealm.showDialog(<PainelCACDetalheNovo key={TZMUserData.getIdGenerator()} bloqueado={false} header={`Detalhes por Vendedor  - ${vendedorId + ' - ' + vendedorNome}`} listDetalhes={lista}  />);   
        });        
    }

	detalhesBloqueado = () => {
		this.painelCACNovoService.detalhesBloqueado().then((response) => {
            let lista = response;
			ModalRealm.showDialog(<PainelCACDetalheNovo key={TZMUserData.getIdGenerator()} bloqueado={true} header="Detalhes Bloqueado" listDetalhes={lista}/>);   
        });        
    }

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-8 ui-g-nopad">
						<TZMTabView >
							<TZMTabPanel header="Pedidos por Vendedor / Operador">
								<div className="ui-g">
									<div className="ui-g-6">
										<Tooltip for="#preco" title="Preço Médio = (quantidade * valor unitário) - ICM / valor m2 " tooltipPosition="right" />
										<TZMTable rows={20} paginator value={this.state.operadores}>
											{this.operadores}
										</TZMTable>
									</div>
									<div className="ui-g-6">
										<TZMTable rows={20} paginator value={this.state.vendedores}>
											{this.vendedores}
										</TZMTable>
									</div>
								</div>
							</TZMTabPanel>

							<TZMTabPanel header="Totais por Grupo (Volume)">
								<div className="ui-g">
									<div className="ui-g-12">
										<TZMPanel>
											<div className="ui-g" style={{ textAlign: "center" }}>
												<div
													style={{
														display: "flex",
														justifyContent: "center",
														width: "100%",
														backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
														padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
														borderBottom: "3px solid #77B72A",   // Borda inferior
														boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",  // Sombra
													}}
												>
													<h2
														style={{
															fontSize: "30px",
															fontWeight: "bold",
															color: "#77B72A",
															margin: "0",                 // Remove margem extra do h2
															textAlign: "center"
														}}
													>
														Nacional
													</h2>
												</div>
												<div className="ui-g" style={{ marginTop: "10px" }}>
													{this.state.familiasVolume
														.filter(familia => familia.nacionalidade === "NACIONAL")
														.map((familia) => (
															<React.Fragment key={familia.familiaId}>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }}
																		label={`${familia.familiaDescricao} Fat`}
																		value={toDecimalMathRoundNull(familia.faturado)} 
																	/>
																</div>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }}
																		label={`${familia.familiaDescricao} Cart`}
																		value={toDecimalMathRoundNull(familia.carteira)} 
																	/>
																</div>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }}
																		label={`${familia.familiaDescricao} Bloq`}
																		value={toDecimalMathRoundNull(familia.bloqueado)} 
																	/>
																</div>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", padding: "0", fontWeight: "bold", textAlign: "center" }}
																		label={`${familia.familiaDescricao} Total`}
																		value={toDecimalMathRoundNull(familia.total)}
																	/>
																</div>
															</React.Fragment>
														))}
												</div>
											</div>
										</TZMPanel>

										<div style={{ height: "7px" }} />
										<TZMPanel>
											<div className="ui-g" style={{ textAlign: "center" }}>
												{/* Envolva o título em uma div com flexbox para centralizar */}
												<div
													style={{
														display: "flex",
														justifyContent: "center",
														width: "100%",
														backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
														padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
														borderBottom: "3px solid #FF9800",   // Borda inferior
														boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",  // Sombra
													}}
												>
													<h2
														style={{
															fontSize: "30px",
															fontWeight: "bold",
															color: "#FF9800",
															margin: "0",                 // Remove margem extra do h2
															textAlign: "center"
														}}
													>
														Exportação
													</h2>
												</div>
												<div className="ui-g" style={{ marginTop: "10px" }}>
													{this.state.familiasVolume
														.filter(familia => familia.nacionalidade === "EXPORTACAO")
														.map((familia) => (
															<React.Fragment key={familia.familiaId}>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }}
																		label={`${familia.familiaDescricao} Fat`}
																		value={toDecimalMathRoundNull(familia.faturado)} 
																	/>
																</div>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }}
																		label={`${familia.familiaDescricao} Cart`}
																		value={toDecimalMathRoundNull(familia.carteira)} 
																	/>
																</div>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }}
																		label={`${familia.familiaDescricao} Bloq`}
																		value={toDecimalMathRoundNull(familia.bloqueado)} 
																	/>
																</div>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", padding: "0", fontWeight: "bold", textAlign: "center" }}
																		label={`${familia.familiaDescricao} Total`}
																		value={toDecimalMathRoundNull(familia.total)}
																	/>
																</div>
															</React.Fragment>
														))}
												</div>
											</div>
										</TZMPanel>
										<div style={{ height: "7px" }} />
										<TZMPanel>
											<div className="ui-g" style={{ textAlign: "center" }}>
												{/* Envolva o título em uma div com flexbox para centralizar */}
												<div
													style={{
														display: "flex",
														justifyContent: "center",
														width: "100%",
														backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
														padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
														borderBottom: "3px solid #4286f4",   // Borda inferior
														boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",  // Sombra
													}}
												>
													<h2
														style={{
															fontSize: "30px",
															fontWeight: "bold",
															color: "#4286f4",
															margin: "0",                 // Remove margem extra do h2
															textAlign: "center"
														}}
													>
														Intercompany
													</h2>
												</div>
												<div className="ui-g" style={{ marginTop: "10px" }}>
													{this.state.familiasVolume
														.filter(familia => familia.nacionalidade === "INTERCOMPANY")
														.map((familia) => (
															<React.Fragment key={familia.familiaId}>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }}
																		label={`${familia.familiaDescricao} Fat`}
																		value={toDecimalMathRoundNull(familia.faturado)} 
																	/>
																</div>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }}
																		label={`${familia.familiaDescricao} Cart`}
																		value={toDecimalMathRoundNull(familia.carteira)} 
																	/>
																</div>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }}
																		label={`${familia.familiaDescricao} Bloq`}
																		value={toDecimalMathRoundNull(familia.bloqueado)} 
																	/>
																</div>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", padding: "0", fontWeight: "bold", textAlign: "center" }}
																		label={`${familia.familiaDescricao} Total`}
																		value={toDecimalMathRoundNull(familia.total)}
																	/>
																</div>
															</React.Fragment>
														))}
												</div>
											</div>
										</TZMPanel>
									</div>
								</div>
							</TZMTabPanel>
							<TZMTabPanel header="Totais por Grupo (Moeda)">
								<div className="ui-g">
									<div className="ui-g-12">
										<TZMPanel>
											<div className="ui-g" style={{ textAlign: "center" }}>
												<div
													style={{
														display: "flex",
														justifyContent: "center",
														width: "100%",
														backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
														padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
														borderBottom: "3px solid #77B72A",   // Borda inferior
														boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",  // Sombra
													}}
												>
													<h2
														style={{
															fontSize: "30px",
															fontWeight: "bold",
															color: "#77B72A",
															margin: "0",                 // Remove margem extra do h2
															textAlign: "center"
														}}
													>
														Nacional
													</h2>
												</div>
												<div className="ui-g" style={{ marginTop: "10px" }}>
													{this.state.familiasMoeda
														.filter(familia => familia.nacionalidade === "NACIONAL")
														.map((familia) => (
															<React.Fragment key={familia.familiaId}>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }}
																		label={`${familia.familiaDescricao} Fat`}
																		value={toCurrency(familia.faturado)} 
																	/>
																</div>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }}
																		label={`${familia.familiaDescricao} Cart`}
																		value={toCurrency(familia.carteira)} 
																	/>
																</div>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }}
																		label={`${familia.familiaDescricao} Bloq`}
																		value={toCurrency(familia.bloqueado)} 
																	/>
																</div>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", padding: "0", fontWeight: "bold", textAlign: "center" }}
																		label={`${familia.familiaDescricao} Total`}
																		value={toCurrency(familia.total)}
																	/>
																</div>
															</React.Fragment>
														))}
												</div>
											</div>
										</TZMPanel>

										<div style={{ height: "7px" }} />
										<TZMPanel>
											<div className="ui-g" style={{ textAlign: "center" }}>
												{/* Envolva o título em uma div com flexbox para centralizar */}
												<div
													style={{
														display: "flex",
														justifyContent: "center",
														width: "100%",
														backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
														padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
														borderBottom: "3px solid #FF9800",   // Borda inferior
														boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",  // Sombra
													}}
												>
													<h2
														style={{
															fontSize: "30px",
															fontWeight: "bold",
															color: "#FF9800",
															margin: "0",                 // Remove margem extra do h2
															textAlign: "center"
														}}
													>
														Exportação
													</h2>
												</div>
												<div className="ui-g" style={{ marginTop: "10px" }}>
													{this.state.familiasMoeda
														.filter(familia => familia.nacionalidade === "EXPORTACAO")
														.map((familia) => (
															<React.Fragment key={familia.familiaId}>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }}
																		label={`${familia.familiaDescricao} Fat`}
																		value={toCurrency(familia.faturado)} 
																	/>
																</div>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }}
																		label={`${familia.familiaDescricao} Cart`}
																		value={toCurrency(familia.carteira)} 
																	/>
																</div>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }}
																		label={`${familia.familiaDescricao} Bloq`}
																		value={toCurrency(familia.bloqueado)} 
																	/>
																</div>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", padding: "0", fontWeight: "bold", textAlign: "center" }}
																		label={`${familia.familiaDescricao} Total`}
																		value={toCurrency(familia.total)}
																	/>
																</div>
															</React.Fragment>
														))}
												</div>
											</div>
										</TZMPanel>
										<div style={{ height: "7px" }} />
										<TZMPanel>
											<div className="ui-g" style={{ textAlign: "center" }}>
												{/* Envolva o título em uma div com flexbox para centralizar */}
												<div
													style={{
														display: "flex",
														justifyContent: "center",
														width: "100%",
														backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
														padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
														borderBottom: "3px solid #4286f4",   // Borda inferior
														boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",  // Sombra
													}}
												>
													<h2
														style={{
															fontSize: "30px",
															fontWeight: "bold",
															color: "#4286f4",
															margin: "0",                 // Remove margem extra do h2
															textAlign: "center"
														}}
													>
														Intercompany
													</h2>
												</div>
												<div className="ui-g" style={{ marginTop: "10px" }}>
													{this.state.familiasMoeda
														.filter(familia => familia.nacionalidade === "INTERCOMPANY")
														.map((familia) => (
															<React.Fragment key={familia.familiaId}>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }}
																		label={`${familia.familiaDescricao} Fat`}
																		value={toCurrency(familia.faturado)} 
																	/>
																</div>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }}
																		label={`${familia.familiaDescricao} Cart`}
																		value={toCurrency(familia.carteira)} 
																	/>
																</div>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }}
																		label={`${familia.familiaDescricao} Bloq`}
																		value={toCurrency(familia.bloqueado)} 
																	/>
																</div>
																<div className="ui-g-3">
																	<TZMTextFieldPainel 
																		readOnly  
																		style={{ fontSize: "32px", height: "35px", padding: "0", fontWeight: "bold", textAlign: "center" }}
																		label={`${familia.familiaDescricao} Total`}
																		value={toCurrency(familia.total)}
																	/>
																</div>
															</React.Fragment>
														))}
												</div>
											</div>
										</TZMPanel>
									</div>
								</div>
							</TZMTabPanel>							
						</TZMTabView>
					</div>	
					<div className="ui-g-4">
						<TZMPanel>
							<div className="ui-g" style={{ textAlign: "center" }}>
								<div className="ui-g-12">
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #2399E5",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",  
											fontSize: "40px", 
											height: "45px", 
											color: "#F44336", 
											fontWeight: "bold", 
											textAlign: "center" 
										}} 
										label="Lançado hoje para o mês" 
										value={toDecimalMathRoundNull(this.state.painelCac?.lancadoHojeMes)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{ 
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #2399E5",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center"
										 }} 
										label="Local" 
										value={toDecimalMathRoundNull(this.state.painelCac?.lancadoHojeMesNacional)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{ 
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #2399E5",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}}  
										label="Exportação" 
										value={toDecimalMathRoundNull(this.state.painelCac?.lancadoHojeMesExportacao)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{ 
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #2399E5",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}} 
										label="Intercompany" 
										value={toDecimalMathRoundNull(this.state.painelCac?.lancadoHojeMesInter)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{ 
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #2399E5",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}} 
										label="Office / Pimaco" 
										value={toDecimalMathRoundNull(this.state.painelCac?.lancadoHojeMesOfficePimaco)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #2399E5",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",  
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center",
											padding: "0" 
										}} 
										label="Lançado Hoje Mês" 
										value={toDecimalMathRoundNull(this.state.painelCac?.lancadoHojeMes)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #2399E5",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",  
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center", 
											padding: "0" 
										}} 
										label="Lançado hoje Fut" 
										value={toDecimalMathRoundNull(this.state.painelCac?.lancadoHojeFuturo)} />
								</div>
								<div className="ui-g-12">
									<TZMTextFieldPainel 
										readOnly  
										style={{ 
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #FF9800",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											fontSize: "40px", 
											height: "45px", 
											fontWeight: "bold", 
											textAlign: "center"
										}} 
										label="Lançado mês para o mês" 
										value={toDecimalMathRoundNull(this.state.painelCac?.lancadoMesMes)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #FF9800",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											fontSize: "32px", 
											height: "35px",
											textAlign: "center" 
										}} 
										label="Local" 
										value={toDecimalMathRoundNull(this.state.painelCac?.lancadoMesMesNacional)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{ 
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #FF9800",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
											fontSize: "32px", 
											height: "35px",
											textAlign: "center" 
										}} 
										label="Exportação" 
										value={toDecimalMathRoundNull(this.state.painelCac?.lancadoMesMesExportacao)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #FF9800",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}} 
										label="Intercompany" 
										value={toDecimalMathRoundNull(this.state.painelCac?.lancadoMesMesInter)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #FF9800",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}} 
										label="Office / Pimaco" 
										value={toDecimalMathRoundNull(this.state.painelCac?.lancadoMesMesOfficePimaco)} />
								</div>
								<div className="ui-g-12">
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #77B72A",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",  
											textAlign: "center", 
											fontSize: "32px", 
											height: "35px", 
											fontWeight: "bold" 
										}} 
										label="Carteira Futura" 
										value={toDecimalMathRoundNull(this.state.painelCac?.lancadoMesFuturo)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #77B72A",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}} 
										label="Local" 
										value={toDecimalMathRoundNull(this.state.painelCac?.lancadoMesFuturoNacional)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{ 
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #77B72A",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}} 
										label="Exportação" 
										value={toDecimalMathRoundNull(this.state.painelCac?.lancadoMesFuturoExportacao)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{ 
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #77B72A",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}} 
										label="Intercompany" 
										value={toDecimalMathRoundNull(this.state.painelCac?.lancadoMesFuturoInter)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{ 
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #77B72A",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}}
										label="Office / Pimaco" 
										value={toDecimalMathRoundNull(this.state.painelCac?.lancadoMesFuturoOfficePimaco)} />
								</div>
					
								<div className="ui-g-12">
									<TZMTextFieldPainel 
										readOnly  
										style={{ 
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #f44336",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
											textAlign: "center", 
											fontSize: "32px", 
											height: "35px", 
											fontWeight: "bold" 
										}} 
										label="CART + BLOQ" 
										value={toDecimalMathRoundNull(this.state.painelCac?.carteira + this.state.painelCac?.bloqueado)} />
								</div>																

								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{ 
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #f44336",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}} 
										label="Local" 
										value={toDecimalMathRoundNull(this.state.painelCac?.carteiraNacional + this.state.painelCac?.bloqueadoNacional)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{ 
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #f44336",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}} 
										label="Exportação" 
										value={toDecimalMathRoundNull(this.state.painelCac?.carteiraExportacao + this.state.painelCac?.bloqueadoExportacao)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{ 
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #f44336",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}} 
										label="Intercompany" 
										value={toDecimalMathRoundNull(this.state.painelCac?.carteiraInter + this.state.painelCac?.bloqueadoInter)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{ 
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #f44336",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}} 
										label="Office / Pimaco" 
										value={toDecimalMathRoundNull(this.state.painelCac?.carteiraOfficePimaco + this.state.painelCac?.bloqueadoOfficePimaco)} />
								</div>
							</div>
						</TZMPanel>
						<div style={{ height: "7px" }} />
						
						<TZMPanel>
							<div className="ui-g" style={{ padding: 0, textAlign: "center" }}>
								<div className="ui-g-12" title="Faturado no mês">
									<TZMTextFieldPainel
										readOnly  
										style={{ 
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #2399E5",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											textAlign: "center", 
											fontSize: "40px", 
											height: "45px", 
											color: "#4286f4", 
											fontWeight: "bold" 
										}}
										label="Faturado no mês" 
										value={toDecimalMathRoundNull(this.state.painelCac?.faturadoMes)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{ 
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #2399E5",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}} 
										label="Local" 
										value={toDecimalMathRoundNull(this.state.painelCac?.faturadoMesNacional)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #2399E5",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}} 
										label="Exportação" 
										value={toDecimalMathRoundNull(this.state.painelCac?.faturadoMesExportacao)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #2399E5",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",  
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}} 
										label="Intercompany" 
										value={toDecimalMathRoundNull(this.state.painelCac?.faturadoMesInter)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #2399E5",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",  
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}} 
										label="Office / Pimaco" 
										value={toDecimalMathRoundNull(this.state.painelCac?.faturadoMesOfficePimaco)} />
								</div>								

								<div className="ui-g-6" >
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #2399E5",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",  
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}} 
										onClick={() => this.detalhesBloqueado()} 
										label="Total Bloqueado" value={toDecimalMathRoundNull(this.state.painelCac?.bloqueado)} />
								</div>								
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #2399E5",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",  
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}}
										label="Total Liberado" 
										value={toDecimalMathRoundNull(this.state.painelCac?.totalLiberado)} />
								</div>
								<div className="ui-g-12">
									<TZMTextFieldPainel 
										readOnly
										style={{ 
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #FF9800",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											fontSize: "40px", 
											height: "45px", 
											fontWeight: "bold", 
											textAlign: "center",
											color: "#4286f4"
										}}
										label="Total Geral m2" 
										value={toDecimalMathRoundNull(this.state.painelCac?.totalGeral)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #FF9800",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											fontSize: "32px", 
											height: "35px",
											textAlign: "center" 
										}} 
										label="Local" 
										value={toDecimalMathRoundNull(this.state.painelCac?.totalGeralNacional)} />
								</div>

								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #FF9800",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											fontSize: "32px", 
											height: "35px",
											textAlign: "center" 
										}} 
										label="Exportação" 
										value={toDecimalMathRoundNull(this.state.painelCac?.totalGeralExportacao)} />										
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #FF9800",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											fontSize: "32px", 
											height: "35px",
											textAlign: "center" 
										}} 
										label="Intercompany" 
										value={toDecimalMathRoundNull(this.state.painelCac?.totalGeralInter)} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #FF9800",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											fontSize: "32px", 
											height: "35px",
											textAlign: "center" 
										}} 
										label="Office / Pimaco" 
										value={toDecimalMathRoundNull(this.state.painelCac?.totalGeralOfficePimaco)} />
								</div>

								
								<div className="ui-g-9">
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #77B72A",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
										 	textAlign: "center", 
											fontSize: "40px", 
											height: "45px", 
											fontWeight: "bold" 
										}} 
										label="Budget" 
										value={toDecimalMathRoundNull(this.state.painelCac?.budget)} />
								</div>
								
								<div className="ui-g-3">
									<TZMTextFieldPainel 
										readOnly   
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #77B72A",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											textAlign: "center", 
											fontSize: "40px", 
											height: "45px", 
											fontWeight: "bold" 
										}} 
										label=" % Budget" 
										value={toDecimal(this.state.painelCac?.budgetAtingido)} />
								</div>
								
								<div className="ui-g-9">
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #77B72A",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}} 
										label="Meta Local" 
										value={toDecimalMathRoundNull(this.state.painelCac?.budgetLocal)} />
								</div>
								
								<div className="ui-g-3">
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #77B72A",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
											textAlign: "center", 
											fontSize: "32px", 
											height: "35px" 
										}} 
										label="% Local" 
										value={toDecimal(this.state.painelCac?.budgetAtingidoLocal) } />
								</div>
								

								<div className="ui-g-9">
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #77B72A",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}} 
										label="Meta Intercompany" 
										value={toDecimalMathRoundNull(this.state.painelCac?.budgetIntercompany)} />
								</div>
							
								<div className="ui-g-3">
									<TZMTextFieldPainel 
										readOnly  
										style={{ 
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #77B72A",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											textAlign: "center", 
											fontSize: "32px", 
											height: "35px" 
										}} 
										label="% Intercompany" 
										value={toDecimal(this.state.painelCac?.budgetAtingidoIntercompany)} />
								</div>
								
								<div className="ui-g-9">
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #77B72A",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}} 
										label="Meta Exportação" 
										value={toDecimalMathRoundNull(this.state.painelCac?.budgetExportacao)} />
								</div>
							
								<div className="ui-g-3">
									<TZMTextFieldPainel 
										readOnly  
										style={{ 
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #77B72A",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											textAlign: "center", 
											fontSize: "32px", 
											height: "35px" 
										}} 
										label="% Intercompany" 
										value={toDecimal(this.state.painelCac?.budgetAtingidoExportacao)} />
								</div>


								
								<div className="ui-g-9">
									<TZMTextFieldPainel 
										readOnly  
										style={{ 
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #77B72A",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											fontSize: "32px", 
											height: "35px", 
											textAlign: "center" 
										}} 
										label="Meta Office / Pimaco" 
										value={toDecimalMathRoundNull(this.state.painelCac?.budgetOfficePimaco)} />
								</div>
								
								<div className="ui-g-3">
									<TZMTextFieldPainel 
										readOnly  
										style={{ 
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #77B72A",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											textAlign: "center", 
											fontSize: "32px", 
											height: "35px" 
										}} 
										label="% Office / Pimaco" 
										value={toDecimal(this.state.painelCac?.budgetAtingidoOfficePimaco)} />
								</div>
								
								<div className="ui-g-9">
									<TZMTextFieldPainel 
										readOnly  
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #77B72A",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											textAlign: "center", 
											fontSize: "40px", 
											height: "45px", 
											fontWeight: "bold" 
										}} 
										label="Forecast" 
										value={toDecimalMathRoundNull(this.state.painelCac?.forecast)} />
								</div>
								
								<div className="ui-g-3">
									<TZMTextFieldPainel 
										readOnly 
										style={{
											display: "flex",
											justifyContent: "center",
											width: "100%",
											backgroundColor: "#F0F8F5",          // Fundo na div para cobrir toda a largura
											padding: "2px 0",                    // Reduzido ainda mais o espaçamento vertical
											borderBottom: "3px solid #77B72A",   // Borda inferior
											boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", 
											textAlign: "center", 
											fontSize: "40px", 
											height: "45px", 
											fontWeight: "bold" 
										}} 
										label=" % Forecast" 
										value={toDecimal(this.state.painelCac?.forecastAtingido)} />
								</div>
							</div>
						</TZMPanel>
					</div>
					
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<TZMMenubarInfo countdown={this.state.countdown} />
				<ModalRealm />
			</TZMMainContent>
		);
	}
}
