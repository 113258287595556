import { Fetch } from "../../utilities/Fetch";
import { httpPost } from "../../utilities/TZMRequest";
import { EntidadeService } from "./EntidadeService";

export class ImagemService extends EntidadeService {

	salvar(imagem) {
		return httpPost("/imagens", imagem);
	}

	buscarImagemDoPedido(pedidoId) {
		return Fetch.Get(`/imagens/pedido/${pedidoId}`);
	}

}
