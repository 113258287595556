import TZMPopup from "../../components/dialog/TZMPopup";
import { ModalRealm } from "../../components/modal/ModalRealm";
import { httpGet, httpGetAsync } from "../../utilities/TZMRequest";
import { saveAs } from 'file-saver';
import TZMUserData from "../../utilities/TZMUserData";
import React from 'react';
const reportPath = process.env.REACT_APP_REPORT_URL;

export class LogLantekService {

    completar(pk) {        
		return httpGet(`/lantek/${pk}`);
	}

    listarSimples = async (params) => {
        return await httpGetAsync("/lantek/listar-simples", params, true);
    }

    gerarXML = async () => {
        return await httpGetAsync("/lantek/gerar-xml", null, true);
    }

    downloadXML = async (id, nome) => {        
        return await this.postReport(`/lantek/download-xml/${id}`, nome);
    }

    postReport = async (path, nome, dummy, message) => {
        let header = window.localStorage.getItem("X-Authorization-Key");
        await fetch(`${reportPath}${path}`, { method: "get", headers: { "Authorization": header, "Content-Type": "application/json" }, body: JSON.stringify(this.state) })
        .then((response) => {
            switch (response.status) {
                case 200:
                    response.blob().then((blob) => {
                        const file = new Blob([blob], {type: 'application/xml', });
                        const data = window.URL.createObjectURL(file);
                        saveAs(data, nome );
                    });
                    return response;
                case 404:
                    ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()} header="Advertência" messages={message} />);
                    return Promise.reject();
                default:
                    return Promise.reject();
            }
        }).catch((erro) => {
            console.log(erro);
        });
    }

}