import { Button } from 'primereact/components/button/Button';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import React from "react";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMButton from "../../../components/common/TZMButton";
import TZMCombobox from "../../../components/common/TZMCombobox";
import { TZMSuggestion } from '../../../components/common/TZMSuggestion';
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPopup from "../../../components/dialog/TZMPopup";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMColumn from '../../../components/table/TZMColumn';
import TZMTable from '../../../components/table/TZMTable';
import TZMShortcut from "../../../utilities/TZMShortcut";
import { GestorService } from "../../servicos/GestorService";
import { PerfilService } from '../../servicos/PerfilService';
import { UsuarioService } from "../../servicos/UsuarioService";
import { VendedorService } from '../../servicos/VendedorService';
import TZMUserData from '../../../utilities/TZMUserData';

export default class EditarUsuario extends React.Component {

	state = {
		usuario: this.completarNulos(this.props.usuario),
		visible: true,
		alterado: false,
		vendedores: []
	};

	usuarioService = new UsuarioService();
	vendedorService = new VendedorService();
	gestorService = new GestorService();
	perfilService = new PerfilService();

	handleUpdate = (key, event) => {
		const usuario = this.state.usuario;
		usuario[key] = event.target.value;
		this.setState({alterado: true, usuario});
	}

	handleUpdatePA = (event) => {
		const usuario = this.state.usuario;
		if (!usuario[event.name]) {
			usuario[event.name] = [];
		}
		usuario[event.name].push(event.value);
		this.setState({alterado: true, usuario});
	}

	handleUpdatePR = (event) => {
		const usuario = this.state.usuario;
		if (!usuario[event.name]) {
			usuario[event.name] = [];
		}
		usuario[event.name] = usuario[event.name].filter((e) => e.id !== event.value.id);
		this.setState({alterado: true, usuario});
	}

	completarNulos(usuario) {
		if (usuario.vendedor == null) {
			usuario.vendedor = {pk: {}, nome: " "};
		}
		if (usuario.supervisor == null) {
			usuario.supervisor = {pk: {}, nome: " "};
		}	
		if (usuario.gerente == null) {
			usuario.gerente = {pk: {}, nome: " "};
		}
		return usuario;
	}

	validarUsuario = () => {
		let messages = [];
		if (this.state.usuario.login == null || this.state.usuario.login.length < 1) {
			messages.push("O login é obrigatório");
		}
		if (this.state.usuario.id == null && (this.state.usuario.senha == null || this.state.usuario.senha.length < 1)) {
			messages.push("A senha é obrigatória");
		} else if (this.state.usuario.senha !== this.state.usuario.csenha) {
			messages.push("Os campos de senha não conferem");	
		}		
		if (this.state.usuario.nivel === TZMUserData.REPRESENTANTE && !this.state.usuario.representante) {
			messages.push("O representante é obrigatório.");
		}
				
		if (this.state.usuario.perfis.length === 0 ) { 
			messages.push("Selecione ao menos um perfil.");
		}
		
		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup header="Advertência" messages={messages} />);
			return false;
		}

		return true;
	}

	salvarUsuario = () => {
		if (this.validarUsuario()) {
			this.usuarioService.salvar(this.state.usuario).then(() => this.fecharPainel("onSave"));
		}
	}

	fecharPainel = (modalResponse) => {
		this.setState({visible: false});
		if (this.props[modalResponse]) {
			this.props[modalResponse](this.state.pedido);
		}
	}

	fecharUsuario = () => {
		if (this.state.alterado) {
			ModalRealm.showDialog(<TZMConfirm onYes={() => this.fecharPainel("onClose")} question="Fechar e descartar as alterações feitas?" />);
		} else {
			this.fecharPainel("onClose");
		}
	}

	reativarUsuario = () => {
		this.usuarioService.salvar({...this.state.usuario, ativado: true}).then(() => this.fecharPainel("onDelete"));
	}

	desativarUsuario = () => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
			this.usuarioService.excluir(this.state.usuario).then(() => this.fecharPainel("onDelete"));
		}} question="Tem certeza de que deseja desativar este usuário?" />);
	}

	listarVendedores = async (event) => {
		await this.vendedorService.autoCompletar({vendedor: event.query, sort:'nome'}).then((response) => {
			this.setState({vendedores: response});
		}); 
	}

	selectVendedores = (event) => {
		this.setState({usuario: {...this.state.usuario, vendedor: event.value}});
	}

	completarVendedor = (event) => {
		let usuario = this.state.usuario;
		usuario.vendedor = event.value;
		let pk = Object.assign({}, usuario.vendedor.pk);
		pk.vendedorId = usuario.vendedor.pk.supervisorId;
		this.vwVendedorService.buscar(pk).then((supervisor) => {
			usuario.supervisor = supervisor;
			pk.vendedorId = supervisor.pk.gerenteId;
			pk.supervisorId = supervisor.pk.gerenteId;
			this.vwVendedorService.buscar(pk).then((gerente) => {
				usuario.gerente = gerente;
				this.setState({usuario});
			});
		});
	}

	vendedorTemplate = (vendedor) => {
		return <div><i className={`fa fa-${vendedor.posicao === "ATIVO" ? "check-circle" : "minus-circle"}`} /> {vendedor.pk.vendedorId} - {vendedor.nome}</div>;
	}

	selectTabIndex = (index) => {
		this.setState({selectedTab: index});
	}

	clearVendedor = () => {
		let usuario = this.state.usuario;
		usuario.vendedor = {pk: {}, nome: " "};
		usuario.gerente = {pk: {}, nome: " "};
		usuario.supervisor = {pk: {}, nome: " "};
		this.setState({usuario});
	}

	listarGestores = (event) => {
		this.gestorService.listar({gestores: event.query}).then(gestores => this.setState({gestores}));
	}


	clearEntity = (event) => {
		let usuario = this.state.usuario;
		usuario[event.name] = null;
		this.setState({usuario});
	}

	selectEntity = (event) => {
		let usuario = this.state.usuario;
		usuario[event.name] = event.value;
		this.setState({usuario});
	}
	
	vendedorTemplate = (usuario) => {
		return (
			<div >
				{ usuario.vendedorId !== null ?
				<div style={{ width: "5em", textAlign: "center" }} className="ui-tzm-suggestion-chip">{usuario.vendedorId.vendedorId}</div>
				: 
				<div style={{ width: "5em", textAlign: "center" }} className="ui-tzm-suggestion-chip"></div>
				}				
				<div>{usuario.nome + ' | '+ usuario.email}</div>				
			</div>
		);
	}	

	listarPerfis = (event) => {		
		this.perfilService.buscar(`search=descricao~${event.query};id!${this.state.usuario.perfis.map(p => p.id).join(",")}`, {size: 10, page: 0}).then((perfis) => this.setState({perfis}));
	}

	handlePerfis = (event) => {
		const usuario = this.state.usuario;
		if (event.value) {
			usuario.perfis.push(event.value);
		}
		this.setState({usuario});
	}

	perfilDetails = [
		<TZMColumn key={0} header="Descrição" field="descricao" />,
		<TZMColumn key={1} style={{width: "4em", textAlign: "center"}} header="Ações" body={(p) => {
			return (
				<span key={0} title="Remover Perfil" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.removerPerfil(p)} />
			);
		}} />
	];

	removerPerfil = (perfil) => {
		const usuario = this.state.usuario;
		usuario.perfis = usuario.perfis.filter(p => p.id !== perfil.id);
		this.setState({usuario});
	}

	render() {		
		return (
			<TZMDialog style={{width: "1000px"}} visible={this.state.visible} modal header="Usuário" {...this.props}>
				<TZMTabView activeIndex={this.state.selectedTab}>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Dados Principais</span>}>
						<div className="ui-g">
							<div className="ui-g-3"><TZMTextField autoFocus required disabled={this.state.usuario.id != null} onChange={(event) => this.handleUpdate("login", event)} label="Login" value={this.state.usuario.login} /></div>
							<div className="ui-g-6"><TZMTextField onChange={(event) => this.handleUpdate("nome", event)} label="Nome" value={this.state.usuario.nome} /></div>
							<div className="ui-g-3"><TZMTextField label="Operador" onChange={(event) => this.handleUpdate("operador", event)} value={this.state.usuario.operador} /></div>
							<div className="ui-g-2"><TZMCombobox required onChange={(event) => this.handleUpdate("nivel", event)} label="Nível" value={this.state.usuario.nivel}>
								<option value="ADMIN">Administrador</option>
								<option value="DIRETOR">Diretor</option>
								<option value="GERENTE">Gerente</option>
								<option value="SUPERVISOR">Supervisor</option>
								<option value="REPRESENTANTE">Representante</option>
							</TZMCombobox></div>
							<div className="ui-g-4"><TZMTextField onChange={(event) => this.handleUpdate("email", event)} label="E-mail" value={this.state.usuario.email} /></div>
							<div className="ui-g-3">
								<TZMTextField placeholder={this.state.usuario.id != null ? "**********" : null} warning={this.state.usuario.senha !== this.state.usuario.csenha} 
										required={this.state.usuario.id == null} onChange={(event) => this.handleUpdate("senha", event)} type="password" label="Senha" value={this.state.usuario.senha} /></div>
							<div className="ui-g-3">
								<TZMTextField placeholder={this.state.usuario.id != null ? "**********" : null} warning={this.state.usuario.senha !== this.state.usuario.csenha} 
										required={this.state.usuario.id == null} onChange={(event) => this.handleUpdate("csenha", event)} type="password" label="Confirmação de senha" value={this.state.usuario.csenha} /></div>
							<div className="ui-g-12">
								<Checkbox inputId="changelog" onChange={(event) => this.handleUpdate("mostrarChangelog", { ...event, target: { value: event.checked}})} checked={this.state.usuario.mostrarChangelog}/>
								<label htmlFor="changelog" className="ui-checkbox-label">{this.state.usuario.mostrarChangelog ? `Mostrar Notificação de Changelog` :`Ocultar Notificação de Changelog`} </label>
							</div>
						</div>
					</TZMTabPanel>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Perfis</span>}>
						<div className="ui-g">
							<TZMSuggestion autoclear field="descricao" suggestions={this.state.perfis} label="Perfil" col={12} completeMethod={this.listarPerfis} name="perfil" value={this.state.perfil} onChange={this.handlePerfis} /> 
							<TZMTable header="Perfis do Usuário" emptyMessage="Nenhum perfil selecionado" col={12} children={this.perfilDetails} value={this.state.usuario.perfis} />
						</div>
					</TZMTabPanel>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="3" />Vendedor</span>}>
						<div className="ui-g">
							<div className="ui-g-12">
								<TZMAutoComplete label="Vendedor" suggestions={this.state.vendedores} completeMethod={this.listarVendedores} onClear={this.clearEntity} 
											onSelect={this.selectEntity} onChange={this.selectEntity} name="representante" field="nome" value={this.state.usuario.representante} 
											itemTemplate={this.vendedorTemplate} />
							</div>				
						</div>
					</TZMTabPanel>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="4" />Gestor</span>}>
						<div className="ui-g">
							<div className="ui-g-6">
								<TZMAutoComplete label="Gestor" suggestions={this.state.gestores} completeMethod={this.listarGestores} onClear={this.clearEntity} onSelect={this.selectEntity} onChange={this.selectEntity} name="gestor" field="email" value={this.state.usuario.gestor} />
							</div>
							<div className="ui-g-6"><TZMTextField disabled forac label="Nome" value={this.state.usuario.gestor && this.state.usuario.gestor.operador ? this.state.usuario.gestor.operador.nome : null} /></div>
						</div>
					</TZMTabPanel>
				</TZMTabView>
				<TZMPanelFooter>
					{this.state.usuario.id != null && this.state.usuario.ativado && this.state.usuario.login !== "admin" ? <TZMButton style={{float: "left"}} danger title="Desativar Usuário" icon="fas fa-trash-alt" onClick={this.desativarUsuario} /> : null}
					{this.state.usuario.id != null && !this.state.usuario.ativado ? <TZMButton style={{float: "left"}} title="Reativar Usuário" icon="fas fa-eye" onClick={this.reativarUsuario} /> : null}
					<Button className="ui-button-success ui-tzm-button" label="Salvar" icon="fas fa-save" onClick={this.salvarUsuario} />
					<Button className="ui-button-secondary ui-tzm-button" label="Fechar" icon="fas fa-times" onClick={this.fecharUsuario} />
				</TZMPanelFooter>
				<TZMShortcut
					active={this.state.visible}
					onCtrlS={this.salvarUsuario}
					onEscape={this.fecharUsuario}
					onAlt1={() => this.selectTabIndex(0)}
					onAlt2={() => this.selectTabIndex(1)}
				/>
			</TZMDialog>
		);
	}

}
