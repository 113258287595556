const NO_SELECTION = "0";
const NENHUM_REGISTRO_SELECIONADO = "Nenhum Registro Selecionado...";

export default class Constantes {

	static get nenhumRegistroSelecionado() {
		return NENHUM_REGISTRO_SELECIONADO;
	}
    
	static get semSelecao() {
		return NO_SELECTION;
	}
}
