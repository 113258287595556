import React from "react";
import {Column} from "primereact/components/column/Column";
import "./Table.css";

export default class TZMColumn extends React.Component {

	render() {
		return <Column {...this.props} />;
	}

}
