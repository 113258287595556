import { Column } from "primereact/components/column/Column";
import { ColumnGroup } from "primereact/components/columngroup/ColumnGroup";
import { Panel } from "primereact/components/panel/Panel";
import { Row } from "primereact/components/row/Row";
import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMCalendar from "../../../components/common/TZMCalendar";
import { TZMCurrency } from "../../../components/common/TZMCurrency";
import { toCurrencyFormatterPrecision, toDate,  toPercent } from "../../../components/common/TZMFormatter";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPopup from "../../../components/dialog/TZMPopup";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMTable from "../../../components/table/TZMTable";
import TZMShortcut from "../../../utilities/TZMShortcut";
import TZMUserData from "../../../utilities/TZMUserData";
import { Validator } from "../../../utilities/TZMValidator";
import { AnaliseService } from "../../servicos/AnaliseService";
import { VwPedidoService } from "../../servicos/VwPedidoService";
import EditarPedido from "./EditarPedido";
import { PedidoUtils } from "./PedidoUtils";

export class AnalisePedidoItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            analise: props.analise,
            clienteId: props.clienteId
        };
    }

    vwPedidoService = new VwPedidoService();
    analiseService = new AnaliseService();

    fechar = () => {
        let analise = this.state.analise;
        if (this.props.onClose) {
			this.props.onClose(analise);
		}
        this.setState({ visible: false });
    }

    columnFator(rowData) {        
        let value;
        let color;
        let title;
        if (rowData.fator === 'PRIMEIRO_PRECO') {
            value =  "file-signature";
            color = "#FF9800";
            title = "Primeiro Preço";
        } else {
            if (rowData.alteracao === 'GERENCIA') {
                if (rowData.fator === 'AUMENTO') {
                    value =  "arrow-circle-up";
                    color = "#2196F3";
                    title = "Aumento realizado pela gerência.";
                } else {
                    value =  "arrow-circle-down";
                    color = "#F44336";
                    title = "Redução realizada pela gerência.";
                }
             } else {
                if (rowData.fator === 'AUMENTO') {
                    value =  "arrow-circle-up";
                    color = "#4CAF50";
                    title =  "Aumento por reajuste.";
                } else {
                    value =  "arrow-circle-down";
                    color = "#791d16";
                    title = "Redução por reajuste.";
                }
             }
        }
		return <div className='ui-g' ><div className='ui-g-12' style={{ textAlign: "center" }}>		
		    <span key={1} className={`fas fa-${value}`} title={title} style={{ color }} />&nbsp;		
            {rowData.listaVigente ? 
            <span key={2} className={`fa fa-calendar-day`} title={"Lista Vigente"} style={{  color: '#000000' }} />
            : null}            
	    </div></div>    
	};   

    fatorPercentual = (rowData, column) => {
        return(
            <div className="campo-impostos">
                <div title={toCurrencyFormatterPrecision(rowData["fatorValor"])}  style={{ textAlign: "right" }}>
                    {toPercent(rowData["fatorPercentual"] / 100)}
                </div>
            </div>
        );
	}

    fatorPercentualTotal = (itens) => {
        let valorTotal = toCurrencyFormatterPrecision(itens.map((item) => item.fatorValor).reduce((a, b) => a + b, 0));
        let percentualTotal = toPercent(itens.map((item) => item.fatorPercentual).reduce((a, b) => a + b, 0) /100);
        return(
            <div className="campo-impostos">
                <div title={valorTotal}  style={{ textAlign: "right" }}>
                    {percentualTotal}
                </div>
            </div>
        );
	}

    listaVigente = (rowData) => {		
		return {'listaVigente' :  rowData.listaVigente};
	}

    selectPedido = (pedidoId) => {
        this.vwPedidoService.completar({pedidoId: pedidoId, empresaFilialId: 27300001}).then((response) => {
            ModalRealm.showDialog(<EditarPedido key={TZMUserData.getIdGenerator()}  pedido={response}  />);
        });
	}

    validar = () => {
		let messages = [];
		let analise = this.state.analise;
		
		if (Validator.isEmpty(analise.inicioVenda)) {
			messages.push("A data inicial do histórico de venda é obrigatória.");
		}
		if (Validator.isEmpty(analise.fimVenda)) {
			messages.push("A data final do histórico de venda é obrigatória.");
		}
		if (analise.inicioVenda != null && analise.fimVenda != null) {
			let inicioVenda = new Date(analise.inicioVenda);
			let fimVenda = new Date(analise.fimVenda);
			
			if (inicioVenda > fimVenda) {
				messages.push("A data inicial deve ser menor ou igual a data final.");
			}			
		}
		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()} header="Advertência" messages={messages} />);
			return false;
		}
		return true;
	}


    reanalisarProdutoPreco = () => {
        if (this.validar()){
            let analise = this.state.analise;
            let produtoId =  analise.produtoId;
            let clienteId = this.state.clienteId;
            let inicioVenda = analise.inicioVenda;
            let fimVenda = analise.fimVenda;
            this.analiseService.reanalisarProdutoPreco({produtoId,clienteId,inicioVenda,fimVenda}).then( (result) => {this.setState({analise: result})} );
        }
    }

    showValorUnitario = (rowData) => {
        rowData.indicador = PedidoUtils.analisarValorUnitario(rowData.valorUnitario,rowData.valorUnitarioTabela);
        let color = null;
        switch (rowData.indicador) {
            case  PedidoUtils.VERDE():		
                color = "#008000";
                break;
            case  PedidoUtils.AMARELO():					
                color = "#FFD700";
                break;
            case  PedidoUtils.VERMELHO():					
                color = "#FF0000";
                break;
            default:
                break;
        }
        return (
            <div key={TZMUserData.getIdGenerator()} className="campo-descontos cmp-vlrunit-prtc">
                <div key={TZMUserData.getIdGenerator()} title={rowData.mensagem} className="campo-valunit">
                    <TZMCurrency value={toCurrencyFormatterPrecision(rowData.valorUnitario)} disabled />
                    <i className="fa fa-balance-scale" style={{color}} title={rowData.mensagem}  />
                </div>
            </div>
        );
    }

    handleChange = (event) => {		
		let analise = this.state.analise;
		analise[event.name] = event.target.value;
		this.setState({analise});
	}	

    render() {
        let mensagemPreco =  this.state.analise.itens && this.state.analise.itens.length > 0 ? "Evolução da lista de preço.": null;
        let mensagemVenda =  this.state.analise.vendas && this.state.analise.vendas.length > 0 ? "Dados referente às vendas realizadas para o cliente no período de " 
                        + toDate(this.state.analise.inicioVenda) +" à "+ toDate(this.state.analise.fimVenda) +".": null;

        return (
            <TZMDialog style={{ width: "1200px" }} visible={this.state.visible} modal header="Analise de Item do Pedido" {...this.props}>
                <div className="ui-g">
                    <div className="ui-g-3">
                        <TZMTextField  disabled value={this.state.analise.codigoListaPreco} label="Código Lista Preço" />
                    </div>
                    <div className="ui-g-3">
                        <TZMCalendar  disabled name="vigencia" value={this.state.analise.vigenciaListaPreco} label="Vigência" />
                    </div>
                    <div className="ui-g-3">
                        <TZMCurrency  disabled name="valorUnitarioTabela" value={this.state.analise.valorUnitarioTabela} label="Valor Unitário da Lista de Preço" />
                    </div>
                    <div className="ui-g-3">
                        <TZMCurrency disabled  name="valorUnitario" value={this.state.analise.valorUnitario} label="Valor Unitário do Item" />
                    </div>
                    <TZMTabView onTabChange={(event) => this.setState({ selectedTab: event.index })} activeIndex={this.state.selectedTab}>
                        <TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Histórico de Vendas</span>}>
                            <div className="ui-g">
                                <div className="ui-g-2">
                                    <TZMCalendar label="Histórico de venda entre" placeholder="Inicial" name="inicioVenda" onChange={this.handleChange} value={this.state.analise.inicioVenda} />
                                </div>
                                <div className="ui-g-2">
                                    <TZMCalendar label="&nbsp;" placeholder="Final" name="fimVenda" onChange={this.handleChange} value={this.state.analise.fimVenda} />
                                </div>
                                <div  className="ui-g-1 pesquisar" >
                                    <span className="fas fa-search ui-action-icon" title="Procurar" style={{width:"30px",  fontSize: "20px", color: "#1f89ce"}}  
										onClick={(event) => {
											this.reanalisarProdutoPreco()}}>
									</span>                                    
                                </div>
                                <div className="ui-g-7 ui-mensagem-analise-venda">							
                                    {mensagemVenda}
                                </div>                                
                            </div>         
                           
                            <TZMTable value={this.state.analise.vendas} emptyMessage="Nenhuma venda encontrada."  scrollable 
                                    scrollHeight="400px" ref={(el) => this.dt = el} paginator rows={15} rowsPerPageOptions={[15, 30, 45]} pageLinkSize={15} >
                                <Column key="dataPedido" style={{ width: "12em" }} field="dataPedido" header="Data do Pedido" body={(rowData) => {
                                        return <div style={{ textAlign: "right" }}>{toDate(rowData["dataPedido"])}</div>;}} />
                                <Column key="pedidoId" style={{ textAlign: "center", width: "6em" }} field="pedidoId" header="Pedido" />
                                <Column key="itemId" style={{ textAlign: "center", width: "6em" }} field="itemId" header="Item" />
                                <Column key="valorUnitarioTabela" style={{ width: "9em" }} field="valorUnitarioTabela" header="Vlr. Unitário da Lista de Preço"   body={(rowData) => {
                                        return <div style={{ textAlign: "right" }}>{toCurrencyFormatterPrecision(rowData["valorUnitarioTabela"])}</div>;}} />                                
                                <Column key="valorUnitario" style={{ width: "12em" }} field="valorUnitario" header="Vlr. Unitário Praticado" body={this.showValorUnitario} />

                                <Column key="quantidade" style={{ textAlign: "center", width: "6em" }} field="quantidade" header="Quantidade" />
                                <Column key="percentualIcms" style={{ width: "12em " }} field="percentualIcms" header="ICMS %" body={(rowData) => {
                                        return <div style={{ textAlign: "right" }}>{toPercent(rowData["percentualIcms"])}</div>;}} />
                                <Column key="valorTotalItem" style={{ width: "12em" }} field="valorTotalItem" header="Valor Total" body={(rowData) => {
                                        return <div style={{ textAlign: "right" }}>{toCurrencyFormatterPrecision(rowData["valorTotalItem"])}</div>;}} />
                                <Column style={{ width: "5em", textAlign: "center" }} key="acoes" header="Ações" body={(rowData) => {                                                                
                                    return (
                                        <div>												
                                            <span key={0} title="Visualizar Pedido" className="fa fa-desktop ui-tzm-icon" onClick={() => this.selectPedido(rowData.pedidoId)} />                                        
                                        </div>
                                    );
                                }} />
                            </TZMTable>                            
                        </TZMTabPanel>
                        <TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Histórico da Lista de Preço</span>}>
                            <TZMTable value={this.state.analise.itens} emptyMessage="Nenhuma lista de preço encontrada." rowClassName={this.listaVigente}  
                                        scrollHeight="400px" ref={(el) => this.dt = el} paginator rows={15} rowsPerPageOptions={[15, 30, 45]} pageLinkSize={15}
                                        footerColumnGroup={
                                    <ColumnGroup>
                                        <Row>
                                            <Column colSpan={2} style={{ fontSize: "12px", textAlign: "right" }} footer="Reajuste total no período" />
                                            <Column style={{ textAlign: "right" }} footer={this.fatorPercentualTotal(this.state.analise.itens)} />                                        
                                            <Column colSpan={3}/>
                                        </Row>                                    
                                    </ColumnGroup>}>                            
                                <Column key="vigencia" style={{ width: "9em" }} field="vigencia" header="Vigência" body={(rowData) => {
                                        return <div style={{ textAlign: "center" }}>{toDate(rowData["vigencia"])}</div>;}} />
                                <Column key="preco" style={{ width: "10em" }} field="preco" header="Preço da Lista" body={(rowData) => {
                                        return <div style={{ textAlign: "right" }}>{toCurrencyFormatterPrecision(rowData["preco"])}</div>;}} />
                                <Column key="fatorPercentual" style={{ width: "10em" }} field="fatorPercentual" header=" % Reajuste" body={this.fatorPercentual} />                            
                                <Column key="fator" style={{ textAlign: "center", width: "5em" }} field="fator" header="Fator" body={this.columnFator} />
                                <Column key="motivoReajuste" header="Motivo Reajuste" field="motivoReajuste" style={{ textAlign: "center", width: "*" }}  />
                                <Column key="responsavel" header="Responsável" field="responsavel" style={{ textAlign: "center", width: "*" }}  />
                            </TZMTable>
                            <div className="ui-g">
                                <div className="ui-g-7 ui-mensagem-analise-venda">							
                                    {mensagemPreco}
                                </div>
                                <div className="ui-g-5">
                                    <Panel header="Legenda">
                                        <div className="ui-g">
                                            <div style={{ color: '#000000', textAlign: "center" }} className="ui-g-1"><i className="fa fa-calendar-day" /></div><div className="ui-g-5">Lista vigente</div>
                                            <div style={{ color: '#FF9800', textAlign: "center" }} className="ui-g-1"><i className="fa fa-file-signature" /></div><div className="ui-g-5">Primeiro Preço</div>                            
                                            <div style={{ color: '#2196F3', textAlign: "center" }} className="ui-g-1"><i className="fa fa-arrow-circle-up"/></div><div className="ui-g-5">Aumento realizado pela gerência</div>                                        
                                            <div style={{ color: '#4CAF50', textAlign: "center" }} className="ui-g-1"><i className="fa fa-arrow-circle-up"/></div><div className="ui-g-5">Aumento por reajuste</div>                                        
                                            <div style={{ color: '#F44336', textAlign: "center" }} className="ui-g-1"><i className="fa fa-arrow-circle-down" /></div><div className="ui-g-5">Redução realizada pela gerência</div>                                                                    
                                            <div style={{ color: '#791d16', textAlign: "center" }} className="ui-g-1"><i className="fa fa-arrow-circle-down" /></div><div className="ui-g-5">Redução por reajuste</div>
                                        </div>
                                    </Panel>    
                                </div>
                            </div>                            
                        </TZMTabPanel>                        
                    </TZMTabView>
                </div>
                <TZMPanelFooter>
                    <TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-close" onClick={this.fechar} />
                </TZMPanelFooter>
                <TZMShortcut active={this.state.visible} onEscape={this.fechar}
                />
            </TZMDialog>
        );
    }

}
