import { httpDelete, httpGet, httpPost, httpGetAsync } from "../../utilities/TZMRequest";

export class UsuarioService {

	completar(usuario) {
		return httpGet(`/usuarios/${usuario.id}`);
	}

	completarAsync = async(usuario) =>{
		return await httpGetAsync(`/usuarios/${usuario.id}`,true);
	}

	listar = async (params) => {
		return await httpGetAsync("/usuarios", params, true);
	}

	listarSimples = async (params) => {
		return await httpGetAsync("/usuarios/listar-simples", params, true);
	}	

	autoCompletar = async (params) => {
		return await httpGetAsync("/usuarios/auto-completar", params, false);
	}
	
	listarEmpresa() { 
		return httpGet("/usuarios/empresas");
	}

	salvar(usuario) {
		return httpPost("/usuarios", usuario);
	}

	salvarAsync = async(usuario) => {
		return await httpPost("/usuarios", usuario);
	}

	excluir(usuario) {
		return httpDelete("/usuarios", usuario.id);
	}
}
