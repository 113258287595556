import moment from "moment";
import { Column } from 'primereact/components/column/Column';
import { ColumnGroup } from 'primereact/components/columngroup/ColumnGroup';
import { Growl } from 'primereact/components/growl/Growl';
import { Panel } from "primereact/components/panel/Panel";
import { Row } from 'primereact/components/row/Row';
import React from "react";
import ReactTooltip from 'react-tooltip';
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMButton from "../../../components/common/TZMButton";
import TZMCalendar from "../../../components/common/TZMCalendar";
import TZMCombobox from "../../../components/common/TZMCombobox";
import TZMForm from "../../../components/common/TZMForm";
import { toCurrency, toDate, toDecimal, toPercent } from "../../../components/common/TZMFormatter";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import { Fetch } from "../../../utilities/Fetch";
import Templates from "../../../utilities/Templates";
import TZMShortcut from "../../../utilities/TZMShortcut";
import { UF } from "../../../utilities/UF";
import TZMUserData from "../.././../utilities/TZMUserData";
import UsuarioTemplate from "../../comum/usuario/UsuarioTemplate";
import { BobinaService } from "../../servicos/BobinaService";
import { VwClienteService } from "../../servicos/VwClienteService";
import { IntegracaoService } from "../../servicos/IntegracaoService";
import { PedidoService } from "../../servicos/PedidoService";
import { RegionalService } from "../../servicos/RegionalService";
import { RelatorioService } from "../../servicos/RelatorioService";
import { UsuarioService } from "../../servicos/UsuarioService";
import { VendedorService } from "../../servicos/VendedorService";
import { VwPedidoService } from "../../servicos/VwPedidoService";
import { VwProdutoService } from "../../servicos/VwProdutoService";
import EditarPedido from "./EditarPedido";
import { LogIntegracao } from "../../comum/pedido/LogIntegracao";
import { MotivoBloqueio } from "../../comum/pedido/MotivoBloqueio";
import PrevisaoLancamento from "./PrevisaoLancamento";
import { DataTable } from "primereact/components/datatable/DataTable";
import { MunicipioService } from "../../frete/servicos/MunicipioService";
import { FamiliaService } from "../../servicos/FamiliaService";
import { PedidoUtils } from "./PedidoUtils";
import ClonarPedido from "./ClonarPedido";
import EspelhoLotePedido from "./EspelhoLotePedido";
import { Button } from "primereact/components/button/Button";

const title = "Pedidos";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;
const ambiente = process.env.REACT_APP_AMBIENTE;

export default class Pedido extends React.Component {
	
	state = {
		list: [],
		rows: 15,
		data: {},
		params: {
			pedido: "",
			notaFiscal: "",
			inclusao: this.rangeDiario(),
			entrega: [null, null],
			faturamento: [null, null],
			status: "",
			painel: "true",
			pronto: "false",
			municipio:{nome: ""},
			produto: { pk: {}, descricao: "" },
			cliente: { pk: {}, descricaoAmigavel: "" },
			familia: { descricao: ""},
			pedidoCliente: null,
			uf: "",
			page: 0,
			size: 10000,
			sort: undefined,
			cancelado: false,
		},
		manterCliente: true,
		selectedPedido: "",
		expandedRows: [],
		fixedSeller: false,
		fixedGerente: false,
		fixedSupervisor: false,
		isLoading: false,
		producao: false,
		dataMini: false,
		filtrarGrupo: false,
		regional: {
			regional: null,
			vendedor: null, 
			gerente: null,
			supervisor: null,
		},
		checarNf: false,
		menuLateral: true
	};

	
	usuarioService = new UsuarioService();
	vwClienteService = new VwClienteService();
	vwPedidoService = new VwPedidoService();
	pedidoService = new PedidoService();
	vwProdutoService = new VwProdutoService();
	vendedorService = new VendedorService();
	integracaoService = new IntegracaoService();
	bobinaService = new BobinaService();	
	relatorioService = new RelatorioService();
	regionalService = new RegionalService();	
	municipioService = new MunicipioService();
	familiaService = new FamiliaService();

	pedidoConfirmado = (pedidoId) => {
		this.growl.show({ closable: true, severity: 'success', sticky: true, summary: 'Pedido Confirmado', detail: `Pedido ${pedidoId} aguardando processamento!` });
	}

	pedidoSalvo = (pedidoId) => {
		this.growl.show({ closable: true, severity: 'info', sticky: true, summary: 'Pedido Salvo', detail: `Pedido ${pedidoId} salvo como rascunho!` });
	}

	pedidoDesconto = (pedidoId) => {
		this.growl.show({ closable: true, severity: 'secundary', sticky: true, summary: 'Solicitação de Desconto Encaminhada', detail: `Pedido ${pedidoId} aguardando aprovação!` });
	}

	displayGrowl = (pedido) => {
		if (pedido.status === 'CONFIRMADO') {
			this.pedidoConfirmado(pedido.pedidoId);
		} else if (pedido.status === 'ALCADA_DESCONTO') {
			this.pedidoDesconto(pedido.pedidoId);				
		} else if (pedido.status === 'RASCUNHO') {
			this.pedidoSalvo(pedido.pedidoId);
		}
	}

	optionsStatus = [
		<option key={0} value={null}></option>,
		<option key={1} value="ERRO">Erro</option>,
		<option key={2} value="CANCELADO">Cancelado</option>,
		<option key={3} value="RASCUNHO">Rascunho</option>,
		<option key={4} value="ALCADA_DESCONTO">Desconto Pendente</option>,
		<option key={5} value="DESCONTO_REPROVADO">Desconto Reprovado</option>,
		<option key={6} value="NAO_PROCESSADO">Não-processado</option>,
		<option key={7} value="CARTEIRA">Carteira</option>,
		<option key={8} value="BLOQUEADO">Bloqueado</option>,
		<option key={9} value="FATURADO">Faturado</option>
	];

	componentDidMount() {
		let usuario = TZMUserData.getPrincipal();
		if (TZMUserData.isAutenticado()) {
			Fetch.Get("/ping");			
			let regional = this.state.regional;
			switch (usuario.nivel) {
				case TZMUserData.REPRESENTANTE:
					if (usuario.vendedor) {
						regional.vendedor = usuario.vendedor;
						this.setState({ regional, fixedSeller: true });
					}
					break;
				case TZMUserData.GERENTE:										
					regional.gerente = usuario;										
					this.setState({ regional, fixedGerente: true });
				break;
				case TZMUserData.SUPERVISOR:
					regional.supervisor = usuario;
					this.setState({ regional, fixedSupervisor: true });
				break;			
			default:
				break;
			}
		} 
	}

	rangeDiario() {
		let date = new Date();
		let firstDay = moment(date).format("YYYY-MM-DD");
		return [firstDay, firstDay];
	}

	rangeMensal(startMonth = 0, endMonth = 1) {
		let date = new Date();
		let firstDay = moment(new Date(date.getFullYear(), date.getMonth() + startMonth, 1)).format("YYYY-MM-DD");
		if (endMonth == null) {
			return [firstDay, null];
		}
		return [firstDay, moment(new Date(date.getFullYear(), date.getMonth() + endMonth, 0)).format("YYYY-MM-DD")];
	}

	dateFormat(rowData, column) {
		let campo = rowData[column.field];
		if (campo !== null) {
			return toDate(campo);
		}
		return null;
	}

	currencyFormat(rowData, column) {
		return <div style={{ textAlign: "right" }}>{toCurrency(rowData[column.field])}</div>;
	}


	percentFormat = (rowData, column) => {
		return <div style={{ textAlign: "center" }}>{toPercent(rowData[column.field])}</div>;
	}

	os = (rowData) => {
		return (
			<div>

				{rowData.ordensProducao ? rowData.ordensProducao.map((os) => {
					return (
						<div key={TZMUserData.getIdGenerator()} style={{
							backgroundColor: "#607D8B", padding: "2px 10px 2px 10px", borderRadius: "16px", margin: "2px", fontSize: "12px", color: "#FFF", display: "inline-block"
						}} >
							<span title={os.descricaoOs} >
								{os.pk.ordemProducao}
							</span>
						</div>
					);
				}) : null}
			</div>
		);
	}

	chipFormat = (rowData) => {
		return (
			<div>

				{rowData.movimentos ? rowData.movimentos.filter((movimento) => movimento.movimento === "FAT").map((movimento) => {
					return (
						<div key={TZMUserData.getIdGenerator()} style={{
							backgroundColor: "#607D8B", padding: "2px 10px 2px 10px", borderRadius: "16px", margin: "2px", fontSize: "12px", color: "#FFF", display: "inline-block"
						}} >
							<span title="Nota Fiscal" style={{cursor: "pointer"}} className={movimento._isLoadingNF ? `fa fa-spin fa-circle-notch` : null} 
								onClick={() => this.notaFiscal(movimento.notaFiscalId, movimento.pk.pedidoId)} >
								{`${movimento.notaFiscalId}  (${moment(movimento.pk.dataEmissao).format("DD/MM/YYYY")})`}
							</span>
						</div>
					);
				}) : null}
			</div>
		);
	}

	espelhoPedido = async (pedido) => {

		const list = this.state.list;
		list.filter(p => p.pk.pedidoId === pedido.pk.pedidoId).forEach(p => {
			p._isLoading = true;
		});
		this.setState({ list });
		await this.relatorioService.espelhoPedido(pedido.pk, pedido.pk.pedidoId).then(response => {
			const list = this.state.list;
			list.filter(p => p.pk.pedidoId === pedido.pk.pedidoId).forEach(p => p._isLoading = false);
			this.setState({ list });
		});
	}

	notasFiscais = async (pedido) => {
		const list = this.state.list;
		list.filter(p => p.pk.pedidoId === pedido.pk.pedidoId).forEach(p => {
			p._isLoadingNF = true;
		});
		this.setState({ list });
		
		await this.relatorioService.notasFiscais(pedido.pk.pedidoId).then(response => {
			const list = this.state.list;
			list.filter(p => p.pk.pedidoId === pedido.pk.pedidoId).forEach(p => p._isLoadingNF = false);
			this.setState({ list });
		});
	}

	notaFiscal = async (notaFiscalId, pedidoId) => {		
		await this.relatorioService.notaFiscal(notaFiscalId,pedidoId).then(response => {});
	}

	columns = [
		<Column key={"expander"} style={{ width: "2em" }} expander />, 	
		<Column sortable field="pk.pedidoId" key="pk.pedidoId" style={{ width: "6em", textAlign: "right" }} header="Pedido" />,
		<Column sortable field="pedidoCliente" key="pedidoCliente" style={{ width: "9em" }} header="Pedido/Cliente" />,
		TZMUserData.isRepresentante() ?  <Column key="vendedorNome" style={{width: "0em"}}/> : <Column field="vendedorNome" key="vendedorNome" header="Vendedor" style={{width: "10em", textAlign: "center"}} sortable /> ,
		<Column sortable field="cliente.razaoSocial" key="cliente.razaoSocial" style={{ width: "*" }} header="Cliente" />,		
		<Column sortable field="dataInclusao" key="dataInclusao" style={{ textAlign: "center" , width: "6.3em" }} body={this.dateFormat} header="Inclusão" />,
		<Column sortable field="dataEntrega" key="dataEntrega" style={{ textAlign: "center" ,width: "6.3em" }} body={this.dateFormat} header="Entrega" />,
		<Column sortable field="totalM2" key="totalM2" style={{ width: "7em", textAlign: "right" }} header="Total em m²" body={(rowData) => toDecimal(Math.round(rowData.totalM2))} />,
		<Column sortable field="valorTotal" style={{ width: "9em" }} key="valorTotal" header="Valor" body={this.currencyFormat} />,
		<Column key={"status"} style={{ width: "7em" }} header="" body={this.columnStatus} />,
		<Column style={{ width: "7.5em" }} key="acoes" header="Ações" body={(pedido) => {
			let botoes = [];			
			if (pedido.sistema === 'OMEGA') {
				botoes.push(<span key={0} title="Visualizar" className="fa fa-desktop ui-tzm-icon" onClick={() => this.selectPedido(pedido)} />);
				if ((this.state.fixedSeller && pedido.vendedorClienteId === pedido.vendedorId) || !this.state.fixedSeller ) {
					botoes.push(
						<span 
							key={2} 
							title="Copiar" 
							style={{ margin: "0 3px 0 2px" }} 
							className="fa fa-copy ui-tzm-icon" 
							onClick={() => 
								ModalRealm.showDialog(<ClonarPedido pedido={pedido} onSave={(pedido) => {setTimeout(() => {this.handleList();}, 50);this.displayGrowl(pedido);} }    />)
							} 
						/>
					);
					
				}
				botoes.push(<span key={1} title="Espelho Pedido" className={pedido._isLoading ? `fa fa-spin fa-circle-notch` : `fa fa-file-pdf ui-tzm-icon`} onClick={() => this.espelhoPedido(pedido)} />);
			} else {
				if (TZMUserData.hasRole("CAC_PEDD_E")) {
					botoes.push(<span key={0} title="Editar" className="fa fa-edit ui-tzm-icon" onClick={() => this.selectPedido(pedido)} />);
				}
			}			
			if (pedido.id != null && (pedido.status === 'CONFIRMADO' || pedido.status === 'ERRO' || pedido.status === "NAO_PROCESSADO")) {
				botoes.push(<span key={3} title="Visualizar Registros de Integração" className="fa fa-database ui-tzm-icon" onClick={() => this.showLogsIntegracao(pedido)} />);
			}
			if (pedido.id != null && (pedido.status === 'ERRO' || pedido.status === 'RASCUNHO' || pedido.status === 'DESCONTO_REPROVADO' )) {
				botoes.push(<span key={4} title="Cancelar" className="fa fa-ban ui-tzm-icon" onClick={() => this.cancelarPedido(pedido)} />);
			}
			if (pedido.status === "BLOQUEADO") {
				botoes.push(<span key={5} className={`fas fa-shield-alt ui-tzm-icon`} title="Motivos de Bloqueio" onClick={() => this.motivoBloqueio(pedido)} />)
			}			
			if (pedido.sistema === 'OMEGA') {
				this.showNotasFiscais(pedido, botoes)		
			}
			return <div style={{ textAlign: "center" }}>{botoes}</div>;
		}} />
	];

	showNotasFiscais = async (pedido, botoes) => {
		if (this.state.checarNf) {
			if (pedido.notasGeradas === undefined) {
				botoes.push(<i key={TZMUserData.getIdGenerator()} className="fa fa-spin fa-spinner" />);
			} else if (pedido.notasGeradas > 0) {
				let texto = pedido.notasGeradas > 1 ? `Notas fiscais disponíveis para download.` : `Nota fiscal disponível para download.`;
				botoes.push(
					<span  key={TZMUserData.getIdGenerator()} className={pedido._isLoadingNF ? `fa fa-spin fa-circle-notch` : `badge-notas  ui-tzm-icon`} onClick={() => this.notasFiscais(pedido)} title={texto}>
						{pedido._isLoadingNF ? null : pedido.notasGeradas}
					</span>
				);
			}
		}
	}
	
	columnStatus(rowData) {
		let value, color, titulo;
		if (rowData.pronto !== undefined && rowData.pronto != null) {
			if (rowData.pronto) {
				value = "pallet";
				color = "#4CAF50";
				titulo = "Pronto";
			} else {
				switch (rowData.status) {
					case "CARTEIRA":					
					case "BLOQUEADO":
						value = "dolly-flatbed";
						color = "#607D8B";
						titulo = "Produzir";
						break;
					case "FATURADO":
						value = "pallet";
						color = "#4CAF50";
						titulo = "Pronto";
						break;
					default:
						break;
				}
			}
		}				
		return <div className='ui-g' ><div className='ui-g-12' style={{ textAlign: "center" }}>
		{IntegracaoService.columnStatus(rowData)}&nbsp;
		<span key={1} className={`fas fa-${value}`} title={titulo} style={{ color }} />&nbsp;		
		{rowData.regionalDescricaoExcecao != null ? <span className="fake-link fas fa-globe" data-tip={rowData.regionalDescricaoExcecao} data-for="excecao" data-multiline={true} />  : null}&nbsp;          
		{rowData.dataMinimaFaturamento ? <span key={10} className={'fas fa-calendar-day'} title={`Data mínima para ${toDate(rowData.dataMinimaFaturamento)}`} style={{ color:"#000000"}} /> : null}
		<ReactTooltip id="excecao" place="top" type="info" effect="float"/>         
		</div></div>;
	}

	showLogsIntegracao = (rowData) => {
		this.integracaoService.logsPedido(rowData.pedidoId).then((logs) => {
			ModalRealm.showDialog(<LogIntegracao logs={logs} />);
		});
	}
	
	cancelarPedido = (rowData) => {
		ModalRealm.showDialog(<TZMConfirm question="Tem certeza de que deseja cancelar este pedido? Após cancela-lo não será mais possível desfazer o cancelamento." onYes={() => {
			this.pedidoService.cancelar(rowData.id);
			setTimeout(() => {
				this.handleList();
			}, 50);
		}} />);
	}

	handleList = () => {
		let params = Object.assign({}, this.state.params);
		let regional = this.state.regional;
		Object.keys(params).forEach(k => params[k] != null && typeof params[k] === "string" ? params[k] = params[k].trim() : null);
		params.painel = "false";
		
		params.filtrarGrupo = this.state.filtrarGrupo ? "true" : "false";
		params.empresa = TZMUserData.getEmpresaAAP();
		if (params.produto) {
			if (params.produto.pk.produtoId) {
				params.produto = params.produto.pk;
			} else {
				params.produto = null;
			}
		}

		if (params.municipio) {
			if (params.municipio.codigo) {
				params.municipio = params.municipio.codigo;
			} else {
				params.municipio = null;
			}
		}

		if (params.cliente) {
			if (params.cliente.pk.clienteId) {
				params.cliente = params.cliente.pk;
			} else {
				params.cliente = null;
			}
		}
		if (this.state.producao) {
			params.pronto = "true";
		} else {
			params.pronto = "false";
		}

		if (this.state.dataMinima) {
			params.dataMinima = "true";
		} else {
			params.dataMinima = "false";
		}
		if (regional.vendedor && regional.vendedor.id){
			params.vendedor = regional.vendedor.vendedorId.vendedorId;
		}
		if (regional.regional) {
			params.regional = regional.regional.id; 
		}
		if (params.familia) {
			params.familia = params.familia.id; 
		}

		if (regional.gerente) {
			params.gerente = regional.gerente.id;
		}
		if (regional.supervisor) {
			params.supervisor = regional.supervisor.id;			
		}
		this.pedidoService.listarSimples(params).then((pedidos) => {			
			this.handleViewList(params, pedidos);
		});
		ReactTooltip.rebuild();
	}

	handleViewList = async (params, pedidos) => {
		
		await this.vwPedidoService.listarSimples(params).then(async (vwPedidos) => {
			pedidos = pedidos.concat(vwPedidos);
			if (this.state.detalhados) {
				pedidos.forEach((item) => {
					if (item.id) {
						this.pedidoService.detalhar(item.id).then((response) => {
							item.itens = response;
							let expandedRows = this.state.expandedRows;
							if (!expandedRows) expandedRows = [];
							expandedRows.push(item);
							this.setState({ expandedRows });
						});
					} else {
						this.vwPedidoService.detalhar(item.pk).then((response) => {
							item.itens = response;
							let expandedRows = this.state.expandedRows;
							if (!expandedRows) expandedRows = [];
							expandedRows.push(item);
							this.setState({ expandedRows });
						});
					}
				});
			}
			pedidos.forEach((pedido) => {
				if (pedido.status === "CONFIRMADO") {
					this.integracaoService.statusGeral(pedido.pedidoId).then((status) => {
						pedido.status = status;
						this.forceUpdate();
					});
				}
			});
			if (this.state.checarNf) {
				this.setState({list: pedidos, tableId: TZMUserData.getIdGenerator()});
				this.updatePage({first: 0});

			} else {
				this.setState({list: pedidos});
				this.updatePage({first: 0});
			}
		});
	}

	onSort = (event) => {
		if (this.state.checarNf) {
			let list = this.state.list;
			let proprs = event.sortField.split(".");
			let ordenados = list.sort((a, b) => {
				let val1 = a;
				let val2 = b;
				proprs.forEach(p => {
					val1 = val1[p];
					val2 = val2[p];
				});
				if (val1 === Number(val1) && val2 === Number(val2)) {
					return (val1 - val2) * event.sortOrder;
				} else {
					return String(val1).localeCompare(String(val2)) * event.sortOrder;
				}
			});		 		
			this.setState({list: ordenados});
			this.updatePage({first: 0});
		}	
	}

	updatePage = (event) => {
		if (this.state.checarNf) {
			let linhas = event.rows ? event.rows : this.state.rows;
			const {list} = this.state;
			if (list.length > 0) {
				for (let i = event.first; i < event.first + linhas; ++i) {
					if (list[i] && list[i].sistema === "OMEGA" && list[i].notasGeradas === undefined) {
						this.vwPedidoService.quantidadeNfs(Number(list[i].pk.pedidoId)).then((quantidade) => {
							list.filter(p => p.pk.pedidoId === list[i].pk.pedidoId)[0].notasGeradas = quantidade;
							this.setState({list, rows: linhas});
						});
					}
				}
			} else {
				this.setState({rows: linhas});
			}
		}
	}

	handleUpdate = (key, event) => {
		this.setState({ params: { ...this.state.params, [key]: event.target.value } });
	}	

	rowExpansionTemplate = (data) => {
		let footer = (
			<ColumnGroup>
				<Row>
					<Column colSpan={10} style={{ fontSize: "12px", textAlign: "right" }} footer="Total dos itens" />
					<Column style={{ textAlign: "right" }} footer={toCurrency(data.itens.map((item) => item.valorTotalImposto).reduce((a, b) => a + b, 0))} />
					<Column />
				</Row>
			</ColumnGroup>
		);
		return (
			<TZMTable value={data.itens} rowClassName={PedidoUtils.colorirItensProducaoRowClass} footerColumnGroup={footer}>
				<TZMColumn key="codigoProduto" style={{ textAlign: "center", width: "9em" }} field="produto.pk.produtoId" header="Cod. Produto" />
				<TZMColumn key="descricaoProduto" style={{ width: "*" , minWidth: '5em'}} field="produto.descricao" header="Produto" />
				<TZMColumn key="quantidadeM2" style={{ width: "4em" }} field="quantidadeM2" header="Qtde em m²" body={(rowData) => <div style={{ textAlign: "right" }}>{toDecimal(rowData.quantidadeM2)}</div>} />
				<TZMColumn key="quantidade" style={{ width: "3em" }} field="quantidade" header="Qtde" body={(rowData) => <div style={{ textAlign: "right" }}>{rowData.quantidade}</div>} />
				<TZMColumn key="quantidadeFaturada" style={{ width: "5em" }} field="quantidadeFaturada" header="Qtde Faturada" body={(rowData) => <div style={{ textAlign: "right" }}>{rowData.quantidadeFaturada}</div>} />
				<TZMColumn key="unidade" field="unidade" style={{ textAlign: "center", width: "4em" }} header="Unidade" />
				<TZMColumn key="valorUnitario" style={{ width: "5em" }} field="valorUnitario" header="Vl Unitário" body={this.currencyFormat} />
				<TZMColumn key="percentualIcms" style={{ width: "3em" }} field="percentualIcms" header="ICMS" body={this.percentFormat} />
				<TZMColumn key="notaFiscal" style={{ width: "10em", textAlign: "center" }} body={this.chipFormat} header="Nota Fiscal / Data Emissão" />
				<TZMColumn key="ordemServico" style={{ width: "6.5em", textAlign: "center" }} body={this.os} header="O.S" />
				<TZMColumn key="valorTotalItem" style={{ width: "6.5em" }} field="valorTotalImposto" header="Vl Total" body={this.currencyFormat} />
				<TZMColumn key="status" field="status" style={{ textAlign: "center", width: "2em" }}  body={IntegracaoService.columnStatusItem} />
			</TZMTable>
		);
	}

	rowExpand = (event) => {
		if (this.state.expandedRows != null && this.state.expandedRows.length > event.data.length) {
			this.setState({ expandedRows: event.data });
		} else {
			if (event.data.length > 0) {
				let item = event.data.pop();
				if (item.id) {
					this.pedidoService.detalhar(item.id).then((response) => {
						item.itens = response;
						this.setState({ expandedRows: [...event.data, item] });
					});
				} else {
					this.vwPedidoService.detalhar(item.pk).then((response) => {
						item.itens = response;
						this.setState({ expandedRows: [...event.data, item] });
					});
				}
			} else {
				this.setState({ expandedRows: event.data });
			}
		}
	}

	selectPedido = (pedido) => {
		if (pedido != null) {
			if (pedido.id) {
				this.pedidoService.completar(pedido.id).then((response) => {
					response.status = pedido.status;
					ModalRealm.showDialog(<EditarPedido key={TZMUserData.getIdGenerator()} pedido={response} onSave={(pedido) => {
						this.handleList();
						this.displayGrowl(pedido);
					}} />);
				});
			} else {
				this.vwPedidoService.completar(pedido.pk).then((response) => {
					ModalRealm.showDialog(<EditarPedido key={TZMUserData.getIdGenerator()}  pedido={response} onSave={() => {
						this.handleList();
						this.displayGrowl(pedido);
					}} />);
				});
			}
		}
	}

	motivoBloqueio = (rowData) => {
		ModalRealm.showDialog(<MotivoBloqueio key={TZMUserData.getIdGenerator()} logs={rowData.motivosBloqueio} />);
	}

	newPedido = () => {
		if (TZMUserData.hasRole("CAC_PEDD_C")) {
			let usuario = TZMUserData.getPrincipal();
			this.setState({selectedPedido: null});
			this.pedidoService.criar().then((response) => {		
				if (usuario.nivel === "REPRESENTANTE") {
					let vendedor = usuario.vendedor;
					vendedor.pk = vendedor.vendedorId;
					ModalRealm.showDialog(<EditarPedido key={TZMUserData.getIdGenerator()} pedido={{ ...response, vendedor: vendedor}} onSave={(pedido) => {
						setTimeout(() => {
							this.handleList();
						}, 50);
						this.displayGrowl(pedido);
					}} />);
				} else {
					ModalRealm.showDialog(<EditarPedido key={TZMUserData.getIdGenerator()} pedido={response} onSave={(pedido) => {
						setTimeout(() => {
							this.handleList();
						}, 50);
						this.displayGrowl(pedido);
					}} />);
				}
			});
		}
	}

	toggleHidden = () => {
		this.setState({ params: { ...this.state.params, desativados: !this.state.params.desativados } });
	}

	toggleGrupo = () => {
		this.setState({filtrarGrupo: !this.state.filtrarGrupo});
	}

	withParams = (additionalParams) => {
		let regional = this.state.regional;
		let params = Object.assign({}, this.defaultParams, additionalParams);

		if (regional.vendedor && regional.vendedor.id){
			params.vendedor = regional.vendedor.vendedorId.vendedorId;
		}
		if (regional.gerente){
			params.gerente = regional.gerente.id;
		}
		if (regional.supervisor){
			params.supervisor = regional.supervisor.id;			
		}
		if (regional.regional) {
			params.regional = regional.regional.id; 
		}		
		this.handleViewList(params, []);
	}

	toggleDetailed = () => {
		this.setState({ detalhados: !this.state.detalhados });
	}

	toggleDataMinima = () => {
		this.setState({ dataMinima: !this.state.dataMinima });
		setTimeout(() => {
			this.handleList();
		}, 50);
	}

	toggleProducao = () => {
		this.setState({ producao: !this.state.producao });
		setTimeout(() => {
			this.handleList();
		}, 50);
	}

	toggleNotaFiscal = () => {
		this.setState({ checarNf: !this.state.checarNf });
		setTimeout(() => {
			this.handleList();
		}, 50);
	}

	totalGeralM2 = () => {
		let params = {};
		let regional = this.state.regional;
		if (regional.vendedor && regional.vendedor.id){
			params.vendedor = regional.vendedor.vendedorId.vendedorId;
		}
		if (regional.gerente){
			params.gerente = regional.gerente.id;
		}
		if (regional.supervisor){
			params.supervisor = regional.supervisor.id;			
		}
		if (regional.regional) {
			params.regional = regional.regional.id; 
		}
		let params1 = Object.assign({}, params, {empresa: TZMUserData.getEmpresaAAP(), cliente: null, produto: null, painel: "true", pronto: this.state.producao ? "true" : "false",  status: "FATURADO", faturamento: this.rangeMensal(), inclusao: [null, null], entrega: [null, null] });
		let params2 = Object.assign({}, params, {empresa: TZMUserData.getEmpresaAAP(), cliente: null, produto: null, painel: "true", pronto: this.state.producao ? "true" : "false",  status: "CARTEIRA", entrega: this.rangeMensal(), inclusao: [null, null] });
		let params3 = Object.assign({}, params, {empresa: TZMUserData.getEmpresaAAP(), cliente: null, produto: null, painel: "true", pronto: this.state.producao ? "true" : "false",  status: "BLOQUEADO", entrega: this.rangeMensal(), inclusao: [null, null] });		
		this.vwPedidoService.listarSimples(params1).then((response1) => {
			this.vwPedidoService.listarSimples(params2).then((response2) => {
				this.vwPedidoService.listarSimples(params3).then((response3) => {
					let list = response1.concat(response2).concat(response3);
					this.setState({ list });
				});
			});
		});
	}

	listProdutos = (event) => {
		let params = Object.assign({});
		params.produto = event.query;
		this.vwProdutoService.autoCompletar(params).then((list) => {
			this.setState({ listProdutos: list });
		});
	}

	listarGerentes = (event) => {
		this.usuarioService.autoCompletar({usuario: event.query,regional: TZMUserData.GERENTE,sort:'nome'}).then((gerentes) => this.setState({gerentes}));
	}

	findByNomeOrCodigo = (event) => {
		this.municipioService.findByNomeOrCodigo({municipio: event.query}).then((municipios) => this.setState({municipios}));
	}

	listarSupervisores = (event) => {		
		let supervisores = [];
		let usuario = TZMUserData.getPrincipal();		
		switch (usuario.nivel) {
			case TZMUserData.GERENTE:
				supervisores = usuario.regionais.filter((regional) => regional.supervisor && regional.supervisor.nome.toLowerCase().includes(event.query.toLowerCase()));
				supervisores = supervisores.map(r => r.supervisor);
				supervisores = supervisores.filter((v, i, a) => a.findIndex(w => w.id === v.id) === i);				
				this.setState({supervisores});
			break;			
		default:			
			this.usuarioService.autoCompletar({usuario: event.query,regional: TZMUserData.SUPERVISOR, sort:'nome'}).then((supervisores) => this.setState({supervisores}));
			break;
		}
	}
	
	listarVendedores = async (event) => {
		let usuario = TZMUserData.getPrincipal();
		let vendedores = [];
		switch (usuario.nivel) {
			case TZMUserData.REPRESENTANTE:
					this.setState({vendedores});				
				break;
			case TZMUserData.GERENTE:
				usuario.regionais.forEach((regional) => {
					vendedores = vendedores.concat(regional.vendedores.filter((vendedor) => 
					vendedor.nome.toLowerCase().includes(event.query.toLowerCase()) ||  
					vendedor.descricaoAmigavel.toLowerCase().includes(event.query.toLowerCase())));
				});
				vendedores = vendedores.filter((v, i, a) => a.findIndex(w => w.id === v.id) === i);
				this.setState({vendedores})
			break;
			case TZMUserData.SUPERVISOR:
				if (usuario.regionais) {
					usuario.regionais.forEach((regional) => {
						vendedores = vendedores.concat(regional.vendedores.filter((vendedor) => 
						vendedor.nome.toLowerCase().includes(event.query.toLowerCase()) ||  
						vendedor.descricaoAmigavel.toLowerCase().includes(event.query.toLowerCase())));
					});
					vendedores = vendedores.filter((v, i, a) => a.findIndex(w => w.id === v.id) === i);
					this.setState({vendedores})				
				} else {
					this.setState({vendedores});
				}	
			break;			
		default:
			await this.vendedorService.autoCompletar({vendedor: event.query, sort:'nome'}).then((vendedores) => this.setState({vendedores}));
			break;
		}
	}	

	listarRegionais = async (event) => {
		let regionais = [];	
		let usuario = TZMUserData.getPrincipal();	
		switch (usuario.nivel) {
			case TZMUserData.REPRESENTANTE:
				if (usuario.vendedor) {}
				break;
			case TZMUserData.GERENTE:	
				regionais = usuario.regionais.filter((regional) => regional.descricao.toLowerCase().includes(event.query.toLowerCase()));				
				this.setState({regionais})
			break;
			case TZMUserData.SUPERVISOR:
				if (usuario.regionais) {
					regionais = usuario.regionais.filter((regional) => regional.descricao.toLowerCase().includes(event.query.toLowerCase()));
					this.setState({regionais})				
				} else {
					this.setState({regionais});
				}	
			break;			
		default:
			await this.regionalService.autoCompletar({descricao: event.query, sort: "descricao"}).then((regionais) => this.setState({regionais}));
			break;
		}
	}
	
	listClientes = async (event) => {
		await this.vwClienteService.autoComplete({cliente: event.query, sort: 'razao'}).then((listClientes) => this.setState({listClientes}));
	}

	produtoTemplate = (produto) => {
		return (
			<div className="ui-tzm-suggestion-line"><div className="ui-tzm-suggestion-chip">{produto.produtoId}</div>{produto.descricao}</div>
		);
	}

	municipioTemplate = (municipio) => {
		return (
			<div className="ui-tzm-suggestion-line">{municipio.nome} - {municipio.siglaUf}</div>
		);
	}

	queryAutoComplete = (event) => {		
		let params = this.state.params;
		params[event.name][event.field] = event.value;
		this.setState({ params });
	}

	selectAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name] = event.value;
		this.setState({ params });
	}

	clearAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name] = { pk: {}, [event.field]: "" };
		this.setState({ params });
	}

	handleUpdateParams = (event, field, index) => {
		let params = this.state.params;
		params[field][index] = event.target.value;
		this.setState({ params });
	}

	handleClear = (event) => {
		let regional = this.state.regional;
		regional[event.name] = null;
		this.setState({regional});
	}

	handleSelect = (event) => {
		let regional = this.state.regional;
		regional[event.name] = event.value;
		this.setState({regional});
	}

	colorir = (rowData) => {		
		return { 			
			'semRegional' : rowData.regionalDescricaoExcecao != null
		};
	}

	handleUnselect = (event) => {
		let regional = this.state.regional;
		if (!regional[event.name]) {
			regional[event.name] = [];
		}
		regional[event.name] = regional[event.name].filter((e) => e.id !== event.value.id);
		this.setState({regional});
	}

	renderSuggestion = () => {	
		let usuario = TZMUserData.getPrincipal();								
		switch (usuario.nivel) {
			case TZMUserData.REPRESENTANTE:
				break;
			case TZMUserData.GERENTE:
				return (<div className="ui-g-12 ui-g-nopad">
						<div className="ui-g-4 ui-fluid">
							<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.regionais} dropdown completeMethod={this.listarRegionais} name="regional" field="descricao" 
								label="Regional" onUnselect={this.handleUnselect} placeholder="Descrição" value={this.state.regional.regional} />
						</div>
						<div className="ui-g-4 ui-fluid">							
							<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.gerentes} dropdown completeMethod={this.listarGerentes} name="gerente" field="descricaoAmigavel" 
								disabled={this.state.fixedGerente} label="Gerente" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.gerente} />
							
						</div>
						<div className="ui-g-4 ui-fluid">
							<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.supervisores} dropdown completeMethod={this.listarSupervisores} name="supervisor" field="descricaoAmigavel" 
								disabled={this.state.fixedSupervisor} label="Supervisor" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.supervisor} />
						</div>
					</div>
				);				
			case TZMUserData.SUPERVISOR:				
				return (
					<div className="ui-g-12 ui-g-nopad">
						<div className="ui-g-6 ui-fluid">
							<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.regionais} dropdown completeMethod={this.listarRegionais} name="regional" field="descricao" 
								label="Regional" onUnselect={this.handleUnselect} placeholder="Descrição" value={this.state.regional.regional} />
						</div>

						<div className="ui-g-6 ui-fluid">
							<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.supervisores} dropdown completeMethod={this.listarSupervisores} name="supervisor" field="descricaoAmigavel" 
								disabled={this.state.fixedSupervisor} label="Supervisor" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.supervisor} />
						</div>	
					</div>
				);				
		default:			
			return (
				<div className="ui-g-12 ui-g-nopad">
					<div className="ui-g-4 ui-fluid">
						<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
							suggestions={this.state.regionais} dropdown completeMethod={this.listarRegionais} name="regional" field="descricao" 
							label="Regional" onUnselect={this.handleUnselect} placeholder="Descrição" value={this.state.regional.regional} />
					</div>
					<div className="ui-g-4 ui-fluid">							
						<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
							suggestions={this.state.gerentes} dropdown completeMethod={this.listarGerentes} name="gerente" field="descricaoAmigavel" 
							disabled={this.state.fixedGerente} label="Gerente" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.gerente} />
						
					</div>
					<div className="ui-g-4 ui-fluid">
						<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
							suggestions={this.state.supervisores} dropdown completeMethod={this.listarSupervisores} name="supervisor" field="descricaoAmigavel" 
							disabled={this.state.fixedSupervisor} label="Supervisor" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.supervisor} />
					</div>
				</div>
			);			
		}		
	} 

	listarFamilia = async (event) => {		
		await this.familiaService.autoCompletarFamiliaDummy({descricao: event.query, sort:'descricao'}).then((listFamilias) => this.setState({listFamilias}));
	}

	abrirImpressaoEmLote = () => {return ModalRealm.showDialog(<EspelhoLotePedido/>)};

	render() {
		return (
			<div className="tzm-position-relative">
				<TZMMainContent>		
					<div className="ui-g">
						<div className={`ui-g-${this.state.menuLateral ? '9' : '12'}`}>
							<TZMPanel header={title}>
								<TZMForm>
									<div className="ui-g">
										<div className="ui-g-2">
											<TZMCalendar label="Período de Inclusão" placeholder="Inicial" onChange={(event) => this.handleUpdateParams(event, "inclusao", 0)} value={this.state.params.inclusao[0]} />
										</div>
										<div className="ui-g-2">
											<TZMCalendar label="&nbsp;" placeholder="Final" onChange={(event) => this.handleUpdateParams(event, "inclusao", 1)} value={this.state.params.inclusao[1]} />
										</div>
										<div className="ui-g-2">
											<TZMCalendar label="Período de Entrega" placeholder="Inicial" onChange={(event) => this.handleUpdateParams(event, "entrega", 0)} value={this.state.params.entrega[0]} />
										</div>
										<div className="ui-g-2">
											<TZMCalendar label="&nbsp;" placeholder="Final" onChange={(event) => this.handleUpdateParams(event, "entrega", 1)} value={this.state.params.entrega[1]} />
										</div>
										<div className="ui-g-2">
											<TZMCalendar label="Período de Faturamento" placeholder="Inicial" onChange={(event) => this.handleUpdateParams(event, "faturamento", 0)} value={this.state.params.faturamento[0]} />
										</div>
										<div className="ui-g-2">
											<TZMCalendar label="&nbsp;" placeholder="Final" onChange={(event) => this.handleUpdateParams(event, "faturamento", 1)} value={this.state.params.faturamento[1]} />
										</div>
										<div className="ui-g-2">
											<TZMTextField type="number" autoFocus placeholder="Número" label="Pedido" onChange={(event) => this.handleUpdate("pedido", event)} value={this.state.params.pedido} />
										</div>		
										<div className="ui-g-2">
											<TZMTextField type="text" autoFocus  label="Pedido/Cliente" onChange={(event) => this.handleUpdate("pedidoCliente", event)} value={this.state.params.pedidoCliente} />
										</div>
										<div className="ui-g-5 ui-g-nopad" >
											<div className="ui-g-11">
												<TZMAutoComplete itemTemplate={this.clienteTemplate} onClear={this.clearAutoComplete} name="cliente"
													field="descricaoAmigavel" onSelect={this.selectAutoComplete} suggestions={this.state.listClientes}
													completeMethod={this.listClientes} placeholder="Razão social, fantasia ou CNPJ" onChange={this.queryAutoComplete}
													value={this.state.params.cliente.descricaoAmigavel} label="Cliente" />                                        
											</div>
											<div className="ui-g-1 center-button">
												<TZMButton id="warehouse1" title={this.state.filtrarGrupo ? "Filtrar apenas o cliente selecionado" : "Filtrar todos os clientes do mesmo Grupo Empresarial"}
														style={{ float: "left", color: this.state.filtrarGrupo ? '#4CAF50' : '#607D8B' }} onClick={this.toggleGrupo} secondary="true"
														icon={this.state.filtrarGrupo ?  "fas fa-users " : "fas fa-user-tie"} />								
											</div>    
										</div>
										<div className="ui-g-2">
											<TZMAutoComplete onClear={this.clearAutoComplete} itemTemplate={this.municipioTemplate} onSelect={this.selectAutoComplete} suggestions={this.state.municipios} completeMethod={this.findByNomeOrCodigo} 
																name="municipio" field="nome" placeholder="Nome, Código IBGE" onChange={this.queryAutoComplete} value={this.state.params.municipio.nome} label="Munícipio" /></div>

										<div className="ui-g-1">
											<TZMCombobox value={this.state.params.uf} label="UF" onChange={(event) => this.handleUpdate("uf", event)}>{UF.list}</TZMCombobox>
										</div>
										<div className="ui-g-4">
											<span className="ui-fluid ui-g-nopad">
												<TZMAutoComplete itemTemplate={Templates.vendedorTemplateOmega} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
													suggestions={this.state.vendedores} dropdown completeMethod={this.listarVendedores} name="vendedor" field="descricaoAmigavel" 
													disabled={this.state.fixedSeller} label="Vendedor" placeholder="Código Omega, E-mail ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.vendedor} />
											</span>	
										</div>
										<div className="ui-g-2">
											<TZMTextField disabled forac="true" value={this.state.params.produto.produtoId} label="Produto" />
										</div>
										<div className="ui-g-4">
											<TZMAutoComplete onClear={this.clearAutoComplete} itemTemplate={this.produtoTemplate} onSelect={this.selectAutoComplete} suggestions={this.state.listProdutos} completeMethod={this.listProdutos} name="produto" field="descricao" placeholder="Código ou descrição" onChange={this.queryAutoComplete} value={this.state.params.produto.descricao} label="&nbsp;" />
										</div>
																			<div className="ui-g-1">
											<TZMTextField type="number" autoFocus placeholder="Número da NF" label="Nota Fiscal" onChange={(event) => this.handleUpdate("notaFiscal", event)} value={this.state.params.notaFiscal} />
										</div>

										<div className="ui-g-1">
											<TZMCombobox value={this.state.params.status} label="Status" onChange={(event) => this.handleUpdate("status", event)}>
												{this.optionsStatus}
											</TZMCombobox>
										</div>									
										{this.renderSuggestion()}
										<div className="ui-g-4 ui-fluid">									
											<TZMAutoComplete  onClear={this.clearAutoComplete} onSelect={this.selectAutoComplete} onChange={this.queryAutoComplete}
												suggestions={this.state.listFamilias} dropdown completeMethod={this.listarFamilia} name="familia" field="descricao" 
												label="Família" placeholder="Descrição"  value={this.state.params.familia.descricao} />
										</div>
									</div>
									<TZMPanelFooter>
										<TZMButton title={this.state.detalhados ? "Expandir Pedidos" : "Exibir Resumo"} style={{ float: "left" }} onClick={this.toggleDetailed} secondary="true" 
													icon={this.state.detalhados ? "fas fa-folder-open" : "fas fa-folder"} />
										<TZMButton id="warehouse" title={this.state.producao ? "Ocultar status Produzir/Pronto" : "Mostrar status Produzir/Pronto"} 
													style={{ float: "left", color: this.state.producao ? '#4CAF50' : '#607D8B' }} onClick={this.toggleProducao} secondary="true"
													icon={this.state.producao ? "fas fa-pallet" : "fas fa-warehouse "} />
										<TZMButton id="checarNf
										" title={this.state.checarNf ? "Ocultar checagem de notas fiscais" : "Mostrar opção de download de notas fiscais."} 
													style={{ float: "left", color: this.state.checarNf ? '#4CAF50' : '#607D8B' }} onClick={this.toggleNotaFiscal} secondary="true"
													icon={this.state.checarNf ? "fas fa-file-invoice" : "fas fa-receipt "} />
										
										<TZMButton title={this.state.dataMinina ? "Filtrar todos" : "Filtrar somente data mínima de faturamento"} style={{ float: "left" }} 
													onClick={this.toggleDataMinima} secondary="true" icon={this.state.dataMinima ? "fas fa-calendar-alt" : "fas fa-calendar-day"} />	

										{ambiente === "INTERNO" ? <TZMButton title="Imprimir espelho do pedido em lote." style={{ float: "left" }} onClick={this.abrirImpressaoEmLote} secondary="true" icon="fas fa-print" /> : null}		
										{TZMUserData.hasRole("CAC_PEDD_C") ? <TZMButton success="true" icon="fas fa-plus" label="Novo Pedido" onClick={this.newPedido} /> : null}
										<TZMButton icon="fas fa-search" label="Procurar" onClick={this.handleList} />
									</TZMPanelFooter>
								</TZMForm>
							</TZMPanel>
							<div style={{ height: "8px" }} />
							<TZMPanel header={<div>
								<div className="ui-g-6" style={{ textAlign: "left" }}> Resultado da Pesquisa</div>
								<div className="ui-g-2" style={{ textAlign: "right" }}>Total dos pedidos</div>
								
								<div className="ui-g-2" style={{ textAlign: "right" }}>{toDecimal(Math.round(this.state.list.filter(item => item.status !== "RASCUNHO" && item.status !== "ERRO" && item.status !== "CANCELADO" && item.tipoMovimentoId !== 374)
																											.map((item) => item.totalM2).reduce((a, b) => a + b, 0)))} m²</div>
								<div className="ui-g-2" style={{ textAlign: "right" }}>{toCurrency(Math.round(this.state.list.filter(item => item.status !== "RASCUNHO" && item.status !== "ERRO" && item.status !== "CANCELADO" && item.tipoMovimentoId !== 374)
																											.map((item) => item.valorTotal).reduce((a, b) => a + b, 0)))}</div>
							</div>} >
								<DataTable ref={(el) => this.dt = el} emptyMessage="Nenhum registro encontrado" onRowToggle={this.rowExpand} rowExpansionTemplate={this.rowExpansionTemplate} 
									expandedRows={this.state.expandedRows} key={this.state.tableId} onSort={this.onSort} value={this.state.list} paginator rows={15}  
									rowsPerPageOptions={[15, 30, 45]}
									pageLinkSize={15} rowClassName={this.colorir} onPage={this.updatePage}
									footerColumnGroup={
										<ColumnGroup>
											<Row>
												<Column colSpan={2} style={{ fontSize: "12px", textAlign: "right" }} footer="Total de registros " />
												<Column style={{ textAlign: "right" }} footer={this.state.list.length} />
												<Column colSpan={4} style={{ fontSize: "12px", textAlign: "right" }} footer="Total dos pedidos" />
												<Column style={{ textAlign: "right" }} footer={toDecimal(Math.round(this.state.list.filter(item => item.status !== "RASCUNHO" && item.status !== "ERRO" && item.status !== "CANCELADO" && item.tipoMovimentoId !== 374)
																																	.map((item) => item.totalM2).reduce((a, b) => a + b, 0)))} />
												<Column style={{ textAlign: "right" }} footer={toCurrency(this.state.list.filter(item => item.status !== "RASCUNHO" && item.status !== "ERRO" && item.status !== "CANCELADO" && item.tipoMovimentoId !== 374)
																															.map((item) => item.valorTotal).reduce((a, b) => a + b, 0))} />
												<Column colSpan={2} />
											</Row>
										</ColumnGroup>
									}>
									{this.columns} 
								</DataTable>
							</TZMPanel>
						</div>
						{
							this.state.menuLateral &&
							<div className="ui-g-3">						
								<PrevisaoLancamento 
									regional = {this.state.regional}
									onLancadoHojeMes={() => this.withParams({ empresa: TZMUserData.getEmpresaAAP(), painel: "true", pronto: this.state.producao ? "true" : "false", cliente: null, produto: null,  inclusao: this.rangeDiario(), entrega: this.rangeMensal() })}
									onLancadoHojeFuturo={() => this.withParams({ empresa: TZMUserData.getEmpresaAAP(), painel: "true", pronto: this.state.producao ? "true" : "false", cliente: null, produto: null,  inclusao: this.rangeDiario(), entrega: this.rangeMensal(1, null) })}
									onLancadoHojeExportacao={() => this.withParams({ empresa: TZMUserData.getEmpresaAAP(), painel: "true", pronto: this.state.producao ? "true" : "false", cliente: null, produto: null, inclusao: this.rangeDiario(), uf: "EX", entrega: [null, null] })}
									onLancadoHojeNacional={() => this.withParams({ empresa: TZMUserData.getEmpresaAAP(),painel: "true", pronto: this.state.producao ? "true" : "false", cliente: null, produto: null,  inclusao: this.rangeDiario(), uf: "BR", entrega: [null, null] })}
									onFaturado={() => this.withParams({ empresa: TZMUserData.getEmpresaAAP(), painel: "true", pronto: this.state.producao ? "true" : "false", cliente: null, produto: null,  status: "FATURADO", faturamento: this.rangeMensal(), inclusao: [null, null], entrega: [null, null] })}
									onCarteira={() => this.withParams({ empresa: TZMUserData.getEmpresaAAP(),painel: "true", pronto: this.state.producao ? "true" : "false", cliente: null, produto: null,  status: "CARTEIRA", entrega: this.rangeMensal(), inclusao: [null, null] })}
									onBloqueado={() => this.withParams({ empresa: TZMUserData.getEmpresaAAP(), painel: "true", pronto: this.state.producao ? "true" : "false", cliente: null, produto: null,  status: "BLOQUEADO", entrega: this.rangeMensal(), inclusao: [null, null] })}
									onTotalGeralM2={this.totalGeralM2}
									onPedidosAtraso={() => this.withParams({ empresa: TZMUserData.getEmpresaAAP(), painel: "true", pronto: this.state.producao ? "true" : "false", cliente: null, produto: null,  status: "ATRASO", entrega: [null, null], inclusao: [null, null] })}
									onPedidosProntos={() => this.withParams({ empresa: TZMUserData.getEmpresaAAP(),painel: "true", pronto: "false", cliente: null, produto: null,  status: "PRONTO", faturamento: [null, null], inclusao: [null, null], entrega: this.rangeMensal() })}
								/>
								<div style={{ height: "8px" }} />						
								<Panel header="Legenda">
									<div className="ui-g">
										<div style={{ color: '#F44336', textAlign: "center" }} className="ui-g-1"><i className="fa fa-times" /></div><div className="ui-g-3">Erro</div>
										<div style={{ color: '#F44336', textAlign: "center" }} className="ui-g-1"><i className="fa fa-lock" /></div><div className="ui-g-3">Bloqueado</div>
										<div style={{ color: '#607D8B', textAlign: "center" }} className="ui-g-1"><i className="fa fa-dolly-flatbed" /></div><div className="ui-g-3">Produzir</div>
										<div style={{ color: '#F44336', textAlign: "center" }} className="ui-g-1"><i className="fa fa-ban" /></div><div className="ui-g-3">Cancelado</div>
										<div style={{ color: '#607D8B', textAlign: "center" }} className="ui-g-1"><i className="fa fa-wallet" /></div><div className="ui-g-3">Em carteira</div>
										<div style={{ color: '#4CAF50', textAlign: "center" }} className="ui-g-1"><i className="fa fa-pallet" /></div><div className="ui-g-3">Pronto</div>
										<div style={{ color: '#FF9800', textAlign: "center" }} className="ui-g-1"><i className="fa fa-shopping-cart" /></div><div className="ui-g-3">Rascunho</div>
										<div style={{ color: '#4CAF50', textAlign: "center" }} className="ui-g-1"><i className="fa fa-check" /></div><div className="ui-g-3">Faturado</div>
										<div className="ui-g-4" />
										<div style={{ color: '#4CAF50', textAlign: "center" }} className="ui-g-1"><i className="fa fa-tag" /></div><div className="ui-g-3">Desconto Pendente</div>
										<div className="ui-g-4" />
										<div className="ui-g-4" />
										<div style={{ color: '#FF9800', textAlign: "center" }} className="ui-g-1"><i className="fa fa-exclamation" /></div><div className="ui-g-3">Desconto Reprovado</div>
										<div className="ui-g-4" />
										<div className="ui-g-4" />
										<div style={{ color: '#2196F3', textAlign: "center" }} className="ui-g-1"><i className="fa fa-clock" /></div><div className="ui-g-3">Não Processado</div>
										<div className="ui-g-4" />
										<div className="ui-g-4" />
										<div style={{ color: '#000000', textAlign: "center" }} className="ui-g-1"><i className="fa fa-calendar-day" /></div><div className="ui-g-3">Data Mínima</div>
										<div className="ui-g-4" />
										<div className="ui-g-4" />
									</div>
									</Panel>	
									
									<div style={{ height: "15px" }} />
									<Panel header="Status OS no Autolog">
									
									<div className="ui-g">
										<div className="ui-g-6 ui-md-6 ui-lg-6 ui-g">
											<div  style={{textAlign: "center", width:"20px",height:"20px", margin: "1px" }} className="pendente ui-g-2"></div>
											<div className="ui-g-9 ui-md-8">Pendente</div>
										</div>
										<div className="ui-g-6 ui-md-6 ui-lg-6 ui-g">
											<div style={{textAlign: "center", width:"20px",height:"20px", margin: "1px" }} className="liberado ui-g-2"></div>
											<div className="ui-g-9 ui-md-8">Liberado</div>
										</div>
										<div className="ui-g-6 ui-md-6 ui-lg-6 ui-g">
											<div style={{textAlign: "center", width:"20px",height:"20px", margin: "1px" }} className="execucao ui-g-2"></div>
											<div title="Em Execução" className="ui-g-9 ui-md-8">Em Execução</div>	
										</div>
										<div className="ui-g-6 ui-md-6 ui-lg-6 ui-g">
											<div style={{textAlign: "center", width:"20px",height:"20px", margin: "1px" }} className="divergencia ui-g-2"></div>
											<div className="ui-g-9 ui-md-8">Divergência</div>
										</div>
										<div className="ui-g-6 ui-md-6 ui-lg-6 ui-g">
											<div style={{textAlign: "center", width:"20px",height:"20px", margin: "1px" }} className="encerrado ui-g-2"></div>
											<div className="ui-g-9 ui-md-8">Encerrado</div>
										</div>
										<div className="ui-g-6 ui-md-6 ui-lg-6 ui-g">
											<div style={{textAlign: "center", width:"20px",height:"20px", margin: "1px" }} className="expedido ui-g-2"></div>
											<div className="ui-g-9 ui-md-8">Expedido</div>
										</div>			
										
									</div>
								</Panel>
							</div>
						}
					</div> 				
					<TZMShortcut active={TZMShortcut.modalCount === 0}
						onEnter={this.handleList}
						onCtrlH={this.toggleHidden}
						onCtrlN={this.newPedido}
					/>
					<div className="content-section implementation p-fluid">
						<Growl ref={(el) => this.growl = el} baseZIndex={10001002}></Growl>
					</div>
					<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
					<ModalRealm />
				</TZMMainContent>
				<div className="tzm-position-fixed-botton-rigth">
					<Button 
						className="ui-button-secondary ui-button-icon-only ui-button-rounded " 
						icon={`fa-2x fa ${this.state.menuLateral ? 'fa-arrow-right' : 'fa-arrow-left'}`} 
						onClick={() => this.setState({menuLateral: !this.state.menuLateral })} 
						style={{padding: '14px 12px', borderRadius: '100px'}}
					/>
				</div>
			</div>
		);
	}
}
