import { httpGetAsync, httpPost, httpPostVoid } from "../../utilities/TZMRequest";

export class AlteracaoListaPrecoService {

	listar = async (params) => {
		return await httpGetAsync("/alteracao-lista-preco/listar", params);
	}

	completarAlteracaoListaPrecoItens = async (alteracaoListaPreco) => {
		return await httpGetAsync(`/alteracao-lista-preco/${alteracaoListaPreco.id}`);
	}

	isAgendando = async () => {
		return await httpGetAsync(`/alteracao-lista-preco/agendando`, null);
	}	

	checarProgresso= async (id) => {
		return await httpGetAsync(`/alteracao-lista-preco/progresso/${id}`);
	}

	iniciarProcessamento= async (id) => {
		return await httpGetAsync(`/alteracao-lista-preco/processar/${id}`);
	}

	cancelar = async (alteracaoListaPreco) => {
		return await httpPostVoid(`/alteracao-lista-preco/cancelar`, alteracaoListaPreco);
	}

	validarDuplicados = async (alteracaoListaPreco) => {	
		return await httpPost(`/alteracao-lista-preco/validar-duplicados`, alteracaoListaPreco);	
	}
}