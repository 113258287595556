import React from "react";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import Templates from "../../../utilities/Templates";
import TZMUserData from "../../../utilities/TZMUserData";
import { RegionalService } from "../../servicos/RegionalService";
import { UsuarioService } from "../../servicos/UsuarioService";
import { VendedorService } from "../../servicos/VendedorService";
import UsuarioTemplate from "../usuario/UsuarioTemplate";
import { EditarRegional } from "./EditarRegional";

const title = "Regionais";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class Regional extends React.Component {

	state = {
		desativados: false,
		regional: {
			gerente:null,
			supervisor:null,
			vendedor:null,
			descricao: ""
		}
    };

	regionalService = new RegionalService();
	usuarioService = new UsuarioService();		
	vendedorService = new VendedorService();	

	listarRegionais = () => {

		let regional = this.state.regional;
		let params = Object.assign({}, regional);		

		if (regional.vendedor && regional.vendedor.id){
			params.vendedor = regional.vendedor.vendedorId.vendedorId;
		}

		if (regional.gerente){
			params.gerente = regional.gerente.id;
		}

		if (regional.supervisor){
			params.supervisor = regional.supervisor.id;			
		} 
		
		params.sort = "descricao";
		this.regionalService.listarSimples(params).then((regionais) => this.setState({regionais}));
	}

	listarGerentes = async (event) => {
		await  this.usuarioService.autoCompletar({usuario: event.query,regional: TZMUserData.GERENTE,sort:'nome'}).then((gerentes) => this.setState({gerentes}));
	}

	listarSupervisores = async (event) => {		
		await this.usuarioService.autoCompletar({usuario: event.query,regional: TZMUserData.SUPERVISOR, sort:'nome'}).then((supervisores) => this.setState({supervisores}));
	}

	listarVendedores = async (event) => {
		await this.vendedorService.autoCompletar({vendedor: event.query, sort:'nome'}).then((vendedores) => this.setState({vendedores}));
	}		
    
    alternarOcultos = () => {
		this.setState({desativados: !this.state.desativados});
	}

	editarRegional = (event) => {
		this.setState({selectedRegional: event.data});
		if (event.data != null) {
			if (event.data.id != null) {
				this.regionalService.completar(event.data).then((regional) => {
					ModalRealm.showDialog(<EditarRegional key={TZMUserData.getIdGenerator()} regional={regional}  onModalClose={this.listarRegionais}  />);
				});
			} else {
				ModalRealm.showDialog(<EditarRegional key={TZMUserData.getIdGenerator()} regional={event.data} onModalClose={this.listarRegionais}  />);
			}
		}
	}

	criarRegional = () => {
		this.setState({selectedRegional: null});
		this.editarRegional({data: {descricao: ""}});
	}

	excluirRegional = (regional) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
			this.regionalService.excluir(regional).then(this.listarRegionais);
		}} question="Tem certeza de que deseja excluir esta regional?" />);
	}
	
	handleClear = (event) => {
		let regional = this.state.regional;
		regional[event.name] = null;
		this.setState({regional});
	}

	handleSelect = (event) => {
		let regional = this.state.regional;
		regional[event.name] = event.value;
		this.setState({regional});
	}

	handleUnselect = (event) => {
		let regional = this.state.regional;
		if (!regional[event.name]) {
			regional[event.name] = [];
		}
		regional[event.name] = regional[event.name].filter((e) => e.id !== event.value.id);
		this.setState({regional});
	}

	handleUpdate = (event) => {
		let regional = this.state.regional;
		regional[event.name] = event.target.value;
		this.setState({regional});
	}
    
    columns = [
		<TZMColumn header="Descrição" key={0} field="descricao" style={{width: "*"}} />,
		<TZMColumn header="Gerente" key={1} field="gerente.nome" style={{width: "*"}} />,
		<TZMColumn header="Supervisor" key={2} field="supervisor.nome" style={{width: "*"}} />,
		<TZMColumn header="Ações" key={3} style={{width: "6em", textAlign: "center"}} body={(regional) => {
			return (
				<div>
					{TZMUserData.hasRole("____REGN_E") ? <span title="Editar Regional" className="fa fa-edit ui-tzm-icon" onClick={() => this.editarRegional({data: regional})} /> : null}
					{TZMUserData.hasRole("____REGN_D") ? <span title="Remover Regional" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirRegional(regional)} /> : null}
				</div>
			);
		}} />
	];

    render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">
									<div className="ui-g-3">
										<TZMTextField name="descricao" autoFocus onChange={this.handleUpdate} label="Descrição" placeholder="Descrição" value={this.state.regional.descricao} />
									</div>
									<div className="ui-g-3">
									<span className="ui-fluid">
										<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
											suggestions={this.state.gerentes} dropdown completeMethod={this.listarGerentes} name="gerente" field="descricaoAmigavel" 
											disabled={this.state.fixedGerente} label="Gerente" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.gerente} />
									</span>	
								</div>
								<div className="ui-g-3">
									<span className="ui-fluid">
										<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
											suggestions={this.state.supervisores} dropdown completeMethod={this.listarSupervisores} name="supervisor" field="descricaoAmigavel" 
											disabled={this.state.fixedSupervisor} label="Supervisor" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.supervisor} />
									</span>	
								</div>
								<div className="ui-g-3">
									<span className="ui-fluid ui-g-nopad">
										<TZMAutoComplete itemTemplate={Templates.vendedorTemplateOmega} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
											suggestions={this.state.vendedores} dropdown completeMethod={this.listarVendedores} name="vendedor" field="descricaoAmigavel" 
											disabled={this.state.fixedSeller} label="Vendedor" placeholder="Código Omega, E-mail ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.vendedor} />
									</span>	
								</div>
							</div>
							</TZMForm>
							<TZMPanelFooter>
								<TZMButton title={this.state.desativados ? "Ocultar Desativados" : "Exibir Desativados"} style={{float: "left"}} onClick={this.alternarOcultos} secondary icon={this.state.desativados ? "fas fa-eye": "fas fa-low-vision"} />
								{TZMUserData.hasRole("____REGN_C") ? <TZMButton success icon="fas fa-plus" label="Nova Regional" onClick={this.criarRegional} /> : null}
								<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listarRegionais} />
							</TZMPanelFooter>
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable value={this.state.regionais} paginator rows={50} children={this.columns} />
						</TZMPanel>
					</div>
				</div>
			
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}
}
