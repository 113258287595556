import React from "react";
import moment from "moment";
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import { Column } from 'primereact/components/column/Column';
import TZMTable from "../../../components/table/TZMTable";
import { LogLantekService } from "../../servicos/LogLantekService";
import { VisualizarLogLantek } from "./VisualizarLogLantek";
import TZMUserData from "../../../utilities/TZMUserData";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMCalendar from "../../../components/common/TZMCalendar";
import TZMTextField from "../../../components/common/TZMTextField";
import { VwClienteService } from "../../servicos/VwClienteService";
import { VwProdutoService } from "../../servicos/VwProdutoService";
import TZMCombobox from "../../../components/common/TZMCombobox";

const title = "Integração Lantek";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class LogLantek extends React.Component {
	
	constructor(props) {		
		super();        
		moment.locale('pt-BR');
		this.state = {
			logs:[],			
			params: this.defaultParams,
			filtrarGrupo: false
		};
	}

	defaultParams = {
		pedido: "",
		codigo: "",
		dataIni: moment(new Date()).startOf('month').format("YYYY-MM-DD"),
		dataFim: moment(new Date()).endOf('month').format("YYYY-MM-DD"),
		dataEntregaIni: null,
		dataEntregaFim: null,
		produto: { pk: {}, descricao: "" },
		cliente: { pk: {}, descricaoAmigavel: "" },
		forma: null
	};
	
	logLantekService = new LogLantekService();
	vwClienteService = new VwClienteService();
	vwProdutoService = new VwProdutoService();

	listar = async () => {		
		await this.logLantekService.listarSimples(this.params()).then((logs) => this.setState({logs}));
	}

	gerarXML = async () => {		
		await this.logLantekService.gerarXML().then( () => {
			this.logLantekService.listarSimples(this.params()).then((logs) => this.setState({logs}));
		});		
	}
	
	listProdutos = (event) => {
		let params = Object.assign({});
		params.produto = event.query;
		this.vwProdutoService.autoCompletar(params).then((list) => {
			this.setState({ listProdutos: list });
		});
	}
	
	listClientes = async (event) => {await this.vwClienteService.autoComplete({cliente: event.query, sort: 'razao'}).then((listClientes) => this.setState({listClientes}));}

	produtoTemplate = (value) => {
		return (<div className="ui-tzm-suggestion-line"><div className="ui-tzm-suggestion-chip">{value.pk.produtoId}</div>{value.descricao}</div>);
	}

	params() {
		let params = Object.assign({}, this.state.params);
		Object.keys(params).forEach(k => params[k] != null && typeof params[k] === "string" ? params[k] = params[k].trim() : null);
		params.filtrarGrupo = this.state.filtrarGrupo ? "true" : "false";
		if (params.produto) {
			if (params.produto.pk.produtoId) {
				params.produto = params.produto.pk;
			} else {
				params.produto = null;
			}
		}
		if (params.cliente) {
			if (params.cliente.pk.clienteId) {
				params.cliente = params.cliente.pk;
			} else {
				params.cliente = null;
			}
		}
		return params;
	}

	clienteTemplate(cliente) {
		return <div><i className={`fa fa-${cliente.posicao === "ATIVO" ? "check-circle" : "minus-circle"}`} /> {cliente.pk.clienteId} - {cliente.razaoSocial}</div>;
	}

	selectAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name] = event.value;
		this.setState({ params });
	}

    handleUpdate = (key, event) => {
		let params = this.state.params;
		params[key] = event.target.value;
		this.setState({params});
    }

	queryAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name][event.field] = event.value;
		this.setState({ params });
	}

	clearAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name] = { pk: {}, [event.field]: "" };
		this.setState({ params });
	}

	handleCombobox = (event) => {
		let params = this.state.params;
		params[event.name] = event.target.value;
		this.setState({ params });
	}
	
	checkNullArray(value) {
		return value ? value : [];
	}

	toggleGrupo = () => {
		this.setState({filtrarGrupo: !this.state.filtrarGrupo});
    }

	integradoEm = (rowData) => { return (<div>{moment(rowData.registro).format("DD/MM/YYYY HH:mm:ss")}</div>);}

	visualizarLogLantek = (event) => {		
		this.logLantekService.completar(event.id).then((logLantek) => {
			ModalRealm.showDialog(<VisualizarLogLantek key={TZMUserData.getIdGenerator()} logLantek={logLantek}  onModalClose={this.listar}  />);
		});
	}	
	columns = [
		<Column header="Gerado Em" sortable key="registro" field="registro" name="registro" style={{width: "15em", textAlign: "center"}} body={this.integradoEm} />,		
		<Column header="Gerado Por" key="criadoPor" field="criadoPor" style={{width: "15em"}} />,
		<Column header="Código" key="codigo" field="codigo" style={{width: "15em"}} />,
		<Column header="Forma" key="forma" field="forma" style={{width: "10em", textAlign: "center"}} />,		
		<Column header="Ações" key="acoes" style={{width: "6em", textAlign: "center"}} body={(lantek) => { 
			let botoes = []
			botoes.push( <span key={0} title="Visualizar" className="fa fa-desktop ui-tzm-icon" onClick={() => this.visualizarLogLantek(lantek)} />);
			botoes.push( <span key={1} title="Download XML" className={lantek._isLoading ? `fa fa-spin fa-circle-notch` : `fa fa-file-download ui-tzm-icon`} onClick={() => this.downloadXML(lantek)} />);
			return <div style={{ textAlign: "center" }}>{botoes}</div>
		}} />
	];

	downloadXML = async (lantek) => {
		const logs = this.state.logs;
		logs.filter(p => p.id === lantek.id).forEach(p => {
			p._isLoading = true;
		});
		this.setState({ logs });
		await this.logLantekService.downloadXML(lantek.id, lantek.codigo).then(response => {
			const logs = this.state.logs;
			logs.filter(p => p.id === lantek.id).forEach(p => p._isLoading = false);
			this.setState({ logs });
		});
	}

	optionsForma = [
		<option key={0} value={null}></option>,
		<option key={1} value="AUTOMATICO">Automático</option>,
		<option key={2} value="MANUAL">Manual</option>		
	];

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							<TZMForm>
							<div className="ui-g">
									<div className="ui-g-2">
										<TZMCalendar label="Gerado Entre" placeholder="Inicial" onChange={(event) => this.handleUpdate("dataIni", event)} value={this.state.params.dataIni} />
									</div>
									<div className="ui-g-2">
										<TZMCalendar label="&nbsp;" placeholder="Final" onChange={(event) => this.handleUpdate("dataFim", event)} value={this.state.params.dataFim} />
									</div>
									<div className="ui-g-2">
										<TZMCalendar label="Data Entrega Entre" placeholder="Inicial" onChange={(event) => this.handleUpdate("dataEntregaIni", event)} value={this.state.params.dataEntregaIni} />
									</div>
									<div className="ui-g-2">
										<TZMCalendar label="&nbsp;" placeholder="Final" onChange={(event) => this.handleUpdate("dataEntregaFim", event)} value={this.state.params.dataEntregaFim} />
									</div>
									<div className="ui-g-1">
										<TZMTextField type="number" autoFocus placeholder="Número" label="Pedido" onChange={(event) => this.handleUpdate("pedido", event)} value={this.state.params.pedido} />
									</div>
									<div className="ui-g-1">
										<TZMTextField type="number" autoFocus placeholder="Código" label="Código" onChange={(event) => this.handleUpdate("codigo", event)} value={this.state.params.codigo} />
									</div>

									<div className="ui-g-2">
										<TZMCombobox value={this.state.params.forma} label="Status" onChange={(event) => this.handleUpdate("forma", event)}>
											{this.optionsForma}
										</TZMCombobox>
									</div>

									<div className="ui-g-6 ui-g-nopad" >
										<div className="ui-g-11">
											<TZMAutoComplete itemTemplate={this.clienteTemplate} onClear={this.clearAutoComplete} name="cliente"
												field="descricaoAmigavel" onSelect={this.selectAutoComplete} suggestions={this.state.listClientes}
												completeMethod={this.listClientes} placeholder="Razão social, fantasia ou CNPJ" onChange={this.queryAutoComplete}
												value={this.state.params.cliente.descricaoAmigavel} label="Cliente" />                                        
										</div>
										<div className="ui-g-1 center-button">
											<TZMButton id="warehouse1" title={this.state.filtrarGrupo ? "Filtrar apenas o cliente selecionado" : "Filtrar todos os clientes do mesmo Grupo Empresarial"}
													style={{ float: "left", color: this.state.filtrarGrupo ? '#4CAF50' : '#607D8B' }} onClick={this.toggleGrupo} secondary="true"
													icon={this.state.filtrarGrupo ?  "fas fa-users " : "fas fa-user-tie"} />								
										</div>    
									</div>
									<div className="ui-g-2">
										<TZMTextField disabled forac="true" value={this.state.params.produto.pk.produtoId} label="Produto" />
									</div>
									<div className="ui-g-4">
										<TZMAutoComplete onClear={this.clearAutoComplete} itemTemplate={this.produtoTemplate} onSelect={this.selectAutoComplete} suggestions={this.state.listProdutos} completeMethod={this.listProdutos} name="produto" field="descricao" placeholder="Código ou descrição" onChange={this.queryAutoComplete} value={this.state.params.produto.descricao} label="&nbsp;" />
									</div>
								</div>
							</TZMForm>
							<TZMPanelFooter>												
								<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listar} />
								<TZMButton icon="fas fa-file-code" label="Gerar XML" secundary onClick={this.gerarXML} />
							</TZMPanelFooter>
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable key="logs" value={this.state.logs} paginator rows={50} children={this.columns} />
						</TZMPanel>
					</div>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}
	
}
