import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import TZMUserData from "../../utilities/TZMUserData";
import TZMIcon from "../common/TZMIcon";
import "./Menubar.css";

class TZMAppMenu extends React.Component {

	render() {
		let clickAction;
		let icon = "bars";
		if (!TZMUserData.isAutenticado()) {
			icon = "sign-in-alt";
			clickAction = () => window.location.reload();
		}
		let classes = classNames({
			"ui-appmenu": true,
			"ui-appmenu-active": this.props.active
		});
		return (
			<div title={clickAction ? "Entrar no Sistema" : null} className={classes} {...this.props} onClick={clickAction ? clickAction : this.props.onClick}>
				<TZMIcon name={icon} />
			</div>
		);
	}

}

TZMAppMenu.propTypes = {
	active: PropTypes.bool
};

export default TZMAppMenu;
