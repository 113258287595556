import moment from 'moment';
import { Button } from 'primereact/components/button/Button';
import { Column } from 'primereact/components/column/Column';
import { ColumnGroup } from 'primereact/components/columngroup/ColumnGroup';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Row } from 'primereact/components/row/Row';
import React from 'react';
import { CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import TZMCalendar from "../../../components/common/TZMCalendar";
import TZMForm from "../../../components/common/TZMForm";
import { toDate, toDecimal, toPercent } from "../../../components/common/TZMFormatter";
import TZMTextFieldPainel from "../../../components/common/TZMTextFieldPainel";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from '../../../components/modal/ModalRealm';
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMTabPanel from '../../../components/panel/TZMTabPanel';
import TZMTabView from '../../../components/panel/TZMTabView';
import { Progressbar } from '../../../components/progressbar/Progressbar';
import TZMColumn from "../../../components/table/TZMColumn";
import TZMUserData from "../../../utilities/TZMUserData";
import { RelatorioService } from "../../servicos/RelatorioService";
import { VwAcompanhamentoService } from "../../servicos/VwAcompanhamentoService";
import {AcompanhamentoDiarioService} from '../servicos/AcompanhamentoDiarioService'
import "./Acompanhamento.css";
import { AcompanhamentoDetalhe } from './AcompanhamentoDetalhe';
import TZMTextField from "../../../components/common/TZMTextField";
import TZMButton from "../../../components/common/TZMButton";
import { Validator } from "../../../utilities/TZMValidator";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import { AcompanhamentoDetalheProduzidoProduzir } from './AcompanhamentoDetalheProduzidoProduzir';


const title = "Acompanhamento Diário";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export default class Acompanhamento extends React.Component { 

    constructor(props) {

        super(props);
        moment.locale('pt-BR');
        this.state = {    
            activeIndex: 0,
            email: '',  
            acompanhamentoDiarioConfig: {},
            acompanhamentoDiarioConfigOriginal: {},
            loadingVendido: false,
            loadingAcompanhamento: false,
            loading: false,
            listUnidade: [],
            listDiario: [],  
            listGrafico:[],          
            listDetalhes: [],
            listSegmentacao: [],
            expandedRows:[],            
            meta: {
                metaLocal: 0,
                localVender: 0,
                localFaturar: 0,
                percentualLocalFaturado: 0,
                percentualLocalVendido: 0,
                localGeral: 0,                

                metaInter: 0,
                interVender: 0,
                interFaturar: 0,
                percentualInterFaturado: 0,
                percentualInterVendido: 0,
                interGeral: 0,

                sas: 0,
                sasVender: 0,
                sasFaturar: 0,
                percentualSasFaturado: 0,
                percentualSasVendido: 0,
                sasGeral: 0,

                metaGeral: 0,
                geralVender: 0,
                geralFaturar: 0,
                percentualGeralFaturado: 0,
                percentualGeralVendido: 0,                

                budget: 0,
                budgetVender: 0,
                budgetFaturar: 0,
                percentualBudgetFaturado: 0,
                percentualBudgetVendido: 0,
                totalGeral: 0,

                forecast:0,
                
                forecastVender: 0,
                forecastFaturar: 0,
                percentualForecastFaturado: 0,
                percentualForecastVendido: 0,

            },
            faltaFaturar: 0,
            faltaVender: 0,
            produzir: 0,
            percentualFaturado: 0,
            percentualVendido: 0,
            produzido: 0,
            totalGeral: 0,
            mediaFaturamentoDia: 0,
            mediaVendaDia: 0,
            params: this.defaultParams,
            mes: moment()
            
        };
        this.headerTemplate = this.headerTemplate.bind(this);
        this.footerTemplate = this.footerTemplate.bind(this);                
        this.toDecimalClickFooter = this.toDecimalClickFooter.bind(this);
        this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
        this.footerColumns = this.footerColumns.bind(this);
        this.acompanhamentoService = new VwAcompanhamentoService();
        this.relatorioService = new RelatorioService();
        this.AcompanhamentoDiarioService = new AcompanhamentoDiarioService();
    }   
    
    componentDidMount() {
        if (TZMUserData.isAutenticado()) {
            this.resizeGraphs();
            window.addEventListener("resize", this.resizeGraphs);
        }        
    }

    preencherListas(listUnidade,listDiario) {
        this.segmentacao(listUnidade);  
        this.grafico(listDiario);
    }

    defaultParams = {
        data: this.rangeDiario()
    };
    
    criarListaGrafico(value, index, array) {       
        
        let dia = value["dia"];
        let data = value["data"];
        let vendido = value["lancadoDia"];        
        let faturado = value["faturadoDia"];               
        return {dia,vendido,faturado,data};        
    }
    
    grafico(listDiario) {         
        let listGrafico =  listDiario.filter(a => moment(a.data) <= moment() && (a.faturadoDia > 0 || a.lancadoDia > 0)).map(this.criarListaGrafico);        
        this.setState({ listGrafico });
    }

    segmentacao(listUnidade) {
        let listSegmentacao = [];
        listUnidade.reduce((res, value) => {
            if (!res[value.segmentacao]) {
                res[value.segmentacao] = {
                    vendido: 0,
                    faturado: 0,
                    produzido: 0,
                    produzir: 0,
                    devolucao: 0,
                    carteira: 0,
                    bloqueado: 0,
                    total:0,
                    unidade: value.unidade,
                    segmento: value.segmento,
                    segmentacaoExtenso: value.segmentacaoExtenso,
                    agrupadorExtenso: value.agrupadorExtenso,
                    ordem: value.ordem,
                    segmentacao: value.segmentacao,
                    segmentoId: value.segmentoId,
                    data: value.data,                    
                    agrupador: value.agrupador
                };                
                switch (value.segmentacao)  {
                    case 'EXPORTACAO':
                        case 'SAS':
                        case 'RRE':
                        case 'RRU':
                            res[value.segmentacao].segmentoId = null;
                            res[value.segmentacao].segmento = null;
                            break; 
                        default:
                            break;
                }
                listSegmentacao.push(res[value.segmentacao])
            }
            res[value.segmentacao].vendido += value.vendido;
            res[value.segmentacao].faturado += value.faturado;
            res[value.segmentacao].devolucao += value.devolucao;
            res[value.segmentacao].bloqueado += value.bloqueado;
            res[value.segmentacao].carteira += value.carteira;
            res[value.segmentacao].produzido += value.produzido;
            res[value.segmentacao].produzir += value.produzir;
            res[value.segmentacao].total += value.total;
            return res;
        }, {});                
        listSegmentacao.sort((a, b) => this.compararNumeros(a.ordem, b.ordem));
        
        this.setState({ listSegmentacao });
    }  

    rangeDiario() {
        let date = new Date();
        let firstDay = moment(date).format("YYYY-MM-DD");
        return [firstDay];
    }

    handleList = () => {
        this.acompanhamentoService.listar({ data: this.state.params.data[0] }).then((response) => {
            let listUnidades = response.unidades;
            let listDiario = response.diarios;
            this.preencherListas(listUnidades,listDiario);
            this.setState({
                mes: this.state.params.data[0], listDiario: listDiario, listUnidade: listUnidades,
                meta: response.meta, faltaFaturar: response.faltaFaturar, faltaVender: response.faltaVender,
                produzido: response.produzido, produzir: response.produzir, mediaFaturamentoDia: response.mediaFaturamentoDia,
                mediaVendaDia: response.mediaVendaDia
            });
        });
    }

    status = (rowData) => {
        switch (rowData) {
            case "VENDIDO":
                return "Vendido";
            case "BLOQUEADO":
                return "Bloqueado";
            case "CARTEIRA":
                return "Carteira";
            case "FATURADO":
                return "Faturado";
            case "DEVOLUCAO":
                return "Devolução";
            case "AFATURAR":
                return "A Faturar";
            case "BLOQUEADO_DATA_MINIMA":
                return "Bloqueado por Data Mínima ";
            case "VOLUME_LIBERADO":
                return "Volume Liberado";   
            case "PRODUZIDO":
                return "Produzido";
            case "PRODUZIR":
                return "Produzir";
            default:
                return "Não Identificado ";
        }
    }

    handleListDetalhes = (rowData, status, tipo,agrupamento, somado) => {
        let { data, unidade, segmentoId,agrupador,segmentacao} = rowData;
        let unidades = null;
        if (agrupamento) {
            if (tipo === 'DETALHADO') {
                if (segmentacao !== null) {
                    switch (segmentacao) {
                        case 'EXPORTACAO':
                        case 'SAS':
                        case 'RRE':
                        case 'RRU':
                            // segmentoId = null;
                            break;        
                        default:
                            break;
                    }
                } 
            } else {
                if (agrupador === 'AAP') {
                    unidades = ['NACIONAL','EXPORTACAO'];
                } else {
                    unidades = ['RR_ETIQUETAS','RR_URUGUAY','COLACRIL_SAS'];
                }                    
            }
        }  

        let header  = this.status(status);
        this.acompanhamentoService.listarDetalhes({ data, status, tipo, unidade, segmentoId, agrupador, unidades }).then((response) => {
            let lista = response;
            let total;
            if ((status === "FATURADO") || (status === "DEVOLUCAO") ) {
                total = lista.map((item) => item.faturadoDia).reduce((a, b) => a + b, 0); 
            } else if (status === "PRODUZIDO" ) {
                total = lista.map((item) => item.produzido).reduce((a, b) => a + b, 0); 
            } else if (status === "PRODUZIR" ) {
                total = lista.map((item) => item.produzir).reduce((a, b) => a + b, 0); 
            } else {
                total = lista.map((item) => item.lancadoDia).reduce((a, b) => a + b, 0);
            }
            this.detalhes(lista, status, header + " - " + toDecimal(Math.round(total)) + "(m²)");
        });
    }

    relatorioAcompanhamentoVendidoXFaturado = async () => {
        this.setState({ loading: true, loadingVendido: true })
        await this.relatorioService.relatorioAcompanhamentoVendidoXFaturado({ data: this.state.params.data[0] }).then(response => {
            this.setState({ loading: false, loadingVendido: false })
        });
    }

    relatorioAcompanhamentoConsolidado = async () => {
        this.setState({ loading: true, loadingAcompanhamento: true })
        await this.relatorioService.relatorioAcompanhamentoConsolidado({ data: this.state.params.data[0] }).then(response => {
            this.setState({ loading: false, loadingAcompanhamento: false })
        });
    }

    resizeGraphs = () => {
        this.setState({windowSize: window.innerHeight - 94});
    }

    handleUpdateParams = (event, field, index) => {
        let params = this.state.params;
        params[field][index] = event.target.value;
        this.setState({ params });
    }

    dateFormat(rowData, column) {
        return toDate(rowData[column.field]);
    }

    toDecimal(rowData, column) {
        return <div style={{ textAlign: "right" }}>{toDecimal(Math.round(rowData[column.field]))}</div>
    }

    toPercent(rowData, column) {
        return <div style={{ textAlign: "right" }}>{toPercent(rowData[column.field])}</div>
    }

    mesFormat(rowData, column) {
        return moment(rowData[column.field]).format("DD [-] dddd");
    }
    
    detalhes = (rowData, status, header) => {
        if (status === 'PRODUZIDO' || status === 'PRODUZIR') {
            ModalRealm.showDialog(<AcompanhamentoDetalheProduzidoProduzir key={TZMUserData.getIdGenerator()} header={header} listDetalhes={rowData} status={status} />);
        } else {
            ModalRealm.showDialog(<AcompanhamentoDetalhe key={TZMUserData.getIdGenerator()} header={header} listDetalhes={rowData} status={status} />);
        }
    }

    toDecimalClick(rowData, column, status,agrupamento) {
        return <div style={{ textAlign: "right" }}>
            <span style={{ cursor: "pointer" }} title="Detalhar" onClick={() => this.handleListDetalhes(rowData, status, "DETALHADO",agrupamento)} >
                {toDecimal(Math.round(rowData[column.field]))}
            </span>
        </div>;
    }

    toDecimalClickFooter(rowData, column, status,agrupamento) {
        return <div style={{ textAlign: "right" }}>
            {rowData !== null && rowData.length > 0 ?
                <span style={{ fontSize: "14px", cursor: "pointer" }} title="Detalhar" onClick={() => this.handleListDetalhes(rowData[0], status, "TOTAL",agrupamento)} >
                    {toDecimal(Math.round(rowData.map((item) => item[column]).reduce((a, b) => a + b, 0)))}
                </span>
                :
                <span style={{ fontSize: "14px" }}>{0}</span>}
        </div>;
    } 

    colunsDestinatários = [
        <TZMColumn key='emails' header='E-mail' style={{width: "*"}} body={(email) => {
            return(
                <div>
                    {email}
                </div>
            )
        }}/>,
        <TZMColumn key='acoes' header='Ações' style={{width: "6em", textAlign: 'center'}} body={(email) => {
			return (
				<div>
					<span title="Remover destinatário" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirDestinatario(email)} />
				</div>
			);
		}}/>
    ]

    excluirDestinatario(email){
        let emails = [...this.state.acompanhamentoDiarioConfig.emails]
        emails = emails.filter((value) => { 
            return value !== email;
        });
        const acompanhamentoDiarioConfig = {id: this.state.acompanhamentoDiarioConfigOriginal.id, emails: emails}
        this.setState({acompanhamentoDiarioConfig})
    }

    columnsDiario = [
        <TZMColumn sortable field="data" key="data" body={this.mesFormat} header="Dia" />,
        <TZMColumn field="lancadoDia" key="lancadoDia" body={(rowData, column) => this.toDecimalClick(rowData, column, 'VENDIDO',false)} style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} header="Vendido (m²)" />,
        <TZMColumn field="faturadoDia" key="faturadoDia" body={(rowData, column) => this.toDecimalClick(rowData, column, 'FATURADO',false)} style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} header="Faturado (m²)" />,
        <TZMColumn field="faturar" key="faturar" body={(rowData, column) => this.toDecimalClick(rowData, column, 'AFATURAR',false)} style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} header="A Faturar (Liberado + Bloqueado) (m²)" />,
        <TZMColumn field="bloqueadoDataMinima" key="bloqueadoDataMinima" body={(rowData, column) => this.toDecimalClick(rowData, column, 'BLOQUEADO_DATA_MINIMA',false)} style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} header="Bloqueado Data Mínima (m²)" />,
        <TZMColumn field="volumeLiberadoFaturar" key="volumeLiberadoFaturar" body={(rowData, column) => this.toDecimalClick(rowData, column, 'VOLUME_LIBERADO',false)} style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} header="Volume liberado a faturar (m²)" />
    ];

    displayExpander = (rowData) => {
        let expandedRows = this.state.expandedRows;
        const contains = expandedRows.some(r => r === rowData);
        switch (rowData.segmentacao) {
            case 'EXPORTACAO':
            case 'SAS':
            case 'RRE':
            case 'RRU':
            return <i style={{fontSize: "14px !important", cursor: "pointer"}} className={`fa fa-${contains ? "minus" : "plus"}`} onClick={() => {
                if (contains) {
                    expandedRows = expandedRows.filter(r => r !== rowData);
                } else {
                    expandedRows.push(rowData);
                }
                this.setState({expandedRows});
            }} />
            default:
                return null;
        }
    }

    columnsSegmentacao =  [
        <Column key="display" style={{width: "2em", textAlign: "center"}} body={(rowData) => this.displayExpander(rowData)} />,
        <Column field="segmentacaoExtenso" style={{fontWeight: "bold",  textAlign: "center"}} key="segmentacaoExtenso" header="Segmento" />,
        <Column field="faturado" key="faturado" body={(rowData, column) => this.toDecimalClick(rowData, column, 'FATURADO',true)} style={{ fontWeight: "bold",  textAlign: "center" }} header="Faturado (m²)" />,
        <Column field="carteira" key="carteira" body={(rowData, column) => this.toDecimalClick(rowData, column, 'CARTEIRA',true)} style={{ fontWeight: "bold", textAlign: "center" }} header="Carteira Liberado (m²)" />,
        <Column field="bloqueado" key="bloqueado" body={(rowData, column) => this.toDecimalClick(rowData, column, 'BLOQUEADO',true)} style={{ fontWeight: "bold", textAlign: "center" }} header="Carteira Bloqueado (m²)" />,        
        <Column field="produzido" key="produzido"  body={(rowData, column) => this.toDecimalClick(rowData, column, 'PRODUZIDO',true)} style={{ fontWeight: "bold",  textAlign: "center" }} header="Produzido (m²)" />,
        <Column field="produzir" key="produzir"  body={(rowData, column) => this.toDecimalClick(rowData, column, 'PRODUZIR',true)} style={{ fontWeight: "bold", textAlign: "center" }} header="Produzir (m²)" />,
        <Column field="devolucao" key="devolucao" body={(rowData, column) => this.toDecimalClick(rowData, column, 'DEVOLUCAO',true)} style={{ fontWeight: "bold",  textAlign: "center" }} header="Devolução (m²)" />,
        <Column field="total" key="total" body={this.toDecimal} style={{ fontWeight: "bold",  textAlign: "center" }} header="Total Faturado + Carteira" />
    ];

    columnsSegmentacaoDetalhe = [        
        <Column field="segmento" key="segmento" style={{ fontWeight: "bold",  textAlign: "center" }} />,
        <Column field="faturado" key="faturado" body={(rowData, column) => this.toDecimalClick(rowData, column, 'FATURADO',false)} style={{ fontWeight: "bold",  textAlign: "center" }} header="Faturado (m²)" />,
        <Column field="carteira" key="carteira" body={(rowData, column) => this.toDecimalClick(rowData, column, 'CARTEIRA',false)} style={{ fontWeight: "bold", textAlign: "center" }} header="Carteira Liberado (m²)" />,
        <Column field="bloqueado" key="bloqueado" body={(rowData, column) => this.toDecimalClick(rowData, column, 'BLOQUEADO',false)} style={{ fontWeight: "bold", textAlign: "center" }} header="Carteira Bloqueado (m²)" />,        
        <Column field="produzido" key="produzido" body={(rowData, column) => this.toDecimalClick(rowData, column, 'PRODUZIDO',true)}  style={{ fontWeight: "bold",  textAlign: "center" }} header="Produzido (m²)" />,
        <Column field="produzir" key="produzir" body={(rowData, column) => this.toDecimalClick(rowData, column, 'PRODUZIR',true)}  style={{ fontWeight: "bold",  textAlign: "center" }} header="Produzir (m²)" />,
        <Column field="devolucao" key="devolucao" body={(rowData, column) => this.toDecimalClick(rowData, column, 'DEVOLUCAO',false)} style={{ fontWeight: "bold",  textAlign: "center" }} header="Devolução (m²)" />,
        <Column field="total" key="total" body={this.toDecimal} style={{ fontWeight: "bold",  textAlign: "center" }} header="Total Faturado + Carteira" />
    ];

    footerColumns(list) {
        return [
            <Column key={0} colSpan={2} style={{ fontWeight: "bold", fontSize: "14px", textAlign: "Center" }} footer="Total Geral" /> ,
            <Column key={1} style={{ fontWeight: "bold", textAlign: "right",fontSize: "14px"  }} footer={this.toDecimalClickFooter(list, "faturado", "FATURADO",false)} />,
            <Column key={2} style={{ fontWeight: "bold", textAlign: "right",fontSize: "14px"  }} footer={this.toDecimalClickFooter(list, "carteira", "CARTEIRA",false)} />,
            <Column key={3} style={{ fontWeight: "bold", textAlign: "right",fontSize: "14px"  }} footer={this.toDecimalClickFooter(list, "bloqueado", "BLOQUEADO",false)} />,            
            <Column key={4} style={{ fontWeight: "bold", textAlign: "right", fontSize: "14px" }} footer={this.toDecimalClickFooter(list, "produzido", "PRODUZIDO",false)} />, 
            <Column key={5} style={{ fontWeight: "bold", textAlign: "right", fontSize: "14px" }} footer={this.toDecimalClickFooter(list, "produzir", "PRODUZIR",false)} />, 
            <Column key={6} style={{ fontWeight: "bold", textAlign: "right",fontSize: "14px"  }} footer={this.toDecimalClickFooter(list, "devolucao", "DEVOLUCAO",false)} />,
            <Column key={7} style={{ fontWeight: "bold", textAlign: "right",fontSize: "14px"  }} footer={toDecimal(Math.round(list.map((item) => item.total).reduce((a, b) => a + b, 0)))} />
        ];
    }
 
    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    compararNumeros = (a, b) => {
        return a - b;
    }

    headerTemplate(data) {
        return <div style={{ fontSize: "16px", color: "#FFFFFF", backgroundColor: "#77B72A" }}>{data.agrupadorExtenso}</div>
    }
    
    footerTemplate(data) {
        let lista = this.state.listSegmentacao.filter(a => a.agrupador === data.agrupador);
        return ([   
                <td colSpan={2} key={data.segmentacao + '_footerTotalLabel'}  style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} > Total {data.agrupadorExtenso}</td>,
                <td  key="faturado" style={{ fontWeight: "bold", textAlign: "right" }}>
                    {this.toDecimalClickFooter(lista, "faturado", "FATURADO",true)}
                </td>,
                <td  key="carteira" style={{ fontWeight: "bold", textAlign: "right" }}>
                    {this.toDecimalClickFooter(lista, "carteira", "CARTEIRA",true)}                        
                </td>,
                <td  key="bloqueado" style={{ fontWeight: "bold", textAlign: "right" }}>
                    {this.toDecimalClickFooter(lista, "bloqueado", "BLOQUEADO",true)}
                </td>,
                <td  key="produzido" style={{ fontWeight: "bold", textAlign: "right", fontSize: "14px" }}>  
                    {this.toDecimalClickFooter(lista, "produzido", "PRODUZIDO",true)}                    
                </td>,
                <td  key="produzir" style={{ fontWeight: "bold", textAlign: "right", fontSize: "14px" }}>
                    {this.toDecimalClickFooter(lista, "produzir", "PRODUZIR",true)}                    
                </td>,
                <td  key="devolucao" style={{ fontWeight: "bold", textAlign: "right" }}>
                    {this.toDecimalClickFooter(lista, "devolucao", "DEVOLUCAO",true)}                        
                </td>,
                <td  key="total" style={{ fontWeight: "bold", textAlign: "right", fontSize: "14px" }}>
                    {toDecimal(Math.round(lista.map((item) => item.total).reduce((a, b) => a + b, 0)))}
                </td>
            ]
        );
    }

    rowExpansionTemplate(data) {
        let listUnidade = this.state.listUnidade;
        let lista = listUnidade.filter(detalhe => { return data.segmentacao === detalhe.segmentacao ? true : false });
        lista.sort((a, b) => this.compararNumeros(a.segmentoId, b.segmentoId));        
		return (
            <div className="ui-grid ui-fluid" style={{padding: '1em'}}>
                <DataTable value={lista} >
                    {this.columnsSegmentacaoDetalhe}
                </DataTable>
            </div>
		);
    }
    
    customTooltip = ({ active, payload, label }) => {
    if (active && payload !== null) {
        return (
        <div className="style1">
            <div className="label" style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} >
                {`Dia ${moment(label).format("DD [-] dddd")}`}
            </div>
            <div className="intro" style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center", color: "green" }}>
                {`Faturado : ${toDecimal(Math.round(payload[0].value))} ${payload[0].unit}` }
            </div>
            <div className="intro" style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center",color: "red" }}>
                {`Vendido : ${toDecimal(Math.round(payload[1].value))} ${payload[1].unit}` }
            </div>              
        </div>
        );
    }
    
    return null;
    };

    addEmail = () => {
        let email = this.state.email.trim();
        if(!Validator.isEmail(email)) return;
        let emails = [...this.state.acompanhamentoDiarioConfig.emails]
        const exists = emails.find((e) => e === email)
        if(!!exists) return;

        emails.unshift(email)
        const acompanhamentoDiarioConfig = {id: this.state.acompanhamentoDiarioConfigOriginal.id, emails: emails}
        this.setState({acompanhamentoDiarioConfig})
        this.setState({email: ''})
    }
      
    handleChange = (event) => {		
		this.setState({email: event.target.value});
	}

    changeTab = (event) => {
        this.setState({ activeIndex: event.index })

        if(event.index === 3){
            this.AcompanhamentoDiarioService.acompanhamentoDiarioConfig().then((acompanhamentoDiarioConfig) => {
                let acompanhamentoDiarioConfigOrdenar = acompanhamentoDiarioConfig;                
                acompanhamentoDiarioConfigOrdenar.emails = acompanhamentoDiarioConfigOrdenar.emails.sort();
                this.setState({acompanhamentoDiarioConfig: acompanhamentoDiarioConfigOrdenar})
                this.setState({acompanhamentoDiarioConfigOriginal: acompanhamentoDiarioConfigOrdenar})
            })
        }
    }

    saveEmailsConfirmation = () => {
        ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} onYes={this.saveEmails} question="Tem certeza que deseja salvar esses destinatários?" />)
    }

    saveEmails = () => {
        this.AcompanhamentoDiarioService.salvarAcompanhamentoDiarioConfig(this.state.acompanhamentoDiarioConfig).then(() => {
            this.setState({acompanhamentoDiarioConfigOriginal: this.state.acompanhamentoDiarioConfig})
        })
    }

    cancelEmails = () => {
        this.setState({acompanhamentoDiarioConfig: this.state.acompanhamentoDiarioConfigOriginal})
    }

    render() {        
        return (
            <TZMMainContent>
                <div className="ui-g">
                    <div className="ui-g-9">
                        <TZMPanel header={title}>
                            <TZMForm>
                                <div className="ui-g">
                                    <div className="ui-g-1">
                                        <TZMCalendar label="Mês" dateFormat="mm/yy" onChange={(event) => this.handleUpdateParams(event, "data", 0)} value={this.state.params.data[0]} />
                                    </div>
                                    <div style={{ padding: "12px" }} className="ui-g-11">
                                        <Button icon="fa fa-search" label="Procurar" disabled={this.state.loading} className="ui-button-primary" onClick={this.handleList} />
                                        <Button icon={this.state.loadingVendido ? `fa fa-spin fa-circle-notch` : `fa fa-file-pdf`} disabled={this.state.loading} label="Vendido X Faturado" className="ui-button-success" onClick={this.relatorioAcompanhamentoVendidoXFaturado} />
                                        <Button icon={this.state.loadingAcompanhamento ? `fa fa-spin fa-circle-notch` : `fa fa-file-pdf`} disabled={this.state.loading} label="Consolidado" className="ui-button-success" onClick={this.relatorioAcompanhamentoConsolidado} />
                                    </div>
                                </div>
                                <TZMTabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.changeTab(e)}>                                
                                    <TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Diário</span>}>
                                        <div className="ui-g">
                                            <div className="ui-graphic-label-consolidado">Vendido x Faturado</div>
                                            <DataTable emptyMessage="Nenhum registro encontrado" ref={(el) => this.dt = el} value={this.state.listDiario}
                                                footerColumnGroup={
                                                    <ColumnGroup>
                                                        <Row>
                                                            <Column style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} footer="TOTAL" />                                                            
                                                            <Column style={{ fontWeight: "bold" }} footer={this.toDecimalClickFooter(this.state.listDiario, "lancadoDia", "VENDIDO")} />
                                                            <Column style={{ fontWeight: "bold" }} footer={this.toDecimalClickFooter(this.state.listDiario, "faturadoDia", "FATURADO")} />
                                                            <Column style={{ fontWeight: "bold" }} footer={this.toDecimalClickFooter(this.state.listDiario, "faturar", "AFATURAR")} />
                                                            <Column style={{ fontWeight: "bold" }} footer={this.toDecimalClickFooter(this.state.listDiario, "bloqueadoDataMinima", "BLOQUEADO_DATA_MINIMA")} />
                                                            <Column style={{ fontWeight: "bold" }} footer={this.toDecimalClickFooter(this.state.listDiario, "volumeLiberadoFaturar", "VOLUME_LIBERADO")} />
                                                        </Row>
                                                        <Row>
                                                            <Column style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} footer="Média/Dia" />
                                                            <Column style={{ fontWeight: "bold", fontSize: "14px", textAlign: "right" }} footer={toDecimal(Math.round(this.state.mediaVendaDia))} />
                                                            <Column style={{ fontWeight: "bold", fontSize: "14px", textAlign: "right" }} footer={toDecimal(Math.round(this.state.mediaFaturamentoDia))} />
                                                            <Column colSpan={3} />
                                                        </Row>
                                                    </ColumnGroup>
                                                }>
                                                {this.columnsDiario}
                                            </DataTable>
                                        </div>
                                    </TZMTabPanel>
                                    <TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Consolidado</span>}>
                                        <div className="ui-g">
                                            <div className="ui-g-12">
                                            <DataTable  emptyMessage="Nenhum registro encontrado" value={this.state.listSegmentacao} rowGroupMode="subheader" groupField="agrupador" 
                                                         expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({expandedRows:e.data})} rowExpansionTemplate={this.rowExpansionTemplate}
                                                        sortField="agrupador" sortOrder={1} rowGroupHeaderTemplate={this.headerTemplate} rowGroupFooterTemplate={this.footerTemplate}
                                                        footerColumnGroup={
                                                            <ColumnGroup>
                                                                <Row>
                                                                    <Column key="footer" colSpan={9} style={{ opacity: "0" }} footer={"T"}/> 
                                                                </Row> 
                                                                <Row>{this.footerColumns(this.state.listSegmentacao)}</Row>
                                                                <Row>                                                                
                                                                    <Column key={0} colSpan={2} style={{ fontWeight: "bold", fontSize: "12px", textAlign: "Center" }} footer="Total (Faturado - Devolução)  " />                                                                     
                                                                    <Column key={1} style={{ fontWeight: "bold", textAlign: "right",fontSize: "14px"  }} footer={toDecimal(Math.round(this.state.listSegmentacao.map((item) => (item.faturado-item.devolucao)).reduce((a, b) => a + b, 0)))} />
                                                                    <Column key={2} colSpan={6} style={{ fontWeight: "bold", fontSize: "14px", textAlign: "Center" }}  />
                                                                </Row>
                                                                
                                                            </ColumnGroup>}>           
                                                {this.columnsSegmentacao}
                                            </DataTable>
                                            </div>                                            
                                        </div>
                                    </TZMTabPanel>
                                    <TZMTabPanel header={<span><span className="ui-tab-number" children="3" />Gráfico</span>}>
                                        <div className="ui-graphic-label" style={{ fontWeight: "bold", fontSize: "16px", textAlign: "center" }}>Venda x Faturamento</div>
                                        <div style={{ fontWeight: "bold", fontSize: "14px", textAlign: "right" }} >
                                       
                                            <div style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }}>  Média Diária                                                                                              
                                                <div style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} className="legenda-dash">
                                                    <hr className="legenda-dash-1" />Faturado: {toDecimal(Math.round(this.state.mediaFaturamentoDia))}
                                                </div>
                                                <div style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} className="legenda-dash">
                                                    <hr className="legenda-dash-2" />Vendido: {toDecimal(Math.round(this.state.mediaVendaDia))}
                                                </div>
                                            </div>
                                        </div>

                                        <ResponsiveContainer height={Math.round(this.state.windowSize /1.3)} width="100%">
                                            <LineChart data={this.state.listGrafico} margin={{ top: 1, right: 1, left: 30, bottom: 5 }}>
                                            <CartesianGrid stroke="#ccc" strokeDasharray="4 4" />
                                                <ReferenceLine y={this.state.mediaVendaDia} stroke="red" strokeWidth={2} strokeDasharray="4 4" />
                                                <ReferenceLine y={this.state.mediaFaturamentoDia} strokeWidth={2}  strokeDasharray="4 4"  stroke="green" />        

                                                <Legend verticalAlign="bottom" align="center" height={30} iconSize={25}/>
                                                <XAxis tick={{strokeWidth: 0.9}} tickFormatter={(value) => `${moment(value).format("DD")}`} dataKey="data" />   

                                                <YAxis tickFormatter={(value) => `${toDecimal(Math.round(value))}`} tick={{strokeWidth: 0.9}} domain={[0, dataMax => (dataMax * 1.03)]}
                                                        type="number" name={"Quantidade"} unit={" m²"} tickCount={8}/>
                                                <Tooltip content={this.customTooltip} />                                            
                                                <Line type="linear" dataKey="faturado" strokeOpacity={10} unit={"m²"}  stroke="green"
                                                name={<span style={{ fontWeight: "bold", fontSize: "14px", verticalAlign:"middle", textAlign: "center" }}>Faturado</span>}  />
                                                
                                                <Line type="linear" dataKey="vendido" strokeOpacity={10} unit={"m²"}  stroke="red"
                                                name={<span style={{ fontWeight: "bold", fontSize: "14px", verticalAlign:"middle", textAlign: "center" }}>Vendido</span>}  />
                                            </LineChart>                                        
                                        </ResponsiveContainer>                                    
                                    </TZMTabPanel>
                                    <TZMTabPanel  header={<span><span className="ui-tab-number" children="4" />Destinatários</span>}>
                                        <div className="ui-g">
                                            <div className="ui-g-12">
                                                <div className="ui-g ">
                                                    <div className="ui-g-4">
                                                        <TZMTextField onBlur={this.addEmail} label="Adicionar E-mail" name="email" value={this.state.email} onChange={this.handleChange} />
                                                    </div>
                                                    <div className="ui-g-1 centralize">
                                                        <TZMButton success icon="fas fa-plus" title="Adicionar E-mail" onClick={this.addEmail} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ui-g-6"> 
                                                <DataTable 
                                                    emptyMessage="Nenhum registro encontrado" 
                                                    paginator rows={20}                    
                                                    value={this.state.acompanhamentoDiarioConfig?.emails} 
                                                    footer={
                                                        <div className="ui-g">
                                                             <div className="ui-g-3">
                                                                <TZMButton secondary  title="Cancelar alteração" label="Cancelar" icon="fas fa-times" onClick={this.cancelEmails} />
                                                             </div>
                                                            <div className="ui-g-6"/>
                                                            <div className="ui-g-3">
                                                                <TZMButton success title="Salvar alteração" label="Salvar"   icon="fas fa-save" onClick={this.saveEmails} />
                                                            </div>
                                                        </div>
                                                    }>
                                                    {this.colunsDestinatários}
                                                </DataTable>      
                                            </div>
                                        </div>
                                    </TZMTabPanel>
                                </TZMTabView>
                            </TZMForm>
                        </TZMPanel>

                    </div>
                    <div className="ui-g-3">
                        <TZMPanel header="Budget / Forecast (m²)">
                            <TZMForm>
                                <div className="ui-g">
                                    <div className="ui-graphic-label-acompanhamento"> {`Budget ` + this.capitalize(moment(this.state.meta.mes).format("MMMM"))}  </div>
                                    {/* AQUI PARA BAIXO É O FORECAST */}
                                    <div className="ui-g-12" style={{ borderStyle: "groove" }} >
                                        <div className="ui-graphic-label-forecast"> Forecast </div>
                                        <TZMTextFieldPainel style={{ fontSize: "30px", height: "35px", fontWeight: "bold", textAlign: "center" }} readOnly value={toDecimal(this.state.meta.forecast)} />
                                        <div className="ui-g-7" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>Falta faturar para o Forecast (m²)</div>
                                        <div className="ui-g-5" style={{ textAlign: "right", fontSize: "15px", fontWeight: "bold" }}>
                                            {toDecimal(Math.round(this.state.meta.forecastFaturar))}
                                        </div>
                                        <div title="Percentual do Budget faturado atingido" className="ui-g-12">
                                            <Progressbar value={this.state.meta.percentualForecastFaturado} />
                                        </div>
                                        <div className="ui-g-7" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>Falta vender para o Forecast (m²)</div>
                                        <div className="ui-g-5" style={{ textAlign: "right", fontSize: "15px", fontWeight: "bold" }}>
                                            {toDecimal(Math.round(this.state.meta.forecastVender))}
                                        </div>
                                        <div title="Percentual do Budget vendido atingido" className="ui-g-12" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>
                                            <Progressbar value={this.state.meta.percentualForecastVendido} />
                                        </div>
                                        <div className="ui-g-12" style={{ textAlign: "center", fontSize: "15px", fontWeight: "bold" }}>
                                            Carteira Liberado + Carteira Bloqueado + Faturado
                                        </div>
                                        <TZMTextFieldPainel style={{ fontSize: "30px", height: "35px", fontWeight: "bold", textAlign: "center" }} readOnly value={toDecimal(Math.round(this.state.meta.totalGeral))} />
                                    </div>

                                    {/* AQUI PARA CIMA É O FORECAST */}

                                    <div className="ui-g-12" style={{ borderStyle: "groove" }} >
                                        <div className="ui-graphic-label-consolidado"> Budget Geral</div>
                                        <TZMTextFieldPainel style={{ fontSize: "30px", height: "35px", fontWeight: "bold", textAlign: "center" }} readOnly value={toDecimal(this.state.meta.budget)} />
                                        <div className="ui-g-7" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>Falta faturar para o Budget (m²)</div>
                                        <div className="ui-g-5" style={{ textAlign: "right", fontSize: "15px", fontWeight: "bold" }}>
                                            {toDecimal(Math.round(this.state.meta.geralFaturar))}
                                        </div>
                                        <div title="Percentual do Budget faturado atingido" className="ui-g-12">
                                            <Progressbar value={this.state.meta.percentualGeralFaturado} />
                                        </div>
                                        <div className="ui-g-7" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>Falta vender para o Budget (m²)</div>
                                        <div className="ui-g-5" style={{ textAlign: "right", fontSize: "15px", fontWeight: "bold" }}>
                                            {toDecimal(Math.round(this.state.meta.geralVender))}
                                        </div>
                                        <div title="Percentual do Budget vendido atingido" className="ui-g-12" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>
                                            <Progressbar value={this.state.meta.percentualGeralVendido} />
                                        </div>
                                        <div className="ui-g-12" style={{ textAlign: "center", fontSize: "15px", fontWeight: "bold" }}>
                                            Carteira Liberado + Carteira Bloqueado + Faturado
                                        </div>
                                        <TZMTextFieldPainel style={{ fontSize: "30px", height: "35px", fontWeight: "bold", textAlign: "center" }} readOnly value={toDecimal(Math.round(this.state.meta.totalGeral))} />
                                    </div>

                                    <div className="ui-g-12" />
                                    <div className="ui-g-12" style={{ borderStyle: "groove" }} >
                                        <div className="ui-graphic-label-colacril">  Budget Auto Adesivos Paraná </div>
                                        <TZMTextFieldPainel style={{ fontSize: "30px", height: "35px", fontWeight: "bold", textAlign: "center" }} readOnly value={toDecimal(this.state.meta.metaLocal)} />
                                        <div className="ui-g-7" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>Falta faturar para o Budget (m²)</div>
                                        <div className="ui-g-5" style={{ textAlign: "right", fontSize: "15px", fontWeight: "bold" }}>
                                            {toDecimal(Math.round(this.state.meta.localFaturar))}
                                        </div>
                                        <div title="Percentual do Budget faturado atingido" className="ui-g-12">
                                            <Progressbar value={this.state.meta.percentualLocalFaturado} />
                                        </div>
                                        <div className="ui-g-7" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>Falta vender para o Budget (m²)</div>
                                        <div className="ui-g-5" style={{ textAlign: "right", fontSize: "15px", fontWeight: "bold" }}>
                                            {toDecimal(Math.round(this.state.meta.localVender))}
                                        </div>
                                        <div title="Percentual do Budget vendido atingido" className="ui-g-12" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>
                                            <Progressbar value={this.state.meta.percentualLocalVendido} />
                                        </div>
                                        <div className="ui-g-12" style={{ textAlign: "center", fontSize: "15px", fontWeight: "bold" }}>
                                            Carteira Liberado + Carteira Bloqueado + Faturado
                                        </div>
                                        <TZMTextFieldPainel style={{ fontSize: "30px", height: "35px", fontWeight: "bold", textAlign: "center" }} readOnly value={toDecimal(Math.round(this.state.meta.localGeral))} />

                                    </div>                                    
                                    <div className="ui-g-12" />
                                    <div className="ui-g-12" style={{ borderStyle: "groove" }} >
                                        <div className="ui-graphic-label-colacril"> Budget RRE + RRUY</div>
                                        <TZMTextFieldPainel style={{ fontSize: "30px", height: "35px", fontWeight: "bold", textAlign: "center" }} readOnly value={toDecimal(this.state.meta.metaInter)} />
                                        <div className="ui-g-7" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>Falta faturar para o Budget (m²)</div>
                                        <div className="ui-g-5" style={{ textAlign: "right", fontSize: "15px", fontWeight: "bold" }}>
                                            {toDecimal(Math.round(this.state.meta.interFaturar))}
                                        </div>
                                        <div title="Percentual do Budget faturado atingido" className="ui-g-12">
                                            <Progressbar value={this.state.meta.percentualInterFaturado} />
                                        </div>
                                        <div className="ui-g-7" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>Falta vender para o Budget (m²)</div>
                                        <div className="ui-g-5" style={{ textAlign: "right", fontSize: "15px", fontWeight: "bold" }}>
                                            {toDecimal(Math.round(this.state.meta.interVender))}
                                        </div>
                                        <div title="Percentual do Budget vendido atingido" className="ui-g-12" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>
                                            <Progressbar value={this.state.meta.percentualInterVendido} />
                                        </div>
                                        <div className="ui-g-12" style={{ textAlign: "center", fontSize: "15px", fontWeight: "bold" }}>
                                            Carteira Liberado + Carteira Bloqueado + Faturado
                                        </div>
                                        <TZMTextFieldPainel style={{ fontSize: "30px", height: "35px", fontWeight: "bold", textAlign: "center" }} readOnly value={toDecimal(Math.round(this.state.meta.interGeral))} />

                                    </div>
                                    <div className="ui-g-12" />
                                     
                                    <div className="ui-g-12" style={{ borderStyle: "groove" }} >
                                        <div className="ui-graphic-label-colacril">Budget Colacril SAS</div>
                                        <TZMTextFieldPainel style={{ fontSize: "30px", height: "35px", fontWeight: "bold", textAlign: "center" }} readOnly value={toDecimal(this.state.meta.sas)} />
                                        <div className="ui-g-7" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>Falta faturar para o Budget (m²)</div>
                                        <div className="ui-g-5" style={{ textAlign: "right", fontSize: "15px", fontWeight: "bold" }}>
                                            {toDecimal(Math.round(this.state.meta.sasFaturar))}
                                        </div>
                                        <div title="Percentual do Budget faturado atingido" className="ui-g-12">
                                            <Progressbar value={this.state.meta.percentualSasFaturado} />
                                        </div>
                                        <div className="ui-g-7" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>Falta vender para o Budget (m²)</div>
                                        <div className="ui-g-5" style={{ textAlign: "right", fontSize: "15px", fontWeight: "bold" }}>
                                            {toDecimal(Math.round(this.state.meta.sasVender))}
                                        </div>
                                        <div title="Percentual do Budget vendido atingido" className="ui-g-12" style={{ textAlign: "left", fontSize: "15px", fontWeight: "bold" }}>
                                            <Progressbar value={this.state.meta.percentualSasVendido} />
                                        </div>
                                        <div className="ui-g-12" style={{ textAlign: "center", fontSize: "15px", fontWeight: "bold" }}>
                                            Carteira Liberado + Carteira Bloqueado + Faturado
                                        </div>
                                        <TZMTextFieldPainel style={{ fontSize: "30px", height: "35px", fontWeight: "bold", textAlign: "center" }} readOnly value={toDecimal(Math.round(this.state.meta.sasGeral))} />

                                    </div>                                    
                                    <div className="ui-item-separator" />
                                </div>
                            </TZMForm>
                        </TZMPanel>
                    </div>
                </div>
                <span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
                <ModalRealm />
            </TZMMainContent>
        )
    }
}
