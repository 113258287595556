import React from "react";

import "./Sidebar.css";

export default class TZMSidebarCardFooter extends React.Component {

	render() {
		return (
			<div className="ui-sidebar-card-footer" {...this.props} />
		);
	}

}
