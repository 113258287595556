import React from "react";
import "./Panel.css";

export default class TZMPanelFooter extends React.Component {

	render() {
		return (
			<div className="ui-panel-footer" {...this.props} />
		);
	}

}
