import { httpDelete, httpGet, httpPost } from "../../utilities/TZMRequest";

export class MetaService {

	completar(meta) {
		return httpGet(`/metas/${meta.id}`);
	}

	listar(params) {
		return httpGet("/metas", params);
	}

	salvar(meta) {
		return httpPost("/metas", meta);
	}

	excluir(meta) {
		return httpDelete("/metas", meta.id);
	}

	criar() {
		return httpGet("/metas/criar-meta");
	}

}


