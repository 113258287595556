import { httpDelete, httpGet, httpPost } from "../../utilities/TZMRequest";

export class ContaEmailService {
    
    completar(contaEmail) {
		return httpGet(`/contas-email/${contaEmail.id}`);
	}

	listar(params) {
		return httpGet("/contas-email", params);
	}

	salvar(contaEmail) {
		return httpPost("/contas-email", contaEmail);
	}

	excluir(contaEmail) {
		return httpDelete("/contas-email", contaEmail.id);
	}

}
