import { Fetch } from "../../utilities/Fetch";
import { httpGet } from "../../utilities/TZMRequest";

export class CondicaoPagamentoService {

	autoCompletar(query) {
		return Fetch.GetJSON(`/condicoes-pagamento?condicaopagamento=${query}`);
	}

	listar(params) {
		return httpGet("/condicoes-pagamento", params);
	}

	bonificacao(empresa) {
		return httpGet("/condicoes-pagamento/bonificacao", {empresa: empresa});
	}

}
