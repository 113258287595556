import 'moment/min/locales';
import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMTextField from '../../../components/common/TZMTextField';
import TZMConfirm from '../../../components/dialog/TZMConfirm';
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from '../../../components/modal/ModalRealm';
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import { Fetch } from '../../../utilities/Fetch';
import TZMShortcut from "../../../utilities/TZMShortcut";
import TZMUserData from "../../../utilities/TZMUserData";
import { AlcadaService } from "../../servicos/AlcadaService";
import EditarAlcada from "./EditarAlcada";

const title = "Alçadas";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export default class Alcada extends React.Component {

	state = {
		alcadas: [],
		params: {
			descricao: ""
		}
	};

	alcadaService = new AlcadaService();

	componentDidMount() {
		if (TZMUserData.isAutenticado()) {
			Fetch.Get("/ping");
		} else {
			window.location.replace("/");		
		}
	}

	handleList = () => {
		const params = {
			search: `descricao~${this.state.params.descricao}`
		};
		this.alcadaService.listar(params).then((alcadas) => this.setState({alcadas}));
	}

	selectAlcada = (event) => {
		this.setState({selectedAlcada: event.data});
		if (event.data != null) {
			if (event.data.id != null) {
				this.alcadaService.completar(event.data).then((alcada) => {
					ModalRealm.showDialog(<EditarAlcada alcada={alcada} onClose={this.handleList} onDelete={this.handleList} onSave={this.handleList} />);
				});
			} else {
				ModalRealm.showDialog(<EditarAlcada alcada={event.data} onClose={this.handleList} onDelete={this.handleList} onSave={this.handleList} />);
			}
		}
	}

	newAlcada = () => {
		if (TZMUserData.hasRole("____ALCD_C")) {
			this.setState({selectedAlcada: null});
			this.selectAlcada({data: {alcadaLocal: 0}});
		}
	}

	columns = [
		<TZMColumn key={0} header="Descrição" sortable field="descricao" />,		
		<TZMColumn key={1} header="Ações" style={{width: "5em", textAlign: "center"}} body={(alcada) => {
			return (
				<div>
					{TZMUserData.hasRole("____ALCD_E") ? <span key={0} title="Editar Alçada" className="fa fa-edit ui-tzm-icon" onClick={() => this.selectAlcada({data: alcada})} /> : null}
					{TZMUserData.hasRole("____ALCD_D") ? <span key={1} title="Remover Alçada" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.deleteAlcada(alcada)} /> : null}
				</div>
			);
		}} />
	];

	deleteAlcada = (alcada) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
			this.alcadaService.excluir(alcada).then(this.handleList);
		}} question="Tem certeza de que deseja excluir esta alçada?" />);
	}

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">
									<TZMTextField col={12} label="Descrição" value={this.state.params.descricao} name="descricao" onChange={this.handleChange} />
								</div>
							</TZMForm>
							<TZMPanelFooter>
								{TZMUserData.hasRole("____ALCD_C") ? <TZMButton success={true} icon="fas fa-plus" label="Nova Alcada" onClick={this.newAlcada} /> : null}
								<TZMButton primary={true} icon="fas fa-search" label="Procurar" onClick={this.handleList} />
							</TZMPanelFooter>
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable value={this.state.alcadas} paginator rows={50} children={this.columns} />
						</TZMPanel>
					</div>
				</div>
				{this.state.dialogs}
				<TZMShortcut active={TZMShortcut.modalCount === 0}
					onEnter={this.handleList}
					onCtrlH={this.toggleHidden}
					onCtrlN={this.newAlcada}
				/>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}

	handleChange = (event) => {
		const { params } = this.state;
		params[event.name] = event.value;
		this.setState({params});
	}

}
