import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMTextField from "../../../components/common/TZMTextField";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMUserData from "../../../utilities/TZMUserData";
import { TransportadoraService } from "../servicos/TransportadoraService";
import { EditarTransportadora } from "./EditarTransportadora";
import { Column } from "primereact/components/column/Column";
import TZMMainContent from "../../../components/main/TZMMainContent";
import TZMButtonDesativado from "../../../components/common/TZMButtonDesativado";
import TZMTable from "../../../components/table/TZMTable";

const title = "Transportadoras";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class Transportadora extends React.Component {

	state = {
		transportadoras:[],		
		params: {
			transportadora: null,			
			desativados: false,
			page: 0,
			size: 300,
			sort: "razaoSocial"
		}
	};

	transportadoraService = new TransportadoraService();	

	listar = () => {
		let params = Object.assign({}, this.state.params);				
		this.transportadoraService.listarSimples(params).then((transportadoras) => this.setState({transportadoras}));
	}
	
	editar = (event) => {
		this.setState({selectedTransportadora: event.data});
		if (event.data != null) {
			if (event.data.id != null) {
				this.transportadoraService.completar(event.data).then((transportadora) => {
					ModalRealm.showDialog(<EditarTransportadora key={TZMUserData.getIdGenerator()} transportadora={transportadora}  onModalClose={this.listar}  />);
				});
			} else {
				ModalRealm.showDialog(<EditarTransportadora key={TZMUserData.getIdGenerator()} transportadora={event.data} onModalClose={this.listar}  />);
			}
		}
	}

	handleUpdate = (event) => {		
		let params = this.state.params;
		params[event.name] = event.target.value;
		this.setState({params});
	}
	
	handleChange = (event) => {		
		let params = this.state.params;
		params[event.name] = event.value;
		this.setState({params});
	}
	
	handleClear = (event) => {
		let params = this.state.produto;
		params[event.name] = null;
		this.setState({params});
	}	

	handleRefresh = () => {
		this.transportadoraService.atualizar();
	}

	columns = [			
		<Column header="Razão Social" key="razaoSocial" field="razaoSocial" style={{textAlign: "left",width: "15em" }} />,
		<Column header="Nome Fantasia" key="nomeFantasia" field="nomeFantasia" style={{textAlign: "left",width: "15em" }} />,
		<Column header="CNPJ" key="cpfCnpjFormatado" field="cpfCnpjFormatado" style={{textAlign: "center",width: "12em"}} />,
		<Column header="Município" key="municipio" field="municipio" style={{width: "20em"}} />,
		<Column header="Cógido IBGE" key="codigoIbge" field="codigoIbge" style={{width: "12em"}} />,
		<Column header="Ações" key="acoes" style={{width: "6em", textAlign: "center"}} body={(transportadora) => { 
		return (
				<div>
					{TZMUserData.hasRole("FRETE_TRANS_E") ? <span title="Editar Transportadora" className="fa fa-edit ui-tzm-icon" onClick={() => this.editar({data: transportadora})} /> : null}					
				</div>
			);
		}} />
	];

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}> 
							<TZMForm>
								<div className="ui-g">									
									<div className="ui-g-6">
										<TZMTextField name="transportadora" autoFocus onChange={this.handleUpdate} label="Descrição" placeholder="Razão Social,Nome Fantasia, CNPJ ou Código" value={this.state.params.transportadora} />
									</div>
								</div>
							</TZMForm>	
							<TZMPanelFooter >
								<TZMButtonDesativado  name="desativados" desativados={this.state.params.desativados}  onClick={this.handleChange} />
																
								{TZMUserData.isAdministrador() ? 
								<TZMButton icon="fas fa-sync" label="Atualizar" title="Integrar transportadoras" className="ui-button-secondary"  onClick={this.handleRefresh}  />
								: null}								
								<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listar} />
							</TZMPanelFooter>
							
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable value={this.state.transportadoras} paginator rows={50} children={this.columns} />
						</TZMPanel>
					</div>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}
	
}
