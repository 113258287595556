import React from "react";
import "./Menubar.css";

import TZMProgressbar from "./TZMProgressbar";
const menubar = process.env.REACT_APP_EMPRESA === 'COLACRIL' ? 'ui-menubar' : 'ui-menubar-rre';

export default class TZMMenubar extends React.Component {

	render() {
		return (
			<div>
				<div className={menubar} {...this.props}>
					{this.props.children}
					<TZMProgressbar />
				</div>
				<div style={{height: "52px"}} />
			</div>
		);
	}

}
