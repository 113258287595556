import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTable from "../../../components/table/TZMTable";
import TZMShortcut from "../../../utilities/TZMShortcut";
import { Column } from "primereact/components/column/Column";

export class MotivoBloqueio extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            logs: props.logs
        };
    }

    fechar = () => {
        this.setState({ visible: false });
    }

    columnStatus(rowData) {         
        return <div key="icone">
        <span style={{ textAlign: "center" ,padding: "5px", fontSize: "16px", color: "#5c5d5e" }} 
            className={`fas fa-${rowData.icone}`}  />
        </div>;
    }

    columns = [
        <Column key="statusMotivo" style={{ width: "4em", textAlign: "center" }} body={this.columnStatus} />,
        <Column key="siglaMotivo" header="Sigla" style={{ width: "4em", textAlign: "center" }} field="sigla" />,
        <Column key="descricaoMotivo" header="Motivo" field="descricao" style={{ width: "*" }} />,
    ];

    render() {
        return (
            <TZMDialog style={{ width: "500px" }} visible={this.state.visible} modal header="Motivos de Bloqueio" {...this.props}>
                <div className="ui-g">
                    <TZMTable value={this.state.logs}>{this.columns}</TZMTable>
                </div>
                <TZMPanelFooter>
                    <TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-close" onClick={this.fechar} />
                </TZMPanelFooter>
                <TZMShortcut active={this.state.visible} onEscape={this.fechar}
                />
            </TZMDialog>
        );
    }

}
