import React from 'react';
import TZMButton from '../../../components/common/TZMButton';
import TZMTextField from '../../../components/common/TZMTextField';
import TZMDialog from '../../../components/dialog/TZMDialog';
import TZMPopup from '../../../components/dialog/TZMPopup';
import { ModalRealm } from '../../../components/modal/ModalRealm';
import TZMPanelFooter from '../../../components/panel/TZMPanelFooter';
import { RelatorioService } from "../../servicos/RelatorioService";
import TZMProgressbar from '../../../components/menubar/TZMProgressbar';

export default class EspelhoLotePedido extends React.Component {

    constructor() {
		super();
		this.state = {
			visible: true,
            params: {
                pedidoInicio: "",
                pedidoFim: "",
            },
		};
	} 

    relatorioService = new RelatorioService();

    handleUpdate = (key, event) => {
        this.setState({ params: { ...this.state.params, [key]: event.target.value } });
    }

    validarImpressaoEmLote = () => {
		let messages = [];

		if (!this.state.params.pedidoInicio) {
			messages.push("Número do pedido inicial não preenchido.");
		}
		if (!this.state.params.pedidoFim) {
			messages.push("Número do pedido final não preenchido.");
		} 
        
        const inicio = this.state.params.pedidoInicio;
        const fim = this.state.params.pedidoFim;

        const diferenca = parseInt(fim) - parseInt(inicio);
        
        
		if (diferenca < 0) {
			messages.push("O número de pedido final deve ser maior que o inicial.");
		}

		if (diferenca > 50) {
			messages.push("O intervalo deve ser de no máximo 50 pedidos.");
		}

		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup header="Advertência" messages={messages} />);
			return true;
		}

		return false;
	}

	fecharPainel = () => {
		this.setState({visible: false});
	}

    onYes = async () => {
        try {
            let params = Object.assign({}, this.state.params);
            if (this.validarImpressaoEmLote()) return;
            await this.relatorioService.espelhoPedidoLote(params,`PedidosLote_${params.pedidoInicio}_${params.pedidoInicio}`).then((response) => {
                return response;
            })
            .finally(() =>    {                
                this.fecharPainel()
            })
        } catch (error) {
            TZMProgressbar.setActive(false)
            this.fecharPainel()
        }
	}   

    render() {
        return (
          <TZMDialog header="Impressão" visible={this.state.visible} modal>				 
              <div style={{fontSize: '1.3rem'}}  className="ui-question-message">{"Selecione o intervalo de nº de pedidos que você deseja imprimir"}</div>		
              <div className='ui-g impressaoEmLoteGroup'>
                <div className="ui-g-6">
                    <TZMTextField 
                        type="number" 
                        autoFocus 
                        placeholder="Número do pedido inicial" 
                        label="Inicio"
                        onChange={(event) => this.handleUpdate("pedidoInicio", event)} 
                        value={this.state.params.pedidoInicio} 
                    />
                </div>	
                <div className="ui-g-6">
                    <TZMTextField 
                        type="number"  
                        placeholder="Número do pedido final" 
                        label="Fim" 
                        onChange={(event) => this.handleUpdate("pedidoFim", event)} 
                        value={this.state.params.pedidoFim}    
                    />
                </div>	
              </div>      
              <TZMPanelFooter>
                  <TZMButton icon={ `fas fa-file-pdf`} label={'Imprimir'} onClick={this.onYes} />
              </TZMPanelFooter>
          </TZMDialog>
        )
    }
}