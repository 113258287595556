import React from "react";
import {observable} from "mobx";
import {observer} from "mobx-react";
import classNames from "classnames";

import "./Menubar.css";

const TZMProgressbar = observer(class TZMProgressbar extends React.Component {
	
	static staticProps = observable({
		active: false
	});

	static setActive(active) {
		TZMProgressbar.staticProps.active = active;
	}

	render() {
		let classes = classNames({
			"ui-menubar-progressbar": true,
			"ui-menubar-progressbar-rolling": TZMProgressbar.staticProps.active
		});
		let cover = TZMProgressbar.staticProps.active ? <div className="ui-progressbar-cover" /> : <div />;
		return (
			<div className={classes}>{cover}</div>
		);
	}

});

export default TZMProgressbar;