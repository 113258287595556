import { DataTable } from "primereact/components/datatable/DataTable";
import React from "react";
import "./Table.css";


export default class TZMTable extends React.Component {

	render() {
		return (
			<div className={this.props.col ? `ui-g-${this.props.col}` : ""}>
				<DataTable  emptyMessage="Nenhum registro encontrado"  {...this.props}>
					{this.props.children}
				</DataTable>
			</div>
		);
	}

}
