import { Sidebar } from "primereact/components/sidebar/Sidebar";
import React from "react";
import "./Sidebar.css";

export default class TZMSidebar extends React.Component {

	render() {
		return (
			<Sidebar {...this.props} />
		);
	}

}
