import { httpDelete, httpGet, httpPost, httpGetAsync } from "../../utilities/TZMRequest";
export class VendedorService {

    completar(vendedor) {
        return httpGet(`/vendedores/${vendedor.id}`);
    }

    listar(params) {
        return httpGet("/vendedores", params);
    }

    listarSimples = async (params) => {
        return await httpGetAsync("/vendedores/listar-simples", params, true);
    }

    salvar(vendedor) {
        return httpPost("/vendedores", vendedor);
    }

    excluir(vendedor) {
        return httpDelete("/vendedores", vendedor.id);
    }       

    autoCompletar = async (params) => {
        return await httpGetAsync("/vendedores/auto-completar", params, false);
    }

    atualizar  = async () => {
        return httpGetAsync("/vendedores/atualizar");
    }

    listarAlcadas = async (params) => {
        return httpGetAsync("/vendedores/listar-alcadas",params,false);
    }
    
 
}
