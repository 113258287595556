import { Dialog } from "primereact/components/dialog/Dialog";
import React from "react";
import TZMShortcut from "../../utilities/TZMShortcut";
import "./Dialog.css";

export default class TZMDialog extends React.Component {

	constructor() {
		super(); 
		this.state = {
			uniqueID: Math.floor((Math.random() * 10000000) + 1)
		};
	}

	componentWillMount() {
		++TZMShortcut.modalCount;
	}

	onHide = () => {
		if (this.props.onHide) {
			this.props.onHide();
		}
		--TZMShortcut.modalCount;
	}

	render() {
		return (
			<Dialog key={this.state.uniqueID} {...this.props} onHide={this.onHide}>
				{this.props.children}
			</Dialog>
		);
	}

}
