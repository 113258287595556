import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTable from "../../../components/table/TZMTable";
import TZMShortcut from "../../../utilities/TZMShortcut";
import { Column } from "primereact/components/column/Column";

export class AnaliseMensagem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            mensagens: props.mensagens.map(m => ({mensagem: m}))
        };
    }

    fechar = () => {
        this.setState({visible: false});
    }
  
    columns = [                        
        <Column key="value" header="Mensagem" field="mensagem" style={{ width: "*" }} />,
    ];

    render() {
        return (
            <TZMDialog style={{width: "500px"}} visible={this.state.visible} modal header="Mensagens" {...this.props}>
                <div className="ui-g">
                    <TZMTable value={this.state.mensagens}>{this.columns}</TZMTable>
                </div>
                <TZMPanelFooter>
                    <TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-close" onClick={this.fechar} />
                </TZMPanelFooter>
                <TZMShortcut active={this.state.visible} onEscape={this.fechar}
                />
            </TZMDialog>
        );
    }

}
