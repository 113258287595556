import { httpDelete, httpGet, httpPost, httpGetAsync } from "../../utilities/TZMRequest";
export class FamiliaService {

    completar(familia) {
        return httpGet(`/familias/${familia.id}`);
    }

    listar(params) {
        return httpGet("/familias", params);
    }

    listarSimples = async (params) => {
        return await httpGetAsync("/familias/listar-simples", params, true);
    }

    salvar(familia) {
        return httpPost("/familias", familia);
    }

    excluir(familia) {
        return httpDelete("/familias", familia.id);
    }
    
    autoCompletar = async (params) => {
        return await httpGetAsync("/familias/listar-simples", params, false);
    }

    autoCompletarFamilia = async (params) => {
        return await httpGetAsync("/familias/auto-completar-familia", params, false);
    }

    autoCompletarFamiliaDummy = async (params) => {
        return await httpGetAsync("/familias/auto-completar-familia-dummy", params, false);
    }
 
}
