import React from "react";
import TZMShortcut from "../../utilities/TZMShortcut";
import TZMButton from "../common/TZMButton";
import TZMPanelFooter from "../panel/TZMPanelFooter";
import "./Dialog.css";
import TZMDialog from "./TZMDialog";
import TZMUserData from "../../utilities/TZMUserData";


export default class TZMConfirmAdvertencia extends React.Component {

	state = {
		visible: true,
		key: Math.random()
	};

	fecharPainel = () => {
		this.setState({visible: false});
	}

	onYes = () => {
		if (this.props.onYes) {
			this.props.onYes();
		}
		this.fecharPainel();
	}

	onNo = () => {
		if (this.props.onNo) {
			this.props.onNo();
		}
		this.fecharPainel();
	}

	render() {
		let content = this.props.children;
		if (this.props.messages) {
			content = this.props.messages.map((message) => {
				return <li key={TZMUserData.getIdGenerator()} >{message}</li>
			});
			content = <div className="ui-popup-messages">
				<div>Atenção para as seguintes mensagens do sistema:<br />
				</div><ul>{content}</ul><b>Deseja continuar?</b></div>;
		}
		return (
			<TZMDialog key={this.state.key} modal visible={this.state.visible} {...this.props} onHide={this.fecharPainel}>
				{content}
				<TZMPanelFooter>
					<TZMButton success icon="fas fa-check" label={<span><u>S</u>im</span>} onClick={this.onYes} />
					<TZMButton danger icon="fas fa-close" label={<span><u>N</u>ão</span>} onClick={this.onNo} />				
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onEscape={this.fecharPainel} />
			</TZMDialog>
		);
	}

}
