import React from "react";

const SEM_INTERVALO = "SEM_INTERVALO";
const DIARIO = "DIARIO";
const SEMANAL = "SEMANAL";
const QUINZENAL = "QUINZENAL";
const BIMESTRAL = "BIMESTRAL";
const TRIMESTRAL = "TRIMESTRAL";
const QUADRIMESTRAL = "QUADRIMESTRAL";
const SEMESTRAL = "SEMESTRAL";
const ANUAL = "ANUAL";

export class Intervalo {
    
	static intervalos = [
		<option key={SEM_INTERVALO} value={SEM_INTERVALO}>Sem Intervalo</option>,
		<option key={DIARIO} value={DIARIO}>Diário</option>,
		<option key={SEMANAL} value={SEMANAL}>Semanal</option>,
		<option key={QUINZENAL} value={QUINZENAL}>Quinzenal</option>,
		<option key={BIMESTRAL} value={BIMESTRAL}>Bimestral</option>,
		<option key={TRIMESTRAL} value={TRIMESTRAL}>Trimestral</option>,
		<option key={QUADRIMESTRAL} value={QUADRIMESTRAL}>Quadrimestral</option>,
		<option key={SEMESTRAL} value={SEMESTRAL}>Semestral</option>,
		<option key={ANUAL} value={ANUAL}>Anual</option>,
	];

	static descricaoIntervalo(intervalo, quantidade) {		
		switch (intervalo) {
			case SEM_INTERVALO:
				return "Histórico completo."
			case DIARIO:
				if (quantidade > 1)
					return "Histórico referente aos últimos " + quantidade + " dias."				
				return "Histórico referente ao último dia."							
			case SEMANAL:				
				if (quantidade > 1)
					return "Histórico referente as últimas " + quantidade + " semanas."
				return "Histórico referente a última semana."	
			case QUINZENAL:
				return "Histórico referente aos últimos " + (quantidade * 15) + " dias."				
			case BIMESTRAL:				
				return "Histórico referente aos últimos " + (quantidade * 2) + " meses."
			case TRIMESTRAL:				
				return "Histórico referente aos últimos " + (quantidade * 3) + " meses."
			case QUADRIMESTRAL:				
				return "Histórico referente aos últimos " + (quantidade * 4) + " meses."
			case SEMESTRAL:				
				return "Histórico referente aos últimos " + (quantidade * 6) + " meses."
			case ANUAL:				
				if (quantidade > 1)
					return "Histórico referente aos últimos " + quantidade  + " anos."
				return "Histórico referente ao último ano."
			default:
				break;
		}



	}

}
