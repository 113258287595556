import React from "react";
import "./Main.css";

export default class TZMMainContent extends React.Component {

	render() {
		return (
			<div {...this.props} className="ui-main-content">
				{this.props.children}
			</div>
		);
	}

}
