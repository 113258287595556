import { httpGet } from "../../../utilities/TZMRequest";

export class VwAcompanhamentoNovoService {

	listar(params) {
		return httpGet("/painel-pcp/listar", {...params, sort: "data " });        
	}  

	listarDetalhes(params) {
		return httpGet("/acompanhamentos/listarAcompanhamentoDetalhado", { ...params});
	}
}
