import React from "react";
import "./Common.css";

export default class TZMForm extends React.Component {

	render() {
		return (
			<div className="ui-tzm-form" {...this.props}>
				{this.props.children}
			</div>
		);
	}

}
