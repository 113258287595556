import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./Common.css";

export default class TZMTextArea extends React.Component {

	onChange = (event) => {
		if (this.props.onChange) {
			this.props.onChange({...event, name: this.props.name, field: this.props.field, index: this.props.index, value: event.target.value});
		}
	}

	render() {
		let classes = classNames({
			"ui-textfield": true,
			"ui-textarea": true,
			"ui-input-disabled-autocomplete": this.props.forac,
			"ui-input-warning": this.props.warning,
			"ui-input-required": this.props.required && (this.props.value == null || JSON.stringify(this.props.value).trim().length === 0)
		});
		return (
			<div className={`ui-textfield-group ui-g-${this.props.col}`}>
				<label className="ui-input-label">{this.props.label}</label><br />
				<div className={this.props.exclamation ? "ui-input-exclamation" : ""} title={this.props.exclamation}>
					<textarea type="text" className={classes} {...this.props} onChange={this.onChange}></textarea>
				</div>
			</div>
		);
	}

}

TZMTextArea.propTypes = {
	required: PropTypes.bool
};
