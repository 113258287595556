import React, {Component} from "react";
import {DescontoService} from "../../servicos/DescontoService";
import "./Desconto.css";

export class DescontoEmail extends Component {

	state = {
		mensagem: ""
	};

	descontoService = new DescontoService();

	componentDidMount() {
		const items = Object.assign({}, ...this.props.location.search.substring(1).split("&").map(kv => {
			const values = kv.split("=");
			return {[values[0]]: values[1]};
		}));
		console.log(items);
		this.descontoService.desenvolverPublic({descontoId: items.desconto}, {id: items.usuario}, items.status).then(response => {
			switch (response.status) {
			case 200:
				setTimeout(() => window.location.replace(`/descontos?pedido=${items.pedido || ""}`), 3000);
				if (items.status === "APROVADO_ATUALIZAR_TABELA") {
					items.status = "aprovado e a lista de preço foi atualizada";
				}
				this.setState({mensagem: `O desconto solicitado foi ${items.status.toLowerCase()} com sucesso! Você será redirecionado em breve.`});
				break;
			case 400:
				console.log(response);
				console.log(response.body);
				console.log(response.headers);
				setTimeout(() => window.location.replace(`/descontos?pedido=${items.pedido || ""}`), 3000);
				this.setState({mensagem: `Este desconto já foi finalizado e não pode mais ser alterado. Você será redirecionado em breve.`});
				break;
			case 208:
				let mensagem = "O desconto foi aprovado com sucesso, porém não foi possível atualizar a lista referente a esse desconto, pois a mesma "
				mensagem += " já foi atualizada por outra solicitação de desconto.";
				setTimeout(() => window.location.replace(`/descontos?pedido=${items.pedido || ""}`), 12000);
				this.setState({mensagem});
				break;
			default:
				break;
			}
		});
	}

	render() {
		return (
			<div className="desconto-email">
				<div>{this.state.mensagem ? <span>{this.state.mensagem}</span> : <i className="fa fa-spin fa-spinner"/>}</div>
			</div>
		);
	}

}
