
import 'moment/min/locales';
import React from "react";
import { NeoSuggestion } from '../../../components/common/NeoSuggestion';
import TZMButton from "../../../components/common/TZMButton";
import TZMCombobox from '../../../components/common/TZMCombobox';
import TZMTextField from '../../../components/common/TZMTextField';
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPopup from '../../../components/dialog/TZMPopup';
import { ModalRealm } from '../../../components/modal/ModalRealm';
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMColumn from '../../../components/table/TZMColumn';
import TZMTable from '../../../components/table/TZMTable';
import TZMShortcut from "../../../utilities/TZMShortcut";
import { AlcadaService } from "../../servicos/AlcadaService";
import { TZMNumber } from '../../../components/common/TZMNumber';
import { Column } from 'primereact/components/column/Column';
import { Button } from 'primereact/components/button/Button';

export default class EditarAlcada extends React.Component {

	state = {
		visible: true,
		alterado: false,
		alcada: this.completar(Object.assign({}, this.props.alcada))
	};

	completar(alcada) {
		if (!alcada) {
			alcada = {
				descricao: ""
			};
		}
		if (!alcada.perfis) {
			alcada.perfis = [];
		}
		if (!alcada.tipo) {
			alcada.tipo = "DESCONTO";
		}
		if (!alcada.perfis.length) {
			alcada.perfis.push({
				perfil: null,
				limite: 0,
				nivel: 1
			});
		}		
		return alcada;
	}

	alcadaService = new AlcadaService();

	tiposAlcada = [
		<option key="DESCONTO" value="DESCONTO">Desconto</option>,
		<option key="FRETE" value="FRETE">Frete</option>
	];

	handleUpdate = (event) => {
		const { alcada } = this.state;
		alcada[event.name] = event.value;
		this.setState({alcada, alterado: true});
	}

	handleUpdateItem = (event) => {
		const { alcada } = this.state;
		alcada.perfis[event.index][event.name] = event.value;	
		this.setState({alcada, alterado: true});
	}

	validarAlcada = () => {
		const messages = [];
		if (!this.state.alcada.descricao || !this.state.alcada.descricao.length) {
			messages.push("A descrição é obrigatória");
		}
		if (this.state.alcada.perfis.some(ap => !ap.perfil && ap.nivel < this.state.alcada.perfis.length)) {
			messages.push("Todos os níveis devem ter um perfil associado");
		} else {
			const perfids = this.state.alcada.perfis.filter(ap => ap.perfil).map(ap => ap.perfil.id);
			if (this.state.alcada.perfis.some((ap, i) => ap.perfil && perfids.indexOf(ap.perfil.id) !== i)) {
				messages.push("Cada perfil só pode estar associado a um único nível");
			}
		}
		if (this.state.alcada.perfis.some(ap => !ap.limite && !ap.perfil && ap.nivel < this.state.alcada.perfis.length)) {			
			messages.push("Todos os níveis devem ter um limite preenchido");
		} else {
			if (this.state.alcada.perfis.some((ap, i, a) => i + 2 < a.length && Number(ap.limite) > Number(a[i + 1].limite))) {
				messages.push("O limite de um nível inferior não pode ser maior que o de um nível superior");
			}
		}
		return messages;
	}

	salvarAlcada = () => {
		const { alcada } = this.state;
		let messages;
		if (!((messages = this.validarAlcada()).length)) {			
			this.alcadaService.salvar(alcada).then((response) => {
				this.setState({visible: false});
				if (this.props.onSave) {
					this.props.onSave(response);
				}
			});
		} else {
			ModalRealm.showDialog(<TZMPopup messages={messages} />);
		}
	}

	fecharPainel = () => {
		this.setState({visible: false});
		if (this.props.onClose) {
			this.props.onClose(this.state.alcada);
		}
	}

	fecharAlcada = () => {
		if (this.state.alterado) {
			ModalRealm.showDialog(<TZMConfirm onYes={this.fecharPainel} question="Fechar e descartar as alterações feitas?" />);
		} else {
			this.fecharPainel();
		}
	}

	reativarAlcada = () => {
		this.alcadaService.salvar({...this.state.alcada, ativado: true}).then(() => this.fecharPainel("onDelete"));
	}

	desativarAlcada = () => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
			this.alcadaService.excluir(this.state.alcada).then(() => this.fecharPainel("onDelete"));
		}} question="Tem certeza de que deseja desativar esta alçada?" />);
	}

	trocarNivel = (index, value) => {
		const { alcada } = this.state;
		alcada.perfis = alcada.perfis.swap(index, index + value);
		alcada.perfis.forEach((ap, i) => ap.nivel = i + 1);
		this.setState({alcada});
	}

	removerNivel = (index) => {
		const { alcada } = this.state;
		alcada.perfis.splice(index, 1);
		alcada.perfis.forEach((ap, i) => ap.nivel = i + 1);
		this.setState({alcada});
	}

	insertItem = () => {
		const { alcada } = this.state;
		alcada.perfis.push({
			perfil: null,
			limite: 0,
			nivel: alcada.perfis.length + 1
		});
		this.setState({alcada, alterado: true});
		
	}

	render() {
		return (
			<TZMDialog style={{width: "600px"}} visible={this.state.visible} modal header="Alçada" onHide={this.onHide} {...this.props}>
				<div className="ui-g">
					<TZMTextField label="Descrição" name="descricao" value={this.state.alcada.descricao} col={9} onChange={this.handleUpdate} />
					<TZMCombobox label="Tipo" children={this.tiposAlcada} name="tipo" value={this.state.alcada.tipo} col={3} onChange={this.handleUpdate} />
					<div className="ui-g-12">
						<TZMTable value={this.state.alcada.perfis} paginator rows={8} paginatorLeft={ 
									<div style={{ textAlign: "left" }}>
										<Button icon="fas fa-plus" title="Adicionar" className="tzm-paginator-button" onClick={this.insertItem} />
									</div> } >
							<Column style={{width: "5em", textAlign: "center"}} header="Nível" field="nivel" />
							<Column header="Perfil" body={(ap, c) => <NeoSuggestion.Perfil index={c.rowIndex} name="perfil" col={12} value={ap.perfil} onChange={this.handleUpdateItem} />} />
							<Column style={{width: "7em"}} header={`Limite (${this.state.alcada.tipo === "DESCONTO" ? "%" : "R$"})`} body={(ap, c) => 
							<TZMNumber index={c.rowIndex} name="limite" value={ap.limite} col={12} onChange={this.handleUpdateItem} />} />
								
							<TZMColumn header="Ações" style={{width: "7em", textAlign: "center"}} body={(ap, c) => {
								return (
									<div>
										{c.rowIndex > 0 ? <i className="fa fa-arrow-up ui-tzm-icon" onClick={() => this.trocarNivel(c.rowIndex, -1)} /> : null}
										{c.rowIndex + 1 < this.state.alcada.perfis.length ? <i className="fa fa-arrow-down ui-tzm-icon" onClick={() => this.trocarNivel(c.rowIndex, 1)} /> : null}
										{c.rowIndex + 1 < this.state.alcada.perfis.length ? <i className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.removerNivel(c.rowIndex)} /> : null}
									</div>
								);
							}} />
						</TZMTable>
					</div>
				</div>
				<TZMPanelFooter>
					{this.state.alcada.id != null && this.state.alcada.ativado ? <TZMButton style={{float: "left"}} danger title="Desativar Alçada" icon="fas fa-trash-alt" onClick={this.desativarAlcada} /> : null}
					{this.state.alcada.id != null && !this.state.alcada.ativado ? <TZMButton style={{float: "left"}} title="Reativar Alçada" icon="fas fa-eye" onClick={this.reativarAlcada} /> : null}
					<TZMButton success={true} label="Salvar" icon="fas fa-save" onClick={this.salvarAlcada} />
					<TZMButton secondary={true} label="Fechar" icon="fas fa-times" onClick={this.fecharAlcada} />
				</TZMPanelFooter>
				<TZMShortcut
					active={this.state.visible}
					onCtrlS={this.salvarAlcada}
					onEscape={this.fecharAlcada}
				/>
			</TZMDialog>
		);
	}

}
