import React from "react";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMButton from "../../../components/common/TZMButton";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMShortcut from "../../../utilities/TZMShortcut";
import { GestorService } from "../../servicos/GestorService";
import { OperadorService } from "../../servicos/OperadorService";

export class EditarGestor extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			visible: true,
			gestor: props.gestor
		};
		this.gestorService = new GestorService();
		this.operadorService = new OperadorService();
	}

	onHide = () => this.setState({visible: false});

	handleChange = (event) => {
		let gestor = this.state.gestor;
		gestor[event.name] = event.target.value;
		this.setState({gestor});
	}

	salvarGestor = () => {
		let gestor = Object.assign({}, this.state.gestor);
		this.gestorService.salvar(gestor).then((gestor) => {
			if (this.props.onModalClose) this.props.onModalClose(gestor);
			this.onHide();
		});
	}

	listarOperadores = (event) => {
		this.operadorService.listar({operadores: event.query}).then((operadores) => this.setState({operadores}));
	}

	handleClear = (event) => {
		let gestor = this.state.gestor;
		gestor[event.name] = null;
		this.setState({gestor});
	}

	handleSelect = (event) => {
		let gestor = this.state.gestor;
		gestor[event.name] = event.value;
		this.setState({gestor});
	}

	render() {
		return (
			<TZMDialog style={{width: "600px"}} visible={this.state.visible} modal header="Gestor" onHide={this.onHide} {...this.props}>
				<TZMPanel>
					<div className="ui-g">
						<div className="ui-g-6"><TZMTextField label="E-Mail" name="email" value={this.state.gestor.email} onChange={this.handleChange} /></div>
						<div className="ui-g-6"><TZMAutoComplete onClear={this.handleClear} itemTemplate={this.operadorTemplate} onSelect={this.handleSelect} onChange={this.handleSelect} suggestions={this.state.operadores} completeMethod={this.listarOperadores} name="operador" field="nome" label="Operador" value={this.state.gestor.operador} /></div>
					</div>
				</TZMPanel>
				<TZMPanelFooter>
					<TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvarGestor} />
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onHide} />
				</TZMPanelFooter>
				<TZMShortcut
					active={this.state.visible}
					onCtrlS={this.salvarGestor}
					onEscape={this.onHide}
				/>
			</TZMDialog>
		);
	}

}
