import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTable from "../../../components/table/TZMTable";
import TZMShortcut from "../../../utilities/TZMShortcut";
import { Column } from "primereact/components/column/Column";
import { ClienteUtils } from "./ClienteUtils";
import { ColumnGroup } from "primereact/components/columngroup/ColumnGroup";
import { Row } from "primereact/components/row/Row";
import { toCurrency } from "../../../components/common/TZMFormatter";

export class FichaFinanceira extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            fichas: props.fichas
        };
    }

    fechar = () => {
        this.setState({ visible: false });
    }

    currencyFormat(rowData, column) {
		return <div style={{ textAlign: "right" }}>{toCurrency(rowData[column.field])}</div>;
    }
    
    cliente(rowData) {
		return <div>
			<div style={{textAlign: "left", marginTop: "2px"}}>{rowData.razaoSocial}</div>
			<div style={{textAlign: "left"}}>{rowData.nomeFantasia}</div>
			<div style={{textAlign: "left", marginBottom: "2px"}}>{rowData.cpfCnpjFormatado}</div>
		</div>
	}

    columns = [
        <Column  key="razaoSocial" field="razaoSocial" style={{width: "4em", textAlign: "center"}} body={(rowData) => ClienteUtils.clienteTipoNome(rowData, false)}/>,
        <Column key="nomeFantasia" field="nomeFantasia" body={this.cliente} columnKey="nomeFantasia" header="Cliente (Razão Social, Fantasia e CNPJ)" style={{width: "*", textAlign: "left"}} />,
		<Column header="Cidade" key="cidade" field="cidade" style={{width: "15em",textAlign: "center",}} />,
        <Column header="Estado" key="estado" field="estado" style={{textAlign: "center", width: "5em"}} />,		
        <Column header="Limite Crédito" key="limiteCredito" field="limiteCredito" style={{textAlign: "center", width: "10em"}} body={this.currencyFormat} />,	
        <Column header="Títulos à vencer" key="vencer" field="vencer" style={{textAlign: "center", width: "10em"}} body={this.currencyFormat} />,
        <Column header="Títulos vencidos" key="vencido" field="vencido" style={{textAlign: "center", width: "10em"}} body={this.currencyFormat} />,        
        <Column header="Pedidos à Faturar (Bloqueado)" key="bloqueado" field="bloqueado" style={{textAlign: "center", width: "10em"}} body={this.currencyFormat} />,
        <Column header="Pedidos à Faturar (Liberado)" key="carteira" field="carteira" style={{textAlign: "center", width: "10em"}} body={this.currencyFormat} />,
        <Column header="Limite Disponível" key="limiteDisponivel" field="limiteDisponivel" style={{textAlign: "center", width: "10em"}} body={this.currencyFormat} />,
	];

    render() {
        return (
            <TZMDialog style={{ width: "1380px" }} visible={this.state.visible} modal header="Ficha Financeira" {...this.props}>
                <div className="ui-g">
                    <TZMTable value={this.state.fichas} paginator rows={15} 
                    footerColumnGroup={
                        <ColumnGroup>
                            <Row>
                                <Column colSpan={2} />                                
                                <Column colSpan={2} style={{ fontSize: "12px", textAlign: "right" }} footer="Total" />
                                <Column style={{ textAlign: "right",fontWeight: "bold" }} footer={toCurrency(this.state.fichas.map((item) => item.limiteCredito).reduce((a, b) => a + b, 0))} />
                                <Column style={{ textAlign: "right",fontWeight: "bold" }} footer={toCurrency(this.state.fichas.map((item) => item.vencer).reduce((a, b) => a + b, 0))} />
                                <Column style={{ textAlign: "right",fontWeight: "bold" }} footer={toCurrency(this.state.fichas.map((item) => item.vencido).reduce((a, b) => a + b, 0))} />
                                <Column style={{ textAlign: "right",fontWeight: "bold" }} footer={toCurrency(this.state.fichas.map((item) => item.bloqueado).reduce((a, b) => a + b, 0))} />
                                <Column style={{ textAlign: "right",fontWeight: "bold" }} footer={toCurrency(this.state.fichas.map((item) => item.carteira).reduce((a, b) => a + b, 0))} />
                                <Column style={{ textAlign: "right",fontWeight: "bold" }} footer={toCurrency(this.state.fichas.map((item) => item.limiteDisponivel).reduce((a, b) => a + b, 0))} />
                            </Row>
                        </ColumnGroup>
                    }>
                        {this.columns}
                    </TZMTable>
                </div>
                <TZMPanelFooter>
                    <TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-close" onClick={this.fechar} />
                </TZMPanelFooter>
                <TZMShortcut active={this.state.visible} onEscape={this.fechar}
                />
            </TZMDialog>
        );
    }

}
