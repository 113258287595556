import { Validator } from "../../utilities/TZMValidator";

export class EntidadeService {

	cleanup(entidade) {
		Object.keys(entidade).forEach(key => {
			if (entidade[key] != null) {
				if (typeof entidade[key] === "number" || typeof entidade[key] === "boolean") {

				} else {
					if (typeof entidade[key] === "string") {
						if (Validator.isEmpty(entidade[key])) {
							delete entidade[key];
						}
					} else {
						if (Array.isArray(entidade[key]) || entidade[key].constructor.name === "ObservableArray" || entidade[key].constructor.name === "t") {
							
						} else {
							if (!Validator.isEntidade(entidade[key])) {
								delete entidade[key];
							}
						}
					}
				}
			}
		});
		return entidade;
	}

}
