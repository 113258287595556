import React from "react";
const APROVADO = "APROVADO";
const APROVADO_ATUALIZAR_TABELA = "APROVADO_ATUALIZAR_TABELA";
const APROVADO_ADICIONAR_TABELA = "APROVADO_ADICIONAR_TABELA";
const REPROVADO = "REPROVADO";
const ENCAMINHADO = "ENCAMINHADO";
const SOLICITADO = "SOLICITADO";
const ENFILEIRADO = "ENFILEIRADO";
const EM_ANDAMENTO = "EM_ANDAMENTO";
export class DescontoUtils {

	static LEGENDAS = {
		APROVADO : {value: "check", color: "#4cae4c", title: "Solicitação aprovada"},
		APROVADO_ATUALIZAR_TABELA : {value: "check-double", color: "#2196F3", title: "Solicitação aprovada e lista de preço atualizada"},
		APROVADO_ADICIONAR_TABELA : {value: "clipboard-check", color: "#274157", title: "Solicitação aprovada e item adicionado na lista de preço"},
		REPROVADO: {value: "exclamation", color: "#d43f3a", title: "Solicitação reprovada"},
		EM_ANDAMENTO : {value: "clock", color: "#949494", title: "Em Andamento"}
	};	

	static statusAprovado() {return APROVADO;}

	static statusAprovadoAtualizarTabela() {return APROVADO_ATUALIZAR_TABELA;}

	static statusAprovadoAdicionarTabela() {return APROVADO_ADICIONAR_TABELA;}

	static statusReprovado() {return REPROVADO;}

	static statusEncaminhado() {return ENCAMINHADO;}

	static statusSolicitado() {return SOLICITADO;}

	static statusEnfileirado() {return ENFILEIRADO;}

	static statusEmAndamento() {return EM_ANDAMENTO;}

	static statusDesconto(status) {				
		if (status) {
			let {value, color, title} = DescontoUtils.LEGENDAS[status];
			return <span key="statusDesconto" className={`fas fa-${value}`} title={title} style={{textAlign: "center",  color}}  />;
		}			
		return null;
	}

}