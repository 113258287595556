import React from "react";

import "./Sidebar.css";

export default class TZMSidebarCard extends React.Component {

	render() {
		return (
			<div className="ui-sidebar-card ui-sidebar-card-background" {...this.props} />
		);
	}

}
