import { httpDelete, httpGet, httpGetAsync, httpPost } from "../../utilities/TZMRequest";

export class AnaliseService {

    listar(params) {
        return httpGet("/analises", params, false);
    }   
    
    listarSimples = async (params) => {
        return await httpGetAsync("/analises/listar-simples", params, true);
    }
    
    completar(analise) {
        return httpGet(`/analises/${analise.id}`);
    }

    buscar(id) {
        return httpGet(`/analises/${id}`);
    }
    
    salvar(analise) {
        return httpPost("/analises", analise);
    }

    excluir(analise) {
        return httpDelete("/analises", analise.id);
    }

    analisarProdutoPreco = async (params) => {
        return await httpGetAsync("/analises/analisar-produto-preco", params, true);
    }

    reanalisarProdutoPreco = async (params) => {
        return await httpGetAsync("/analises/reanalisar-produto-preco", params,true);
    }

    analiseVendas = async (params) => {
        return await httpGetAsync("/painel-analise-vendas/listar", params,true);
    }

    listarGrafico = async (params) => {
        return await httpGetAsync("/painel-analise-vendas/listar-grafico", params,true);
    }



}
