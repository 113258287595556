import { Fetch } from "../../utilities/Fetch";
import { httpGetAsync } from "../../utilities/TZMRequest";

export class VwTransportadoraService {

	autoCompletar(query) {
		return Fetch.GetJSON(`/vw-transportadoras?transportadora=${query}&size=10&page=0&sort=razaoSocial`);
	}

	buscarAsync = async (transportadora) =>{
		return  httpGetAsync(`/vw-transportadoras/${JSON.stringify(transportadora.pk)}`,null,true);
	}

	listarSimples = async (params) => {
		return  httpGetAsync('/vw-transportadoras/listar-simples', params, false);
	}

}
