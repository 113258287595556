import React from "react";
import TZMForm from "../components/common/TZMForm";
import TZMDialog from "../components/dialog/TZMDialog";
import TZMPanel from "../components/panel/TZMPanel";
import { Fetch } from "./Fetch";


export default class TZMRecover extends React.Component {

	constructor() {
		super();
		this.state = {
			visible: true
		};
	}

	retryConnection = () => {
		Fetch.Get("/ping").then((response) => {
			if (response.status === 200) {
				clearTimeout(this.retryRequest);
				window.location.reload();
			}
		}).catch((error) => {
			if (error.response != null && error.response.status === 403) {
				clearTimeout(this.retryRequest);
				window.location.reload();
			} else {
				this.retryRequest = setTimeout(this.retryConnection, 4000);
			}
		});
	}

	componentDidMount() {
		this.retryConnection();
	}

	retryRequest = null;

	render() {
		return (
			<TZMDialog closable={false} style={{width: "650px"}} modal visible={this.state.visible} header="Erro">
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel>
							<TZMForm>
								<div className="ui-g">
									<div className="ui-g-2">
										<div style={{backgroundColor: "#F44336"}} className="ui-error-header-block">503</div>
									</div>
									<div className="ui-g-10">
										<div className="ui-error-header-error">Serviço Temporariamente Indisponível</div>
									</div>
									<div className="ui-g-12">
										Por favor, aguarde. O serviço auxiliar deste aplicativo está inalcançável.<br />
										Este problema pode ser atribuído a uma das possíveis causas:<br />
										<ul>
											<li>Seu computador pode estar com problemas de conexão com a rede.</li>
											<li>O firewall de rede do seu computador pode estar bloqueando a comunicação.</li>
											<li>Suas configurações de rede local podem estar impedindo a comunicação com o servidor.</li>
											<li>O servidor de aplicação pode estar em manutenção.</li>
										</ul>
										Assim que tudo voltar ao normal, a página será automaticamente recarregada.
									</div>
									<div className="ui-g-12" style={{textAlign: "center"}}>
										<div className="ui-unavailable-progressbar" />
									</div>
								</div>
							</TZMForm>
						</TZMPanel>
					</div>
				</div>
			</TZMDialog>
		);
	}

}
