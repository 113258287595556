export default class TZMAcompanhamento {

    static NIVEL_GERENTE = "GERENTE"
	static NIVEL_REGIONAL ="REGIONAL"
	static NIVEL_VENDEDOR= "VENDEDOR"
	static NIVEL_FAMILIA = "FAMILIA"
    static NIVEL_CLIENTE = "CLIENTE"

    static MES_JANEIRO = "1"
    static MES_FEVEREIRO = "2"
    static MES_MARCO = "3"
    static MES_ABRIL = "4"
    static MES_MAIO = "5"
    static MES_JUNHO = "6"
    static MES_JULHO = "7"
    static MES_AGOSTO = "8"
    static MES_SETEMBRO = "9"
    static MES_OUTUBRO = "10"
    static MES_NOVEMBRO = "11"
    static MES_DEZEMBRO = "12"

}