import { Fetch } from "../../utilities/Fetch";

const basePath = process.env.REACT_APP_API_HOST;

export class VersaoService {

  listar(versao) {
    const path = `${basePath}/versoes/${versao}`;
    return fetch(path, {method: "get", headers: Fetch.buildHeaders(path)}).then((response) => response.json());
  }

}
