import classNames from "classnames";
import { Button } from "primereact/components/button/Button";
import React from "react";
import "./Common.css";


export default class TZMButton extends React.Component {

	render() {
		let classes = classNames({
			"ui-tzm-button": this.props.label,
			"ui-tzm-smallbutton": this.props.label == null,
			"ui-button-secondary": this.props.secondary,
			"ui-button-success": this.props.success,
			"ui-button-info": this.props.info,
			"ui-button-warning": this.props.warning,
			"ui-button-danger": this.props.danger
		});
		if (this.props.className) {
			classes += " " + this.props.className;
		}
		return (
			<Button  {...this.props} className={classes} />
		);
	}

}
