import { httpGet } from "../../utilities/TZMRequest";


export class VwAcompanhamentoService {

	listar(params) {
		return httpGet("/acompanhamentos/listarAcompanhamento", {...params, sort: "data " });        
	}  

	listarDetalhes(params) {
		return httpGet("/acompanhamentos/listarAcompanhamentoDetalhado", { ...params});
	}
}
