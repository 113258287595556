import React from "react";
import classNames from "classnames";

export class TZMNumber extends React.Component {

	render() {
		let classes = classNames({
			"ui-textfield": true,
			"ui-input-disabled-autocomplete": this.props.forac,
			"ui-input-warning": this.props.warning,
			"ui-input-required": this.props.required && (this.props.value == null || JSON.stringify(this.props.value).trim().length === 0)
		});
		let icon = null;
		if (this.props.exclamation) icon = "ui-input-exclamation";
		if (this.props.pending) icon = "ui-input-pending";
		if (this.props.confirmed) icon = "ui-input-confirmed";
		if (this.props.integrated) icon = "ui-input-integrated";
		return (
			<div className={`ui-textfield-group${this.props.col ? ` ui-g-${this.props.col}` : ""}`}>
				<label className="ui-input-label">{this.props.label}</label><br />
				<div className={icon} title={this.props.exclamation}>
					<input type="text" {...this.props} className={`${this.props.className} ${classes}`} onKeyDown={this.handleKeyDown} onChange={this.handleChange} />
				</div>
			</div>
		);
	}

	static decimalSeparatorChars = [".", ","];

	handleKeyDown = (event) => {
		if (this.props.onKeyDown) {
			this.props.onKeyDown(event);
		}
		return !((!this.props.decimal || String(this.props.value).includes(".")) && TZMNumber.decimalSeparatorChars.includes(event.key) && event.preventDefault());
	}

	handleChange = (event) => {
		event.target.value = event.target.value.replace(/,/g, ".").replace(/[^0-9.]/g, "");
		if (!event.target.value.length) {
			event.target.value = "0";
		}
		if (this.props.min !== undefined && Number(event.target.value) < this.props.min) {
			event.target.value = String(this.props.min);
		}
		if (this.props.max !== undefined && Number(event.target.value) > this.props.max) {
			event.target.value = String(this.props.max);
		}
		if (event.target.value.startsWith("0") && event.target.value.replace(/[^0-9]/g, "").length > 1) {
			event.target.value = String(Number(event.target.value));
		}
		if (this.props.onChange) {
			this.props.onChange({...event, index: this.props.index, name: this.props.name, value: event.target.value});
		}
	}

}
