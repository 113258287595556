import { Button } from "primereact/components/button/Button";
import React from "react";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMButton from "../../../components/common/TZMButton";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPopup from "../../../components/dialog/TZMPopup";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import Templates from "../../../utilities/Templates";
import TZMShortcut from "../../../utilities/TZMShortcut";
import { Validator } from "../../../utilities/TZMValidator";
import { VwClienteService } from "../../servicos/VwClienteService";
import { RegionalService } from "../../servicos/RegionalService";
import { FamiliaService } from "../../servicos/FamiliaService";
import { UsuarioService } from "../../servicos/UsuarioService";
import { VendedorService } from "../../servicos/VendedorService";
import UsuarioTemplate from "../usuario/UsuarioTemplate";
import TZMCombobox from "../../../components/common/TZMCombobox";
import {AlcadaService} from "../../servicos/AlcadaService";

export class EditarRegional extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			visible: true,
			regional: this.completarNulos(this.props.regional),
			expandedRows: [],
			clientes: [],
			alcadas: []
		};
        this.regionalService = new RegionalService();
		this.usuarioService = new UsuarioService();
		this.vendedorService = new VendedorService();
		this.familiaService = new FamiliaService();
		this.vwClienteService = new VwClienteService();
		this.alcadaService = new AlcadaService();
	}

	completarNulos(regional) {				
		if (!regional.vendedores) {
			regional.vendedores = [];
		}
		if (!regional.excecoes) {
			regional.excecoes = [];
		}
		if (!regional.familias) {
			regional.familias = [];
		}
		if (!regional.filtro) {
			regional.filtro = 'REPRESENTANTE'
		}

		return regional;
	}

	onHide = () => this.setState({visible: false});

	handleChange = (event) => {		
		let regional = this.state.regional;
		regional[event.name] = event.target.value;
		this.setState({regional});
	}

	salvarRegional = () => {
		if (this.validarRegional()){
			let regional = Object.assign({}, this.state.regional);
			this.regionalService.salvar(regional).then((regional) => {
				if (this.props.onModalClose) this.props.onModalClose(regional);
				this.onHide();
			});
		}
	}

	handleUpdate = (key, event) => {
		this.setState({ regional: { ...this.state.regional, [key]: event.target.value } });
	}	

	handleClear = (event) => {
		let regional = this.state.regional;
		regional[event.name] = null;
		this.setState({regional});
	}

	handleSelect = (event) => {
		let regional = this.state.regional;
		regional[event.name] = event.value;
		this.setState({regional});
    }

    addVendedores = (event) => {
		let regional = this.state.regional;
		regional.vendedores.unshift(event.value);
		regional.vendedor = null;
		this.setState({regional});
	}
	
	addFamilia = (event) => {
		let regional = this.state.regional;
		regional.familias.unshift(event.value);
		regional.familia = null;
		this.setState({regional});
	}
	
    listarUsuarios = async (event) => {
		await this.usuarioService.autoCompletar({usuario: event.query,sort:'nome'}).then((usuarios) => this.setState({usuarios}));
	}

	listarVendedores = async  (event) => {
		let remover = this.state.regional.vendedores;	
		await this.vendedorService.autoCompletar({vendedor: event.query,notin:remover.map((vendedor) => vendedor.id), sort:'nome'}).then((vendedores) => { 
				this.setState({vendedores})
			}
		);
	}

	listarFamilias = async  (event) => {
		let regional = this.state.regional;	
		let params = [];
		if (regional.familias) {
			params.notin = regional.familias.map((familia) => familia.id);
		}
		params.familia = event.query;
		params.sort = 'descricao';		
		await this.familiaService.autoCompletar(params).then((familias) => this.setState({familias}));
	}

	excluirVendedor = (usuario) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
		let regional = this.state.regional;
		regional.vendedores =  regional.vendedores.filter(vendedor => vendedor !== usuario);
		this.setState({regional});
		}} question="Tem certeza de que deseja remover este vendedor?" />);
	}

	excluirFamilia = (familia) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
		let regional = this.state.regional;
		regional.familias =  regional.familias.filter(f => f !== familia);
		this.setState({regional});
		}} question="Tem certeza de que deseja remover esta família?" />);
	}

	excluirExcecao = (excecao) => {
		ModalRealm.showDialog(<TZMConfirm key={Math.random()} onYes={() => {
		let regional = this.state.regional;
		regional.excecoes.splice(regional.excecoes.findIndex((item) => item === excecao), 1);
		this.setState({regional});
		}} question="Tem certeza de que deseja remover esta exceção?" />);
	}


    columnsVendedores = [
		<TZMColumn key="codigo" header="Código Omega"  body={Templates.codigoOmega}  style={{width: "8em", textAlign: "center"}} />,		
		<TZMColumn key="nome" sortable header="Nome" field="nome" style={{width: "*"}} />,
		<TZMColumn key="celular" sortable header="Celular" field="celular" style={{width: "12em"}} />,
		<TZMColumn key="telefone" sortable header="Telefone" field="telefone" style={{width: "12em"}} />,
		<TZMColumn key="email" sortable header="E-mail" body={Templates.formataEmail} style={{width: "25em"}} />,
		<TZMColumn key="acao" header="Ações" style={{width: "6em", textAlign: "center"}} body={(usuario) => {
			return (
				<div>
					<span title="Remover Vendedor" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirVendedor(usuario)} />
				</div>
			);
		}} />
		
	];

	columnsFamilias = [		
		<TZMColumn key="descricao" header="Descrição"  field="descricao" name="descricao"  style={{width: "*", textAlign: "center"}} />,		
		<TZMColumn key="acao" header="Ações" style={{width: "6em", textAlign: "center"}} body={(familia) => {
			return (
				<div>
					<span title="Remover Família" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirFamilia(familia)} />
				</div>
			);
		}} />		
	];

	listClientes = (event, notIn) => {
        this.vwClienteService.autoComplete({cliente: event.query, sort: 'razaoSocial', clienteId_notIn: notIn.map(c => c.pk.clienteId).join(",")}).then((clientes) => this.setState({clientes}));
    }

	editorCliente = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
				<TZMAutoComplete itemTemplate={Templates.clienteTemplate} field="descricaoAmigavel" placeholder="Razão social, fantasia ou CNPJ" 
						value={this.state.regional.excecoes[props.rowIndex]._cliente}  onChange={(event) => {
						let regional = this.state.regional;
						regional.excecoes[props.rowIndex]._cliente = event.value;
						this.setState({regional});
					}} suggestions={this.state.clientes} completeMethod={(e) => this.listClientes(e, rowData.clientes || [])} onSelect={(event) => {
						if (event && event.value && Validator.isEntidade(event.value)) {
							let regional = this.state.regional;
							if (!regional.excecoes[props.rowIndex].clientes) {
								regional.excecoes[props.rowIndex].clientes = [];
							}
							regional.excecoes[props.rowIndex]._cliente = null;
							regional.excecoes[props.rowIndex].clientes.unshift(event.value);
							if (event.value.grupoId) {
								this.vwClienteService.query({
									page: 0,
									size: 100,
									search: `grupoId:${event.value.grupoId}`
								}).then((groupies) => {
									if (groupies && groupies.length) {
										ModalRealm.showDialog(<TZMConfirm question={`Há outros ${groupies.length - 1} clientes pertencentes ao mesmo grupo. Deseja adicioná-los também?`} onYes={() => {
											regional.excecoes[props.rowIndex].clientes = groupies.concat(regional.excecoes[props.rowIndex].clientes);
											const keys = regional.excecoes[props.rowIndex].clientes.map(c => c.pk.clienteId);
											regional.excecoes[props.rowIndex].clientes = regional.excecoes[props.rowIndex].clientes.filter((v, i) => keys.indexOf(v.pk.clienteId) === i);
											this.setState({regional});
										}} onNo={() => this.setState({regional})} />);
									} else {
										this.setState({regional});
									}
								});
							} else {
								this.setState({regional});
							}
						}
					}} />
				<div style={{height: "4px"}} />
				<div className="limited-height-200">
					<TZMTable value={rowData.clientes} emptyMessage="Nenhum cliente adicionado">
						<TZMColumn header="Razão Social" field="razao" />
						<TZMColumn header="Ações" style={{width: "5em", textAlign: "center"}} body={(c) => {
							return (
								<div>
									<span title="Remover Cliente" className="fa fa-trash-alt ui-tzm-icon" onClick={() => {
										let regional = this.state.regional;
										regional.excecoes[props.rowIndex].clientes = regional.excecoes[props.rowIndex].clientes.filter(cl => cl.pk.clienteId !== c.pk.clienteId);
										this.setState({regional});
									}} />
								</div>
							);
						}} />
					</TZMTable>
				</div>
			</div>
		);
	}

	editorVendedor = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
				<TZMAutoComplete itemTemplate={Templates.vendedorTemplateOmega} placeholder="Código Omega, E-mail ou Nome"  field="nome" 
								  value={this.state.regional.excecoes[props.rowIndex].vendedor}  onChange={(event) => {
									let regional = this.state.regional;
									regional.excecoes[props.rowIndex].vendedor = event.value;
									this.setState({ regional });
								}} suggestions={this.state.vendedores} completeMethod={this.listarVendedores} onSelect={(event) => {
									if (event.value && event.value.pk) {
										this.handleUpdateItem("vendedor", props.rowIndex, event.value);
									}
								}} />
			</div>
		);
	}

	handleUpdateItem = (key, index, value) => {
		let regional = this.state.regional;
		regional.excecoes[index][key] = value;
		this.setState({ regional });
	}

	insertItem = () => {	
		let regional = this.state.regional;
		if (!regional.excecoes) {
			regional.excecoes = [];
		}
		regional.excecoes.unshift({vendedor:null, cliente:null});	
		this.setState({regional});
		
	}

	rowExpand = (event) => {
		if (this.state.expandedRows != null && this.state.expandedRows.length > event.data.length) {
			this.setState({ expandedRows: event.data });
		} else {
			if (event.data.length > 0) {
				let item = event.data.pop();				
				this.setState({ expandedRows: [...event.data, item.itens] });
			} else {
				this.setState({ expandedRows: event.data });
			}
		}
	}

	rowExpansionTemplate = (data) => {
		return (
			<TZMTable value={data.itens} >
				<TZMColumn key="descricao" style={{ textAlign: "center", width: "10em" }} field="descricao" header="Tipo" />
			</TZMTable>
		);
	}

	validarRegional = () => {
		let messages = [];
		let regional = this.state.regional;
		
		if (Validator.isEmpty(regional.descricao)) {
			messages.push("A descrição da regional é obrigatória.");
		}
		if (regional.filtro === 'REPRESENTANTE') {
			if (regional.vendedores.length === 0) {
				messages.push("É obrigatório adicionar ao menos um Representante.");
			}

		} else if (regional.filtro === 'FAMILIA'){
			if (regional.familias.length === 0) {
				messages.push("É obrigatório adicionar ao menos uma Família.");
			}
		}
		if (regional.excecoes !== null && regional.excecoes.length > 0) {
			regional.excecoes.forEach((excecao) => {
				if (!excecao.clientes || !excecao.clientes.length) {
					messages.push("Existem exceções sem cliente preenchido");
				}
				if (!Validator.isEntidade(excecao.vendedor)) {
					messages.push("Existem exceções sem vendedor preenchido");
				}
			});
		}
		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup header="Advertência" messages={messages} />);
			return false;
		}

		// if (regional.filtro === 'REPRESENTANTE') {
		// 	regional.familias = [];		
		// } else if (regional.filtro === 'FAMILIA'){		
		// 	regional.vendedores = [];
		// }
		return true;
	}

	optionsFiltro = [		
		<option key={1} value="REPRESENTANTE">Representantes</option>,
		<option key={2} value="FAMILIA">Família</option>	
	];

	listarAlcadas = (event) => {
		this.alcadaService.listar({search: `descricao~${event.query}`}, false).then(alcadas => this.setState({alcadas}));
	}
	
	render() {
		return (
			<TZMDialog style={{width: "1000px"}} visible={this.state.visible} modal header="Regional" onHide={this.onHide} {...this.props}>
				<TZMPanel>
					<div className="ui-g">
						<div className="ui-g-6">
                            <TZMTextField label="Descrição" name="descricao" value={this.state.regional.descricao} onChange={this.handleChange} />
                        </div>
						<div className="ui-g-3">
							<TZMCombobox value={this.state.regional.filtro} label="Filtro" onChange={(event) => this.handleUpdate("filtro", event)}>
								{this.optionsFiltro}
							</TZMCombobox>
						</div>
						<div className="ui-g-3">
							<TZMAutoComplete onClear={this.handleClear}
											 onSelect={this.handleSelect}
											 onChange={this.handleSelect}
											 suggestions={this.state.alcadas}
											 completeMethod={this.listarAlcadas}
											 placeholder="Nenhuma"
											 name="alcada"
											 field="descricao"
											 label="Alçada"
											 value={this.state.regional.alcada} />
						</div>
                        <div className="ui-g-6">
                            <TZMAutoComplete onClear={this.handleClear} itemTemplate={UsuarioTemplate.usuarioTemplate} onSelect={this.handleSelect} onChange={this.handleSelect} 
                            suggestions={this.state.usuarios} completeMethod={this.listarUsuarios} placeholder="E-mail, Login ou Nome" name="gerente" field="nome" label="Gerente" value={this.state.regional.gerente} />
                        </div>
                        <div className="ui-g-6">
                            <TZMAutoComplete onClear={this.handleClear} itemTemplate={UsuarioTemplate.usuarioTemplate} onSelect={this.handleSelect} onChange={this.handleSelect} 
                            suggestions={this.state.usuarios} completeMethod={this.listarUsuarios} placeholder="E-mail, Login ou Nome" name="supervisor" field="nome" label="Supervisor" value={this.state.regional.supervisor} />
                        </div>
                    </div>
				</TZMPanel>
				<TZMTabView  onTabChange={(event) => this.setState({ selectedTab: event.index })} activeIndex={this.state.selectedTab}>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Representantes</span>}>						
						<TZMPanel>
							<TZMAutoComplete onClear={this.handleClear} itemTemplate={Templates.vendedorTemplateOmega} onSelect={this.addVendedores} onChange={this.handleSelect} 
							suggestions={this.state.vendedores} completeMethod={this.listarVendedores}  placeholder="Código Omega, E-mail ou Nome" name="vendedor" field="nome" label="Vendedor" 
							value={this.state.regional.vendedor} />						
						</TZMPanel>	
						<TZMTable value={this.state.regional.vendedores} paginator rows={10} rowsPerPageOptions={[10, 20, 30]} pageLinkSize={15} children={this.columnsVendedores} />
					</TZMTabPanel>								
					<TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Exceções</span>}>
						<TZMTable  value={this.state.regional.excecoes}  paginator rows={10}  paginatorLeft={
									<div style={{ textAlign: "left" }}>
										<Button icon="fas fa-plus" title="Adicionar novo item" className="tzm-paginator-button" onClick={this.insertItem} />
									</div> 
									} emptyMessage="Nenhuma exceção adicionada" >
							<TZMColumn key="vendedor" body={this.editorVendedor} style={{ width: "30em" }} header="Vendedor" />
							<TZMColumn key="clientes" body={this.editorCliente} style={{ width: "*" }} header="Clientes" />
							<TZMColumn key="acao" header="Ações" style={{width: "6em", textAlign: "center"}} body={(excecao) => {
								return (
									<div>
										<span title="Remover Exceção" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirExcecao(excecao)} />
									</div>
								);
							}} />
						</TZMTable>
					</TZMTabPanel>
					<TZMTabPanel  header={<span><span className="ui-tab-number" children="3" />Famílias</span>}> 
						<TZMPanel>
							<TZMAutoComplete onClear={this.handleClear}  onSelect={this.addFamilia} onChange={this.handleSelect} 
							suggestions={this.state.familias} completeMethod={this.listarFamilias}  placeholder="Descrição" name="familia" field="descricao" label="Família" 
							value={this.state.regional.familia} />						
						</TZMPanel>	
						<TZMTable value={this.state.regional.familias} paginator rows={10} rowsPerPageOptions={[10, 20, 30]} pageLinkSize={15} children={this.columnsFamilias} />					
					</TZMTabPanel>					

				</TZMTabView>	
				<TZMPanelFooter>
					<TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvarRegional} />
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onHide} />
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onCtrlS={this.salvarRegional} onEscape={this.onHide}/>
			</TZMDialog>
		);
	}

}
