import { httpGet } from "../../utilities/TZMRequest";

export class PrevisaoLancamentoService {

    previsaoLancamento(params) {
        return httpGet("/lancamentos/previsao/", params);
    }  

    previsaoLancamentoRFID(params) {
        return httpGet("/lancamentosrfid/previsao/", params);
    }

}
