import { httpDelete, httpGet, httpGetAsync, httpPost } from "../../utilities/TZMRequest";

export class PerfilService {

	completar(usuario) {
		return httpGet(`/perfis/${usuario.id}`);
	}

	listar = async (params) => {
		return await httpGetAsync("/perfis", params, true);
	}

	buscar = async (query) => {		
		return await httpGetAsync(`/perfis?${query}`, null, false);
	}

	salvar(usuario) {
		return httpPost("/perfis", usuario);
	}

	excluir(usuario) {
		return httpDelete("/perfis", usuario.id);
	}

}
