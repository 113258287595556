import { httpDelete, httpGet, httpGetAsync, httpPost } from "../../utilities/TZMRequest";

export class AlcadaService {

	completar(alcada) {
		return httpGet(`/alcadas/${alcada.id}`);
	}

	listar(params, loading = true) {
		return httpGet("/alcadas", params, loading);
	}

	salvar(alcada) {
		return httpPost("/alcadas", alcada);
	}

	excluir(alcada) {
		return httpDelete("/alcadas", alcada.id);
	}

	async buscar(alcadaPerfilId) {
		return await httpGetAsync(`/alcadas/vendedor/${alcadaPerfilId}`);
	}
	
    vendedorRegional = async (params) => {
        return await httpGetAsync("/alcadas/vendedor-regional", params, false);
    }

	async buscarAlcadaForVendedorRegional(clienteId, vendedorId, familia) {
		return await httpGetAsync(`/alcadas/vendedor-regional?familia=${familia}&clienteId=${clienteId}&vendedorId=${vendedorId}`);
	}

}
