import React from "react";
import {TabView} from "primereact/components/tabview/TabView";

import "./Panel.css";

export default class TZMTabView extends React.Component {

	render() {
		return <TabView {...this.props} />;
	}

}
