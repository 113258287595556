import React from "react";
import "./Progressbar.css";

export class Progressbar extends React.Component {

  render() {
    const value = this.props.value > 100 ? 100 : this.props.value;
    // const backgroundColor = `rgb(${255 - 255 * (value / 100)}, ${(255 * (value / 100)) * .8}, 0)`;
    return (
      <div className="nx-progressbar">
        <div className="nx-progressbar-bar" style={{ backgroundColor: "#1F4788", width: `${value}%`}} />
        <span style={{color: value < 50 ? "#000000" : "#FFFFFF", textShadow: value < 50 ? "0 0 8px #FFFFFF" : "0 0 8px #000000"}}>{value}%</span>
        <i className="nx-progressbar-icon" />
      </div>
    );
  }

}
