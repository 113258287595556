
import React from "react";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMTable from "../../../components/table/TZMTable";
import { FamiliaService } from "../../servicos/FamiliaService";
import { VwGrupoProdutoService } from "../../servicos/VwGrupoProdutoService";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import { Column } from "primereact/components/column/Column";
import { Validator } from "../../../utilities/TZMValidator";
import TZMPopup from "../../../components/dialog/TZMPopup";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";

export class EditarFamilia extends React.Component {

	constructor(props) {
		super(props);
		this.state = {			
			visible: true,
			familia: this.completarNulos(this.props.familia)			
		};		
		this.familiaService = new FamiliaService();
		this.vwGrupoProdutoService = new VwGrupoProdutoService();
	}

	completarNulos(familia) {	
		if (!familia.grupos) {
			familia.grupos = [];
		}
		return familia;
	}

	onHide = () => this.setState({visible: false});

	handleChange = (event) => {
		let familia = this.state.familia;
		familia[event.name] = event.target.value;
		this.setState({familia});
	}

	salvarFamilia = () => {
		if (this.validarFamilia()){
			let familia = Object.assign({}, this.state.familia);
			this.familiaService.salvar(familia).then((f) => {
				if (this.props.onModalClose) this.props.onModalClose(f);
				this.onHide();
			});
		}
	}

	handleClear = (event) => {
		let familia = this.state.familia;
		familia[event.name] = null;
		this.setState({familia});
	}

	handleSelect = (event) => {
		let familia = this.state.familia;
		familia[event.name] = event.value;
		this.setState({familia});
    }
   
	addGrupo = (event) => {
		let familia = this.state.familia;
		familia.grupos.unshift(event.value);
		familia.grupo = null;
		this.setState({familia});
	}

	listarGrupos = async  (event) => {
		let remover = this.state.familia.grupos;	
		await this.vwGrupoProdutoService.listarSimples({descricao: event.query, notin: remover.map((grupo) => grupo.grupoId)}).then((grupos) => this.setState({grupos}));
	}

	excluirGrupo = (grupo) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
		let familia = this.state.familia;
		familia.grupos =  familia.grupos.filter(f => f !== grupo);
		this.setState({familia});
		}} question="Tem certeza de que deseja remover este grupo?" />);
	}

	columnsGrupos = [		
		<Column sortable key="grupo" header="Grupo"  field="grupoId" name="grupoId"  style={{width: "10em", textAlign: "center"}} />,		
		<Column sortable key="descricao" header="Descrição"  field="descricao" name="descricao"  style={{width: "*"}} />,		
		<Column key="acao" header="Ações" style={{width: "6em", textAlign: "center"}} body={(grupo) => {
			return (
				<div>
					<span title="Remover Grupo" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirGrupo(grupo)} />
				</div>
			);
		}} />		
	];

	validarFamilia = () => {
		let messages = [];
		let familia = this.state.familia;
		if (Validator.isEmpty(familia.descricao)) {
			messages.push("A descrição da família é obrigatória.");
		}		
		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup header="Advertência" messages={messages} />);
			return false;
		}
		return true;
	}

	handleCChange = (event) => {
		let familia = this.state.familia;
		familia[event.name] = event.value;
		this.setState({ familia, alterado: true });
	}

	handleChangeGGF = (event) => {
		let familia = this.state.familia;
		familia.ggfs[event.index][event.name] = event.value;
		this.setState({ familia, alterado: true });
	}

	grupoTemplate(grupo) {
		return `${grupo.grupoId} - ${grupo.descricao}`;
	}


	render() {
		return (
			<TZMDialog style={{width: "800px"}} visible={this.state.visible} modal header="Família" onHide={this.onHide} {...this.props}>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMTextField label="Descrição" name="descricao" value={this.state.familia.descricao} onChange={this.handleChange} />
					</div>					
				</div>
				<TZMTabView onTabChange={(event) => this.setState({ selectedTab: event.index })} activeIndex={this.state.selectedTab}>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Grupos</span>}>
						<TZMPanel>
							<TZMAutoComplete onClear={this.handleClear}  itemTemplate={this.grupoTemplate} onSelect={this.addGrupo} onChange={this.handleSelect} 
											suggestions={this.state.grupos} completeMethod={this.listarGrupos}  placeholder="Descrição ou Código" name="grupo" 
											field="descricao" label="Grupo" value={this.state.familia.grupo} />						
						</TZMPanel>	
						<TZMTable ref={(el) => this.dt = el} value={this.state.familia.grupos} paginator rows={15} rowsPerPageOptions={[15, 30, 45]} pageLinkSize={15}> 
							{this.columnsGrupos} 
						</TZMTable>
					</TZMTabPanel>

				</TZMTabView>
				<TZMPanelFooter>
					<TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvarFamilia} />
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onHide} />
				</TZMPanelFooter>
			</TZMDialog>
		);
	}

}
