import { Fetch } from "../../utilities/Fetch";
import { httpGet, httpGetAsync } from "../../utilities/TZMRequest";

export class VwVendedorService {

	buscar(id) {
		return httpGet(`/vw-vendedores/${JSON.stringify(id)}`);
	}

	buscarAsync = async(id) => {
		return await httpGetAsync(`/vw-vendedores/${JSON.stringify(id)}`);
	}

	autoCompletar = async (query) =>{		
		return await Fetch.GetJSONAsync(`/vw-vendedores?vendedor=${query}&size=10&page=0&sort=nome`);
	}

	listar(params) {
		return httpGet("/vw-vendedores", params);
	}

	completarVendedor = async (pk) => {		
		let vendedor =  await this.buscarAsync(pk);
		pk.vendedorId = vendedor.pk.supervisorId;
		let supervisor =  await this.buscarAsync(pk);
		pk.vendedorId = supervisor.pk.gerenteId;
		pk.supervisorId = supervisor.pk.gerenteId;
		let gerente =  await this.buscarAsync(pk);
		
		vendedor.supervisor = supervisor;
		vendedor.gerente = gerente;
		return vendedor;		
	}

}
  