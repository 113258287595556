import { Button } from "primereact/components/button/Button";
import React from "react";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMButton from "../../../components/common/TZMButton";
import TZMIcon from "../../../components/common/TZMIcon";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMDialog from "../../../components/dialog/TZMDialog";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMUserData from "../../../utilities/TZMUserData";
import { Validator } from "../../../utilities/TZMValidator";
import { ListaPrecoService } from "../../servicos/ListaPrecoService";
import { VwProdutoService } from "../../servicos/VwProdutoService";
import TZMPopup from "../../../components/dialog/TZMPopup";
import { Column } from "primereact/components/column/Column";
import "./ListaPreco.css";
import { UsuarioService } from "../../servicos/UsuarioService";
import { TZMCurrency } from "../../../components/common/TZMCurrency";
import TZMCalendar from "../../../components/common/TZMCalendar";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import { DataTable } from "primereact/components/datatable/DataTable";
import { ClienteService } from "../../servicos/ClienteService";
import { ClienteUtils } from "../cliente/ClienteUtils";
import moment from "moment";

export class EditarListaPreco extends React.Component {

	constructor(props) {
        super(props);        
		this.state = {
            listaPreco: this.completarNulos(this.props.listaPreco),
			clientes: [],
			clientesListaPreco: [],
            params: {
                produto: { pk: {}, descricao: "" }
            },
            alterado: false,
			visible: true
		};
        this.listaPrecoService = new ListaPrecoService();
		this.vwProdutoService = new VwProdutoService();
		this.usuarioService = new UsuarioService();
		this.clienteService = new ClienteService();
    }

    completarNulos(listaPreco) {
		if (!listaPreco.itens) {
			listaPreco.itens = [];
		}
		listaPreco.cliente = null;
		return listaPreco;
	}

	componentDidMount() {
		if (this.state.listaPreco.id) {
			this.listarClientesListaPreco();
		}
	}

	listarClientesListaPreco = () => {							
		this.clienteService.listarSimples({listaPrecoId: this.state.listaPreco.id}).then((clientesListaPreco) => this.setState({clientesListaPreco}));
	}

	listarClientes = async (event) => {				
		let params = Object.assign({});	
		let notIn = this.state.clientesListaPreco
		params.cliente = event.query;		
		params.desativados = false;
		params.semListaPreco = true;
		params.sort = 'razao';
		params.clienteId_notIn = notIn.map(c => c.clienteId).join(",")		
        await this.clienteService.autoComplete(params).then((clientes) => this.setState({clientes}));
    }

	onHide = () => this.setState({visible: false});
 
	salvar = () => {
		if (this.validar()) {			
			this.listaPrecoService.salvar(this.state.listaPreco).then((listaPreco) => {
				if (this.props.onModalClose) this.props.onModalClose(listaPreco);
				this.onHide();
			});
		}
	}

	validar = () => {
		let messages = [];
		let listaPreco = this.state.listaPreco;
		if (!listaPreco.descricao || !listaPreco.descricao.length) {
			messages.push("A descrição é obrigatória");
		}
		if (listaPreco.itens == null || listaPreco.itens.length === 0) {
			messages.push("Nenhum produto adicionado.");
		} else {
			listaPreco.itens.forEach((item, index) => {		
				if (!Validator.isEntidade(item.produto)) {
					messages.push("Produto não selecionado.");
				} else {
					if (!item.preco || item.preco === 0) {
						messages.push(`O preço do item ${item.produto.descricao} não pode ser vazio ou igual a 0(zero).`);
					}
					if (!item.inicio) {
						messages.push(`A vigência do item ${item.produto.descricao} não pode ser vazia.`);
					}
					if (listaPreco.itens.findIndex(i => Validator.isEntidade(i.produto) && i.produto.pk.produtoId === item.produto.pk.produtoId ) !== index) {
						messages.push(`Já existe o item ${item.produto.descricao} para essa lista de preço.`);
					}
				}
				
			});
		}
		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()}  header="Advertência" messages={messages} />);
			return false;
		}
		return true;
	}

	handleChange = (event) => {
		const {listaPreco} = this.state;		
		listaPreco[event.name] = event.value;
		this.setState({listaPreco});
	}	

	produtoTemplate = (value) => {
		return (
			<div className="ui-tzm-suggestion-line"><div className="ui-tzm-suggestion-chip">{value.pk.produtoId}</div>{value.descricao}</div>
		);
    }
    
    blurOnEnter = (event) => {
		if (event.keyCode === 9 || event.keyCode === 13) document.activeElement.blur();
	}
    
    listarProdutos = (event) => {
		let params = Object.assign({});
		params.produto = event.query;
		this.vwProdutoService.autoCompletar(params).then((produtos) => {
			this.setState({ produtos });
		});
	}

    editorProduto = (rowData, props) => {
		const desativado = rowData.id ? true : false;
		return (
			<div className="tzm-ui-tablelike">
                <TZMAutoComplete inputClassName="editorProduto" field="descricao" itemTemplate={this.produtoTemplate} onKeyDown={this.blurOnEnter}
                    disabled={desativado} value={this.state.listaPreco.itens[props.rowIndex].produto} onChange={(event) => {
					let listaPreco = this.state.listaPreco;
					listaPreco.itens[props.rowIndex].produto = event.value;
					this.setState({ listaPreco });
				}} suggestions={this.state.produtos} completeMethod={this.listarProdutos} onSelect={(event) => {
					if (event.value && event.value.pk) {						
						this.handleUpdateItem("produto", props.rowIndex, event.value);
					}
				}} />
			</div>
		);
	}
	
	bodyProduto = (rowData) => {		
		return (rowData.produto ? <div className="tzm-ui-tablelike">{rowData.produto.descricao}</div> : null);
    } 

    handleUpdateItem = (key, index, value) => {
		console.log("value", value);
		let listaPreco = this.state.listaPreco;
		listaPreco.itens[index][key] = value;
		this.setState({ listaPreco, alterado: true });
    }
    
	editorPreco = (rowData, props) => {		
		return (			
			<div style={{ textAlign: "right"}}>
				<TZMCurrency className="ui-textfield-currency-group ui-textfield" name="preco" prefix="R$" 
							value={this.state.listaPreco.itens[props.rowIndex].preco} disabled={rowData.id}
							onChange={(event) => this.handleUpdateItem("preco", props.rowIndex, event.value)} />
			</div>
		);
	}

    paginacaoItens = (event) => {this.setState({ firstItem: event.first });}
    
    insertItem = () => {
		let listaPreco = this.state.listaPreco;
		if (!listaPreco.itens) {
			listaPreco.itens = [];
		}
		listaPreco.itens.unshift({ produto: { pk: {}, descricao: " "}, alteracao: "REAJUSTE", fator: "NENHUM" , preco: 0, inicio: moment(new Date()).format("YYYY-MM-DD") });		
		this.setState({ listaPreco, alterado: true});
		setTimeout(() => {
			document.getElementsByClassName("editorProduto")[0].focus();
		}, 200);		
	}

	deleteItem = (event, rowData) => {
		event.preventDefault();
		event.stopPropagation();
		if (Validator.isEntidade(rowData.produto)) {
			ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} onYes={() => {
				let listaPreco = this.state.listaPreco;
				listaPreco.itens.splice(listaPreco.itens.findIndex((item) => item === rowData), 1);
				let firstItem = listaPreco.itens.length % 10 === 0 ? listaPreco.itens.length >= 10 ? listaPreco.itens.length - 10 : 0 : this.state.firstItem;
				this.setState({ listaPreco, alterado: true, firstItem });
			}} question="Tem certeza de que deseja remover este item?" />);
		} else {
			let listaPreco = this.state.listaPreco;
			listaPreco.itens.splice(listaPreco.itens.findIndex((item) => item === rowData), 1);
			let firstItem = listaPreco.itens.length % 10 === 0 ? listaPreco.itens.length >= 10 ? listaPreco.itens.length - 10 : 0 : this.state.firstItem;
			this.setState({ listaPreco, alterado: true, firstItem });
		}
	}

	editorVigencia = (rowData, props) => {		
		return (			
			<div style={{ textAlign: "right"}}>
                <TZMCalendar onChange={(event) => this.handleUpdateItem("inicio", props.rowIndex, event.target.value)} 
								name="inicio" disabled={rowData.id} value={this.state.listaPreco.itens[props.rowIndex].inicio} />
			</div>
		);
	}

	desvincular = (cliente) => {
		ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()}  onYes={() => {		
		this.clienteService.completar(cliente).then((clienteCompleto) =>{
			clienteCompleto.listaPreco = null;
			this.clienteService.salvar(clienteCompleto).then((clienteSalvo) => {
				let clientesListaPreco = this.state.clientesListaPreco;								
				clientesListaPreco =  clientesListaPreco.filter(c => c.clienteId !== cliente.clienteId);				
				this.setState({clientesListaPreco});		
			});
		});
		}} question="Tem certeza de que deseja desvincular o cliente"  />);
	}

	addCliente = (event) => {		
		let listaPreco = this.state.listaPreco;
		listaPreco.cliente = null;
		this.clienteService.completar(event.value).then((clienteCompleto) =>{
			let lista = Object.assign({}, this.state.listaPreco);
			lista.itens = null;
			clienteCompleto.listaPreco = lista;
			this.clienteService.salvar(clienteCompleto).then((clienteSalvo) => {
				let clientesListaPreco = this.state.clientesListaPreco;
				let cliente = event.value;
				cliente.listaPreco = lista;
				clientesListaPreco.unshift(cliente);
				this.setState({listaPreco,clientesListaPreco});		
			});
		});
	}

	confirmarExcluir = () => {
		ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} onYes={this.excluir} question="Tem certeza de que deseja exluir está lista de preço."   />);	  
	}

	excluir = () => {
		let listaPreco = Object.assign({}, this.state.listaPreco);
		listaPreco.ativado = false;				
		this.listaPrecoService.excluir(listaPreco).then((listaPreco) => {this.onHide()});
	}

	fechar = () => {
		if (this.state.alterado) {
			ModalRealm.showDialog(<TZMConfirm onYes={() => this.onHide()} question="Fechar e descartar as alterações feitas?" />);
		} else {
			this.onHide();
		}
	}

	reativar = () => {
		this.listaPrecoService.salvar({...this.state.listaPreco, ativado: true}).then(() => this.onHide());
	}

	columnsClientes = [			
		<Column header="Situação" key="situacao" field="posicao" style={{textAlign: "center",width: "5em", }} />,		
		<Column header="Cliente" key="cliente" field="clienteId" style={{textAlign: "left",width: "10em", }} />,		
		<Column header="Razão Social" key="razaoSocial" field="razaoSocial" style={{width: "*"}} body={(rowData) => ClienteUtils.clienteTipoNome(rowData)}/>,
		<Column header="Nome Fantasia" key="nomeFantasia" field="nomeFantasia" style={{width: "25em"}} />,
		<Column header="CNPJ" key="cpfCnpjFormatado" field="cpfCnpjFormatado" style={{textAlign: "center",width: "12em"}} />,
		<Column header="Cidade" key="cidade" field="cidade" style={{textAlign: "center",width: "15em"}} />,
		<Column header="Estado" key="estado" field="estado" style={{textAlign: "center", width: "5em"}} />,
		<Column header="Ações" key="acoes" style={{width: "6em", textAlign: "center"}} body={(cliente) => { 
		return (
				<div>
					{TZMUserData.hasRole("____CLIE_E") ? <span title="Desvincular Cliente" className="fa fa-unlink ui-tzm-icon" onClick={() => this.desvincular(cliente)} /> : null}					
				</div>
			);
		}} />
	];

	handleClear = (event) => {
		let listaPreco = this.state.listaPreco;
		listaPreco[event.name] = null;
		this.setState({listaPreco,alterado: true});
	}

    render() {
		return (
			<TZMDialog style={{width: "1400px"}} visible={this.state.visible} modal header="Lista de Preço" onHide={this.onHide} {...this.props}>
				<div className="ui-g">
					<div className="ui-g-4">
						<TZMTextField  name="descricao" value={this.state.listaPreco.descricao} label="Descrição" onChange={this.handleChange}/>
					</div>							
					<div className="ui-g-2">
						<TZMTextField  disabled value={this.state.listaPreco.codigo} label="Código" />
					</div>
				</div>				
				<TZMTabView onTabChange={(event) => this.setState({ selectedTab: event.index })} activeIndex={this.state.selectedTab}>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Produtos</span>}>
						<div className="ui-g">
							<div className="ui-g-12">
							<DataTable scrollable scrollHeight="400px" ref={(el) => this.dt = el} paginator rows={30} rowsPerPageOptions={[30, 45, 60]} 
								pageLinkSize={30} onPage={this.paginacaoItens} first={this.state.firstItem} value={this.state.listaPreco.itens}
											rowClassName={this.colorir}  paginatorLeft={ 
										<div style={{ textAlign: "left" }}>																									
											<Button icon="fas fa-plus" title="Adicionar Produtos" className="tzm-paginator-button" onClick={() =>{this.insertItem()}} />												
										</div>
								} emptyMessage="Nenhum item adicionado">
									<Column key="produtoId" field="produtoId" style={{ textAlign: "center", width: "18em" }} header="SKU" body={(item) => Validator.isEntidade(item.produto) ? item.produto.pk.produtoId : ""} />
									<Column key="descricao" body={this.editorProduto} style={{ width: "*" }} header="Produto" />							
									<Column key="preco"  style={{ center: "right", width: "15em" }}  header="Preço" body={this.editorPreco} />
									<Column field="inicio" key="inicio" header="Vigência" body={this.editorVigencia} style={{width: "12em", textAlign: "center"}}/>
									<Column style={{ width: "6em", textAlign: "center" }} key="acoes" header="Ações" body={(rowData) => {
										return (
											<div>	
												{!rowData.id ?											
												<span className="fake-link" onClick={(event) => this.deleteItem(event, rowData)}>
													<TZMIcon title="Excluir Item" className="ui-action-icon" name="trash-alt" />
												</span>  : null}
											</div>
										);
									}} /> 
								</DataTable>
							</div> 
						</div>
					</TZMTabPanel>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Clientes</span>}>
						<div className="ui-g">
							<div className="ui-g-12">
									<TZMAutoComplete  disabled={!this.state.listaPreco.id} onClear={this.handleClear} itemTemplate={ClienteUtils.templateTipo} onSelect={this.addCliente} onChange={this.handleChange}
										suggestions={this.state.clientes} completeMethod={this.listarClientes} name="cliente" field="descricaoAmigavel" 
											label="Cliente"  placeholder="Razão social, fantasia ou CNPJ" value={this.state.listaPreco.cliente} />
							</div>
							<div className="ui-g">
								<DataTable emptyMessage="Nenhum cliente encontrado." scrollable scrollHeight="400px" value={this.state.clientesListaPreco} children={this.columnsClientes} />
							</div>
						</div>
					</TZMTabPanel>	
				</TZMTabView>
				<TZMPanelFooter>
					{this.state.listaPreco.id != null && !this.state.listaPreco.ativado ? <TZMButton style={{float: "left"}} title="Reativar Lista de Preço" icon="fas fa-eye" onClick={this.reativar} /> : null}					
					{this.state.listaPreco.id != null && this.state.listaPreco.ativado ? <TZMButton className="ui-button-danger" style={{float: "left"}} title="Excluir Lista de Preço" icon="fas fa-trash-alt" onClick={this.confirmarExcluir} /> : null}					
					{this.state.listaPreco.ativado ?  <TZMButton className="ui-button-success" label="Salvar" icon="fas fa-save" onClick={this.salvar}/> : null}
					<TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-times" onClick={this.fechar} />
				</TZMPanelFooter>			
			</TZMDialog>
		);
	}

}
