import { Fetch } from "../../utilities/Fetch";
import { httpGet } from "../../utilities/TZMRequest";

export class CFOPService {

	autoCompletar(query) {
		return Fetch.GetJSON(`/cfops?cfops=${query}&size=10&page=0&sort=descricao`);
	}

	listar(params) {
		return httpGet("/cfops", params);
	}

}
