const VERMELHO = "VERMELHO";
const AMARELO = "AMARELO";
const VERDE = "VERDE";

export class PedidoUtils { 
    
	static AMARELO() {return AMARELO;}
	static VERMELHO() {return VERMELHO;}
	static VERDE() {return VERDE;}


	static analisarValorUnitario(valorUnitario, valorUnitarioTabela) {
		if (valorUnitarioTabela === 0) {
			return VERMELHO;
		} else if (valorUnitario >= valorUnitarioTabela ) {
			return VERDE;
		} else {
			return AMARELO;
		}        
	}

	static colorirItensProducaoRowClass = (rowData) => {  

		console.log("rowData",rowData);
		      
		switch (rowData.statusOs) {
		case 0: //Pendente
			return {"pendente": true};
		case 1: //Liberado   
			return {"liberado": true}; //OK
		case 2: // Em execução
			return {"execucao": true}; //OK		
		case 4: //Separado 
			return {"separado": true};
		case 5: //Em Conferência   
			return {"emconferencia": true};
		case 6: //Conferido    
			return {"conferido": true};
		case 7: // Divergência      
			return {"divergencia": true}; //OK
		case 8: // Encerrado  
			return {"encerrado": true}; //OK
		case 9: //Cancelado
			return {"cancelado": true};
		case 10: //Expedido
			return {"expedido": true};		
		default:
			return false;
		}
	}

	static colorirItensProducao = (rowData) => {        
		switch (rowData.statusOs) {
		case 0: //Pendente
			return "pendente";
		case 1: //Liberado   
			return "liberado";
		case 2: // Em execução
			return "execucao";
		case 4: //Separado 
			return "separado";
		case 5: //Em Conferência   
			return "emconferencia";
		case 6: //Conferido    
			return "conferido";
		case 7: // Divergência      
			return "divergencia";
		case 8: // Encerrado  
			return "encerrado";
		case 9: //Cancelado
			return "cancelado";
		case 10: //Expedido
			return "expedido";
		default:
			return null;
		}
	}
            
	

	
}