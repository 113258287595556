import React from "react";
import {Panel} from 'primereact/components/panel/Panel';
import "./Panel.css";

export default class TZMPanel extends React.Component {

	render() {
		return (
			<Panel {...this.props}>
				{this.props.children}
			</Panel>
		);
	}

}
