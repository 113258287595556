import { Fetch } from "../../../utilities/Fetch";
import { httpGetAsync } from "../../../utilities/TZMRequest";



export class PainelCACNovoService {

	familiasVolume() {
		return Fetch.GetJSON("/painel-cac-novo/familias-volume");
	}
	
	familiasMoeda() {
		return Fetch.GetJSON("/painel-cac-novo/familias-moeda");
	}

	operadores() {
		return Fetch.GetJSON("/painel-cac-novo/operadores");
	}
	
	vendedores() {
		return Fetch.GetJSON("/painel-cac-novo/vendedores");
	}

	lancado() {
		return Fetch.GetJSON("/painel-cac-novo/lancado");
	}
	
	detalhesOperador  = async (operador) => {
		return  httpGetAsync(`/painel-cac-novo/detalhes/operadores/${operador}`);
	}

	detalhesVendedor  = async (vendedor) => {
		return  httpGetAsync(`/painel-cac-novo/detalhes/vendedores/${vendedor}`);
	}

	detalhesBloqueado  = async () => {
		return  httpGetAsync("/painel-cac-novo/detalhes/bloqueado");
	}


}
