import { httpDelete, httpGet, httpPost } from "../../utilities/TZMRequest";

export class BudgetService {

	completar(budget) {
		return httpGet(`/budgets/${budget.id}`);
	}

	listar(params) {
		return httpGet(`/budgets?${params}`);
	}

	async salvar(budget) {
		return await httpPost("/budgets", budget);
	}

	async excluir(budget) {
		return await httpDelete("/budgets", budget.id);
	}

	async atualizar(budgetPack) {
		return await httpPost("/budgets/atualizar", budgetPack);
	}

}
