import React from "react";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMButton from "../../../components/common/TZMButton";
import TZMCombobox from "../../../components/common/TZMCombobox";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPopup from "../../../components/dialog/TZMPopup";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMShortcut from "../../../utilities/TZMShortcut";
import { Validator } from "../../../utilities/TZMValidator";
import { CFOPService } from "../../servicos/CFOPService";

export class EditarItemPedido extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			visible: true,
			itemPedido: props.itemPedido
		};
		this.cfopService = new CFOPService();
	}

	listCfops = (event) => {
		this.cfopService.autoCompletar(event.query).then((listCfops) => this.setState({ listCfops }));
	}

	onHide = () => this.setState({ visible: false });

	mountEnumOptions = (enumList) => {
		if (enumList != null) {
			let enums = [<option key={0} value={null}></option>];
			enums.push(...enumList.map((value) => <option key={value.value} value={value.value}>{value.descriptor}</option>));
			return enums;
		}
		return null;
	}

	cfopTemplate(cfop) {
		return `${cfop.pk.cfopId} - ${cfop.descricao}`;
	}

	handleEntityCombobox = (event) => {
		let itemPedido = this.state.itemPedido;
		if (event.target.value != null && event.target.value !== "") {
			itemPedido[event.name] = JSON.parse(event.target.value);
		} else {
			itemPedido[event.name] = null;
		}
		this.setState({ itemPedido, alterado: true });
	}

	onSalvar = (event) => {
		let itemPedido = this.state.itemPedido;
		if (this.validarItemPedido(itemPedido)) {
			if (this.props.onSalvar) this.props.onSalvar(itemPedido);
			this.onHide();
		}
	}

	handleUpdate = (event) => {
		let itemPedido = this.state.itemPedido;
		itemPedido[event.name] = event.value;
		this.setState({ itemPedido, alterado: true });
	}

	selectAutoComplete = (event) => {
		let itemPedido = this.state.itemPedido;
		itemPedido[event.name] = event.value;
		this.setState({ itemPedido, alterado: true });
	}

	clearAutoComplete = (event) => {
		let itemPedido = this.state.itemPedido;
		itemPedido[event.name] = { pk: {}, [event.field]: " " };
		this.setState({ itemPedido, alterado: true });
	}

	queryAutoComplete = (event) => {
		let itemPedido = this.state.itemPedido;
		itemPedido[event.name] = event.value;
		this.setState({ itemPedido, alterado: true });
	}

	validarItemPedido = (itemPedido) => {
		let messages = [];
		if (!Validator.isEntidade(itemPedido.tipoMovimento)) {
			messages.push("O tipo de movimento é obrigatório");
		}
		if (!Validator.isEntidade(itemPedido.cfop)) {
			messages.push("O CFOP é obrigatório");
		}
		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup header="Advertência" messages={messages} />);
			return false;
		}
		return true;
	}

	render() {
		return (
			<TZMDialog style={{ width: "400px" }} visible={this.state.visible} modal header="Detalhes de Item de Pedido" onHide={this.onHide} {...this.props}>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMCombobox field="descricao" name="tipoMovimento" label="Tipo de Movimento" onChange={this.handleEntityCombobox} value={JSON.stringify(this.state.itemPedido.tipoMovimento)}>{this.mountEnumOptions(this.props.tiposMovimento)}</TZMCombobox>
					</div>
					<div className="ui-g-12">
						<TZMAutoComplete minLength={1} onClear={this.clearAutoComplete} onSelect={this.selectAutoComplete} itemTemplate={this.cfopTemplate}
							onChange={this.queryAutoComplete} suggestions={this.state.listCfops} field="descricaoAmigavel" name="cfop" completeMethod={this.listCfops}
							label="CFOP" value={this.state.itemPedido.cfop} />
					</div>
				</div>
				<TZMPanelFooter>
					<TZMButton className="ui-button-success" label="Salvar" icon="fas fa-save" onClick={this.onSalvar} />
					<TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-close" onClick={this.onHide} />
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onCtrlS={this.onSalvar} onEscape={this.onHide} />
			</TZMDialog>
		);
	}

}
