import { Fetch } from "../../utilities/Fetch";
import { httpGet, httpGetAsync } from "../../utilities/TZMRequest";

export class VwClienteService {

	buscar(cliente) {
		return Fetch.GetJSON(`/vw-clientes/${JSON.stringify(cliente.pk)}`);
	}

	buscarAsync = async (cliente) =>{
		return  httpGetAsync(`/vw-clientes/${JSON.stringify(cliente.pk)}`,null,true);
	}
	
	listarSimples = async (params) => {
        return  httpGetAsync("/vw-clientes/listar-simples", params, false);
	}
	
	autoComplete = async (params) => {
        return  httpGetAsync("/vw-clientes/listar-simples", params, false);
    }

	listar(params) {
		return httpGet("/vw-clientes", params);
	}

	query(params) {
		return httpGet(`/vw-clientes/query?${Object.keys(params).map(k => `${k}=${params[k]}`).join("&")}`);
	}

	listarFichaFinanceira = async (cliente) =>{		
		return  httpGetAsync("/vw-clientes/listar-ficha-financeira",{grupoId: cliente.grupoId,clienteId: cliente.pk.clienteId, tipo:cliente.tipo}, true);
	}

}
