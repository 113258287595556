import React from "react";
import {Column} from "primereact/components/column/Column";
import "./TablePainel.css";

export default class TZMColumnPainel extends React.Component {

	render() {
		return (
			<Column {...this.props} headerClassName="ui-column-grande" header={<span style={{fontSize: "20px"}}>{this.props.header}</span>}>
				{this.props.children}
			</Column>
		);
	}

}
