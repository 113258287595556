import React from "react";
import TZMShortcut from "../../utilities/TZMShortcut";
import TZMButton from "../common/TZMButton";
import TZMPanelFooter from "../panel/TZMPanelFooter";
import "./Dialog.css";
import TZMDialog from "./TZMDialog";
import TZMUserData from "../../utilities/TZMUserData";


export default class TZMPopup extends React.Component {

	state = {
		visible: true,
		key: Math.random()
	};

	fecharPopup = () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
		this.setState({visible: false});
	}

	render() {
		let content = this.props.children;
		if (this.props.messages) {
			content = this.props.messages.map((message) => {
				return <li key={TZMUserData.getIdGenerator()} >{message}</li>
			});
			content = <div className="ui-popup-messages"><div>Atenção para as seguintes mensagens do sistema:<br /></div><ul>{content}</ul></div>;
		}
		return (
			<TZMDialog key={this.state.key} modal visible={this.state.visible} {...this.props} onHide={this.fecharPopup}>
				{content}
				<TZMPanelFooter>
					<TZMButton autoFocus icon="fas fa-close" label="Fechar" onClick={this.fecharPopup} />
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onEscape={this.fecharPopup} />
			</TZMDialog>
		);
	}

}
