import { Tooltip } from 'primereact/components/tooltip/Tooltip';
import React from "react";
import { toDecimal, toDecimalFixed } from "../../components/common/TZMFormatter";
import TZMTextFieldPainel from "../../components/common/TZMTextFieldPainel";
import TZMMainContent from "../../components/main/TZMMainContent";
import { TZMMenubarInfo } from "../../components/menubar/TZMMenubarInfo";
import { ModalRealm } from '../../components/modal/ModalRealm';
import TZMPanel from "../../components/panel/TZMPanel";
import TZMColumnPainel from "../../components/table/TZMColumnPainel";
import TZMTable from "../../components/table/TZMTable";
import { Fetch } from '../../utilities/Fetch';
import TZMUserData from "../../utilities/TZMUserData";
import { PainelCACService } from "../servicos/PainelCACService";
import TZMTabView from '../../components/panel/TZMTabView';
import TZMTabPanel from '../../components/panel/TZMTabPanel';
import { PainelCACDetalhe } from './PainelCACDetalhe';
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;
const title = "Painel CAC";

export default class PainelCAC extends React.Component {

	constructor() {
		super();
		this.state = {
			countdown: 0,
			pedidoOperador: [],
			pedidoVendedor: [],
			monitorTotais: {
				totalHojeMes: 0,
				lancadoHojeMes: 0,
				lancadoHojeNacional: 0,
				lancadoHojeExportacao: 0,
				lancadoHojeInter: 0,
				lancadoHojeFuturo: 0,
				lancadoHojeMesNacional: 0,
				lancadoHojeMesExportacao: 0,
				lancadoHojeMesInter: 0,
				lancadoMesMes: 0,
				lancadoMesMesNacional: 0,
				lancadoMesMesExportacao: 0,
				lancadoMesMesInter: 0,
				lancadoMesFuturo: 0,
				lancadoMesFuturoNacional: 0,
				lancadoMesFuturoExportacao: 0,
				lancadoMesFuturoInter: 0,
				cartBloq: 0,
				cartBloqInter: 0,
				cartBloqNacional: 0,
				cartBloqExportacao: 0,
				faturadoMes: 0,
				faturadoMesNacional: 0,
				faturadoMesExportacao: 0,
				faturadoMesInter: 0,
				totalGeral: 0,
				totalGeralNacional: 0,
				totalGeralExportacao: 0,
				totalGeralInter: 0,
				totalNacional: 0,
				totalExportacao: 0,
				officeFaturado: 0,
				officeCarteira: 0,
				officeTotal: 0,
				filmeFaturado: 0,
				filmeCarteira: 0,
				filmeTotalrolo: 0,
				filmeTotal: 0,

				filmeColoridoFaturado: 0,
				filmeColoridoCarteira: 0,
				filmeColoridoTotalrolo: 0,
				filmeColoridoTotal: 0,

				fitaFaturado: 0,
				fitaCarteira: 0,
				fitaTotalrolo: 0,
				fitaTotal: 0,

				bloqueadoNacional: 0,
				bloqueadoNacionalPrecoMedio: 0,
				carteiraNacional: 0,
				carteiraNacionalPrecoMedio: 0,
				bloqueadoExportacao: 0,
				bloqueadoExportacaoPrecoMedio: 0,
				carteiraExportacao: 0,
				carteiraExportacaoPrecoMedio: 0,
				bloqueadoInter: 0,
				bloqueadoInterPrecoMedio: 0,
				carteiraInter: 0,
				carteiraInterPrecoMedio: 0,
				rruyTotal:0,
				rreTotal:0,
				sasTotal:0,
				meta: 0,
				metaLocal: 0,
				metaInter: 0,
				metaSas: 0,
				metaAtingida: 0,
				totalLiberado:0
			}
			
		};
		this.painelCACService = new PainelCACService();
		this.reloadPreview = this.reloadPreview.bind(this);
	}

	monthFormatter = new Intl.DateTimeFormat("pt-BR", { month: "long" });

	monthName = (monthIndex) => {
		let now = new Date(); 
		now.setMonth(now.getMonth() + monthIndex);
		return this.monthFormatter.format(now);
	}

	reloadInterval = null;

	componentDidMount() {
		if (TZMUserData.isAutenticado()) {
			Fetch.Get("/ping");
			this.realoadInterval = setInterval(this.reloadPreview, 1000);
		}
	}

	componentWillUnmount() {
		clearInterval(this.realoadInterval);
	}

	async reloadPreview() {
		if (this.state.countdown > 1) {
			this.setState({ countdown: this.state.countdown - 1 });
		} else {
			this.setState({ countdown: 60 });			
			await this.painelCACService.pedidoVendedor().then((response) => {
				this.setState({ pedidoVendedor: response });
			});
			await this.painelCACService.pedidoOperador().then((response) => {
				this.setState({ pedidoOperador: response });
			});
			await this.painelCACService.monitorTotais().then((response) => {
				response.totalLiberado = response.totalGeral - response.bloqueado;
				
				this.setState({ monitorTotais: response });
			});
		}
	}

	detalhesVendedor = (vendedorId, vendedorNome) => {
		this.painelCACService.detalhesVendedor(vendedorId).then((response) => {
            let lista = response;
			ModalRealm.showDialog(<PainelCACDetalhe key={TZMUserData.getIdGenerator()} bloqueado={false} header={`Detalhes por Vendedor  - ${vendedorId + ' - ' + vendedorNome}`} listDetalhes={lista}  />);   
        });        
    }

	detalhesBloqueado = () => {
		this.painelCACService.detalhesBloqueado().then((response) => {
            let lista = response;
			ModalRealm.showDialog(<PainelCACDetalhe key={TZMUserData.getIdGenerator()} bloqueado={true} header="Detalhes Bloqueado" listDetalhes={lista}/>);   
        });        
    }

	pedidosVendedor = [
		<TZMColumnPainel body={(rowData, column) =>
			<div title={`Código do Vendedor: ` + rowData["vendedorId"]}  onClick={() => this.detalhesVendedor(rowData.vendedorId, rowData.nome)} style={{ cursor: "pointer" , fontSize: "25px", fontWeight: "bold" }}>
				{rowData["nome"]}
			</div>}
			field="nome" key="nome" header={<span style={{ fontSize: "20px" }} > Pedidos por Vendedor</span>} style={{ width: "*" }} />,

		<TZMColumnPainel body={(rowData, column) => <div style={{ textAlign: "right", fontSize: "25px", fontWeight: "bold" }}>{toDecimal(Math.round(rowData["quantidade"]))}</div>} field="quantidade" key="volume" header={<span style={{ fontSize: "20px" }}>Volume m2</span>} style={{ width: "10em" }} />,
	];

	detalhesOperador = (operador) => {
		this.painelCACService.detalhesOperador(operador).then((response) => {
            let lista = response;
			ModalRealm.showDialog(<PainelCACDetalhe key={TZMUserData.getIdGenerator()} bloqueado={false} header={`Detalhes por Operador  - ${operador}`} listDetalhes={lista}  />);   
        });        
    }

	pedidosOperador = [
		<TZMColumnPainel body={(rowData, column) => 
			<div style={{ cursor: "pointer" ,fontSize: "25px", fontWeight: "bold" }} onClick={() => this.detalhesOperador(rowData.operador)}>
				{rowData["operador"]}
			</div>} field="operador" key="operador" style={{ width: "*" }} header={<span style={{ fontSize: "20px" }}>Pedidos por Operador</span>} />,
		<TZMColumnPainel body={(rowData, column) => <div style={{ textAlign: "right", fontSize: "25px", fontWeight: "bold" }}>{toDecimal(Math.round(rowData["quantidade"]))}</div>} field="quantidade" key="volume" style={{ width: "10em" }} header={<span style={{ fontSize: "20px" }}>Volume m2</span>} />,
		<TZMColumnPainel body={(rowData, column) => <div style={{ textAlign: "right", fontSize: "25px", fontWeight: "bold" }}>{rowData["precoMedio"].toCurrency()}</div>} field="precoMedio" key="precoMedio" header={<div id="preco" children="Preço Médio" style={{ fontSize: "20px" }} />} style={{ width: "10em" }} />
	];

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-8 ui-g-nopad">
					<TZMTabView >
						<TZMTabPanel header="Pedidos por Vendedor / Operador">
						<div className="ui-g">
							<div className="ui-g-6">
								<Tooltip for="#preco" title="Preço Médio = (quantidade * valor unitário) - ICM / valor m2 " tooltipPosition="right" />
								<TZMTable rows={21} paginator value={this.state.pedidoOperador}>
									{this.pedidosOperador}
								</TZMTable>
							</div>
							<div className="ui-g-6">
								<TZMTable rows={22} paginator value={this.state.pedidoVendedor}>
									{this.pedidosVendedor}
								</TZMTable>
							</div>
							</div>
						</TZMTabPanel>
						<TZMTabPanel header="Totais por Grupo">
							<div className="ui-g">
							<div className="ui-g-12">
							<TZMPanel>
								<div className="ui-g" style={{ textAlign: "center" }}>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="Pimaco FAT" value={this.state.monitorTotais.pimacoFaturado != null ? this.state.monitorTotais.pimacoFaturado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="Pimaco Cart" value={this.state.monitorTotais.pimacoCarteira != null ? this.state.monitorTotais.pimacoCarteira.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="Pimaco Bloq" value={this.state.monitorTotais.pimacoBloqueado != null ? this.state.monitorTotais.pimacoBloqueado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", padding: "0", fontWeight: "bold", textAlign: "center" }} label="Pimaco Total" value={this.state.monitorTotais.pimacoTotal != null ? this.state.monitorTotais.pimacoTotal.toInteger() : 0} />
									</div>

									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="Etiqueta Office FAT" value={this.state.monitorTotais.officeFaturado != null ? this.state.monitorTotais.officeFaturado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="Etiqueta Office Cart" value={this.state.monitorTotais.officeCarteira != null ? this.state.monitorTotais.officeCarteira.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="Etiqueta Office Bloq" value={this.state.monitorTotais.officeBloqueado != null ? this.state.monitorTotais.officeBloqueado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", padding: "0", fontWeight: "bold", textAlign: "center" }} label="Etiqueta Office Total" value={this.state.monitorTotais.officeTotal != null ? this.state.monitorTotais.officeTotal.toInteger() : 0} />
									</div>

									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="Filme FAT" value={this.state.monitorTotais.filmeFaturado != null ? this.state.monitorTotais.filmeFaturado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
								
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="Filme CART" value={this.state.monitorTotais.filmeCarteira != null ? this.state.monitorTotais.filmeCarteira.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="Filme Total ROLO" value={this.state.monitorTotais.filmeTotalrolo != null ? this.state.monitorTotais.filmeTotalrolo.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", padding: "0", fontWeight: "bold", textAlign: "center" }} label="Filme Total M2" value={this.state.monitorTotais.filmeTotal != null ? this.state.monitorTotais.filmeTotal.toInteger() : 0} />
									</div>

									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="Filme Colorido FAT" value={this.state.monitorTotais.filmeColoridoFaturado != null ? this.state.monitorTotais.filmeColoridoFaturado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="Filme Colorido CART" value={this.state.monitorTotais.filmeColoridoCarteira != null ? this.state.monitorTotais.filmeColoridoCarteira.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="Filme Colorido Total ROLO" value={this.state.monitorTotais.filmeColoridoTotalrolo != null ? this.state.monitorTotais.filmeColoridoTotalrolo.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", padding: "0", fontWeight: "bold", textAlign: "center" }} label="Filme Colorido Total M2" value={this.state.monitorTotais.filmeColoridoTotal != null ? this.state.monitorTotais.filmeColoridoTotal.toInteger() : 0} />
									</div>

									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="Fita FAT" value={this.state.monitorTotais.fitaFaturado != null ? this.state.monitorTotais.fitaFaturado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="Fita CART" value={this.state.monitorTotais.fitaCarteira != null ? this.state.monitorTotais.fitaCarteira.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="Fita Total ROLO" value={this.state.monitorTotais.fitaTotalrolo != null ? this.state.monitorTotais.fitaTotalrolo.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", padding: "0", fontWeight: "bold", textAlign: "center" }} label="Fita Total M2" value={this.state.monitorTotais.fitaTotal != null ? this.state.monitorTotais.fitaTotal.toInteger() : 0} />
									</div>

									{/*- CAMPOS NOVOS -*/}
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="BOBINA FAT" value={this.state.monitorTotais.bobinaFaturado != null ? this.state.monitorTotais.bobinaFaturado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="BOBINA CART" value={this.state.monitorTotais.bobinaCarteira != null ? this.state.monitorTotais.bobinaCarteira.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="BOBINA BLOQ" value={this.state.monitorTotais.bobinaBloqueado != null ? this.state.monitorTotais.bobinaBloqueado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", padding: "0", fontWeight: "bold", textAlign: "center" }} label="Bobina Total M2" value={this.state.monitorTotais.bobinaTotal != null ? this.state.monitorTotais.bobinaTotal.toInteger() : 0} />
									</div>

									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="FOLHA FAT" value={this.state.monitorTotais.folhaFaturado != null ? this.state.monitorTotais.folhaFaturado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="FOLHA CART" value={this.state.monitorTotais.folhaCarteira != null ? this.state.monitorTotais.folhaCarteira.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="FOLHA BLOQ" value={this.state.monitorTotais.folhaBloqueado != null ? this.state.monitorTotais.folhaBloqueado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", padding: "0", fontWeight: "bold", textAlign: "center" }} label="FOLHA Total M2" value={this.state.monitorTotais.folhaTotal != null ? this.state.monitorTotais.folhaTotal.toInteger() : 0} />
									</div>

									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="RIBBON FAT" value={this.state.monitorTotais.ribbonFaturado != null ? this.state.monitorTotais.ribbonFaturado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="RIBBON CART" value={this.state.monitorTotais.ribbonCarteira != null ? this.state.monitorTotais.ribbonCarteira.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="RIBBON BLOQ" value={this.state.monitorTotais.ribbonBloqueado != null ? this.state.monitorTotais.ribbonBloqueado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", padding: "0", fontWeight: "bold", textAlign: "center" }} label="RIBBON Total M2" value={this.state.monitorTotais.ribbonTotal != null ? this.state.monitorTotais.ribbonTotal.toInteger() : 0} />
									</div>

									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="SIGN FAT" value={this.state.monitorTotais.signFaturado != null ? this.state.monitorTotais.signFaturado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="SIGN CART" value={this.state.monitorTotais.signCarteira != null ? this.state.monitorTotais.signCarteira.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="SIGN BLOQ" value={this.state.monitorTotais.signBloqueado != null ? this.state.monitorTotais.signBloqueado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", padding: "0", fontWeight: "bold", textAlign: "center" }} label="SIGN Total M2" value={this.state.monitorTotais.signTotal != null ? this.state.monitorTotais.signTotal.toInteger() : 0} />
									</div>

									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="EXPO FAT" value={this.state.monitorTotais.expoFaturado != null ? this.state.monitorTotais.expoFaturado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="EXPO CART" value={this.state.monitorTotais.expoCarteira != null ? this.state.monitorTotais.expoCarteira.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="EXPO BLOQ" value={this.state.monitorTotais.expoBloqueado != null ? this.state.monitorTotais.expoBloqueado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", padding: "0", fontWeight: "bold", textAlign: "center" }} label="EXPO Total M2" value={this.state.monitorTotais.expoTotal != null ? this.state.monitorTotais.expoTotal.toInteger() : 0} />
									</div>

									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="RRE FAT" value={this.state.monitorTotais.rreFaturado != null ? this.state.monitorTotais.rreFaturado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="RRE CART" value={this.state.monitorTotais.rreCarteira != null ? this.state.monitorTotais.rreCarteira.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="RRE BLOQ" value={this.state.monitorTotais.rreBloqueado != null ? this.state.monitorTotais.rreBloqueado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", padding: "0", fontWeight: "bold", textAlign: "center" }} label="RRE Total M2" value={this.state.monitorTotais.rreTotal != null ? this.state.monitorTotais.rreTotal.toInteger() : 0} />
									</div>

									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="RRUY FAT" value={this.state.monitorTotais.rruyFaturado != null ? this.state.monitorTotais.rruyFaturado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="RRUY CART" value={this.state.monitorTotais.rruyCarteira != null ? this.state.monitorTotais.rruyCarteira.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="RRUY BLOQ" value={this.state.monitorTotais.rruyBloqueado != null ? this.state.monitorTotais.rruyBloqueado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", padding: "0", fontWeight: "bold", textAlign: "center" }} label="RRUY Total M2" value={this.state.monitorTotais.rruyTotal != null ? this.state.monitorTotais.rruyTotal.toInteger() : 0} />
									</div>

									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="SAS FAT" value={this.state.monitorTotais.sasFaturado != null ? this.state.monitorTotais.sasFaturado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="SAS CART" value={this.state.monitorTotais.sasCarteira != null ? this.state.monitorTotais.sasCarteira.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="SAS BLOQ" value={this.state.monitorTotais.sasBloqueado != null ? this.state.monitorTotais.sasBloqueado.toInteger() : 0} />
									</div>
									<div className="ui-g-3">
										<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", padding: "0", fontWeight: "bold", textAlign: "center" }} label="SAS Total M2" value={this.state.monitorTotais.sasTotal != null ? this.state.monitorTotais.sasTotal.toInteger() : 0} />
									</div>										
								</div>
							</TZMPanel>
							</div>
							</div>
						</TZMTabPanel>
					</TZMTabView>
						
					</div>
					<div className="ui-g-4">
						<TZMPanel>
							<div className="ui-g" style={{ textAlign: "center" }}>
								<div className="ui-g-12">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "40px", height: "45px", color: "#F44336", fontWeight: "bold", textAlign: "center" }} label="Lançado hoje para o Mês" value={this.state.monitorTotais.lancadoHojeMes != null ? this.state.monitorTotais.lancadoHojeMes.toInteger() : 0} />
								</div>
								<div className="ui-g-4">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Local" value={this.state.monitorTotais.lancadoHojeMesNacional != null ? this.state.monitorTotais.lancadoHojeMesNacional.toInteger() : 0} />
								</div>
								<div className="ui-g-4">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Expo" value={this.state.monitorTotais.lancadoHojeMesExportacao != null ? this.state.monitorTotais.lancadoHojeMesExportacao.toInteger() : 0} />
								</div>
								<div className="ui-g-4">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="RRE/RRUY/SAS" value={this.state.monitorTotais.lancadoHojeMesInter != null ? this.state.monitorTotais.lancadoHojeMesInter.toInteger() : 0} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="Lançado Hoje Mês" value={this.state.monitorTotais.lancadoHojeMes != null ? this.state.monitorTotais.lancadoHojeMes.toInteger() : 0} />
								</div>
								<div className="ui-g-6">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center", padding: "0" }} label="Lançado hoje Fut" value={this.state.monitorTotais.lancadoHojeFuturo != null ? this.state.monitorTotais.lancadoHojeFuturo.toInteger() : 0} />
								</div>
								<div className="ui-g-12">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "40px", height: "45px", fontWeight: "bold", textAlign: "center" }} label="Lançado mês para o mês" value={this.state.monitorTotais.lancadoMesMes != null ? this.state.monitorTotais.lancadoMesMes.toInteger() : 0} />
								</div>
								<div className="ui-g-4">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Local" value={this.state.monitorTotais.lancadoMesMesNacional != null ? this.state.monitorTotais.lancadoMesMesNacional.toInteger() : 0} />
								</div>
								<div className="ui-g-4">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Expo" value={this.state.monitorTotais.lancadoMesMesExportacao != null ? this.state.monitorTotais.lancadoMesMesExportacao.toInteger() : 0} />
								</div>
								<div className="ui-g-4">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="RRE/RRUY/SAS" value={this.state.monitorTotais.lancadoMesMesInter != null ? this.state.monitorTotais.lancadoMesMesInter.toInteger() : 0} />
								</div>
								<div className="ui-g-12">
									<TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "32px", height: "35px", fontWeight: "bold" }} label="Carteira Futura" value={this.state.monitorTotais.lancadoMesFuturo != null ? this.state.monitorTotais.lancadoMesFuturo.toInteger() : 0} />
								</div>
								<div className="ui-g-4">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Local" value={this.state.monitorTotais.lancadoMesFuturoNacional != null ? this.state.monitorTotais.lancadoMesFuturoNacional.toInteger() : 0} />
								</div>
								<div className="ui-g-4">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Expo" value={this.state.monitorTotais.lancadoMesFuturoExportacao != null ? this.state.monitorTotais.lancadoMesFuturoExportacao.toInteger() : 0} />
								</div>
								<div className="ui-g-4">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="RRE/RRUY/SAS" value={this.state.monitorTotais.lancadoMesFuturoInter != null ? this.state.monitorTotais.lancadoMesFuturoInter.toInteger() : 0} />
								</div>
								
								{/**
								 <div className="ui-g-6">
									<TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "32px", height: "35px", fontWeight: "bold" }} label="Carteira" value={this.state.monitorTotais.carteira != null ? this.state.monitorTotais.carteira.toInteger() : 0} />
								</div>

								<div className="ui-g-6">
									<TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "32px", height: "35px", fontWeight: "bold" }} label="Bloqueado" value={this.state.monitorTotais.bloqueado != null ? this.state.monitorTotais.bloqueado.toInteger() : 0} />
								</div>
								 */}
							
								<div className="ui-g-12">
									<TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "32px", height: "35px", fontWeight: "bold" }} label="CART + BLOQ" value={this.state.monitorTotais.cartBloq != null ? this.state.monitorTotais.cartBloq.toInteger() : 0} />
								</div>																

								<div className="ui-g-4">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Local" value={this.state.monitorTotais.cartBloqNacional != null ? this.state.monitorTotais.cartBloqNacional.toInteger() : 0} />
								</div>
								<div className="ui-g-4">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Expo" value={this.state.monitorTotais.cartBloqExportacao != null ? this.state.monitorTotais.cartBloqExportacao.toInteger() : 0} />
								</div>
								<div className="ui-g-4">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="RRE/RRUY/SAS" value={this.state.monitorTotais.cartBloqInter != null ? this.state.monitorTotais.cartBloqInter.toInteger() : 0} />
								</div>
							</div>
						</TZMPanel>
						<div style={{ height: "7px" }} />
						<TZMPanel>
							<div className="ui-g" style={{ padding: 0, textAlign: "center" }}>
								<div className="ui-g-12" title="Faturado - Devolução = Faturado no mês">
									<TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "40px", height: "45px", color: "#4286f4", fontWeight: "bold" }}
												label="Faturado no mês" value={this.state.monitorTotais.faturadoMes != null ? this.state.monitorTotais.faturadoMes.toInteger() : 0} />
								</div>
								<div className="ui-g-4">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Local" value={this.state.monitorTotais.faturadoMesNacional != null ? this.state.monitorTotais.faturadoMesNacional.toInteger() : 0} />
								</div>
								<div className="ui-g-4">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Expo" value={this.state.monitorTotais.faturadoMesExportacao != null ? this.state.monitorTotais.faturadoMesExportacao.toInteger() : 0} />
								</div>
								<div className="ui-g-4">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="RRE/RRUY/SAS" value={this.state.monitorTotais.faturadoMesInter != null ? this.state.monitorTotais.faturadoMesInter.toInteger() : 0} />
								</div>

								<div className="ui-g-6" >
									<TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "32px", height: "35px", fontWeight: "bold", cursor: "pointer" }} onClick={() => this.detalhesBloqueado()} label="Total Bloqueado" value={this.state.monitorTotais.bloqueado != null ? this.state.monitorTotais.bloqueado.toInteger() : 0} />
								</div>								
								<div className="ui-g-6">
									<TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "32px", height: "35px", fontWeight: "bold" }} label="Total Liberado" value={(this.state.monitorTotais.totalLiberado) != null ? (this.state.monitorTotais.totalLiberado).toInteger() : 0} />
								</div>

								<div className="ui-g-12">
									<TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "40px", height: "45px", color: "#4286f4", fontWeight: "bold" }} label="Total Geral m2" value={this.state.monitorTotais.totalGeral != null ? this.state.monitorTotais.totalGeral.toInteger() : 0} />
								</div>
								<div className="ui-g-4">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Local" value={this.state.monitorTotais.totalGeralNacional != null ? this.state.monitorTotais.totalGeralNacional.toInteger() : 0} />
								</div>
								<div className="ui-g-4">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Expo" value={this.state.monitorTotais.totalGeralExportacao != null ? this.state.monitorTotais.totalGeralExportacao.toInteger() : 0} />
								</div>
								<div className="ui-g-4">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="RRE/RRUY/SAS" value={this.state.monitorTotais.totalGeralInter != null ? this.state.monitorTotais.totalGeralInter.toInteger() : 0} />
								</div>
								<div className="ui-g-9">
									<TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "40px", height: "45px", fontWeight: "bold" }} 
												label="Budget" value={this.state.monitorTotais.budget != null ? this.state.monitorTotais.budget.toInteger() : 0} />
								</div>
								
								<div className="ui-g-3">
									<TZMTextFieldPainel readOnly   style={{ textAlign: "center", fontSize: "40px", height: "45px", fontWeight: "bold" }} 
												label=" % Budget" value={this.state.monitorTotais.budgetAtingido != null ? toDecimalFixed(this.state.monitorTotais.budgetAtingido, 1) : 0} />
								</div>

								<div className="ui-g-9">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Meta Local/Expo" value={this.state.monitorTotais.metaLocal != null ? this.state.monitorTotais.metaLocal.toInteger() : 0} />
								</div>
								<div className="ui-g-3">
									<TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "32px", height: "35px" }} label="% Local/Exp" 
										value={Number(this.state.monitorTotais.metaLocal === 0) ? 0 :
											toDecimalFixed(
												(
													(Number(this.state.monitorTotais.totalGeralNacional) + Number(this.state.monitorTotais.totalGeralExportacao)) 
													/ 
													Number(this.state.monitorTotais.metaLocal === 0 ? 1 : this.state.monitorTotais.metaLocal) * 100), 1)} />
								</div>

								<div className="ui-g-9">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Meta RRE/RRUY" value={this.state.monitorTotais.metaInter != null ? this.state.monitorTotais.metaInter.toInteger() : 0} />
								</div>
								<div className="ui-g-3">
									<TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "32px", height: "35px" }} label="% RRE/RRUY" 
										value={Number(this.state.monitorTotais.metaInter === 0) ? 0 :
											toDecimalFixed(
												(
													(Number(this.state.monitorTotais.rreTotal) + Number(this.state.monitorTotais.rruyTotal)) 
													/ 
													Number(this.state.monitorTotais.metaInter === 0 ? 1 : this.state.monitorTotais.metaInter) * 100), 1)} />
								</div>

								
								<div className="ui-g-9">
									<TZMTextFieldPainel readOnly  style={{ fontSize: "32px", height: "35px", textAlign: "center" }} label="Meta SAS" value={this.state.monitorTotais.metaSas != null ? this.state.monitorTotais.metaSas.toInteger() : 0} />
								</div>
								<div className="ui-g-3">
									<TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "32px", height: "35px" }} label="% SAS" value={toDecimalFixed((Number(this.state.monitorTotais.sasTotal)  / Number(this.state.monitorTotais.metaSas === 0 ? 1 : this.state.monitorTotais.metaSas) * 100), 1)} />
								</div>
								<div className="ui-g-9">
									<TZMTextFieldPainel readOnly  style={{ textAlign: "center", fontSize: "40px", height: "45px", fontWeight: "bold" }} 
												label="Forecast" value={this.state.monitorTotais.forecast != null ? this.state.monitorTotais.forecast.toInteger() : 0} />
								</div>
								
								<div className="ui-g-3">
									<TZMTextFieldPainel readOnly   style={{ textAlign: "center", fontSize: "40px", height: "45px", fontWeight: "bold" }} 
												label=" % Forecast" value={this.state.monitorTotais.forecastAtingido != null ? toDecimalFixed(this.state.monitorTotais.forecastAtingido, 1) : 0} />
								</div>
							</div>
						</TZMPanel>
					</div>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<TZMMenubarInfo countdown={this.state.countdown} />
				<ModalRealm />
			</TZMMainContent>
		);
	}
}
