import { httpDelete, httpGet, httpPost } from "../../utilities/TZMRequest";

export class GestorService {

	completar(gestor) {
		return httpGet(`/gestores/${gestor.id}`);
	}

	listar(params) {
		return httpGet("/gestores", params);
	}

	salvar(gestor) {
		return httpPost("/gestores", gestor);
	}

	excluir(gestor) {
		return httpDelete("/gestores", gestor.id);
	}

}
