import moment from "moment";
import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMCalendar from "../../../components/common/TZMCalendar";
import {TZMNumber} from "../../../components/common/TZMNumber";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMShortcut from "../../../utilities/TZMShortcut";

export class VisualizarFCI extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            fci: props.fci
        };
    }

    fechar = () => {
        this.setState({ visible: false });
    }

    render() {
        return (
            <TZMDialog style={{ width: "400px" }} visible={this.state.visible} modal header="Ficha de Conteúdo de Importação" {...this.props}>
                <div className="ui-g">
                    <div className="ui-g-6">                        
                        <TZMCalendar appendTo={document.body} disabled label="Vigência" value={moment(this.state.fci.dataVigencia).format("YYYY-MM-DD")} />
                    </div>
                    <div className="ui-g-6">
                        <TZMNumber name="rodada" label="Rodada" disabled value={this.state.fci.rodada} />
                    </div>
                    {/* <div className="ui-g-2">
                        <TZMNumber name="conteudoImportacao" label="Conteúdo de Importação" disabled value={this.state.fci.conteudoImportacao} />
                    </div>  */}
                </div>
                <TZMPanelFooter>
                    <TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-close" onClick={this.fechar} />
                </TZMPanelFooter>
                <TZMShortcut active={this.state.visible} onEscape={this.fechar}
                />
            </TZMDialog>
        );
    }

}
