import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import TZMShortcut from "../../../utilities/TZMShortcut";
import TZMUserData from "../../../utilities/TZMUserData";
import { GestorService } from "../../servicos/GestorService";
import { EditarGestor } from "./EditarGestor";

const title = "Gestores";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class Gestor extends React.Component {

	state = {
		desativados: false,
		gestor: ""
	};

	gestorService = new GestorService();
	listarGestores = () => {
		this.gestorService.listar(this.state).then((gestores) => this.setState({gestores}));
	}

	alternarOcultos = () => {
		this.setState({desativados: !this.state.desativados});
	}

	editarGestor = (gestor) => {
		ModalRealm.showDialog(<EditarGestor gestor={gestor} onModalClose={this.listarGestores} />);
	}

	criarGestor = () => {
		this.editarGestor({nome: ""});
	}

	excluirGestor = (gestor) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => { this.gestorService.excluir(gestor).then(this.listarGestores);}} question="Tem certeza de que deseja excluir este gestor?" />);
	}

	columns = [
		<TZMColumn header="E-Mail" key={0} field="email" style={{width: "*"}} />,
		<TZMColumn header="Operador" key={1} style={{width: "*"}} body={(gestor) => {
			return gestor.operador ? gestor.operador.nome : null;
		}} />,
		<TZMColumn header="Ações" key={2} style={{width: "6em", textAlign: "center"}} body={(gestor) => {
			return (
				<div>
					{TZMUserData.hasRole("____GEST_E") ? <span title="Editar Gestor" className="fa fa-edit ui-tzm-icon" onClick={() => this.editarGestor(gestor)} /> : null}
					{TZMUserData.hasRole("____GEST_D") ? <span title="Remover Gestor" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirGestor(gestor)} /> : null}
				</div>
			);
		}} />
	];

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">
									<div className="ui-g-12">
										<TZMTextField name="email" autoFocus onChange={this.handleUpdate} label="Gestor" placeholder="E-mail" value={this.state.email} />
									</div>
								</div>
							</TZMForm>
							<TZMPanelFooter>
								<TZMButton title={this.state.desativados ? "Ocultar Desativados" : "Exibir Desativados"} style={{float: "left"}} onClick={this.alternarOcultos} secondary icon={this.state.desativados ? "fas fa-eye": "fas fa-low-vision"} />
								{TZMUserData.hasRole("____GEST_C") ? <TZMButton success icon="fas fa-plus" label="Novo Gestor" onClick={this.criarGestor} /> : null}
								<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listarGestores} />
							</TZMPanelFooter>
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable value={this.state.gestores} paginator rows={50} children={this.columns} />
						</TZMPanel>
					</div>
				</div>
				<TZMShortcut active={TZMShortcut.modalCount === 0}
					onEnter={this.listarGestores}
					onCtrlH={this.alternarOcultos}
					onCtrlN={this.criarGestor}
				/>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}

}
