import moment from 'moment';
import { Column } from 'primereact/components/column/Column';
import { MultiSelect } from 'primereact/components/multiselect/MultiSelect';
import { ColumnGroup } from 'primereact/components/columngroup/ColumnGroup';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Row } from 'primereact/components/row/Row';
import React from "react";
import TZMButton from '../../components/common/TZMButton';
import { toCurrency, toDecimal, toPercent } from '../../components/common/TZMFormatter';
import TZMDialog from '../../components/dialog/TZMDialog';
import TZMPanelFooter from '../../components/panel/TZMPanelFooter';
import TZMShortcut from '../../utilities/TZMShortcut';

export class PainelCACDetalhe extends React.Component {

    constructor(props) {
        super(props);        
        moment.locale('pt-BR');        
        this.state = {
            visible: true,
            header: props.header,
            bloqueado: props.bloqueado,
            bloqueios: [],
            detalhes: props.listDetalhes,
            detalhesOriginal: props.listDetalhes,
            bloqueio: null
        };        
    }

    componentDidMount() {
        if (this.state.bloqueado) {
            this.bloqueios();
        }
    }

    fechar = () => {
        this.setState({ visible: false });
    }

    mesFormat(rowData, column) {
        return moment(rowData[column.field]).format("DD/MM/YYYY");
    }

    currencyFormat(rowData, column) {
        return <div style={{ textAlign: "right" }}>{toCurrency(rowData[column.field])}</div>;
    }

    toDecimal(rowData, column) {
        return <div style={{ textAlign: "right" }}>{toDecimal(Math.round(rowData[column.field]))}</div>
    }

    percentFormat = (rowData, column) => {
		return <div style={{ textAlign: "right" }}>{toPercent(rowData[column.field]/100)}</div>;
	}



    bloqueios = () => {
        let detalhes = this.state.detalhes;
        let bloqueios = [];
        for (const detalhe of detalhes) {            
            if(!bloqueios.some(b => b === detalhe.siglaBloqueio)) {
                bloqueios.push(detalhe.siglaBloqueio);
            }    
        }        
        let options = bloqueios.sort(function (a, b) {return a.replace(/\s/g, '').localeCompare(b.replace(/\s/g, ''));}).map((bloqueio) => ({label: bloqueio, value: bloqueio}));
        this.setState({bloqueios: options});
    }

    filtrarBloqueio = (event) => {
        let detalhes = this.state.detalhesOriginal;
        let detalhesFiltrados = [];
        if (event.value) {
            for (const detalhe of detalhes) {
                if(event.value.some(b => b === detalhe.siglaBloqueio) && 
                    !detalhesFiltrados.some( p => p.pedidoId === detalhe.pedidoId && p.itemId === detalhe.itemId)) {
                    detalhesFiltrados.push(detalhe);
                }      

            }
        }
        if (detalhesFiltrados.length > 0) {
            this.setState({bloqueio: event.value, detalhes: detalhesFiltrados});
        } else {
            this.setState({bloqueio: event.value, detalhes});
        }
        
        
    }

    dataTableBloqueado = () => {
        return (            
            <div className="ui-g">      
                <DataTable emptyMessage="Nenhum registro encontrado" ref={(el) => this.dt = el} value={this.state.detalhes} 
                    paginator rows={15} pageLinkSize={10} footerColumnGroup={
                        <ColumnGroup>
                            <Row>
                                <Column colSpan={2} style={{ fontWeight: "bold", fontSize: "12px", textAlign: "left" }} footer={`Total de Registros: ` + this.state.detalhes.length} />                                    
                                <Column colSpan={5}/>
                                <Column style={{ fontWeight: "bold", fontSize: "12px", textAlign: "right" }} footer="Totais " />
                                <Column style={{ fontWeight: "bold", fontSize: "12px", textAlign: "right" }} footer={toDecimal(Math.round(this.state.detalhes.map((item) => item.quantidade).reduce((a, b) => a + b, 0)))} />
                                <Column style={{ fontWeight: "bold", fontSize: "12px", textAlign: "right" }} footer={toCurrency(this.state.detalhes.map((item) => item.valorTotal).reduce((a, b) => a + b, 0))} />
                                <Column />
                            </Row>
                        </ColumnGroup>
                    }>                        
                    <Column style={{ width: "8em", textAlign: "center",   fontSize: "12px" }} sortable field="clienteId" key="clienteId" header="Cliente"  />
                    <Column style={{ width: "*", textAlign: "left",   fontSize: "12px" }} sortable field="clienteRazaoSocial" key="clienteRazaoSocial" header="Razão Social"  />
                    <Column style={{ width: "7em", textAlign: "center",   fontSize: "12px" }} sortable field="pedidoId" key="pedidoId" header="Pedido"  />
                    <Column style={{ width: "3em", textAlign: "center",   fontSize: "12px" }} field="itemId" key="itemId" header="Item" />
                    <Column style={{ width: "11em", textAlign: "center",   fontSize: "12px" }} sortable field="produtoId" key="produtoId" header="Cod. Produto"  />
                    <Column style={{ width: "*", textAlign: "center",   fontSize: "12px" }} sortable field="produtoDescricao" key="produtoDescricao" header="Produto"  />
                    <Column style={{ width: "4.5em", textAlign: "center", fontSize: "12px" }} sortable field="unidade" key="unidade" header="Unidade"  />
                    <Column style={{ width: "6.5em", textAlign: "center",   fontSize: "12px" }}  sortable field="dataEntrega" key="dataEntrega" header="Data Entrega"  body={this.mesFormat} />
                    <Column style={{ width: "5em", textAlign: "center",   fontSize: "12px" }} field="quantidade" key="quantidade" body={this.toDecimal} header="Volume" />
                    <Column style={{ width: "9em", textAlign: "center",   fontSize: "12px" }} header="Valor Total"  field="valorTotal" body={this.currencyFormat} />
                    <Column key="filterMotivo"  field="filterMotivo" header={ 
                        <div>   
                            <MultiSelect value={this.state.bloqueio} defaultLabel="Selecione" options={this.state.bloqueios} onChange={this.filtrarBloqueio} 
                             style={{minWidth:'18em', textAlign: "center"}} />                                    
                        </div>
                        } style={{width: "*", textAlign: "center"}} 
                    
                    body={ (p) => p.motivosBloqueio.map( e => 
                            <div key={Math.random()} >                                
                                <span style={{ textAlign: "center" ,padding: "5px", fontSize: "14px", color: "#5c5d5e" }} className={`fas fa-${e.icone}`}  />
                                {e.sigla} - {e.descricao}
                            </div>)
                        }/>
                </DataTable>
                
            </div>
        );      
    }

    dataTable = () => {
        return (
          <div className="ui-g">
                <DataTable emptyMessage="Nenhum registro encontrado" ref={(el) => this.dt = el} value={this.state.detalhes}
                    paginator={true} rows={15} pageLinkSize={10} footerColumnGroup={
                        <ColumnGroup>
                            <Row>
                                <Column colSpan={2} style={{ fontWeight: "bold", fontSize: "12px", textAlign: "left" }} footer={`Total de Registros: ` + this.state.detalhes.length} />                                    
                                <Column colSpan={5}/>
                                <Column style={{ fontWeight: "bold", fontSize: "12px", textAlign: "right" }} footer="Totais " />
                                <Column style={{ fontWeight: "bold", fontSize: "12px", textAlign: "right" }} footer={toDecimal(Math.round(this.state.detalhes.map((item) => item.quantidade).reduce((a, b) => a + b, 0)))} />
                                <Column colSpan={2}/>
                                <Column style={{ fontWeight: "bold", fontSize: "12px", textAlign: "right" }} footer={toCurrency(this.state.detalhes.map((item) => item.valorTotal).reduce((a, b) => a + b, 0))} />
                            </Row>
                        </ColumnGroup>
                    }>                        
                    <Column style={{ width: "8em", textAlign: "center",   fontSize: "12px" }} sortable field="clienteId" key="clienteId" header="Cliente" filter={true} />
                    <Column style={{ width: "*", textAlign: "left",   fontSize: "12px" }} sortable field="clienteRazaoSocial" key="clienteRazaoSocial" header="Razão Social" filter={true} />
                    <Column style={{ width: "7em", textAlign: "center",   fontSize: "12px" }} sortable field="pedidoId" key="pedidoId" header="Pedido" filter={true} />
                    <Column style={{ width: "3em", textAlign: "center",   fontSize: "12px" }} field="itemId" key="itemId" header="Item" />
                    <Column style={{ width: "11em", textAlign: "center",   fontSize: "12px" }} sortable field="produtoId" key="produtoId" header="Cod. Produto" filter={true} />
                    <Column style={{ width: "*", textAlign: "center",   fontSize: "12px" }} sortable field="produtoDescricao" key="produtoDescricao" header="Produto" filter={true} />
                    <Column style={{ width: "4.5em", textAlign: "center", fontSize: "12px" }} sortable field="unidade" key="unidade" header="Unidade" filter={true} />
                    <Column style={{ width: "6.5em", textAlign: "center",   fontSize: "12px" }} filter={true} sortable field="dataEntrega" key="dataEntrega" header="Data Entrega"  body={this.mesFormat} />
                    <Column style={{ width: "5em", textAlign: "center",   fontSize: "12px" }} field="quantidade" key="quantidade" body={this.toDecimal} header="Volume" />
                    <Column key="percentualIcms" style={{ width: "5em", textAlign: "center",   fontSize: "12px" }} field="percentualIcms" header="ICMS %" body={this.percentFormat}/>
                    <Column style={{ width: "5em", textAlign: "center",   fontSize: "12px" }} header="Valor Unitário"  field="valorUnitario" body={this.currencyFormat} />
                    <Column style={{ width: "9em", textAlign: "center",   fontSize: "12px" }} header="Valor Total"  field="valorTotal" body={this.currencyFormat} />
                </DataTable>
            </div>
        );
    }

    render() {
        return (
            <TZMDialog style={{ width: "95%" }} visible={this.state.visible} modal header={this.state.header} {...this.props}>                           
                {this.state.bloqueado ? this.dataTableBloqueado() : this.dataTable()}
                <TZMPanelFooter>
                    <TZMButton className="ui-button-secondary"  label="Fechar" icon="fas fa-close" onClick={this.fechar} />                    
                </TZMPanelFooter>
                <TZMShortcut active={this.state.visible} onEscape={this.fechar} />
            </TZMDialog>
        );
    }
}