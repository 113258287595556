import moment from "moment";
import { Column } from "primereact/components/column/Column";
import React from "react";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMButton from "../../../components/common/TZMButton";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPopup from "../../../components/dialog/TZMPopup";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTable from "../../../components/table/TZMTable";
import TZMShortcut from "../../../utilities/TZMShortcut";
import { BudgetService } from "../../servicos/BudgetService";
import { VendedorService } from "../../servicos/VendedorService";
import Templates from "../../../utilities/Templates";

export class EditarBudget extends React.Component {

	state = {
		visible: true,
		vendedor: this.props.vendedor ? Object.assign({}, this.props.vendedor) : null,
		vendedores: [],
		budgets: [],
		_budgets: [],
		ano: moment().get("year")
    };
    
    budgetService = new BudgetService();
	vendedorService = new VendedorService();
	
	componentDidMount() {
		moment.locale('pt-BR');
		this.updateBudgets();
	}

	onHide = () => this.setState({visible: false});

	handleChange = (event) => {
		this.setState({[event.name]: event.value});
		switch (event.name) {
			case "ano":
				this.updateBudgetList();
				break;
			default:
				break;
		}
	}

	handleSelect = (event) => {
		this.setState({[event.name]: event.value});
		this.updateBudgets();
	}

	salvarBudget = async () => {
        const messages = [];
        if (!this.state.vendedor) messages.push("O vendedor é obrigatório");
        if (!messages.length) {
			const budgets = this.state.budgets.filter(b => b.id || b.unidade);
			this.budgetService.atualizar({
				"excluir": budgets.filter(b => b.id && !b.unidade && b._mod),
            	"salvar": budgets.filter(b => b.unidade && b._mod)
			}).then(() => {
				if (this.props.onSalvar) {
					this.props.onSalvar();
				}
				this.onHide();
			});
        } else {
            ModalRealm.showDialog(<TZMPopup key={Math.random()} header="Advertência" messages={messages} />);
        }
	}

	listarVendedores = (event) => {
		this.vendedorService.autoCompletar({vendedor: event.query}).then((vendedores) => this.setState({vendedores}));
	}

	handleClear = (event) => {
		let budget = this.state.budget;
		budget[event.name] = null;
		this.setState({budget});
		this.updateBudgets();
	}

	updateBudgets = () => {
		setTimeout(() => {
			if (this.state.vendedor) {
				this.budgetService.listar(`vendedor=id:${this.state.vendedor.id}&sort=referencia`).then(budgets => {
					this.setState({budgets});
					this.updateBudgetList();
				});
			}
		}, 50);
	}

	updateBudgetList = () => {
		setTimeout(() => {
			let budgets = this.state.budgets;
			let _budgets = budgets.filter(b => moment(b.referencia).get("year") === Number(this.state.ano));
			for (let i = 1; i <= 12; ++i) {
				const referencia = moment(new Date(Number(this.state.ano), i % 12, 1)).format("YYYY-MM-DD");
				if (!_budgets.some(b => b.referencia === referencia)) {
					const budget = {
						vendedor: this.state.vendedor,
						referencia,
						unidade: 0,
						moeda: 0
					};
					_budgets.push(budget);
					budgets.push(budget);
				}
			}
			_budgets = _budgets.sort((a, b) => a.referencia.localeCompare(b.referencia));
			this.setState({_budgets, budgets});
		}, 50);
	}

	columns = [
		 
		<Column key={0} style={{width: "10em"}} header="Referência" body={budget => moment(budget.referencia).format("MMMM")} />,
		<Column key={1} style={{width: "*"}} header="Budget (M²)" body={budget => <TZMTextField value={budget.unidade} onChange={(event) => {
			budget.unidade = event.target.value;
			budget._mod = true;
			this.forceUpdate();
		}} />} />,
		<Column key={2} style={{width: "*"}} header="Budget R$" body={budget => <TZMTextField value={budget.moeda} onChange={(event) => {
			budget.moeda = event.target.value;
			budget._mod = true;
			this.forceUpdate();
		}} />} />
	];

	render() {
		return (
			<TZMDialog style={{width: "650px"}} visible={this.state.visible} modal header="Budget" onHide={this.onHide} {...this.props}>
				<TZMPanel>
					<div className="ui-g">
						<div className="ui-g-10">
							<TZMAutoComplete onClear={this.handleClear} itemTemplate={Templates.vendedorTemplateOmega} onSelect={this.handleSelect} onChange={this.handleChange} 
							suggestions={this.state.vendedores} completeMethod={this.listarVendedores} name="vendedor" field="nome" label="Vendedor" value={this.state.vendedor} />
						</div>
						<div className="ui-g-2">
							<TZMTextField label="Ano" type="number" onChange={this.handleChange} name="ano" value={this.state.ano} />
						</div>
                        <div className="ui-g-12">
							<TZMTable value={this.state._budgets} children={this.columns} />
						</div>
					</div>
				</TZMPanel>
				<TZMPanelFooter>
					<TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvarBudget} />
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onHide} />
				</TZMPanelFooter>
				<TZMShortcut
					active={this.state.visible}
					onCtrlS={this.salvarBudget}
					onEscape={this.onHide}
				/>
			</TZMDialog>
		);
	}

}
