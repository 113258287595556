import { Column } from "primereact/components/column/Column";
import { DataTable } from "primereact/components/datatable/DataTable";
import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMDialog from "../../../components/dialog/TZMDialog";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMUserData from "../../../utilities/TZMUserData";
import { VwProdutoService } from "../../servicos/VwProdutoService";

export class ProcurarProduto extends React.Component {

	constructor(props) {
        super();
		this.state = {                        
            produtos: props.produtos,
            visible: true,
            itemPedido: props.itemPedido,
            params: {
                produtoId: null,
                descricao: null,
                traducao: null
            }
        };        
        this.vwProdutoService = new VwProdutoService();         
    }

    handleUpdate = (key, event) => {
		let params = this.state.params;
		params[key] = event.target.value;
		this.setState({params});
    }

    fechar = () => {
        this.setState({ visible: false });
    }
   
    listarProdutos = async () => {
        let params =  Object.assign({}, this.state.params);
        params.size = 200;
        await this.vwProdutoService.listarSimples(params).then((produtos) => {this.setState({produtos})});
    }

    confirmarSelecao = (produto) => {        
        ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} onYes={() => {
            let itemPedido = this.state.itemPedido;        
            itemPedido.produto = produto;   
            this.props.onSalvar(itemPedido);
            this.fechar();
        }} question={`Deseja selecionar o produto ${produto.descricao} ?`} />);	
	}
    
	render() {
        return (
            <TZMDialog style={{ width: "1200px" }} visible={this.state.visible} modal header="Consultar Produto" {...this.props}>
                <TZMPanel>
                    <TZMForm>
                        <div className="ui-g">                
                            <div className="ui-g-4">
                                <TZMTextField name="produtoId" onChange={(event) => this.handleUpdate("produtoId", event)} label="Produto" placeholder="SKU" value={this.state.params.produtoId} />
                            </div>
                            <div className="ui-g-4">
                                <TZMTextField name="descricao" onChange={(event) => this.handleUpdate("descricao", event)} label="Descrição" placeholder="Descrição" value={this.state.params.descricao} />                            
                            </div>
                            <div className="ui-g-4">
                                <TZMTextField name="traducao" onChange={(event) => this.handleUpdate("traducao", event)} label="Tradução" placeholder="Tradução" value={this.state.params.traducao} />
                            </div>
                        </div>                        
                        <TZMPanelFooter>
                            <TZMButton icon="fas fa-search" label="Procurar" onClick={this.listarProdutos} />
                        </TZMPanelFooter>
                    </TZMForm>
                </TZMPanel>
                <TZMPanel>
                    <TZMForm>
                        <div className="ui-g">
                            <DataTable  ref={(el) => this.dt = el} emptyMessage="Nenhum registro encontrado" value={this.state.produtos} paginator rows={15} 
                                        rowsPerPageOptions={[15, 30, 45]} pageLinkSize={15}>                
                                <Column field="produtoId" key="produtoId" style={{ textAlign: "center", width: "15em" }} header="SKU" />
                                <Column field="descricao" key="descricao" style={{width:"*", textAlign: "left" }} header="Produto" />
                                <Column field="unidade" key="unidade" style={{ textAlign: "center", width: "4.5em" }} header="Unidade" />
                                <Column field="nomeConhecido" key="nomeConhecido" style={{ textAlign: "center", width: "13em" }} header="Tradução" />
                                <Column style={{ width: "6em", textAlign: "center" }} key="acoes" header="Ações" body={(rowData) => {
                                    return <div style={{ textAlign: "center" }}>
                                        <span key={1} title="Selecionar Produto"  className="fa fa-check ui-tzm-icon" onClick={() => this.confirmarSelecao(rowData)} />
                                    </div>;            
                                }}/>
                            </DataTable>
                        </div> 
                    </TZMForm>
                </TZMPanel>
                <TZMPanelFooter>                
                    <TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-close" onClick={this.fechar} />                
                </TZMPanelFooter>            
            </TZMDialog>
        );
    }
}
