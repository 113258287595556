import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMShortcut from "../../../utilities/TZMShortcut";
import { ProdutoService } from "../../servicos/ProdutoService";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import { VwProdutoService } from "../../servicos/VwProdutoService";
import { FamiliaService } from "../../servicos/FamiliaService";

export class EditarProduto extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			visible: true,
            produto: props.produto,
            params: {
                produto: { pk: {}, descricao: "" }
            }
		};
        this.produtoService = new ProdutoService();		
		this.vwProdutoService = new VwProdutoService();
		this.familiaService = new FamiliaService();
	}

	onHide = () => this.setState({visible: false});

	handleChange = (event) => {
		let produto = this.state.produto;
		produto[event.name] = event.target.value;
		this.setState({produto});
	}

	salvarProduto = () => {
		let produto = Object.assign({}, this.state.produto);
		this.produtoService.salvar(produto).then((produto) => {
			if (this.props.onModalClose) this.props.onModalClose(produto);
			this.onHide();
		});
	}

	handleClear = (event) => {
		let produto = this.state.produto;
		produto[event.name] = null;
		this.setState({produto});
	}

	handleSelect = (event) => {
		let produto = this.state.produto;
		produto[event.name] = event.value;
		this.setState({produto});
    }

    produtoTemplate = (value) => {
		return (
			<div className="ui-tzm-suggestion-line"><div className="ui-tzm-suggestion-chip">{value.pk.produtoId}</div>{value.descricao}</div>
		);
    }

	listVwProdutos = (event) => {
		let params = Object.assign({});
		params.produto = event.query;
		this.vwProdutoService.autoCompletar(params).then((list) => {
			this.setState({ listProdutos: list });
		});
	}

    clearAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name] = { pk: {}, [event.field]: "" };
		this.setState({ params });
    }
    
	queryAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name][event.field] = event.value;
		this.setState({ params });
	}

	selectAutoComplete = (event) => {
		let params = this.state.params;
        params[event.name] = event.value;
        let vwProduto = params.produto;
        let produto = this.state.produto;
        produto.descricao = vwProduto.descricao;
        produto.sku = vwProduto.pk.produtoId;
        produto.unidade = vwProduto.unidade;
        produto.fatorUnitario = vwProduto.fatorUnitario;
		this.setState({ produto,params });
	}

	listarFamilia = async (event) => {		
		await this.familiaService.autoCompletarFamilia({descricao: event.query, sort:'descricao'}).then((listFamilia) => this.setState({listFamilia}));
	}   

	handleUnselect = (event) => {
		let produto = this.state.produto;
		if (!produto[event.name]) {
			produto[event.name] = [];
		}
		produto[event.name] = produto[event.name].filter((e) => e.id !== event.value.id);
		this.setState({produto});
	}

	handleUpdate = (event) => {		
		let produto = this.state.produto;		
		produto[event.name] = event.target.value;
		this.setState({produto});
	}

    render() {
		return (
			<TZMDialog style={{width: "1000px"}} visible={this.state.visible} modal header="Produto" onHide={this.onHide} {...this.props}>
				<TZMPanel>
					<div className="ui-g">
						<div className="ui-g-6">
                            <TZMTextField label="SKU" disabled name="sku" value={this.state.produto.sku} onChange={this.handleChange} />
                        </div>
						<div className="ui-g-6">
                            <TZMTextField label="Descrição" disabled name="descricao" value={this.state.produto.descricao} onChange={this.handleChange} />
                        </div>
						<div className="ui-g-2">
                            <TZMTextField label="Fator Unitário" disabled name="fatorUnitario" value={this.state.produto.fatorUnitario} onChange={this.handleChange} />
                        </div>	
                        <div className="ui-g-2">
                            <TZMTextField label="Unidade" disabled name="unidade" value={this.state.produto.unidade} onChange={this.handleChange} />
                        </div>
						<div className="ui-g-2">
                            <TZMTextField label="Grupo" disabled name="grupoId" value={this.state.produto.grupoId} onChange={this.handleChange} />
                        </div>						
						<div className="ui-g-6 ui-fluid">									
							<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.listFamilia} dropdown completeMethod={this.listarFamilia} name="familia" field="descricao" 
								label="Tipo" placeholder="Descrição" onUnselect={this.handleUnselect} value={this.state.produto.familia} />
						</div>                        
                    </div>
				</TZMPanel>
				<TZMPanelFooter>
					<TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvarProduto} />
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onHide} />
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onCtrlS={this.salvarProduto} onEscape={this.onHide}/>
			</TZMDialog>
		);
	}

}
