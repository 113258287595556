import { Button } from "primereact/components/button/Button";
import { Column } from 'primereact/components/column/Column';
import { ColumnGroup } from 'primereact/components/columngroup/ColumnGroup';
import { Row } from 'primereact/components/row/Row';
import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMIcon from "../../../components/common/TZMIcon";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMConfirmAdvertencia from "../../../components/dialog/TZMConfirmAdvertencia";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPopup from "../../../components/dialog/TZMPopup";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import TZMShortcut from "../../../utilities/TZMShortcut";
import TZMUserData from "../../../utilities/TZMUserData";
import { BobinaService } from "../../servicos/BobinaService";

export class EditarBobina extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			itemPedido: this.preencherNulos(this.props.itemPedido),
			cortes: this.props.cortes,
			visible: true,
			alterado: false,
			selectedGrade: 0,
			selectedIndex: -1
		};		
		this.bobinaService = new BobinaService();
	}

	componentWillMount() {
		this.recalcularGrades();
	}

	validarBobina = (itemPedido) => {
		let messages = [];
		if (itemPedido.produto.pk.produtoId == null) {
			messages.push("O produto é obrigatório");
		}
		if (itemPedido.quantidade == null) {
			messages.push("A quantidade é obrigatória");
		}
		if (itemPedido.bobina.cortes.some(c => c.largura == null || c.largura < 1)) {
			messages.push("Um corte não pode ter largura vazia ou menor que 1mm");
		}
		if (itemPedido.bobina.cortes.some(c => c.numero == null || c.numero < 1)) {
			messages.push("Um corte não pode ter zero bobinas");
		}
		if (itemPedido.bobina.cortes.some(c => c.numero == null || c.numero < 1)) {
			messages.push("Um corte não pode ter zero bobinas");
		}
		if (itemPedido.bobina.m2Corte == null || itemPedido.bobina.m2Corte <= 0) {
			messages.push("M² comprados não pode ser vazio");
		}
		let cortar = itemPedido.bobina.m2Corte.reduce((a, b) => a + b, 0);
		if (itemPedido.bobina.m2Corte.reduce((a, b) => a + b, 0) !== Number(itemPedido.quantidade)) {
			messages.push("O total a ser cortado (" + cortar + " m²) difere da quantidade comprada (" + itemPedido.quantidade + " m²).");
		}
		if (itemPedido.bobina.cortes.some(c => c.subtotal < 0)) {
			messages.push("O corte de uma bobina não pode exceder sua largura");
		} else {
			let maxGrades = itemPedido.bobina.cortes.map(c => Number(c.grade)).reduce((a, b) => Math.max(a, b), 0);			
			for (let i = 0; i <= maxGrades; ++i) {
				if (itemPedido.bobina.largura - itemPedido.bobina.cortes.filter(c => c.grade === i).map(c => c.largura * c.numero).reduce((a, b) => a + b, 0) > 20) {
					messages.push("A programação de corte de uma bobina não pode deixar sobra maior que 20mm");
				}
			}
		}
		for (let i = 0; i < itemPedido.bobina.quantidadeGrades; ++i) {
			if (!itemPedido.bobina.cortes.some(c => c.grade === i)) {
				messages.push("Cada grade deve ter ao menos 1 corte preenchido");
			}
		}
		if (this.aproveitamentoTotal() > itemPedido.quantidade) {
			messages.push("A quantidade a ser cortada é maior que a quantidade comprada");
		}
		let quantidadeTiradas = this.calcularQuantidadeTiradas();
		if (Math.round(quantidadeTiradas) !== quantidadeTiradas) {
			messages.push("A quantidade comprada é incompatível com as medidas da bobina");
		}
		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()} header="Advertência" messages={messages} />);
			return false;
		}
		return true;
	}

	validarCortes = (itemPedido) => {
		let messages = [];
		let cortes = this.state.cortes;
		for (let i = 0; i < itemPedido.bobina.quantidadeGrades; ++i) {
			itemPedido.bobina.cortes.filter(c => !cortes.includes(c.largura)).forEach(c => {
				messages.push(`A largura do corte ${c.largura} nunca foi comprada por esse cliente para o produto ${itemPedido.produto.descricao}.`);
			});
		}
		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMConfirmAdvertencia key={TZMUserData.getIdGenerator()}  header="Advertência" messages={messages} onYes={() => {this.finalizar();}}/>);
		} else {
			this.finalizar();
		}
	}

	salvarBobina = (event) => {
		if (this.validarBobina(this.state.itemPedido)) {
			this.validarCortes(this.state.itemPedido);
		}
	}

	finalizar() {
		let itemPedido = this.state.itemPedido;
		itemPedido.unidade = itemPedido.produto.unidade;
		itemPedido.bobina.alterado = this.state.alterado;
		itemPedido.bobina.quantidadeTiradas = this.calcularQuantidadeTiradas();
		if (itemPedido.bobina.cortes) {
			for (let i = 0; i < itemPedido.bobina.cortes.length; ++i) {
				let quantidadeTiradas = this.calcularTiradasGrade(itemPedido.bobina.cortes[i].grade);
				itemPedido.bobina.cortes[i].total = itemPedido.bobina.cortes[i].numero * quantidadeTiradas;
				itemPedido.bobina.cortes[i].totalM2 = itemPedido.bobina.cortes.filter(item => item.grade === itemPedido.bobina.cortes[i].grade).map(item => item.m2).reduce((a, b) => a + b, 0);
				itemPedido.bobina.cortes[i].totalM2CompradoGrade = itemPedido.bobina.m2Corte[itemPedido.bobina.cortes[i].grade];
				itemPedido.bobina.cortes[i].quantidadeTiradas = quantidadeTiradas;
			}
		}
		this.fecharPainel();
		if (this.props.onSave) {
			this.props.onSave(itemPedido);
		}
	}

	preencherNulos(itemPedido) {
		if (!itemPedido.produto) {
			itemPedido.produto = { pk: {}, descricao: " " };
		}
		if (!itemPedido.bobina) {
			itemPedido.bobina = { quantidadeGrades: 0, cortado: false, largura: 0, comprimento: 0 };
		} else {
			itemPedido.bobina.m2 = itemPedido.bobina.largura * itemPedido.bobina.comprimento / 1000;
		}
		if (!itemPedido.bobina.m2Corte) {
			itemPedido.bobina.m2Corte = [];
		}
		if (!itemPedido.bobina.cortes) {
			itemPedido.bobina.quantidadeGrades = 1;
			itemPedido.bobina.cortes = [{ grade: 0, numero: 0, largura: 0, subtotal: 0, m2: 0 }];
		} else {
			let quantidadeGrades = 0;
			itemPedido.bobina.cortes = itemPedido.bobina.cortes.sort((a, b) => a.grade - b.grade);
			itemPedido.bobina.cortes.forEach((corte) => {
				if (corte.totalM2CompradoGrade) {
					itemPedido.bobina.m2Corte[corte.grade - 1] = corte.totalM2CompradoGrade;
				}
				if (quantidadeGrades < corte.grade) {
					quantidadeGrades = corte.grade;
				}
			});
			itemPedido.bobina.cortes.forEach(c => --c.grade);
			itemPedido.bobina.quantidadeGrades = quantidadeGrades === 0 ? 1 : quantidadeGrades;
		}
		itemPedido.bobina.cortes = itemPedido.bobina.cortes.sort((a, b) => a.itemId - b.itemId);
		return itemPedido;
	}

	fecharItemPedido = () => {
		if (this.state.alterado && this.state.itemPedido.sistema === 'TZM') {
			ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} onYes={this.fecharPainel} question="Fechar e descartar as alterações feitas?" />);
		} else {
			this.fecharPainel();
		}
	}

	fecharPainel = () => {
		if (this.state.itemPedido.bobina.cortes != null) {
			this.state.itemPedido.bobina.cortes.forEach(c => ++c.grade);
		}
		this.setState({ visible: false });
	}

	handleUpdate = (key, event) => {
		let itemPedido = this.state.itemPedido;
		itemPedido[key] = event.target.value;
		this.setState({ itemPedido, alterado: true });
	}

	handleBobinaUpdate = (key, event) => {
		event.target.value = event.target.value.replace(/[^0-9.]/g, "");
		let itemPedido = this.state.itemPedido;
		itemPedido.bobina[key] = event.target.value;
		this.setState({ alterado: true, itemPedido });
		this.recalcularGrades();
	}

	deleteCorte = (event, rowData) => {
		event.preventDefault();
		event.stopPropagation();
		ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} onYes={() => {
			let itemPedido = this.state.itemPedido;
			itemPedido.bobina.cortes.splice(itemPedido.bobina.cortes.findIndex((item) => item === rowData), 1);
			this.setState({ pedido: itemPedido, alterado: true });
		}} question="Tem certeza de que deseja remover este item?" />);
	}

	calcularTiradasGrade = (index) => {
		let value = Math.ceil(this.state.itemPedido.bobina.m2Corte[index] / this.state.itemPedido.bobina.m2);
		return value ? value : 0;
	}

	buildGradeOptions = () => {
		let options = [];
		for (let i = 0; i < this.state.itemPedido.bobina.quantidadeGrades; ++i) {
			options.push({ label: `Grade ${i}`, value: i });
		}
		return options;
	}

	insertCorte = (grade) => {
		let itemPedido = this.state.itemPedido;
		if (!itemPedido.bobina.cortes) itemPedido.bobina.cortes = [];
		let subtotal = itemPedido.bobina.largura - itemPedido.bobina.cortes.filter(c => c.grade === grade).map((item) => item.aproveitamento).reduce((a, b) => a + b, 0);
		itemPedido.bobina.cortes.push({ grade, numero: 0, largura: 0, m2: 0, totalBobinas: 0, aproveitamento: 0, subtotal });
		this.setState({ itemPedido, alterado: true });
	}

	editorCorteTextField = (field, cortes, index) => {
		return (
			<TZMTextField min={0} type="number" value={cortes[index][field]} onChange={(event) => {
				let itemPedido = this.state.itemPedido;
				let corte = cortes[index];
				corte[field] = Number(event.target.value);
				corte.aproveitamento = corte.largura * corte.numero;
				corte.totalBobinas = corte.numero * this.calcularTiradasGrade(corte.grade);
				corte.m2 = corte.aproveitamento * (this.state.itemPedido.bobina.comprimento / 1000) * this.calcularTiradasGrade(corte.grade);
				cortes.forEach((corte, index) => {
					corte.subtotal = itemPedido.bobina.largura - cortes.slice(0, index + 1).map((item) => item.aproveitamento).reduce((a, b) => a + b, 0);
				});
				this.setState({ itemPedido });
			}} onKeyDown={(event) => {
				if (field === "numero" && !event.shiftKey) {
					switch (event.keyCode) {
						case 9:
							if (index === cortes.length - 1) {
								this.insertCorte(cortes[index].grade);
							}
							break;
						default:
					}
				}
			}} />
		);
	}

	recalcularGrades = () => {
		let itemPedido = this.state.itemPedido;
		itemPedido.bobina.m2 = itemPedido.bobina.comprimento * itemPedido.bobina.largura / 1000;
		itemPedido.bobina.m2Corte = itemPedido.bobina.m2Corte.slice(0, itemPedido.bobina.quantidadeGrades);
		itemPedido.bobina.cortes = itemPedido.bobina.cortes.filter(c => Number(c.grade) < Number(itemPedido.bobina.quantidadeGrades));
		itemPedido.bobina.cortes.forEach((corte) => {
			corte.aproveitamento = corte.largura * corte.numero;
			corte.totalBobinas = corte.numero * this.calcularTiradasGrade(corte.grade);
			corte.m2 = corte.aproveitamento * (this.state.itemPedido.bobina.comprimento / 1000) * this.calcularTiradasGrade(corte.grade);
			itemPedido.bobina.cortes.forEach((corte, index) => {
				corte.subtotal = itemPedido.bobina.largura - itemPedido.bobina.cortes.filter((item) => item.grade === corte.grade).slice(0, index + 1).map((item) => item.aproveitamento).reduce((a, b) => a + b, 0);
			});
		});
		this.setState({ itemPedido });
	}

	handleM2Blur = (event, i) => {
		let itemPedido = this.state.itemPedido;
		const preset = itemPedido.bobina.m2Corte[i];
		itemPedido.bobina.m2Corte[i] = Math.floor(itemPedido.bobina.m2Corte[i] / itemPedido.bobina.m2) * itemPedido.bobina.m2;
		if (preset !== itemPedido.bobina.m2Corte[i]) {
			ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()} header="Advertência" messages={[`O valor em M² foi alterado de ${preset} para ${itemPedido.bobina.m2Corte[i]} para se adequar ao tamanho da bobina`]} />);
		}
		this.setState({itemPedido});
		this.recalcularGrades();
	}

	buildGrades = () => {
		let abasGrades = [];
		for (let i = 0; i < this.state.itemPedido.bobina.quantidadeGrades; ++i) {
			let cortes = this.state.itemPedido.bobina.cortes.filter((corte) => corte.grade === i);
			abasGrades.push(
				<TZMTabPanel header={`Grade ${i + 1}`} key={`id_grade_${i}`}>
					<div className="ui-g">
						<div className="ui-g-3"><TZMTextField type="number" min={0} onBlur={(e) => this.handleM2Blur(e, i)} required label="M² comprados" value={this.state.itemPedido.bobina.m2Corte[i]} onChange={(event) => {
							let itemPedido = this.state.itemPedido;
							itemPedido.bobina.m2Corte[i] = Number(event.target.value);
							this.setState({ itemPedido });
							this.recalcularGrades();
						}} /></div>
						<div className="ui-g-6" />
						<div className="ui-g-3"><TZMTextField label="Quantidade de tiradas" disabled value={this.calcularTiradasGrade(i)} /></div>
						<div className="ui-g-12">
							<TZMTable scrollable scrollHeight="220px" value={cortes} emptyMessage="Nenhum item adicionado" footerColumnGroup={
								<ColumnGroup>
									<Row>
										<Column style={{ textAlign: "left", width: "*" }} footer={this.state.itemPedido.sistema === 'TZM' ? <Button icon="fas fa-plus" title="Adicionar novo corte" onClick={() => this.insertCorte(i)} /> : null} />
										<Column style={{ textAlign: "right", width: "*" }} footer={cortes.map((item) => item.numero).reduce((a, b) => a + b, 0)} />
										<Column style={{ textAlign: "right", width: "*" }} footer={cortes.map((item) => item.aproveitamento).reduce((a, b) => a + b, 0)} />
										<Column style={{ textAlign: "right", width: "*" }} footer={null} />
										<Column style={{ textAlign: "right", width: "*" }} footer={cortes.map((item) => item.totalBobinas).reduce((a, b) => a + b, 0)} />
										<Column style={{ textAlign: "right", width: "*" }} footer={cortes.map((item) => item.m2).reduce((a, b) => a + b, 0).toFixed(2)} />
										<Column style={{ textAlign: "right", width: "5em" }} footer={null} />
									</Row>
								</ColumnGroup> 
							}>
								<TZMColumn key="ponta" style={{ width: "3em", textAlign: "center" }} header="" body={(rowData) => {
									return <div ><i className={`fas fa-${rowData.ponta ? "tape" : "coins"}`} title={rowData.ponta ? "Ponta" : "Corte"} style={{ color: `${rowData.ponta ? "rgba(0,0,0,0.5)" : "rgba(0, 0, 0, 1)"}` }} /></div>;

								}} />
								<TZMColumn key="largura" bodyStyle={{ textAlign: "right", width: "*" }} body={(rowData, column) => this.editorCorteTextField("largura", cortes, column.rowIndex)} header="Largura da Bobina" />
								<TZMColumn key="numero" bodyStyle={{ textAlign: "right", width: "*" }} body={(rowData, column) => this.editorCorteTextField("numero", cortes, column.rowIndex)} header="Bobinas/Tirada" />
								<TZMColumn key="aproveitamento" bodyStyle={{ textAlign: "right", width: "*" }} field="aproveitamento" header="Aproveitamento" />
								<TZMColumn key="subtotal" bodyStyle={{ textAlign: "right", width: "*" }} field="subtotal" header="Restante" />
								<TZMColumn key="totalBobinas" bodyStyle={{ textAlign: "right", width: "*" }} field="totalBobinas" header="Total de Bobinas" />
								<TZMColumn key="m2" bodyStyle={{ textAlign: "right", width: "*" }} body={(rowData, column) => rowData.m2 ? rowData.m2.toFixed(2) : null} header="M² de Bobinas" />
								<TZMColumn key="acoes" render={this.state.itemPedido.sistema === 'TZM'} style={{ textAlign: "center", width: "5em" }} header="Ações" body={(rowData) => {
									if (this.state.itemPedido.sistema === 'TZM') {
										return <span className="fake-link"onClick={(event) => this.deleteCorte(event, rowData)}><TZMIcon className="ui-action-icon ui-action-icon-delete" name="trash-alt" /></span>;
									}
								}}
								/>
							</TZMTable>
						</div>
					</div>
				</TZMTabPanel>
			);
		}
		return abasGrades;
	}

	totalReservado = () => {
		return this.state.itemPedido.bobina.m2Corte.reduce((a, b) => a + b, 0);
	}

	aproveitamentoTotal = () => {
		return Number(this.state.itemPedido.bobina.cortes.map((item) => item.aproveitamento * (this.state.itemPedido.bobina.comprimento / 1000) * this.calcularTiradasGrade(item.grade)).reduce((a, b) => a + b, 0).toFixed(2));
	}

	totalRestante = () => {
		return this.totalReservado() - this.aproveitamentoTotal();
	}

	calcularQuantidadeTiradas = () => {
		return this.state.itemPedido.bobina.m2 > 0 ? this.state.itemPedido.quantidade / this.state.itemPedido.bobina.m2 : 0;
	}

	render() {
		let totalRestante = this.totalRestante();
		let totalCortado = this.totalReservado();
		let quantidadeTiradas = this.calcularQuantidadeTiradas();
		let cortes = this.state.cortes;		
		let split =  cortes && cortes.length > 0 ? cortes.join(", ") : "";	
		
		return (
			<TZMDialog style={{ width: "1000px" }} closable={false} visible={this.state.visible} modal header="Grade de Corte" {...this.props}>
				<div className="ui-g">
					<div className="ui-g-2"><TZMTextField required min={0} type="number" value={this.state.itemPedido.bobina.largura} onChange={(event) => this.handleBobinaUpdate("largura", event)} label="Largura da Jumbo" /></div>
					<div className="ui-g-2"><TZMTextField required min={0} type="number" value={this.state.itemPedido.bobina.comprimento} onChange={(event) => this.handleBobinaUpdate("comprimento", event)} label="Comprimento da Jumbo" /></div>
					<div className="ui-g-2"><TZMTextField disabled type="number" required label="Quantidade" onChange={(event) => this.handleBobinaUpdate("quantidade", event)} value={this.state.itemPedido.quantidade} /></div>
					<div className="ui-g-2"><TZMTextField disabled min={0} value={this.state.itemPedido.bobina.m2} type="number" label="M² por Jumbo" /></div>
					<div className="ui-g-2"><TZMTextField style={{ textAlign: "right" }} type="number" value={quantidadeTiradas.toFixed(2)} exclamation={Math.round(quantidadeTiradas) !== quantidadeTiradas ? "A quantidade comprada é incompatível com as medidas da bobina" : null} disabled label="Quantidade de tiradas" /></div>
					<div className="ui-g-2"><TZMTextField onChange={(event) => this.handleBobinaUpdate("quantidadeGrades", event)} value={this.state.itemPedido.bobina.quantidadeGrades} type="number" min={this.state.itemPedido.bobina.cortes && this.state.itemPedido.bobina.cortes.length > 0 ? this.state.itemPedido.bobina.cortes.map((item) => item.grade).reduce((a, b) => Math.max(a, b)) : 1} max={this.state.itemPedido.bobina.m2 > 0 ? this.state.itemPedido.quantidade / this.state.itemPedido.bobina.m2 : 0} label="Quantidade de grades" /></div>					
					<div className="ui-g-12 ui-g-nopad">
						<TZMTabView onTabChange={(event) => this.setState({ selectedGrade: event.index + 1 })}>{this.buildGrades()}</TZMTabView>
					</div>
					<div className="ui-g-3"><TZMTextField style={{ textAlign: "right", color: totalCortado !== Number(this.state.itemPedido.quantidade) ? "#F44336" : "#000000" }} exclamation={totalCortado !== Number(this.state.itemPedido.quantidade) ? "O total a ser cortado difere da quantidade comprada" : null} disabled label="Aproveitamento total" value={totalCortado} /></div>
					<div className="ui-g-3"><TZMTextField style={{ textAlign: "right" }} disabled label="Total de bobinas" value={this.state.itemPedido.bobina.cortes.map((item) => item.numero * this.calcularTiradasGrade(item.grade)).reduce((a, b) => a + b, 0)} /></div>
					<div className="ui-g-3"><TZMTextField style={{ textAlign: "right", color: totalRestante < 0 ? "#F44336" : "#000000" }} exclamation={totalRestante < 0 ? "O total a ser produzido é maior que o valor comprado" : null} disabled label="Total em m²" value={this.state.itemPedido.bobina.cortes.map((item) => item.aproveitamento * (this.state.itemPedido.bobina.comprimento / 1000) * this.calcularTiradasGrade(item.grade)).reduce((a, b) => a + b, 0).toFixed(2)} /></div>
					<div className="ui-g-3"><TZMTextField style={{ textAlign: "right", color: totalRestante < 0 ? "#F44336" : "#000000" }} exclamation={totalRestante < 0 ? "O total a ser produzido é maior que o valor comprado" : null} disabled label="Restante em m²" value={totalRestante.toFixed(2)} /></div>
					<div className="ui-g cortes-bobina">
						{cortes && cortes.length > 0 ? ` * Largura de cortes utilizado para o cliente/produto: ${split} mm.` : null } 
					</div>					
				</div>
				<TZMPanelFooter>
					{this.state.itemPedido.sistema === 'TZM' ? <TZMButton success label="Confirmar" icon="fas fa-check-circle" onClick={this.salvarBobina} />: null}					
					<TZMButton secondary label="Fechar" icon="fas fa-close" onClick={this.fecharItemPedido} />
				</TZMPanelFooter>
				<TZMShortcut
					active={this.state.visible}
					onCtrlS={this.salvarBobina}
					onEscape={this.fecharItemPedido}
					onInsert={() => this.insertCorte(this.state.selectedGrade)}
				/>
			</TZMDialog>
		);
	}

}
