import React from "react";

import "./Common.css";
import logo from "../../media/logo.png";

export default class TZMLogo extends React.Component {

	render() {
		return (
			<div className="ui-logo-back"><img className="ui-logo" src={logo} alt="" /></div>
		);
	}

}
