
import { Button } from "primereact/components/button/Button";
import React from "react";
import "./Common.css";


export default class TZMButtonDesativado extends React.Component {

    onClick = () =>{
        if (this.props.onClick) {
            this.props.onClick({name: this.props.name, value:!this.props.desativados});
        }
    }

	render() {
		const desativados = this.props.desativados;
		return (
            <Button id="desativado" {...this.props} onClick={this.onClick} style={{float: "left"}}
                className={desativados ? "ui-tzm-smallbutton ui-component ui-button-danger": "ui-component ui-tzm-smallbutton ui-button-warning"} 
                icon={desativados ? "fas fa-eye": "fas fa-low-vision"}
				title={desativados ? "Ocultar Desativados" : "Exibir Desativados"}/>
		);
	}

}
