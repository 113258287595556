import React from "react";
import CurrencyInput from 'react-currency-input';

const precision = process.env.REACT_APP_PRECISION;

export class TZMCurrency extends React.Component {

	onChangeEvent = (event, maskedValue, floatValue) => {
		this.props.onChange({name: this.props.name, value: floatValue, index: this.props.index});
	}

	render() {
		return (
			<div className={`ui-textfield-currency ui-g-${this.props.col}`}>
				{this.props.label ? <div><label className="ui-input-label">{this.props.label}</label><br/></div> : null}
				<CurrencyInput className="ui-textfield-currency ui-textfield"
							   decimalSeparator=","
							   thousandSeparator="."
							   precision={precision}
							   prefix="R$ "
							   {...this.props}
							   onChange={this.onChangeEvent}
							   onChangeEvent={this.onChangeEvent}/>
			</div>
		);
	}
}
