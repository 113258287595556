import moment from 'moment';
import { Column } from 'primereact/components/column/Column';
import { ColumnGroup } from 'primereact/components/columngroup/ColumnGroup';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Row } from 'primereact/components/row/Row';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import TZMAutoComplete from '../../../components/common/TZMAutoComplete';
import TZMButton from '../../../components/common/TZMButton';
import TZMCalendar from '../../../components/common/TZMCalendar';
import TZMCombobox from '../../../components/common/TZMCombobox';
import TZMForm from '../../../components/common/TZMForm';
import { toDecimal } from '../../../components/common/TZMFormatter';
import TZMTextField from '../../../components/common/TZMTextField';
import TZMMainContent from '../../../components/main/TZMMainContent';
import { ModalRealm } from '../../../components/modal/ModalRealm';
import TZMPanel from '../../../components/panel/TZMPanel';
import TZMPanelFooter from '../../../components/panel/TZMPanelFooter';
import TZMTabPanel from '../../../components/panel/TZMTabPanel';
import TZMTabView from '../../../components/panel/TZMTabView';
import { Fetch } from '../../../utilities/Fetch';
import Templates from '../../../utilities/Templates';
import TZMAcompanhamento from '../../../utilities/TZMAcompanhamento';
import TZMUserData from '../../../utilities/TZMUserData';
import { UF } from '../../../utilities/UF';
import UsuarioTemplate from '../../comum/usuario/UsuarioTemplate';
import { FamiliaService } from '../../servicos/FamiliaService';
import { PedidoService } from '../../servicos/PedidoService';
import { RegionalService } from '../../servicos/RegionalService';
import { UsuarioService } from '../../servicos/UsuarioService';
import { VendedorService } from '../../servicos/VendedorService';
import { VwClienteService } from '../../servicos/VwClienteService';
import { VwProdutoService } from '../../servicos/VwProdutoService';
import { PainelGerencialAAPService } from '../servicos/PainelGerencialAAPService';
import "./PainelGerencialAAP.css";
import { PainelGerencialDetalheAAP } from './PainelGerencialDetalheAAP';

const title = "Painel Gerencial";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export default class PainelGerencialAAP extends React.Component {

    constructor(props) {
        super(props);        
        moment.locale('pt-BR');
        this.state = {	
            activeIndex: 0,					
			params: this.defaultParams,
			fixedSeller: false,
			fixedGerente: false,
			fixedSupervisor: false,
			isLoading: false,
            filtrarGrupo: false,
            formatoBudget: false,
            descricaoBudget: 'm²',
			regional: {
				regional: null,
				vendedor: null, 
				gerente: null,
				supervisor: null,
            },
            acompanhamento: [],
            listMes:[],
            listDiario:[],
            listDetalhes:[],
            listGrafico:[],
            budget: 0,
            total: 0,
            percentual:0,
            expandedRowsGerente:[],
            expandedRowsRegional:[],
            expandedRowsFamilia:[],
            expandedRowsCliente:[],
            expandedRowsDiario:[],
            dates:null, 
            mes: null            
        };
        
		this.usuarioService = new UsuarioService();
		this.vwClienteService = new VwClienteService();		
		this.pedidoService = new PedidoService();
		this.vwProdutoService = new VwProdutoService();
		this.vendedorService = new VendedorService();				
        this.regionalService = new RegionalService();
        this.familiaService = new FamiliaService();

        this.painelGerencialAAPService = new PainelGerencialAAPService();
        this.rowExpansionGerente = this.rowExpansionGerente.bind(this);        
        this.rowExpansionRegional = this.rowExpansionRegional.bind(this);        
        this.rowExpansionFamilia = this.rowExpansionFamilia.bind(this);    
        this.rowExpansionCliente = this.rowExpansionCliente.bind(this);
        this.rowExpansionDiario = this.rowExpansionDiario.bind(this);
    }



    defaultParams = {
        data: this.rangeDiario(),        
		produto: { pk: {}, descricao: "" },
        cliente: { pk: {}, descricaoAmigavel: ""},
        familia: { descricao: ""},
		uf: ""
    };
    
    rangeDiario() {
		let date = new Date();
		let firstDay = moment(date).format("YYYY-MM-DD");
		return [firstDay, firstDay];
	}

    mesFormat(rowData, column) {
        return moment(rowData[column.field]).format("DD [-] dddd");
    }

    resizeGraphs = () => {
        this.setState({windowSize: window.innerHeight - 94});
    }

    componentDidMount() {
		let usuario = TZMUserData.getPrincipal();
		if (TZMUserData.isAutenticado()) {
            this.resizeGraphs();
            window.addEventListener("resize", this.resizeGraphs);            
			Fetch.Get("/ping");			
			let regional = this.state.regional;
			switch (usuario.nivel) {				
				case TZMUserData.REPRESENTANTE:
					if (usuario.vendedor) { 
						regional.vendedor = usuario.vendedor;
						this.setState({ regional, fixedSeller: true });
					}
					break;
				case TZMUserData.GERENTE:										
					regional.gerente = usuario;										
					this.setState({ regional, fixedGerente: true });
				break;
				case TZMUserData.SUPERVISOR:
					regional.supervisor = usuario;
					this.setState({ regional, fixedSupervisor: true });
				break;			
			default:
				break;
			}
		} 
	}

	listarFamilia = async (event) => {		
		await this.familiaService.autoCompletarFamiliaDummy({descricao: event.query, sort:'descricao'}).then((listFamilias) => this.setState({listFamilias}));
	}

    handleList = () => {
        let params = this.withParams();
        let descricaoBudget = this.state.descricaoBudget;
        let mes = this.state.params.data[0];

        if (this.state.formatoBudget) {
            descricaoBudget = 'R$';
        } else {
            descricaoBudget = 'm²'
        }
        
		Object.keys(params).forEach(k => params[k] != null && typeof params[k] === "string" ? params[k] = params[k].trim() : null);	
        params.mes = this.state.params.data[0];
        this.painelGerencialAAPService.listar(params).then((response) => {
            this.setState({mes, descricaoBudget: descricaoBudget,listMes: response.vendas,listDiario: response.diarios,listGrafico: response.grafico, total: response.total, budget: response.budget, percentual: response.percentual});
        });
		
    }

    detalhes = (rowData, header) => {
        ModalRealm.showDialog(<PainelGerencialDetalheAAP key={TZMUserData.getIdGenerator()} header={header} listDetalhes={rowData} />);
    }

    displayExpanderGerente = (rowData) => {
        let expandedRowsGerente = this.state.expandedRowsGerente;
        const contains = expandedRowsGerente.some(r => r === rowData); 
            return <i style={{fontSize: "14px !important", cursor: "pointer"}} className={`fa fa-${contains ? "minus" : "plus"}`} onClick={() => {
                if (contains) {
                    expandedRowsGerente = expandedRowsGerente.filter(r => r !== rowData);
                } else {
                    expandedRowsGerente.push(rowData);
                }
                this.setState({expandedRowsGerente});
            }} />          
    }

    displayExpanderRegional = (rowData) => {
        let expandedRowsRegional = this.state.expandedRowsRegional;
        const contains = expandedRowsRegional.some(r => r === rowData); 
            return <i style={{fontSize: "14px !important", cursor: "pointer"}} className={`fa fa-${contains ? "minus" : "plus"}`} onClick={() => {
                if (contains) {
                    expandedRowsRegional = expandedRowsRegional.filter(r => r !== rowData);
                } else {
                    expandedRowsRegional.push(rowData);
                }
                this.setState({expandedRowsRegional});
            }} />          
    }

    displayExpanderFamilia = (rowData) => {
        let expandedRowsFamilia = this.state.expandedRowsFamilia;
        const contains = expandedRowsFamilia.some(r => r === rowData); 
            return <i style={{fontSize: "14px !important", cursor: "pointer"}} className={`fa fa-${contains ? "minus" : "plus"}`} onClick={() => {
                if (contains) {
                    expandedRowsFamilia = expandedRowsFamilia.filter(r => r !== rowData);
                } else {
                    expandedRowsFamilia.push(rowData);
                }
                this.setState({expandedRowsFamilia});
            }} />          
    }

    displayExpanderCliente = (rowData) => {
        let expandedRowsCliente = this.state.expandedRowsCliente;
        const contains = expandedRowsCliente.some(r => r === rowData); 
            return <i style={{fontSize: "14px !important", cursor: "pointer"}} className={`fa fa-${contains ? "minus" : "plus"}`} onClick={() => {
                if (contains) {
                    expandedRowsCliente = expandedRowsCliente.filter(r => r !== rowData);
                } else {
                    expandedRowsCliente.push(rowData);
                }
                this.setState({expandedRowsCliente});
            }} />          
    }

    displayExpanderDiario = (rowData) => {
        let expandedRowsDiario = this.state.expandedRowsDiario;
        const contains = expandedRowsDiario.some(r => r === rowData); 
            return <i style={{fontSize: "14px !important", cursor: "pointer"}} className={`fa fa-${contains ? "minus" : "plus"}`} onClick={() => {
                if (contains) {
                    expandedRowsDiario = expandedRowsDiario.filter(r => r !== rowData);
                } else {
                    expandedRowsDiario.push(rowData);
                }
                this.setState({expandedRowsDiario});
            }} />          
    }

    regional = (rowData) => {
        return (
            <div style={{ fontWeight: "bold"}}>
                <i  style={{ textAlign: "left", fontSize: "15px"}} className="fa fa-globe"/> 
                {'  ' + rowData.regional.descricao}
            </div>            
        );
    }
    
    columnsRegionais = [
        <Column key="display1" style={{width: "2em"}}/>,      
        <Column key="display" style={{width: "2em", textAlign: "left"}} body={(rowData) => this.displayExpanderRegional(rowData)} />,      
        <Column key="regional"  style={{width: "26em", textAlign: "left"}}  body={(rowData) => this.regional(rowData)} />,
        <Column key="janeiro" field="janeiro"  header="Janeiro" style={{ textAlign: "center", fontWeight: "bold"}}  body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_JANEIRO, TZMAcompanhamento.NIVEL_REGIONAL)} />,
        <Column key="fevereiro"  field="fevereiro" header="Fevereiro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_FEVEREIRO, TZMAcompanhamento.NIVEL_REGIONAL)} />,
        <Column key="março" field="marco"  header="Março" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_MARCO, TZMAcompanhamento.NIVEL_REGIONAL)} />,
        <Column key="abril" field="abril" header="Abril" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_ABRIL, TZMAcompanhamento.NIVEL_REGIONAL)} />,
        <Column key="maio" field="maio" header="Maio" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_MAIO, TZMAcompanhamento.NIVEL_REGIONAL)} />,
        <Column key="junho" field="junho" header="Junho" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_JUNHO, TZMAcompanhamento.NIVEL_REGIONAL)} />,
        <Column key="julho"  field="julho" header="Julho" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_JULHO, TZMAcompanhamento.NIVEL_REGIONAL)} />,
        <Column key="agosto" field="agosto" header="Agosto" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_AGOSTO, TZMAcompanhamento.NIVEL_REGIONAL)} />,
        <Column key="setembro" field="setembro"  header="Setembro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_SETEMBRO, TZMAcompanhamento.NIVEL_REGIONAL)} />,
        <Column key="outubro" field="outubro"  header="Outubro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_OUTUBRO, TZMAcompanhamento.NIVEL_REGIONAL)} />,
        <Column key="novembro" field="novembro" header="Novembro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_NOVEMBRO, TZMAcompanhamento.NIVEL_REGIONAL)} />,
        <Column key="dezembro" field="dezembro" header="Dezembro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_DEZEMBRO, TZMAcompanhamento.NIVEL_REGIONAL)} />,
        <Column key="totanAno"  header="Total" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.totalAno))}</div>} />,  
        
        <Column key="janeiroBudget"  header="Janeiro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetJaneiro))}</div>} />,
        <Column key="fevereiroBudget"  header="Fevereiro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetFevereiro))}</div>} />,
        <Column key="marcoBudget"  header="Março" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetMarco))}</div>} />,
        <Column key="abrilBudget"  header="Abril" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetAbril))}</div>} />,
        <Column key="maioBudget"  header="Maio" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetMaio))}</div>} />,
        <Column key="junhoBudget"  header="Junho" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetJunho))}</div>} />,
        <Column key="julhoBudget"  header="Julho" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetJulho))}</div>} />,
        <Column key="agostoBudget"  header="Agosto" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetAgosto))}</div>} />,
        <Column key="setembroBudget"  header="Setembro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetSetembro))}</div>} />,
        <Column key="outubroBudget"  header="Outubro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetOutubro))}</div>} />,
        <Column key="novembroBudget"  header="Novembro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetNovembro))}</div>} />,
        <Column key="dezembroBudget"  header="Dezembro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetDezembro))}</div>} />,
        <Column key="ytdBudget"  header="YTD" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetTotalAno))}</div>} />,

    ];

   vendedor = (rowData) => {
        return (
            <div style={{ fontWeight: "bold"}}>
                <i  style={{textAlign: "left", fontSize: "15px"}} className="fa fa-user-tag"/>                 
                {'  ' + rowData.vendedorId +  (rowData.vendedorNome !== null ? ' - ' + rowData.vendedorNome : ' ')}
            </div>            
        );
    }

    columnsVendedores = [    
        <Column key="display1" style={{width: "4em"}}/>,
        <Column key="display" style={{width: "2em", textAlign: "left"}} body={(rowData) => this.displayExpanderFamilia(rowData)} />,
        <Column key="vendedor"   style={{width: "24em", textAlign: "left"}}  body={(rowData) => this.vendedor(rowData) } />,    
        <Column key="janeiro" field="janeiro"  header="Janeiro" style={{textAlign: "center", fontWeight: "bold"}}  body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_JANEIRO, TZMAcompanhamento.NIVEL_VENDEDOR)} />,
        <Column key="fevereiro"  field="fevereiro" header="Fevereiro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_FEVEREIRO, TZMAcompanhamento.NIVEL_VENDEDOR)} />,
        <Column key="março" field="marco"  header="Março" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_MARCO, TZMAcompanhamento.NIVEL_VENDEDOR)} />,
        <Column key="abril" field="abril" header="Abril" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_ABRIL, TZMAcompanhamento.NIVEL_VENDEDOR)} />,
        <Column key="maio" field="maio" header="Maio" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_MAIO, TZMAcompanhamento.NIVEL_VENDEDOR)} />,
        <Column key="junho" field="junho" header="Junho" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_JUNHO, TZMAcompanhamento.NIVEL_VENDEDOR)} />,
        <Column key="julho"  field="julho" header="Julho" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_JULHO, TZMAcompanhamento.NIVEL_VENDEDOR)} />,
        <Column key="agosto" field="agosto" header="Agosto" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_AGOSTO, TZMAcompanhamento.NIVEL_VENDEDOR)} />,
        <Column key="setembro" field="setembro"  header="Setembro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_SETEMBRO, TZMAcompanhamento.NIVEL_VENDEDOR)} />,
        <Column key="outubro" field="outubro"  header="Outubro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_OUTUBRO, TZMAcompanhamento.NIVEL_VENDEDOR)} />,
        <Column key="novembro" field="novembro" header="Novembro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_NOVEMBRO, TZMAcompanhamento.NIVEL_VENDEDOR)} />,
        <Column key="dezembro" field="dezembro" header="Dezembro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_DEZEMBRO, TZMAcompanhamento.NIVEL_VENDEDOR)} />,
        <Column key="totanAno"   style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.totalAno))}</div>} />,
        <Column key="janeiroBudget"  header="Janeiro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetJaneiro))}</div>} />,
        <Column key="fevereiroBudget"  header="Fevereiro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetFevereiro))}</div>} />,
        <Column key="marcoBudget"  header="Março" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetMarco))}</div>} />,
        <Column key="abrilBudget"  header="Abril" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetAbril))}</div>} />,
        <Column key="maioBudget"  header="Maio" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetMaio))}</div>} />,
        <Column key="junhoBudget"  header="Junho" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetJunho))}</div>} />,
        <Column key="julhoBudget"  header="Julho" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetJulho))}</div>} />,
        <Column key="agostoBudget"  header="Agosto" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetAgosto))}</div>} />,
        <Column key="setembroBudget"  header="Setembro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetSetembro))}</div>} />,
        <Column key="outubroBudget"  header="Outubro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetOutubro))}</div>} />,
        <Column key="novembroBudget"  header="Novembro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetNovembro))}</div>} />,
        <Column key="dezembroBudget"  header="Dezembro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetDezembro))}</div>} />,
        <Column key="ytdBudget"  header="YTD" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetTotalAno))}</div>} />,

    ];
    
    familia = (rowData) => {
        return (
            <div style={{ fontWeight: "bold"}}>
                <i  style={{textAlign: "left", fontSize: "15px"}} className="fa fa-boxes"/> 
                {'  ' + rowData.familia.descricao}
            </div>            
        );
    }

    columnsFamilias = [            
        <Column key="display1" style={{width: "6em"}}/>,
        <Column key="display" style={{width: "2em", textAlign: "left"}} body={(rowData) => this.displayExpanderCliente(rowData)} />,
        <Column key="familia"   style={{width: "22em", textAlign: "left"}}  body={(rowData) => this.familia(rowData)} />,    
        <Column key="janeiro" field="janeiro"  header="Janeiro" style={{textAlign: "center", fontWeight: "bold"}}  body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_JANEIRO, TZMAcompanhamento.NIVEL_FAMILIA)} />,
        <Column key="fevereiro"  field="fevereiro" header="Fevereiro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_FEVEREIRO, TZMAcompanhamento.NIVEL_FAMILIA)} />,
        <Column key="março" field="marco"  header="Março" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_MARCO, TZMAcompanhamento.NIVEL_FAMILIA)} />,
        <Column key="abril" field="abril" header="Abril" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_ABRIL, TZMAcompanhamento.NIVEL_FAMILIA)} />,
        <Column key="maio" field="maio" header="Maio" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_MAIO, TZMAcompanhamento.NIVEL_FAMILIA)} />,
        <Column key="junho" field="junho" header="Junho" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_JUNHO, TZMAcompanhamento.NIVEL_FAMILIA)} />,
        <Column key="julho"  field="julho" header="Julho" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_JULHO, TZMAcompanhamento.NIVEL_FAMILIA)} />,
        <Column key="agosto" field="agosto" header="Agosto" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_AGOSTO, TZMAcompanhamento.NIVEL_FAMILIA)} />,
        <Column key="setembro" field="setembro"  header="Setembro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_SETEMBRO, TZMAcompanhamento.NIVEL_FAMILIA)} />,
        <Column key="outubro" field="outubro"  header="Outubro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_OUTUBRO, TZMAcompanhamento.NIVEL_FAMILIA)} />,
        <Column key="novembro" field="novembro" header="Novembro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_NOVEMBRO, TZMAcompanhamento.NIVEL_FAMILIA)} />,
        <Column key="dezembro" field="dezembro" header="Dezembro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_DEZEMBRO, TZMAcompanhamento.NIVEL_FAMILIA)} />,
        <Column key="totanAno"   style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.totalAno))}</div>} />,
        <Column key="janeiroBudget"  />,
        <Column key="fevereiroBudget"  />,
        <Column key="marcoBudget"  />,
        <Column key="abrilBudget"  />,
        <Column key="maioBudget"  />,
        <Column key="junhoBudget" />,
        <Column key="julhoBudget"  />,
        <Column key="agostoBudget" />,
        <Column key="setembroBudget" />,
        <Column key="outubroBudget"  />,
        <Column key="novembroBudget" />,
        <Column key="dezembroBudget"  />,
        <Column key="ytdBudget"  />
    ];    

    cliente = (rowData) => {
        let tip = 'Código: ' + rowData.agrupadorClienteId  + ' <br/>  Razão Social: ' + rowData.agrupadorRazaoSocial + '<br/> CNPJ: ' + rowData.agrupadorCpfCnpj 
        return (
            <div>            
                <label data-tip={tip} data-for="cliente" data-multiline={true} className={`fa fa-user-tie`} /> 
                {'  ' + rowData.agrupadorNomeFantasia}
                <ReactTooltip id="cliente" place="top" type="info" effect="float" />			
            </div>
        );
    }

    columnsClientes = [    
        <Column key="display1" style={{width: "10em"}}/>,
        <Column key="nomeFantasia"   style={{width: "20em", textAlign: "left"}}  body={(rowData) => this.cliente(rowData)} />,    
        <Column key="janeiro" field="janeiro"  header="Janeiro" style={{textAlign: "center", fontWeight: "bold"}}  body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_JANEIRO, TZMAcompanhamento.NIVEL_CLIENTE)} />,
        <Column key="fevereiro"  field="fevereiro" header="Fevereiro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_FEVEREIRO, TZMAcompanhamento.NIVEL_CLIENTE)} />,
        <Column key="março" field="marco"  header="Março" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_MARCO, TZMAcompanhamento.NIVEL_CLIENTE)} />,
        <Column key="abril" field="abril" header="Abril" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_ABRIL, TZMAcompanhamento.NIVEL_CLIENTE)} />,
        <Column key="maio" field="maio" header="Maio" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_MAIO, TZMAcompanhamento.NIVEL_CLIENTE)} />,
        <Column key="junho" field="junho" header="Junho" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_JUNHO, TZMAcompanhamento.NIVEL_CLIENTE)} />,
        <Column key="julho"  field="julho" header="Julho" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_JULHO, TZMAcompanhamento.NIVEL_CLIENTE)} />,
        <Column key="agosto" field="agosto" header="Agosto" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_AGOSTO, TZMAcompanhamento.NIVEL_CLIENTE)} />,
        <Column key="setembro" field="setembro"  header="Setembro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_SETEMBRO, TZMAcompanhamento.NIVEL_CLIENTE)} />,
        <Column key="outubro" field="outubro"  header="Outubro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_OUTUBRO, TZMAcompanhamento.NIVEL_CLIENTE)} />,
        <Column key="novembro" field="novembro" header="Novembro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_NOVEMBRO, TZMAcompanhamento.NIVEL_CLIENTE)} />,
        <Column key="dezembro" field="dezembro" header="Dezembro" style={{textAlign: "center", fontWeight: "bold"}} body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_DEZEMBRO, TZMAcompanhamento.NIVEL_CLIENTE)} />,
        <Column key="totanAno"   style={{textAlign: "center", fontWeight: "bold"}} body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.totalAno))}</div>} />,
        <Column key="janeiroBudget"  />,
        <Column key="fevereiroBudget"  />,
        <Column key="marcoBudget"  />,
        <Column key="abrilBudget"  />,
        <Column key="maioBudget"  />,
        <Column key="junhoBudget" />,
        <Column key="julhoBudget"  />,
        <Column key="agostoBudget" />,
        <Column key="setembroBudget" />,
        <Column key="outubroBudget"  />,
        <Column key="novembroBudget" />,
        <Column key="dezembroBudget"  />,
        <Column key="ytdBudget"  />
    ];

    rowExpansionGerente = (data) => {
        return (
            <div className="ui-grid ui-fluid" style={{padding: '1em'}}>
                <DataTable rowClassName={() => ({"acompanhamento-nivel-2" : true})} value={data.regionais} emptyMessage="Nenhum registro encontrado" ref={(el2) => this.dt = el2} 
                          expandedRows={this.state.expandedRowsRegional} onRowToggle={(e) => this.setState({expandedRowsRegional:e.data})}
                         rowExpansionTemplate={ (rowData) => this.rowExpansionRegional(rowData)} dataKey="regional.descricao">
                    {this.columnsRegionais}
                </DataTable>
            </div>
		);
    }

    rowExpansionRegional = (data) =>{
        return (
            <div className="ui-grid ui-fluid" style={{padding: '1em'}}>
                <DataTable rowClassName={() => ({"acompanhamento-nivel-3" : true})} value={data.vendedores} emptyMessage="Nenhum registro encontrado" ref={(el1) => this.dt = el1} 
                  expandedRows={this.state.expandedRowsFamilia} onRowToggle={(e) => this.setState({expandedRowsFamilia:e.data})}
                  rowExpansionTemplate={ (rowData) => this.rowExpansionFamilia(rowData)} dataKey="vendedorId">
                    {this.columnsVendedores}
                </DataTable>
            </div>
		);
    }

    rowExpansionFamilia = (data) =>{
        return (
            <div className="ui-grid ui-fluid" style={{padding: '1em'}}>
                <DataTable rowClassName={() => ({"acompanhamento-nivel-4" : true})} value={data.familias} emptyMessage="Nenhum registro encontrado" ref={(el3) => this.dt = el3} dataKey="familia.descricao"
                            expandedRows={this.state.expandedRowsCliente} onRowToggle={(e) => this.setState({expandedRowsCliente:e.data})}
                            rowExpansionTemplate={ (rowData) => this.rowExpansionCliente(rowData)} >
                    {this.columnsFamilias}
                </DataTable>
            </div>
		);
    }

    rowExpansionCliente = (data) =>{
        return (
            <div className="ui-grid ui-fluid" style={{padding: '1em'}}>
                <DataTable rowClassName={() => ({"acompanhamento-nivel-5" : true})} value={data.clientes} emptyMessage="Nenhum registro encontrado" ref={(el4) => this.dt = el4} dataKey="nomeFantasia">
                    {this.columnsClientes}
                </DataTable>
            </div>
		);
    }

    rowExpansionDiario = (data) =>{
        return (
            <div className="ui-grid ui-fluid" style={{padding: '1em'}}>
                <DataTable  value={data.familias} emptyMessage="Nenhum registro encontrado" ref={(el5) => this.dt = el5} dataKey="familia.descricao">
                    {this.columnsDiarioFamilia}
                </DataTable>
            </div>
		);
    }

    columnsDiario = [
        <Column key="display" style={{width: "2em", textAlign: "center"}} body={(rowData) => this.displayExpanderDiario(rowData)} />,
        <Column style={{width: "8em", textAlign: "left", fontWeight: "bold"}} field="data" key="data" body={this.mesFormat} header="Dia" />,    
        <Column key="bloqueado"  header="Bloqueado" style={{width: "8em", textAlign: "center", fontWeight: "bold"}}  body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.bloqueado))}</div>} />,    
        <Column key="carteira"  header="Carteira" style={{width: "8em", textAlign: "center", fontWeight: "bold"}}  body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.carteira))}</div>} />,
        <Column key="faturado"  header="Faturado" style={{width: "8em", textAlign: "center", fontWeight: "bold"}}  body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.faturado))}</div>} />,        
        <Column key="total"  header="Total" style={{width: "8em", textAlign: "center", fontWeight: "bold"}}  body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.total))}</div>} />,
        <Column key="totalAcumulado"  header="Total Acumulado" style={{width: "8em", textAlign: "center", fontWeight: "bold"}}  body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.totalAcumulado))}</div>} />,
        <Column key="devolucao"  header="Dev." style={{width: "8em", textAlign: "center", fontWeight: "bold"}}  body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.devolucao))}</div>} />,
        <Column key="devolucaoAcumulado"  header="Dev. Acumulado" style={{width: "8em", textAlign: "center", fontWeight: "bold"}}  body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.devolucaoAcumulado))}</div>} />,
        <Column key="totalFinal"  header="Total Final" style={{width: "8em", textAlign: "center", fontWeight: "bold"}}  body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.totalFinal))}</div>} />
    ];

    gerente = (rowData) => {
        return (
            <div style={{fontWeight: "bold"}} > 
                <i  style={{fontSize: "15px"}} className="fa fa-user-alt"/> 
                {rowData.gerente !== null ? '   ' + rowData.gerente.nome : '   ' +rowData.supervisor.nome}
            </div>            
        );
    }

    headerGroup = 
    <ColumnGroup>
        <Row>
            <Column key={0} style={{width: "2em", textAlign: "center", fontWeight: "bold"}} rowSpan={2} />            
            <Column key={1} header="Gerente" style={{width: "28em", textAlign: "left",fontWeight: "bold"}}  rowSpan={2} />            
            <Column key={2} header="Fat + Carteira Mês" style={{textAlign: "center", fontWeight: "bold"}}  colSpan={13} />
            <Column key={3} header="Budget" style={{textAlign: "center", fontWeight: "bold"}} colSpan={13} />
        </Row>
        <Row>
            <Column key={4} header="Janeiro" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={5} header="Fevereiro" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={6} header="Março" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={7} header="Abril" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={8} header="Maio" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={9} header="Junho" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={10} header="Julho" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={11} header="Agosto" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={12} header="Setembro" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={13} header="Outubro" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={14} header="Novembro" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={15} header="Dezembro" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={16} header="YTD" style={{ textAlign: "center",fontWeight: "bold" }}/>

            <Column key={17} header="Janeiro" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={18} header="Fevereiro" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={19} header="Março" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={20} header="Abril" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={21} header="Maio" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={22} header="Junho" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={23} header="Julho" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={24} header="Agosto" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={25} header="Setembro" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={26} header="Outubro" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={27} header="Novembro" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={28} header="Dezembro" style={{ textAlign: "center",fontWeight: "bold" }}/>
            <Column key={29} header="YTD" style={{ textAlign: "center",fontWeight: "bold" }}/>
        </Row>
    </ColumnGroup>;

    columnsGerentes = [          
        <Column key={0} style={{width: "2em", textAlign: "center"}} body={(rowData) => this.displayExpanderGerente(rowData)} />,      
        <Column key={1}  body={(rowData) => this.gerente(rowData)}/>,
        <Column key="janeiro" field="janeiro"  body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_JANEIRO, TZMAcompanhamento.NIVEL_GERENTE)} />,
        <Column key="fevereiro"  field="fevereiro" body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_FEVEREIRO, TZMAcompanhamento.NIVEL_GERENTE)} />,
        <Column key="março" field="marco"   body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_MARCO, TZMAcompanhamento.NIVEL_GERENTE)} />,
        <Column key="abril" field="abril" body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_ABRIL, TZMAcompanhamento.NIVEL_GERENTE)} />,
        <Column key="maio" field="maio" body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_MAIO, TZMAcompanhamento.NIVEL_GERENTE)} />,
        <Column key="junho" field="junho" body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_JUNHO, TZMAcompanhamento.NIVEL_GERENTE)} />,
        <Column key="julho"  field="julho" body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_JULHO, TZMAcompanhamento.NIVEL_GERENTE)} />,
        <Column key="agosto" field="agosto" body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_AGOSTO, TZMAcompanhamento.NIVEL_GERENTE)} />,
        <Column key="setembro" field="setembro" body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_SETEMBRO, TZMAcompanhamento.NIVEL_GERENTE)} />,
        <Column key="outubro" field="outubro"  body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_OUTUBRO, TZMAcompanhamento.NIVEL_GERENTE)} />,
        <Column key="novembro" field="novembro" body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_NOVEMBRO, TZMAcompanhamento.NIVEL_GERENTE)} />,
        <Column key="dezembro" field="dezembro" body={(rowData, column) => this.toDecimalClickAnual(rowData, column,TZMAcompanhamento.MES_DEZEMBRO, TZMAcompanhamento.NIVEL_GERENTE)} />,
        <Column key="ytdm2" body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.totalAno))}</div>} />,
        <Column key="janeiroBudget" body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetJaneiro))}</div>} />,
        <Column key="fevereiroBudget"  body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetFevereiro))}</div>} />,
        <Column key="marcoBudget" body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetMarco))}</div>} />,
        <Column key="abrilBudget"  body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetAbril))}</div>} />,
        <Column key="maioBudget"  body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetMaio))}</div>} />,
        <Column key="junhoBudget" body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetJunho))}</div>} />,
        <Column key="julhoBudget" body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetJulho))}</div>} />,
        <Column key="agostoBudget"  body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetAgosto))}</div>} />,
        <Column key="setembroBudget" body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetSetembro))}</div>} />,
        <Column key="outubroBudget"  body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetOutubro))}</div>} />,
        <Column key="novembroBudget"  body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetNovembro))}</div>} />,
        <Column key="dezembroBudget"  body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetDezembro))}</div>} />,
        <Column key="ytdBudget" body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.budgetTotalAno))}</div>} />,

    ];

    columnsDiarioFamilia = [
        <Column key="display1" style={{width: "2em", textAlign: "center"}} />,
        <Column key="familia"  header="Família" style={{width: "30em", textAlign: "left", fontWeight: "bold"}}  body={(v) => v.familia.descricao } />,            
        <Column key="bloqueado"  header="Bloqueado" style={{width: "8em", textAlign: "center", fontWeight: "bold"}}  body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.bloqueado))}</div>} />,
        <Column key="carteira"  header="Carteira" style={{width: "8em", textAlign: "center", fontWeight: "bold"}}  body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.carteira))}</div>} />,
        <Column key="faturado"  header="Faturado" style={{width: "8em", textAlign: "center", fontWeight: "bold"}}  body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.faturado))}</div>} />,        
        <Column key="total"  header="Total" style={{width: "8em", textAlign: "center", fontWeight: "bold"}}  body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.total))}</div>} />,
        <Column key="space"   style={{width: "8em", textAlign: "center", fontWeight: "bold"}} />,
        <Column key="devolucao" header="Devolução"  style={{width: "8em", textAlign: "center", fontWeight: "bold"}}  body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.devolucao))}</div>} />,
        <Column key="space1"   style={{width: "8em", textAlign: "center", fontWeight: "bold"}} />,
        <Column key="totalFinal"  header="Total Final" style={{width: "8em", textAlign: "center", fontWeight: "bold"}}  body={(rowData) => <div className="acompanhamento-values">{toDecimal(Math.round(rowData.totalFinal))}</div>} />,
        
    ];

    renderSuggestion = () => {	
		let usuario = TZMUserData.getPrincipal();								
		switch (usuario.nivel) {				
            case TZMUserData.REPRESENTANTE:
                return (<div className="ui-g-12 ui-g-nopad">
                            <div className="ui-g-6 ui-fluid">
                                <TZMAutoComplete itemTemplate={Templates.vendedorTemplateOmega} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
                                    suggestions={this.state.vendedores} dropdown completeMethod={this.listarVendedores} name="vendedor" field="descricaoAmigavel" 
                                    disabled={this.state.fixedSeller} label="Vendedor" placeholder="Código Omega, E-mail ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.vendedor} />
                            </div>                        
					    </div>
				);				
			case TZMUserData.GERENTE:
				return (<div className="ui-g-12 ui-g-nopad">
						<div className="ui-g-3 ui-fluid">
							<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.regionais} dropdown completeMethod={this.listarRegionais} name="regional" field="descricao" 
								label="Regional" onUnselect={this.handleUnselect} placeholder="Descrição" value={this.state.regional.regional} />
						</div>
						<div className="ui-g-3 ui-fluid">							
							<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.gerentes} dropdown completeMethod={this.listarGerentes} name="gerente" field="descricaoAmigavel" 
								disabled={this.state.fixedGerente} label="Gerente" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.gerente} />
							
						</div>
						<div className="ui-g-3 ui-fluid">
							<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.supervisores} dropdown completeMethod={this.listarSupervisores} name="supervisor" field="descricaoAmigavel" 
								disabled={this.state.fixedSupervisor} label="Supervisor" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.supervisor} />
						</div>
                        <div className="ui-g-3 ui-fluid">
                            <TZMAutoComplete itemTemplate={Templates.vendedorTemplateOmega} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
                                suggestions={this.state.vendedores} dropdown completeMethod={this.listarVendedores} name="vendedor" field="descricaoAmigavel" 
                                disabled={this.state.fixedSeller} label="Vendedor" placeholder="Código Omega, E-mail ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.vendedor} />
                        </div>                        
					</div>
				);				
			case TZMUserData.SUPERVISOR:				
				return (
					<div className="ui-g-12 ui-g-nopad">
						<div className="ui-g-4 ui-fluid">
							<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.regionais} dropdown completeMethod={this.listarRegionais} name="regional" field="descricao" 
								label="Regional" onUnselect={this.handleUnselect} placeholder="Descrição" value={this.state.regional.regional} />
						</div>

						<div className="ui-g-4 ui-fluid">
							<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.supervisores} dropdown completeMethod={this.listarSupervisores} name="supervisor" field="descricaoAmigavel" 
								disabled={this.state.fixedSupervisor} label="Supervisor" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.supervisor} />
						</div>	
                        <div className="ui-g-4 ui-fluid">
                            <TZMAutoComplete itemTemplate={Templates.vendedorTemplateOmega} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
                                suggestions={this.state.vendedores} dropdown completeMethod={this.listarVendedores} name="vendedor" field="descricaoAmigavel" 
                                disabled={this.state.fixedSeller} label="Vendedor" placeholder="Código Omega, E-mail ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.vendedor} />
                        </div>                                                
					</div>
				);				
		default:			
			return (
				<div className="ui-g-12 ui-g-nopad">
					<div className="ui-g-3 ui-fluid">
						<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
							suggestions={this.state.regionais} dropdown completeMethod={this.listarRegionais} name="regional" field="descricao" 
							label="Regional" onUnselect={this.handleUnselect} placeholder="Descrição" value={this.state.regional.regional} />
					</div>
					<div className="ui-g-3 ui-fluid">							
						<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
							suggestions={this.state.gerentes} dropdown completeMethod={this.listarGerentes} name="gerente" field="descricaoAmigavel" 
							disabled={this.state.fixedGerente} label="Gerente" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.gerente} />
					</div>
					<div className="ui-g-3 ui-fluid">
						<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
							suggestions={this.state.supervisores} dropdown completeMethod={this.listarSupervisores} name="supervisor" field="descricaoAmigavel" 
							disabled={this.state.fixedSupervisor} label="Supervisor" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.supervisor} />
					</div>
                    <div className="ui-g-3 ui-fluid">
                            <TZMAutoComplete itemTemplate={Templates.vendedorTemplateOmega} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
                                suggestions={this.state.vendedores} dropdown completeMethod={this.listarVendedores} name="vendedor" field="descricaoAmigavel" 
                                disabled={this.state.fixedSeller} label="Vendedor" placeholder="Código Omega, E-mail ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.vendedor} />
                        </div>                                                

				</div>
			);			
		}		
    }
    
	listProdutos = (event) => {
		let params = Object.assign({});
		params.produto = event.query;
		this.vwProdutoService.autoCompletar(params).then((list) => {
			this.setState({ listProdutos: list });
		});
	}

	listarGerentes = (event) => {
		this.usuarioService.autoCompletar({usuario: event.query,regional: TZMUserData.GERENTE,sort:'nome'}).then((gerentes) => this.setState({gerentes}));
	}

	listarSupervisores = (event) => {		
		let supervisores = [];
		let usuario = TZMUserData.getPrincipal();		
		switch (usuario.nivel) {
			case TZMUserData.GERENTE:
				supervisores = usuario.regionais.filter((regional) => regional.supervisor && regional.supervisor.nome.toLowerCase().includes(event.query.toLowerCase()));
				supervisores = supervisores.map(r => r.supervisor);
				supervisores = supervisores.filter((v, i, a) => a.findIndex(w => w.id === v.id) === i);				
				this.setState({supervisores})	
			break;			
		default:			
			this.usuarioService.autoCompletar({usuario: event.query,regional: TZMUserData.SUPERVISOR, sort:'nome'}).then((supervisores) => this.setState({supervisores}));
			break;
		}
	}

	listarVendedores = async (event) => {
		let usuario = TZMUserData.getPrincipal();
		let vendedores = [];
		switch (usuario.nivel) {
			case TZMUserData.REPRESENTANTE:
					this.setState({vendedores});				
				break;
			case TZMUserData.GERENTE:
				usuario.regionais.forEach((regional) => {
					vendedores = vendedores.concat(regional.vendedores.filter((vendedor) => 
					vendedor.nome.toLowerCase().includes(event.query.toLowerCase()) ||  
					vendedor.descricaoAmigavel.toLowerCase().includes(event.query.toLowerCase())));
				});
				vendedores = vendedores.filter((v, i, a) => a.findIndex(w => w.id === v.id) === i);
				this.setState({vendedores})
			break;
			case TZMUserData.SUPERVISOR:
				if (usuario.regionais) {
					usuario.regionais.forEach((regional) => {
						vendedores = vendedores.concat(regional.vendedores.filter((vendedor) => 
						vendedor.nome.toLowerCase().includes(event.query.toLowerCase()) ||  
						vendedor.descricaoAmigavel.toLowerCase().includes(event.query.toLowerCase())));
					});
					vendedores = vendedores.filter((v, i, a) => a.findIndex(w => w.id === v.id) === i);
					this.setState({vendedores})				
				} else {
					this.setState({vendedores});
				}	
			break;			
		default:
			await this.vendedorService.autoCompletar({vendedor: event.query, sort:'nome'}).then((vendedores) => this.setState({vendedores}));
			break;
		}
	}
	
	listarRegionais = async (event) => {
		let regionais = [];
		let usuario = TZMUserData.getPrincipal();	
		switch (usuario.nivel) {
			case TZMUserData.REPRESENTANTE:
				if (usuario.vendedor) {					
				}
				break;
			case TZMUserData.GERENTE:	
				regionais = usuario.regionais.filter((regional) => regional.descricao.toLowerCase().includes(event.query.toLowerCase()));				
				this.setState({regionais})
			break;
			case TZMUserData.SUPERVISOR:
				if (usuario.regionais) {
					regionais = usuario.regionais.filter((regional) => regional.descricao.toLowerCase().includes(event.query.toLowerCase()));
					this.setState({regionais})				
				} else {
					this.setState({regionais});
				}	
			break;			
		default:
			await this.regionalService.autoCompletar({descricao: event.query, sort: "descricao"}).then((regionais) => {				
				this.setState({regionais})
			});
			break;
		}
	}

    listClientes = async (event) => {
        await this.vwClienteService.autoComplete({cliente: event.query, sort: 'razao'}).then((listClientes) => this.setState({listClientes}));
    }

	produtoTemplate = (value) => {
		return (
			<div className="ui-tzm-suggestion-line"><div className="ui-tzm-suggestion-chip">{value.pk.produtoId}</div>{value.descricao}</div>
		);
	}

	clienteTemplate(cliente) {
		return <div><i className={`fa fa-${cliente.posicao === "ATIVO" ? "check-circle" : "minus-circle"}`} /> {cliente.pk.clienteId} - {cliente.razaoSocial}</div>;
	}

	queryAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name][event.field] = event.value;
		this.setState({ params });
	}

	selectAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name] = event.value;
		this.setState({ params });
	}

	clearAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name] = { pk: {}, [event.field]: "" };
		this.setState({ params });
	}

	handleUpdateParams = (event, field, index) => {
		let params = this.state.params;
		params[field][index] = event.target.value;
		this.setState({ params });
	}

	handleClear = (event) => {
		let regional = this.state.regional;
		regional[event.name] = null;
		this.setState({regional});
	}

	handleSelect = (event) => {
		let regional = this.state.regional;
		regional[event.name] = event.value;
		this.setState({regional});
	}

	handleUnselect = (event) => {
		let regional = this.state.regional;
		if (!regional[event.name]) {
			regional[event.name] = [];
		}
		regional[event.name] = regional[event.name].filter((e) => e.id !== event.value.id);
		this.setState({regional});
    }

    handleUpdate = (key, event) => {
		this.setState({ params: { ...this.state.params, [key]: event.target.value } });
    }
    
    toggleGrupo = () => {
		this.setState({filtrarGrupo: !this.state.filtrarGrupo});
    }

    toggleBudget = () => {        
		this.setState({formatoBudget: !this.state.formatoBudget});
    }
    
    handleListDetalhes = (params) => {        
        this.painelGerencialAAPService.listarDetalhe(params).then((response) => {		
            this.detalhes(response,  null);            
        });
    }
    
	withParams = () => {
        let newParams = Object.assign({});  
        let params = Object.assign({}, this.state.params);
        newParams.ano = (moment(this.state.params.data[1]).format('YYYY'));
        let regional = this.state.regional;

        if (this.state.formatoBudget) {
            newParams.formatoBudget = 'MOEDA'  
        } else {
            newParams.formatoBudget = 'UNIDADE'
        }
     
        newParams.filtrarGrupo = this.state.filtrarGrupo ? "true" : "false";
		if (regional.vendedor && regional.vendedor.id){
			newParams.vendedor = regional.vendedor.vendedorId.vendedorId;
		}

		if (regional.regional) {
			newParams.regional = regional.regional.id; 
		}

		if (regional.gerente){
			newParams.gerente = regional.gerente.id;
		}

		if (regional.supervisor){
			newParams.supervisor = regional.supervisor.id;			
        }
        
        if (params.cliente && params.cliente.pk.clienteId) {
            newParams.cliente = params.cliente.pk;            
        }

        if (params.produto && params.produto.pk.produtoId) {
            newParams.produto = params.produto.pk;            
        }

        if (params.familia && params.familia.id) {
            newParams.familia = params.familia;            
        }
        return newParams;
	}

    toDecimalClickAnual(rowData, column, mes, nivel) {
        let params = this.withParams();
        params.mes = mes;
        switch (nivel) {
            case TZMAcompanhamento.NIVEL_GERENTE:
                if (rowData.gerente) {
                    params.gerente_detalhe = rowData.gerente;
                } else {
                    params.supervisor_detalhe = rowData.supervisor;
                }
                break;
            case TZMAcompanhamento.NIVEL_REGIONAL: 
                if (rowData.gerente) {
                    params.gerente_detalhe = rowData.gerente;
                } else {
                    params.supervisor_detalhe = rowData.supervisor;
                }   
                params.regional_detalhe = rowData.regional;            
                break;
            case TZMAcompanhamento.NIVEL_VENDEDOR:  
                if (rowData.gerente) {
                    params.gerente_detalhe = rowData.gerente;
                } else {
                    params.supervisor_detalhe = rowData.supervisor;
                }   
                params.regional_detalhe = rowData.regional;      
                params.vendedor_detalhe = rowData.vendedorId;               
                break;
            case TZMAcompanhamento.NIVEL_FAMILIA:  
                if (rowData.gerente) {
                    params.gerente_detalhe = rowData.gerente;
                } else {
                    params.supervisor_detalhe = rowData.supervisor;
                }   
                params.regional_detalhe = rowData.regional;      
                params.vendedor_detalhe = rowData.vendedorId;               
                params.familia_detalhe = rowData.familia;     
                break;              
            case TZMAcompanhamento.NIVEL_CLIENTE:
                if (rowData.gerente) {
                    params.gerente_detalhe = rowData.gerente;
                } else {
                    params.supervisor_detalhe = rowData.supervisor;
                }   
                params.regional_detalhe = rowData.regional;      
                params.vendedor_detalhe = rowData.vendedorId;               
                params.familia_detalhe = rowData.familia;                
                params.cliente_detalhe = rowData.agrupadorClienteId;
                break;
            default:
                break;
        }

        return <div className="acompanhamento-values">
            <span style={{ cursor: "pointer" }} title="Detalhar" onClick={() => this.handleListDetalhes(params)}>{toDecimal(Math.round(rowData[column.field]))}</span>
        </div>;
    }

    customTooltipLine = (params, payload, data) => {
        let descricaoBudget = this.state.descricaoBudget;        
        if (descricaoBudget === 'R$') {
            return (            
                <div  className="intro" style={{fontWeight: "bold", fontSize: "14px", textAlign: "center", color: params.color}}>                 
                    {`${params.title} ${moment(data).subtract(payload.dataKey === "faturadoAnterior" ? 1 : 0, "years").format("MMM/YY")} : ${payload.unit}   ${toDecimal(Math.round(payload.value))} `}                
                </div>
            );
        } else {
            return (            
                <div   className="intro" style={{fontWeight: "bold", fontSize: "14px", textAlign: "center", color: params.color}}>                 
                    {`${params.title} ${moment(data).subtract(payload.dataKey === "faturadoAnterior" ? 1 : 0, "years").format("MMM/YY")} :  ${toDecimal(Math.round(payload.value))}   ${payload.unit} `}                
                </div>
            );
        }
    }

    customTooltipLineProps = {
        "faturado": {color: "orange", title: "Real"},
        "faturadoAnterior": {color: "gray", title: "Real"},
        "budget": {color: "red", title: "Meta"}
    };

    customTooltip = ({ active, payload, label }) => {
        if (active && payload !== null) {
            let data = this.state.mes;
            return (            
                <div className="style1">
                    <div className="label" style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} >
                        {`Dia ${label}`}
                    </div>
                    {payload.map(i => this.customTooltipLine(this.customTooltipLineProps[i.dataKey], i, data))}               
                </div>
            );
        }
        return null;
    };

    handleRefresh = () => {

		let params = this.withParams();
		let descricaoBudget = this.state.descricaoBudget;
		let mes = this.state.params.data[0];

		if (this.state.formatoBudget) {
			descricaoBudget = 'R$';
		} else {
			descricaoBudget = 'm²'
		}
		Object.keys(params).forEach(k => params[k] != null && typeof params[k] === "string" ? params[k] = params[k].trim() : null);	
		params.mes = this.state.params.data[0];
		this.painelGerencialAAPService.atualizar().then(() => {
			this.painelGerencialAAPService.listar(params).then((response) => {
				this.setState({mes, descricaoBudget: descricaoBudget,listMes: response.vendas,listDiario: response.diarios,listGrafico: 
								response.grafico, total: response.total, budget: response.budget, percentual: response.percentual});
			});
        });
    }
		

    render() {
        return (
            <TZMMainContent>  
                <TZMPanel header={title} style={{marginTop:'2em'}} toggleable={true} collapsed={this.state.panelCollapsed} onToggle={(e) => this.setState({panelCollapsed: e.value})}>
                    <TZMForm>
                        <div className="ui-g">
                            <div className="ui-g-4 ui-g-nopad" >
                                <div className="ui-g-11">
                                    <TZMAutoComplete itemTemplate={this.clienteTemplate} onClear={this.clearAutoComplete} name="cliente"
                                        field="descricaoAmigavel" onSelect={this.selectAutoComplete} suggestions={this.state.listClientes}
                                        completeMethod={this.listClientes} placeholder="Razão social, fantasia ou CNPJ" onChange={this.queryAutoComplete}
                                        value={this.state.params.cliente.descricaoAmigavel} label="Cliente" />                                        
                                </div>
                                <div className="ui-g-1 center-button">
                                    <TZMButton id="warehouse1" title={this.state.filtrarGrupo ? "Filtrar apenas o cliente selecionado" : "Filtrar todos os clientes do mesmo Grupo Empresarial"}
                                            style={{ float: "left", color: this.state.filtrarGrupo ? '#4CAF50' : '#607D8B' }} onClick={this.toggleGrupo} secondary="true"
                                            icon={this.state.filtrarGrupo ?  "fas fa-users " : "fas fa-user-tie"} />								
                                </div>    
                            </div>
                            <div className="ui-g-1">
                                <TZMCombobox value={this.state.params.uf} label="UF" onChange={(event) => this.handleUpdate("uf", event)}>{UF.list}</TZMCombobox>
                            </div>                            
                            <div className="ui-g-1">
                                <TZMTextField disabled forac="true" value={this.state.params.produto.pk.produtoId} label="Produto" />
                            </div>
                            <div className="ui-g-3">
                                <TZMAutoComplete onClear={this.clearAutoComplete} itemTemplate={this.produtoTemplate} onSelect={this.selectAutoComplete} suggestions={this.state.listProdutos} 
                                completeMethod={this.listProdutos} name="produto" field="descricao" placeholder="Código ou descrição" 
                                onChange={this.queryAutoComplete} value={this.state.params.produto.descricao} label="&nbsp;" />
                            </div>                                
                            <div className="ui-g-3 ui-fluid">									
                                    <TZMAutoComplete  onClear={this.clearAutoComplete} onSelect={this.selectAutoComplete} onChange={this.queryAutoComplete}
                                        suggestions={this.state.listFamilias} dropdown completeMethod={this.listarFamilia} name="familia" field="descricao" 
                                        label="Família" placeholder="Descrição"  value={this.state.params.familia.descricao} />
                            </div>
                            {this.renderSuggestion()}
                        </div>                        
                        <TZMPanelFooter> 
                            {TZMUserData.isAdministrador() ? 
								<TZMButton icon="fas fa-sync" label="Atualizar" className="ui-button-secondary" style={{ float: "left"}} onClick={this.handleRefresh} title="Atualiza os dados de venda/faturamento para a data/hora atual." />
								: null} 
                            <TZMButton id="formatoBudget" label={this.state.formatoBudget ? "Budget em R$" : "Budget em M²"}
                                            style={{ float: "left"}} onClick={this.toggleBudget} warning 
                                            icon={this.state.formatoBudget ?  "fas fa-money-bill-alt" : "fas fa-tape"} 
                                            title="Selecione entre R$ ou M2 e depois clique em Procurar" />
                            <TZMButton icon="fas fa-search" label="Procurar" onClick={this.handleList} />
                        </TZMPanelFooter>
                    </TZMForm>
                </TZMPanel>                
                <TZMPanel style={{ width: this.state.activeIndex === 0 ? "150%" : "100%"}} >
                    <TZMForm>
                        <TZMTabView onTabChange={(event) => this.setState({ activeIndex: event.index })} activeIndex={this.state.activeIndex}> 
                            <TZMTabPanel  key={"anual"} header={<span><span className="ui-tab-number" children="1" />Anual</span>}>
                                <div className="ui-g">
                                    <div className="ui-g">
                                        <div className="ui-g-6">
                                            <TZMCalendar appendTo={null} label="Ano" dateFormat="yy" onChange={(event) => this.handleUpdateParams(event, "data", 1)} value={this.state.params.data[1]} />
                                        </div>
                                    </div>
                                    <div className="ui-g-5">
                                        <div className="ui-g-2" style={{ color: '#2196F3', textAlign: "center"}} >
                                            <i className="fa fa-user-alt" style={{ textAlign: "center",  fontSize: "15px"}} />
                                            <div style={{ color: '#2196F3', textAlign: "center", fontSize: "14px", fontWeight: "bold" }} >Gerente</div>
                                        </div>
                                        <div className="ui-g-2" style={{ color: '#4CAF50', textAlign: "center" }}>
                                            <i className="fa fa-globe" style={{ textAlign: "center",  fontSize: "15x"}} />
                                            <div style={{ color: '#4CAF50', textAlign: "center", fontSize: "14px", fontWeight: "bold" }} >Regional</div>                                                
                                        </div>
                                        <div className="ui-g-2" style={{ color: '#FFC107', textAlign: "center" }}>
                                            <i className="fa fa-user-tag" style={{ textAlign: "center",  fontSize: "15px"}} />
                                            <div style={{ color: '#FFC107', textAlign: "center", fontSize: "14px", fontWeight: "bold" }} >Vendedor</div>                                                
                                        </div>
                                        <div className="ui-g-2" style={{ color: '#FF5722', textAlign: "center" }}>
                                            <i className="fa fa-boxes" style={{ textAlign: "center",  fontSize: "15px"}} />
                                            <div style={{ color: '#FF5722', textAlign: "center", fontSize: "14px", fontWeight: "bold" }} >Família</div>                                                
                                        </div>
                                        <div className="ui-g-2" style={{ color: '#9E9E9E', textAlign: "center" }}>
                                            <i className="fa fa-user-tie" style={{ textAlign: "center",  fontSize: "15px"}} />
                                            <div style={{ color: '#9E9E9E', textAlign: "center", fontSize: "14px", fontWeight: "bold" }} >Cliente</div>                                                
                                        </div>
                                    </div>
                                </div>
                                <div className="ui-g">
                                    <DataTable rowClassName={() => ({"acompanhamento-nivel-1": true})} className="nested-table" emptyMessage="Nenhum registro encontrado" ref={(el) => this.dt = el} value={this.state.listMes} 
                                            expandedRows={this.state.expandedRowsGerente} onRowToggle={(e) => this.setState({expandedRowsGerente:e.data})}
                                            rowExpansionTemplate={ (rowData) => this.rowExpansionGerente(rowData) } dataKey="gerente.nome"  headerColumnGroup={this.headerGroup}                                            
                                            footerColumnGroup={
                                        <ColumnGroup>
                                            <Row>
                                                <Column  style={{ fontWeight: "bold", textAlign: "center" }} footer="" /> 
                                                <Column style={{ fontWeight: "bold", textAlign: "center" }} footer="TOTAL" />                                                            
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.janeiro).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.fevereiro).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.marco).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.abril).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.maio).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.junho).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.julho).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.agosto).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.setembro).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.outubro).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.novembro).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.dezembro).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.totalAno).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.budgetJaneiro).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.budgetFevereiro).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.budgetMarco).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.budgetAbril).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.budgetMaio).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.budgetJunho).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.budgetJulho).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.budgetAgosto).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.budgetSetembro).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.budgetOutubro).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.budgetNovembro).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.budgetDezembro).reduce((a, b) => a + b, 0)))} />
                                                <Column style={{ fontWeight: "bold", textAlign: "right"  }} footer={toDecimal(Math.round(this.state.listMes.map((item) => item.budgetTotalAno).reduce((a, b) => a + b, 0)))} />
                                            </Row>                                       
                                        </ColumnGroup> }>
                                    {this.columnsGerentes}
                                </DataTable>
                            </div>
                            
                            </TZMTabPanel> 
                            <TZMTabPanel key={"diario"} header={<span><span className="ui-tab-number" children="2" />Diário</span>}>
                                <div className="ui-g">                                    
                                    <div className="ui-g-1">
                                        <TZMCalendar appendTo={null} label="Mês" dateFormat="mm/yy" onChange={(event) => this.handleUpdateParams(event, "data", 0)} value={this.state.params.data[0]} />
                                    </div>
                                    <div className="ui-g-10">
                                        <div className="ui-datatable ui-widget" style={{float: "right"}}>
                                            <div className="ui-datatable-tablewrapper">
                                                <table>
                                                    <thead className="ui-datatable-thead">
                                                        <tr className="ui-state-default">
                                                            <th style={{ fontWeight: "bold" }} className="ui-state-default ui-unselectable-text">{this.state.descricaoBudget.toUpperCase()} Faturado</th>
                                                            <th style={{ fontWeight: "bold" }} className="ui-state-default ui-unselectable-text">{this.state.descricaoBudget.toUpperCase()} Carteira</th>
                                                            <th style={{ fontWeight: "bold" }} className="ui-state-default ui-unselectable-text">{this.state.descricaoBudget.toUpperCase()} Bloqueado</th>
                                                            <th style={{ fontWeight: "bold" }} className="ui-state-default ui-unselectable-text">{this.state.descricaoBudget.toUpperCase()} Fat + Cart + Bloq</th>
                                                            <th style={{ fontWeight: "bold" }} className="ui-state-default ui-unselectable-text">{this.state.descricaoBudget.toUpperCase()} Budget</th>
                                                            <th style={{ fontWeight: "bold" }} className="ui-state-default ui-unselectable-text">Realizado (%)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="ui-datatable-data ui-widget-content">
                                                        <tr className="ui-widget-content ui-datatable-even">
                                                            <td  style={{ textAlign: "center", fontSize: "14px", fontWeight: "bold" }} > 
                                                                {toDecimal(Math.round(this.state.listDiario.map((item) => item.faturado).reduce((a, b) => a + b, 0)))}
                                                            </td>
                                                            <td  style={{ textAlign: "center", fontSize: "14px", fontWeight: "bold" }} > 
                                                                {toDecimal(Math.round(this.state.listDiario.map((item) => item.carteira).reduce((a, b) => a + b, 0)))}
                                                            </td>
                                                            <td  style={{ textAlign: "center", fontSize: "14px", fontWeight: "bold" }} > 
                                                                {toDecimal(Math.round(this.state.listDiario.map((item) => item.bloqueado).reduce((a, b) => a + b, 0)))}
                                                            </td>
                                                            <td  style={{ textAlign: "center", fontSize: "14px", fontWeight: "bold" }} > 
                                                                {toDecimal(Math.round(this.state.listDiario.map((item) => item.total).reduce((a, b) => a + b, 0)))}
                                                            </td>
                                                            <td  style={{ textAlign: "center", fontSize: "14px", fontWeight: "bold" }} > 
                                                                {toDecimal(Math.round(this.state.budget))}
                                                            </td>
                                                            <td  style={{ textAlign: "center", fontSize: "14px", fontWeight: "bold" }} > 
                                                                {toDecimal(Math.round(this.state.percentual))}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>    
                                </div>
                                <DataTable  emptyMessage="Nenhum registro encontrado" ref={(el) => this.dt = el} value={this.state.listDiario} 
                                            expandedRows={this.state.expandedRowsDiario} onRowToggle={(e) => this.setState({expandedRowsDiario:e.data})}
                                            rowExpansionTemplate={ (rowData) => this.rowExpansionDiario(rowData) } dataKey="data"
                                            footerColumnGroup={
                                            <ColumnGroup>
                                                    <Row>   
                                                    <Column colSpan={2} style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }} footer="Total" />                                                            
                                                    <Column style={{ fontWeight: "bold", textAlign: "right", fontSize: "14px"  }} footer={toDecimal(Math.round(this.state.listDiario.map((item) => item.bloqueado).reduce((a, b) => a + b, 0)))} />
                                                    <Column style={{ fontWeight: "bold", textAlign: "right", fontSize: "14px"  }} footer={toDecimal(Math.round(this.state.listDiario.map((item) => item.carteira).reduce((a, b) => a + b, 0)))} />
                                                    <Column style={{ fontWeight: "bold", textAlign: "right", fontSize: "14px"  }} footer={toDecimal(Math.round(this.state.listDiario.map((item) => item.faturado).reduce((a, b) => a + b, 0)))} />
                                                    <Column style={{ fontWeight: "bold", textAlign: "right", fontSize: "14px"  }} footer={toDecimal(Math.round(this.state.listDiario.map((item) => item.total).reduce((a, b) => a + b, 0)))} />
                                                    <Column />  
                                                    <Column style={{ fontWeight: "bold", textAlign: "right", fontSize: "14px"  }} footer={toDecimal(Math.round(this.state.listDiario.map((item) => item.devolucao).reduce((a, b) => a + b, 0)))} />
                                                    <Column />
                                                    <Column style={{ fontWeight: "bold", textAlign: "right", fontSize: "14px"  }} footer={toDecimal(Math.round(this.state.listDiario.map((item) => item.totalFinal).reduce((a, b) => a + b, 0)))} />
                                                </Row>                                       
                                            </ColumnGroup> }>
                                    {this.columnsDiario}
                                </DataTable>
                            </TZMTabPanel>
                            <TZMTabPanel key={"grafico"} header={<span><span className="ui-tab-number" children="3" />Grafico</span>}>
                                <div className="ui-g">                                    
                                    <div className="ui-g-1">
                                        <TZMCalendar appendTo={null} label="Mês" dateFormat="mm/yy" onChange={(event) => this.handleUpdateParams(event, "data", 0)} value={this.state.params.data[0]} />
                                    </div>
                                    <div className="ui-g-11">
                                        <div className="ui-graphic-label" style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
                                            Faturamento Acumulado
                                        </div>
                                    </div>
                                </div>
                                <ResponsiveContainer height={Math.round(this.state.windowSize /1.3)} width="100%">
                                    <LineChart data={this.state.listGrafico} margin={{ top: 1, right: 1, left: 30, bottom: 5 }}>
                                        
                                        <CartesianGrid stroke="#ccc" strokeDasharray="4 4" />

                                        <Legend verticalAlign="top" align="center" height={30} iconSize={25}/>
                                        <XAxis tick={{strokeWidth: 0.9}} tickFormatter={(value) => `${value}`} dataKey="dia" />   

                                        <YAxis tickFormatter={(value) => `${toDecimal(Math.round(value))}`} tick={{strokeWidth: 0.9}} domain={[0, dataMax => (dataMax * 1.1)]}
                                                type="number" name={"Quantidade"} unit={' ' + this.state.descricaoBudget} tickCount={8}/>

                                        <Tooltip content={this.customTooltip} />                                            
                                        
                                        <Line type="linear" dataKey="faturado"  unit={this.state.descricaoBudget}  stroke="orange" strokeWidth={2}
                                                name={<span style={{ fontWeight: "bold", fontSize: "14px", verticalAlign:"middle", textAlign: "center" }}>
                                                    Real {moment(this.state.params.data[0]).format("MMM/YY")}
                                                </span>}  />

                                        <Line type="linear" dataKey="faturadoAnterior"  unit={this.state.descricaoBudget}  stroke="gray" strokeWidth={2}
                                                    name={<span style={{ fontWeight: "bold", fontSize: "14px", verticalAlign:"middle", textAlign: "center" }}>
                                                        Real {moment(this.state.params.data[0]).subtract(1, 'years').format("MMM/YY")}
                                                    </span>}  />

                                        <Line type="linear" dataKey="budget"  unit={this.state.descricaoBudget}  stroke="red" strokeWidth={2} strokeDasharray="15 15"
                                                name={<span style={{ fontWeight: "bold", fontSize: "14px", verticalAlign:"middle", textAlign: "center" }}>
                                                    Meta {moment(this.state.params.data[0]).format("MMM/YY")}
                                                </span>}  />
                                    </LineChart>                                        
                                </ResponsiveContainer>
                            </TZMTabPanel>
                        </TZMTabView>
                    </TZMForm>
                </TZMPanel>     
                <span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
                <ModalRealm /> 
            </TZMMainContent>
        )
    } 
}
