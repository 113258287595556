import React from "react";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import TZMUserData from "../../../utilities/TZMUserData";
import { FamiliaService } from "../../servicos/FamiliaService";
import { VwGrupoProdutoService } from "../../servicos/VwGrupoProdutoService";
import { EditarFamilia } from "./EditarFamilia";

const title = "Famílias";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class Familia extends React.Component {

	state = {
		desativados: false,
		grupos:[],
		familia: {			
			descricao: ""
		}
    };
	
	familiaService = new FamiliaService();
	vwGrupoProdutoService = new VwGrupoProdutoService()

	listarFamilias = () => {
		let familia = this.state.familia;
		let params = Object.assign({}, familia);	
		if (params.grupo){
			params.grupo = params.grupo.grupoId;
		}
		params.sort = "descricao";
		this.familiaService.listarSimples(params).then((familias) => this.setState({familias}));
	}

	listarGrupos = async  (event) => {		
		await this.vwGrupoProdutoService.listarSimples({descricao: event.query}).then((grupos) => this.setState({grupos}));
	}

    alternarOcultos = () => {
		this.setState({desativados: !this.state.desativados});
	}

	editarFamilia = (event) => {
		this.setState({selectedFamilia: event.data});
		if (event.data != null) {
			if (event.data.id != null) {
				this.familiaService.completar(event.data).then((familia) => {
					ModalRealm.showDialog(<EditarFamilia key={TZMUserData.getIdGenerator()} familia={familia}  onModalClose={this.listarFamilias}  />);
				});
			} else {
				ModalRealm.showDialog(<EditarFamilia key={TZMUserData.getIdGenerator()} familia={event.data} onModalClose={this.listarFamilias}  />);
			}
		}
	}

	criarFamilia = () => {
		this.setState({selectedFamilia: null});
		this.editarFamilia({data: {descricao: ""}});
	}

	excluirFamilia = (familia) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
			this.familiaService.excluir(familia).then(this.listarFamilias);
		}} question="Tem certeza de que deseja excluir está família?" />);
	}
	
	handleClear = (event) => {
		let familia = this.state.familia;
		familia[event.name] = null;
		this.setState({familia});
	}

	handleSelect = (event) => {
		let familia = this.state.familia;
		familia[event.name] = event.value;
		this.setState({familia});
	}

	handleUnselect = (event) => {
		let familia = this.state.familia;
		if (!familia[event.name]) {
			familia[event.name] = [];
		}
		familia[event.name] = familia[event.name].filter((e) => e.id !== event.value.id);
		this.setState({familia});
	}

	handleUpdate = (event) => {
		let familia = this.state.familia;
		familia[event.name] = event.target.value;
		this.setState({familia});
	}
    
    columns = [
		<TZMColumn header="Descrição" key={0} field="descricao" style={{width: "*"}} />,		
		<TZMColumn header="Ações" key={3} style={{width: "6em", textAlign: "center"}} body={(familia) => {
			return (
				<div>					
					{TZMUserData.hasRole("____FAMI_E") ? <span title="Editar Família" className="fa fa-edit ui-tzm-icon" onClick={() => this.editarFamilia({data: familia})} /> : null}
					{TZMUserData.hasRole("____FAMI_D") ? <span title="Remover Família" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirFamilia(familia)} /> : null}
				</div>
			);
		}} />
	];

	grupoTemplate(grupo) {
		return `${grupo.grupoId} - ${grupo.descricao}`;
	}

    render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">
									<div className="ui-g-6">
										<TZMTextField name="descricao" autoFocus onChange={this.handleUpdate} label="Descrição" placeholder="Descrição" value={this.state.familia.descricao} />
									</div>
									<div className="ui-g-6"> 
										<TZMAutoComplete onClear={this.handleClear}  itemTemplate={this.grupoTemplate} onSelect={this.handleSelect} onChange={this.handleSelect} 
										suggestions={this.state.grupos} completeMethod={this.listarGrupos}  placeholder="Descrição" name="grupo" 
										field="descricao" label="Grupo" value={this.state.familia.grupo} />
									</div>
								</div>															
							</TZMForm>
							<TZMPanelFooter>
								<TZMButton title={this.state.desativados ? "Ocultar Desativados" : "Exibir Desativados"} style={{float: "left"}} onClick={this.alternarOcultos} secondary icon={this.state.desativados ? "fas fa-eye": "fas fa-low-vision"} />
								{TZMUserData.hasRole("____FAMI_C") ? <TZMButton success icon="fas fa-plus" label="Nova Família" onClick={this.criarFamilia} /> : null}
								<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listarFamilias} />
							</TZMPanelFooter>
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable value={this.state.familias} paginator rows={50} children={this.columns} />
						</TZMPanel>
					</div>
				</div>
			
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}
}
