import React from "react";

export default class Templates {

	clienteTemplate(cliente) {
		return <div><i className={`fa fa-${cliente.posicao === "ATIVO" ? "check-circle" : "minus-circle"}`} /> {cliente.pk.clienteId} - {cliente.razaoSocial}</div>;
	}
	
	static vendedorTemplateOmega(vendedor){		
		if (vendedor.vendedorId) {
			return (
				<div className="ui-tzm-suggestion-line">
					<div style={{ width: "5em", textAlign: "center" }} className="ui-tzm-suggestion-chip">{vendedor.vendedorId.vendedorId}</div>
					<div>{vendedor.nome}</div>
				</div>
			);	 
		} else {
			return (
				<div className="ui-tzm-suggestion-line">					
					<div style={{ width: "5em", textAlign: "center" }} className="ui-tzm-suggestion-chip"></div>
					<div>{vendedor.nome}</div>
				</div>
			);	
		}
	}

	static vendedorTemplateOmegaPK(vendedor){
		return (
			<div className="ui-tzm-suggestion-line">
				<div style={{ width: "5em", textAlign: "center" }} className="ui-tzm-suggestion-chip">{vendedor.pk.vendedorId}</div>
				<div>{vendedor.nome}</div>
			</div>
		);
	}
	    
	static codigoOmega(vendedor){
		return vendedor.vendedorId !== null ? <div className="ui-tzm-chip-omega">{vendedor.vendedorId.vendedorId}</div> : <div className="ui-tzm-chip-omega"></div>;
	}

	static formataEmail(registro)  {
		return registro.email ? registro.email.split(";").map(e => <div key={Math.random()}>{e}</div>) : "";
	}

}