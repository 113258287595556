import React from "react";
import TZMButton from "../common/TZMButton";
import TZMTextArea from "../common/TZMTextArea";
import TZMPanelFooter from "../panel/TZMPanelFooter";
import TZMDialog from "./TZMDialog";

export class TZMJustificativa extends React.Component {

    state = {
        visible: true,
        key: Math.random(),
        justificativa: undefined
    };

    handleChange = (event) => this.setState({[event.name]: event.value});
  

    fecharPainel = () => {
		this.setState({visible: false});
	}

	onYes = () => {
		if (this.props.onYes) {
			this.props.onYes(this.state.justificativa);
		}
		this.fecharPainel();
	}

	onNo = () => {
		if (this.props.onNo) {
			this.props.onNo();
		}
		this.fecharPainel();
	}
    
	render() {
		let content =  <div className="ui-popup-messages"><div>Informe o motivo da alteração:<br /></div></div>;
		return (
			<TZMDialog key={this.state.key} header="Justificativa" modal visible={this.state.visible} {...this.props} onHide={this.onModalClose}>
                {content}
				<div className="ui-g">
                    <TZMTextArea col={12} name="justificativa" style={{width: "50em", height: "10em"}} onChange={this.handleChange} value={this.state.justificativa} />
                </div>                            
                <TZMPanelFooter>
                    <TZMButton success icon="fas fa-check" label={<span>Finalizar</span>} onClick={this.onYes} />
					<TZMButton danger icon="fas fa-close" label={<span>Fechar</span>} onClick={this.onNo} />
                </TZMPanelFooter>				
			</TZMDialog>
		);
	}
}
