import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import { ListaPrecoService } from "../../servicos/ListaPrecoService";
import { VwProdutoService } from "../../servicos/VwProdutoService";
import "./ListaPreco.css";
import { UsuarioService } from "../../servicos/UsuarioService";
import { TZMCurrency } from "../../../components/common/TZMCurrency";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMCalendar from "../../../components/common/TZMCalendar";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPopup from "../../../components/dialog/TZMPopup";
import TZMUserData from "../../../utilities/TZMUserData";

export class EditarListaPrecoItem extends React.Component {

	constructor(props) {
        super(props);        
		this.state = {
            listaPrecoItem: this.props.listaPrecoItem,
            params: {
                produto: { pk: {}, descricao: "" }
            },
            alterado: false,
			visible: true
		};
        this.listaPrecoService = new ListaPrecoService();
		this.vwProdutoService = new VwProdutoService();
		this.usuarioService = new UsuarioService();
    }

	onHide = () => this.setState({visible: false});
 
	salvar = () => {
		if (this.validar()) {
			let listaPrecoItem = Object.assign({}, this.state.listaPrecoItem);
			this.listaPrecoService.salvarListaPrecoItem(listaPrecoItem).then((item) => {
				if (this.props.onModalClose) this.props.onModalClose(item);
				this.onHide();
			});		
		}
	}

	validar = () => {
		let item = Object.assign({}, this.state.listaPrecoItem);
		let messages = [];			
		if (!item.preco || item.preco === 0) {
			messages.push("O preço do item não pode ser vazio ou igual a 0(zero).");
		}
		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()}  header="Advertência" messages={messages} />);
			return false;
		}
        return true;
        
    }

	handleChange = (event) => {
		const {listaPrecoItem} = this.state;		
		listaPrecoItem[event.name] = event.value;
		this.setState({listaPrecoItem});
	}
    
    render() {
		return (
			<TZMDialog style={{width: "1000"}} visible={this.state.visible} modal header="Item da Lista de Preço" onHide={this.onHide} {...this.props}>
				<div className="ui-g">
					<div className="ui-g-4">
						<TZMTextField  disabled value={this.state.listaPrecoItem.codigo} label="Código" />
					</div>
					<div className="ui-g-4">
						<TZMCalendar label="Vigência" disabled value={this.state.listaPrecoItem.inicio} />
					</div>
					<div className="ui-g-4">
						<TZMCurrency label="Preço" name="preco" value={this.state.listaPrecoItem.preco} onChange={this.handleChange} />
					</div>					
					<div className="ui-g-3">
						<TZMTextField  disabled value={this.state.listaPrecoItem.produto.pk.produtoId} label="Produto" />
					</div>
					<div className="ui-g-9">
						<TZMTextField  disabled value={this.state.listaPrecoItem.produto.descricao} label="Descrição" />
					</div>
				</div>
				<TZMPanelFooter>
					{!this.state.listaPrecoItem.ativado ? <TZMButton style={{float: "left"}} title="Reativar Item" icon="fas fa-eye" onClick={this.reativar} /> : null}					
					{this.state.listaPrecoItem.ativado ?  <TZMButton className="ui-button-danger" style={{float: "left"}} title="Excluir Item" icon="fas fa-trash-alt" onClick={this.confirmarExcluir} /> : null}					
					{this.state.listaPrecoItem.ativado ?  <TZMButton className="ui-button-success" label="Salvar" icon="fas fa-save" onClick={this.salvar}/> : null}
					<TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-times" onClick={this.onHide} />
				</TZMPanelFooter>				
			</TZMDialog>
		);
	}

}
