import "primeicons/primeicons.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, withRouter } from "react-router-dom";
import { Application } from "./Application";
import "./index.css";
import {unregister} from "./serviceworker";
import "./utilities/Extensoes";

const CCRRWeb = withRouter(({history}) => <Application history={history} />);

ReactDOM.render(<BrowserRouter><CCRRWeb /></BrowserRouter>, document.getElementById("root"));
unregister();
