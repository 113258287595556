import React from "react";
import moment from "moment";
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMMainContent from "../../../components/main/TZMMainContent";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import {Column} from "primereact/components/column/Column";
import {DescontoService} from "../../servicos/DescontoService";
import {toCurrency, toDecimalFixed} from "../../../components/common/TZMFormatter";
import TZMUserData from "../../../utilities/TZMUserData";
import EditarDesconto from "./EditarDesconto";
import TZMCalendar from "../../../components/common/TZMCalendar";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMCombobox from "../../../components/common/TZMCombobox";
import TZMTextField from "../../../components/common/TZMTextField";
import Templates from "../../../utilities/Templates";
import {UF} from "../../../utilities/UF";
import UsuarioTemplate from "../usuario/UsuarioTemplate";
import {Fetch} from "../../../utilities/Fetch";
import {UsuarioService} from "../../servicos/UsuarioService";
import {VwClienteService} from "../../servicos/VwClienteService";
import {VwProdutoService} from "../../servicos/VwProdutoService";
import {VendedorService} from "../../servicos/VendedorService";
import {RegionalService} from "../../servicos/RegionalService";
import {FamiliaService} from "../../servicos/FamiliaService";
import {ClienteUtils} from "../cliente/ClienteUtils";
import EditarPedido from "../../aap/pedido/EditarPedido";
import {PedidoService} from "../../servicos/PedidoService";
import {Panel} from "primereact/components/panel/Panel";
import {DescontoUtils} from "./DescontoUtils";
import { TZMCurrency } from "../../../components/common/TZMCurrency";
import { Checkbox } from "primereact/components/checkbox/Checkbox";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import { DataTable } from "primereact/components/datatable/DataTable";

const title = "Gestão de Preços";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class Desconto extends React.Component {

	constructor() {
		super();
		moment.locale('pt-BR');		
		this.state = {				
			descontos: [],			
			params: this.defaultParams,			
			fixedSeller: false,
			fixedGerente: false,
			fixedSupervisor: false,			
			filtrarGrupo: false,
			regional: {regional: null,vendedor: null, gerente: null,supervisor: null,familia: null},
			totalLista : 0,
			total : 0,
			variacaoPerc : 0,
			variacao : 0,
			isChecked: [],
			mainCheckbox: false,
			desconto: null,
			visible: true,
			alterado: false
		};
		this.descontoService = new DescontoService();
		this.usuarioService = new UsuarioService();
		this.vwClienteService = new VwClienteService();
		this.vwProdutoService = new VwProdutoService();
		this.vendedorService = new VendedorService();		
		this.regionalService = new RegionalService();
		this.familiaService = new FamiliaService();
		this.pedidoService = new PedidoService();		
	}
	
	defaultParams = {
		pedido: "",
		dataIni: moment(new Date()).startOf('month').format("YYYY-MM-DD"),
		dataFim: moment(new Date()).endOf('month').format("YYYY-MM-DD"),
		produto: { pk: {}, descricao: "" },
		cliente: { pk: {}, descricaoAmigavel: "" },
		uf: "",
		material: null,
		status: "EM_ANDAMENTO"

	};

	componentDidMount() {
		let usuario = TZMUserData.getPrincipal();
		if (TZMUserData.isAutenticado()) {
			Fetch.Get("/ping");			
			let regional = this.state.regional;
			switch (usuario.nivel) {
				case TZMUserData.REPRESENTANTE:
					if (usuario.vendedor) {
						regional.vendedor = usuario.vendedor;
						this.setState({regional, fixedSeller: true});
					}
					break;
				case TZMUserData.GERENTE:										
					regional.gerente = usuario;										
					this.setState({regional, fixedGerente: true});
				break;
				case TZMUserData.SUPERVISOR:
					regional.supervisor = usuario;
					this.setState({regional, fixedSupervisor: true});
				break;			
			default:
				break;
			}	
		}
		const items = Object.assign({}, ...this.props.location.search.substring(1).split("&").map(kv => {
			const values = kv.split("=");
			return {[values[0]]: values[1]};
		}));
		if (items.pedido) {
			this.setState({pedido: items.pedido}, this.listar);
		}
	}

	listar = () => {		
		let params = Object.assign({}, this.state.params);
		let regional = this.state.regional;
		let totalLista = 0;
		let total = 0;
		let variacaoPerc = 0;
		let variacao = 0;
		let clearCheckbox = [];
		Object.keys(params).forEach(k => params[k] != null && typeof params[k] === "string" ? params[k] = params[k].trim() : null);		
		params.filtrarGrupo = this.state.filtrarGrupo ? "true" : "false";		
		if (params.produto) {
			if (params.produto.pk.produtoId) {
				params.produto = params.produto.pk;
			} else {
				params.produto = null;
			}
		}
		if (params.cliente) {
			if (params.cliente.pk.clienteId) {
				params.cliente = params.cliente.pk;
			} else {
				params.cliente = null;
			}
		}
		if (regional.familia) {
			params.familia = regional.familia.id;
		}
		if (regional.vendedor && regional.vendedor.id){
			params.vendedor = regional.vendedor.vendedorId.vendedorId;
		}
		if (regional.regional) {
			params.regional = regional.regional.id; 
		}
		if (regional.gerente) {
			params.gerente = regional.gerente.id;
		}
		if (regional.supervisor) {
			params.supervisor = regional.supervisor.id;			
		}
		
		this.descontoService.listarSimples(params).then((descontos) => { 
			if (descontos && descontos.length > 0 ) {
				totalLista = descontos.map((p) => p.valorUnitarioTabela * p.quantidade).reduce((a, b) => a + b, 0);
				total = descontos.map((p) => p.valorTotalItem).reduce((a, b) => a + b, 0);
				variacao = descontos.map((p) => p.valorDesconto).reduce((a, b) => a + b, 0) * -1;
				variacaoPerc = variacao / totalLista * 1000 / 10;				
			} 
			
			this.setState({descontos, totalLista, total, variacao, variacaoPerc,isChecked: clearCheckbox,mainCheckbox: false })

		});	
	}

	listProdutos = (event) => {
		let params = Object.assign({});
		params.produto = event.query;
		this.vwProdutoService.autoCompletar(params).then((list) => {
			this.setState({ listProdutos: list });
		});
	}	

	listarGerentes = (event) => {
		this.usuarioService.autoCompletar({usuario: event.query,regional: TZMUserData.GERENTE,sort:'nome'}).then((gerentes) => this.setState({gerentes}));
	}

	listarSupervisores = (event) => {		
		let supervisores = [];
		let usuario = TZMUserData.getPrincipal();		
		switch (usuario.nivel) {
			case TZMUserData.GERENTE:
				supervisores = usuario.regionais.filter((regional) => regional.supervisor && regional.supervisor.nome.toLowerCase().includes(event.query.toLowerCase()));
				supervisores = supervisores.map(r => r.supervisor);
				supervisores = supervisores.filter((v, i, a) => a.findIndex(w => w.id === v.id) === i);				
				this.setState({supervisores})	
			break;			
		default:			
			this.usuarioService.autoCompletar({usuario: event.query,regional: TZMUserData.SUPERVISOR, sort:'nome'}).then((supervisores) => this.setState({supervisores}));
			break;
		}
	}

	listarVendedores = async (event) => {
		let usuario = TZMUserData.getPrincipal();
		let vendedores = [];
		switch (usuario.nivel) {
			case TZMUserData.REPRESENTANTE:
					this.setState({vendedores});				
				break;
			case TZMUserData.GERENTE:
				usuario.regionais.forEach((regional) => {
					vendedores = vendedores.concat(regional.vendedores.filter((vendedor) => 
					vendedor.nome.toLowerCase().includes(event.query.toLowerCase()) ||  
					vendedor.descricaoAmigavel.toLowerCase().includes(event.query.toLowerCase())));
				});
				vendedores = vendedores.filter((v, i, a) => a.findIndex(w => w.id === v.id) === i);
				this.setState({vendedores})
			break;
			case TZMUserData.SUPERVISOR:
				if (usuario.regionais) {
					usuario.regionais.forEach((regional) => {
						vendedores = vendedores.concat(regional.vendedores.filter((vendedor) => 
						vendedor.nome.toLowerCase().includes(event.query.toLowerCase()) ||  
						vendedor.descricaoAmigavel.toLowerCase().includes(event.query.toLowerCase())));
					});
					vendedores = vendedores.filter((v, i, a) => a.findIndex(w => w.id === v.id) === i);
					this.setState({vendedores})				
				} else {
					this.setState({vendedores});
				}	
			break;			
		default:
			await this.vendedorService.autoCompletar({vendedor: event.query, sort:'nome'}).then((vendedores) => this.setState({vendedores}));
			break;
		}
	}
	
	listarRegionais = async (event) => {
		let regionais = [];
		let usuario = TZMUserData.getPrincipal();	
		switch (usuario.nivel) {
			case TZMUserData.REPRESENTANTE:
				if (usuario.vendedor) {}
				break;
			case TZMUserData.GERENTE:	
				regionais = usuario.regionais.filter((regional) => regional.descricao.toLowerCase().includes(event.query.toLowerCase()));				
				this.setState({regionais})
			break;
			case TZMUserData.SUPERVISOR:
				if (usuario.regionais) {
					regionais = usuario.regionais.filter((regional) => regional.descricao.toLowerCase().includes(event.query.toLowerCase()));
					this.setState({regionais})				
				} else {
					this.setState({regionais});
				}	
			break;			
		default:
			await this.regionalService.autoCompletar({descricao: event.query, sort: "descricao"}).then((regionais) =>{				
				this.setState({regionais})
			});
			break;
		}
	}

    listClientes = async (event) => {await this.vwClienteService.autoComplete({cliente: event.query, sort: 'razao'}).then((listClientes) => this.setState({listClientes}));}

	produtoTemplate = (value) => {
		return (<div className="ui-tzm-suggestion-line"><div className="ui-tzm-suggestion-chip">{value.pk.produtoId}</div>{value.descricao}</div>);
	}

	clienteTemplate(cliente) {
		return <div><i className={`fa fa-${cliente.posicao === "ATIVO" ? "check-circle" : "minus-circle"}`} /> {cliente.pk.clienteId} - {cliente.razaoSocial}</div>;
	}

	selectAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name] = event.value;
		this.setState({ params });
	}

    handleUpdate = (key, event) => {
		let params = this.state.params;
		params[key] = event.target.value;
		this.setState({params});
    }

	queryAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name][event.field] = event.value;
		this.setState({ params });
	}

	clearAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name] = { pk: {}, [event.field]: "" };
		this.setState({ params });
	}

	handleClear = (event) => {
		let regional = this.state.regional;
		regional[event.name] = null;
		this.setState({regional});
	}

	handleSelect = (event) => {
		let regional = this.state.regional;
		regional[event.name] = event.value;
		this.setState({regional});
	}

	handleUnselect = (event) => {
		let regional = this.state.regional;
		if (!regional[event.name]) {
			regional[event.name] = [];
		}
		regional[event.name] = regional[event.name].filter((e) => e.id !== event.value.id);
		this.setState({regional});
	}

	handleCombobox = (event) => {
		let params = this.state.params;
		params[event.name] = event.target.value;
		this.setState({ params });
	}
	
	checkNullArray(value) {
		return value ? value : [];
	}

	toggleGrupo = () => {
		this.setState({filtrarGrupo: !this.state.filtrarGrupo});
    }

	renderSuggestion = () => {	
		let usuario = TZMUserData.getPrincipal();								
		switch (usuario.nivel) {			
			case TZMUserData.REPRESENTANTE:
				break;
			case TZMUserData.GERENTE:
				return (<div className="ui-g-12 ui-g-nopad">
						<div className="ui-g-4 ui-fluid">
							<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.regionais} dropdown completeMethod={this.listarRegionais} name="regional" field="descricao" 
								label="Regional" onUnselect={this.handleUnselect} placeholder="Descrição" value={this.state.regional.regional} />
						</div>
						<div className="ui-g-4 ui-fluid">							
							<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.gerentes} dropdown completeMethod={this.listarGerentes} name="gerente" field="descricaoAmigavel" 
								disabled={this.state.fixedGerente} label="Gerente" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.gerente} />
							
						</div>
						<div className="ui-g-4 ui-fluid">
							<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.supervisores} dropdown completeMethod={this.listarSupervisores} name="supervisor" field="descricaoAmigavel" 
								disabled={this.state.fixedSupervisor} label="Supervisor" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.supervisor} />
						</div>
					</div>
				);				
			case TZMUserData.SUPERVISOR:				
				return (
					<div className="ui-g-12 ui-g-nopad">
						<div className="ui-g-6 ui-fluid">
							<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.regionais} dropdown completeMethod={this.listarRegionais} name="regional" field="descricao" 
								label="Regional" onUnselect={this.handleUnselect} placeholder="Descrição" value={this.state.regional.regional} />
						</div>

						<div className="ui-g-6 ui-fluid">
							<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
								suggestions={this.state.supervisores} dropdown completeMethod={this.listarSupervisores} name="supervisor" field="descricaoAmigavel" 
								disabled={this.state.fixedSupervisor} label="Supervisor" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.supervisor} />
						</div>	
					</div>
				);				
		default:			
			return (
				<div className="ui-g-12 ui-g-nopad">
					<div className="ui-g-4 ui-fluid">
						<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
							suggestions={this.state.regionais} dropdown completeMethod={this.listarRegionais} name="regional" field="descricao" 
							label="Regional" onUnselect={this.handleUnselect} placeholder="Descrição" value={this.state.regional.regional} />
					</div>
					<div className="ui-g-4 ui-fluid">							
						<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
							suggestions={this.state.gerentes} dropdown completeMethod={this.listarGerentes} name="gerente" field="descricaoAmigavel" 
							disabled={this.state.fixedGerente} label="Gerente" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.gerente} />
					</div>
					<div className="ui-g-4 ui-fluid">
						<TZMAutoComplete itemTemplate={UsuarioTemplate.usuarioTemplate} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
							suggestions={this.state.supervisores} dropdown completeMethod={this.listarSupervisores} name="supervisor" field="descricaoAmigavel" 
							disabled={this.state.fixedSupervisor} label="Supervisor" placeholder="E-mail, Login ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.supervisor} />
					</div>
				</div>
			);			
		}		
	}

	listarFamilia = async (event) => {		
		await this.familiaService.autoCompletarFamiliaDummy({descricao: event.query, sort:'descricao'}).then((listFamilias) => this.setState({listFamilias}));
	}
	
	editar = (event) => {
		this.setState({selectedDesconto: event.data});
		if (event.data != null) {
			if (event.data.id != null) {
				this.descontoService.completar(event.data).then((desconto) => {
					ModalRealm.showDialog(<EditarDesconto key={TZMUserData.getIdGenerator()} desconto={desconto}  onModalClose={this.listar}  />);
				});
			} else {
				ModalRealm.showDialog(<EditarDesconto key={TZMUserData.getIdGenerator()} desconto={event.data} onModalClose={this.listar}  />);
			}
		}
	}

	
	valorUnitario = (rowData, props) => {
		if (rowData.status === DescontoUtils.statusAprovadoAdicionarTabela()) {
			return (
				<div className="campo-valunit">	
					<TZMCurrency value={rowData.valorUnitario} disabled />
				</div>
			);
		}
		if (rowData.valorUnitarioTabela === 0) {
			return (
				<div className="campo-valunit">	
					<TZMCurrency value={rowData.valorUnitario} disabled />	
					<i className="fas fa-exclamation-triangle" style={{color: '#ffae00'}} title="Sem lista de preço definida."/> 					
				</div>
			);
		} else if (rowData.percentual > 0) {
			return (
				<div className="campo-valunit">	
					<TZMCurrency value={rowData.valorUnitario} disabled />	
					<i className="fa fa-arrow-circle-down" style={{color: '#FF0000'}} title="Venda realizada abaixo do valor da lista de preço."/> 					
				</div>
			);
		} else if (rowData.percentual < 0)  {
			return (
				<div  className="campo-valunit">
					<i className="fa fa-arrow-circle-up" style={{color: '#008000'}} title="Venda realizada acima do valor da lista de preço."  />
					<TZMCurrency value={rowData.valorUnitario} disabled />
				</div>
			);
		}
	}

	variacao = (rowData, props) => {
		if (rowData.status === DescontoUtils.statusAprovadoAdicionarTabela()) {
			return (<div></div>);
		}
		if (rowData.valorUnitarioTabela === 0) {
			return (<div></div>);
		} else if (rowData.percentual > 0) {
			return (
				<div style={{color: '#FF0000'}} >
					-{`${(toDecimalFixed(rowData.percentual  ,2)) }%   ( -${(rowData.valorDesconto).toCurrency()})`}
					{/* <div>
						-{`${(toDecimalFixed(rowData.percentual  ,2)) }%`}
					</div>
					<div>
						{`( -${(rowData.valorDesconto).toCurrency()})`}
					</div> */}
				</div>
			);
		} else if (rowData.percentual < 0)  {
			let percentual = rowData.percentual * (-1);	
			let valorDesconto = rowData.valorDesconto * (-1);
			return (
				<div style={{color: '#008000'}} >
					+{`${(toDecimalFixed(percentual ,2)) } %  ( +${(valorDesconto).toCurrency()})`}
					{/* <div>
						+{`${(toDecimalFixed(percentual ,2)) } %`}
					</div>
					<div>
						+{`( +${(valorDesconto).toCurrency()})`}
					</div> */}
				</div>
			);
		} 
	}	
	
	toggleSelecionado = (cte) => {
		const {aprovar} = this.state;
		aprovar.filter(c => c.id === cte.id).forEach(c => c._selecionado = !c._selecionado);
		this.setState({aprovar});
	}
	
	toggleAllSelecionados = () => {
		const {aprovar} = this.state;
		const _selecionado = aprovar.every(c => c._selecionado);
		aprovar.forEach(c => c._selecionado = !_selecionado);
		this.setState({aprovar});
	}

	modificarDesconto =  (acao) => {		
		const usuario = TZMUserData.getUsuario();				
		const checados = this.state.isChecked;
		switch (acao) {
			case 'R':
				ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} 
					question="Tem certeza de que deseja REPROVAR às solicitações selecionadas?" 
					onYes={ () => {
						 this.descontoService.desenvolverLista(checados, usuario, "REPROVADO").then(this.listar);						
					}}/>);
				break;			
			case 'L':	
				ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} 
					question="Tem certeza de que deseja APROVAR e TRANSFORMAR em lista de preço às solicitações selecionadas?" 
					onYes={ () => {
						  this.descontoService.desenvolverLista(checados, usuario, "APROVADO_ATUALIZAR_TABELA").then(this.listar);						
					}}/>);
				break;
			default:
				ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} 
				question="Tem certeza de que deseja APROVAR às solicitações selecionadas?"
				onYes={  () => {
					 this.descontoService.desenvolverLista(checados, usuario, "APROVADO").then(this.listar);					
				}}/>);
				break;
		}
		
	}

	toggleChecked = (descontoId) => {
		let checked = this.state.isChecked
		let removeDescontoId = [];
		if(checked.includes(descontoId)){
			removeDescontoId = checked.filter((el) => el !== descontoId)
			this.setState({isChecked: removeDescontoId})
		}else {
			checked.push(descontoId)
			this.setState({isChecked: checked})
		}
	};

	isChecked = (descontoId) => {
		const checked = this.state.isChecked
		return checked.includes(descontoId)
	}

	toggleMainChecked = () => {
		let mainChecked = this.state?.mainCheckbox;
		let descontos = this.state.descontos;		
		let emAndamento = [];
		let ids = [];
		mainChecked = !mainChecked
		if(mainChecked){
			descontos.forEach((el) => {
				if(el.status === 'EM_ANDAMENTO') { 
					emAndamento.push(el)
				}
			})
			emAndamento.forEach(el => ids.push(el.descontoId))
		}
		this.setState({isChecked: ids,mainCheckbox: mainChecked })
	};

	handleCheckbox = (data) => {		
		if (data.status === 'EM_ANDAMENTO') {
			return <Checkbox  checked={this.isChecked(data.descontoId)} onChange={() => this.toggleChecked(data.descontoId)}/>;
		}
	}
	
	handleMainCheckbox = () => {
		return <Checkbox checked={this.state?.mainCheckbox} disabled={!this.state.descontos[0]} onChange={() => this.toggleMainChecked()}/>		
	}

	visualizarPedido = (pedidoID) => {
		this.pedidoService.buscar(pedidoID).then((pedido) => {
			ModalRealm.showDialog(<EditarPedido key={TZMUserData.getIdGenerator()} pedido={pedido} />);
		});
	}
	
	optionsStatus = [
		<option key={0} value={null}></option>,
		<option key={1} value="EM_ANDAMENTO">Em Andamento</option>,
		<option key={2} value="APROVADO_TODOS">Aprovado TODOS</option>,
		<option key={3} value="APROVADO">Aprovado</option>,
		<option key={4} value="APROVADO_ATUALIZAR_TABELA">Aprovado Lista Atualizada</option>,
		<option key={5} value="APROVADO_ADICIONAR_TABELA">Aprovado Item Adicionado na Lista</option>,
		<option key={6} value="REPROVADO">Reprovado</option>
	];	

	render() {
		let representante = this.state.fixedSeller;
		let notFill = !this.state.isChecked[0];		
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-11">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">
									<div className="ui-g-2">
										<TZMCalendar label="Período de Solicitação" placeholder="Inicial" onChange={(event) => this.handleUpdate("dataIni", event)} value={this.state.params.dataIni} />
									</div>
									<div className="ui-g-2">
										<TZMCalendar label="&nbsp;" placeholder="Final" onChange={(event) => this.handleUpdate("dataFim", event)} value={this.state.params.dataFim} />
									</div>
									<div className="ui-g-1">
										<TZMTextField type="number" autoFocus placeholder="Número" label="Pedido" onChange={(event) => this.handleUpdate("pedido", event)} value={this.state.params.pedido} />
									</div>
									<div className="ui-g-6 ui-g-nopad" >
										<div className="ui-g-11">
											<TZMAutoComplete itemTemplate={this.clienteTemplate} onClear={this.clearAutoComplete} name="cliente"
												field="descricaoAmigavel" onSelect={this.selectAutoComplete} suggestions={this.state.listClientes}
												completeMethod={this.listClientes} placeholder="Razão social, fantasia ou CNPJ" onChange={this.queryAutoComplete}
												value={this.state.params.cliente.descricaoAmigavel} label="Cliente" />                                        
										</div>
										<div className="ui-g-1 center-button">
											<TZMButton id="warehouse1" title={this.state.filtrarGrupo ? "Filtrar apenas o cliente selecionado" : "Filtrar todos os clientes do mesmo Grupo Empresarial"}
													style={{ float: "left", color: this.state.filtrarGrupo ? '#4CAF50' : '#607D8B' }} onClick={this.toggleGrupo} secondary="true"
													icon={this.state.filtrarGrupo ?  "fas fa-users " : "fas fa-user-tie"} />								
										</div>    
									</div>
									<div className="ui-g-1">
										<TZMCombobox value={this.state.params.uf} label="UF" onChange={(event) => this.handleUpdate("uf", event)}>{UF.list}</TZMCombobox>
									</div>
									<div className="ui-g-4">
										<span className="ui-fluid ui-g-nopad">
											<TZMAutoComplete itemTemplate={Templates.vendedorTemplateOmega} onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
												suggestions={this.state.vendedores} dropdown completeMethod={this.listarVendedores} name="vendedor" field="descricaoAmigavel" 
												disabled={this.state.fixedSeller} label="Vendedor" placeholder="Código Omega, E-mail ou Nome" onUnselect={this.handleUnselect} value={this.state.regional.vendedor} />
										</span>	
									</div>
									<div className="ui-g-2">
										<TZMTextField disabled forac="true" value={this.state.params.produto.pk.produtoId} label="Produto" />
									</div>
									<div className="ui-g-5">
										<TZMAutoComplete onClear={this.clearAutoComplete} itemTemplate={this.produtoTemplate} 
												onSelect={this.selectAutoComplete} suggestions={this.state.listProdutos} 
												completeMethod={this.listProdutos} name="produto" field="descricao" 
												placeholder="Código ou descrição" onChange={this.queryAutoComplete} 
												value={this.state.params.produto.descricao} label="&nbsp;" />
									</div>
									<div className="ui-g-1">
										<TZMCombobox value={this.state.params.status} label="Status" onChange={(event) => this.handleUpdate("status", event)}>
											{this.optionsStatus}
										</TZMCombobox>
									</div>
									{this.renderSuggestion()}
									<div className="ui-g-4 ui-fluid">									
										<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
											suggestions={this.state.listFamilias} dropdown completeMethod={this.listarFamilia} name="familia" field="descricao" 
											label="Família" placeholder="Descrição" onUnselect={this.handleUnselect} value={this.state.regional.familia} />
									</div>
								</div>
								<TZMPanelFooter>
									{ !representante ? <TZMButton  style={{ float: "left" }} title="Aprovar" disabled={notFill}  className="ui-button-success"  icon="fa fa-check" onClick={() => this.modificarDesconto(`A`)} />  : null }
									{ !representante ? <TZMButton  style={{ float: "left" }} title="Aprovar e Atualizar Lista de Preço" disabled={notFill}  className="ui-button-primary"  icon="fa fa-check-double" onClick={() => this.modificarDesconto(`L`)}/> : null }
									{ !representante ? <TZMButton  style={{ float: "left" }} title="Reprovar" disabled={notFill}  className="ui-button-danger"  icon="fa fa-exclamation" onClick={() => this.modificarDesconto(`R`)}	/> : null }										<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listar} />
								</TZMPanelFooter>
							</TZMForm>
						</TZMPanel>
						</div>
						<div className="ui-g-1">
							<Panel header="Legenda">
								<div className="ui-g">
									<div style={{ color: '#4cae4c', textAlign: "center" }} className="ui-g-2"><i className="fa fa-check"/></div><div className="ui-g-10">Aprovado</div>
									<div style={{ color: '#2196F3', textAlign: "center" }} className="ui-g-2"><i className="fa fa-check-double"/></div><div className="ui-g-10">Lista Atualizada</div>
									<div style={{ color: '#274157', textAlign: "center" }} className="ui-g-2"><i className="fa fa-clipboard-check"/></div><div className="ui-g-10">Item Adicionado</div>
									<div style={{ color: '#d43f3a', textAlign: "center" }} className="ui-g-2"><i className="fa fa-exclamation" /></div><div className="ui-g-10">Reprovado</div>
									<div style={{ color: '#949494', textAlign: "center" }} className="ui-g-2"><i className="fa fa-clock" /></div><div className="ui-g-10">Em Andamento</div>
								</div>
							</Panel>
						</div>
						<div className="ui-g">
							<div style={{height: "8px"}} />
							<TZMPanel header={<div>
								<div className="ui-g-9" style={{ textAlign: "left" }}> Resultado da Pesquisa</div>								
								{/**
									<div className="ui-g-1" style={{ textAlign: "center" }}>Total Lista: {toCurrency(this.state.totalLista)} </div>
									<div className="ui-g-1" style={{ textAlign: "right" }}>Total: {toCurrency(this.state.total)} </div>
								 */}
								<div className="ui-g-2" style={{ textAlign: "right", color: this.state.variacao < 0 ? '#FF0000' : '#008000' }}> Variação {toDecimalFixed(this.state.variacaoPerc, 2) } % ( {toCurrency(this.state.variacao)}) </div>
							</div>} >
							
								<DataTable emptyMessage="Nenhum registro encontrado" value={this.state.descontos} paginator rows={50} >
									{!representante ? <Column className='relative' key={14} style={{width: "3em", textAlign: "center"}} field="checkbox"  name="checkbox" header={ <div>{this.handleMainCheckbox()}</div>}  body={(rowData) => <div> {this.handleCheckbox(rowData)}</div> }  /> : <div style={{width: '0', padding: '0', visibility:'hidden', }}></div>}		
									{/* <Column key={1} sortable style={{width: "10em", textAlign: "center"}} header="Solicitado em" field="registro" body={(rowData) =>  <div>{moment(rowData.registro).format("DD/MM/YYYY HH:mm:ss")}</div>} /> */}
									
									<Column 
										key={1} 
										sortable 
										style={{width: "6em", textAlign: "center"}} 
										header="Solicitado em" field="registro" 
										body={(rowData) =>  {
											return(
												<div>
													<div>{moment(rowData.registro).format("DD/MM/YYYY")}</div>
													<div>{moment(rowData.registro).format("HH:mm:ss")}</div>
												</div>
											)
										}} 
									/>
									<Column key={13} sortable style={{width: "10em", textAlign: "center"}} header="Vendedor" field="vendedor" />
									<Column key={2} sortable style={{width: "6em", textAlign: "center"}} header="Pedido" field="pedidoId" />
									<Column key={3} sortable style={{width: "3em", textAlign: "center"}} header="Item" field="itemId" />
									<Column key={4} sortable header="Razão Social"  field="razaoSocial" style={{width: "*"}} body={(rowData) => ClienteUtils.clienteTipoNomeInscricaoEstadual(rowData)}/>
									<Column key={5} sortable style={{width: "*"}} header="Produto" field="produtoDescricao" body={(data) => {
										return (
											<div>
												<div>{data.produtoDescricao}</div>
												<div className="flex">
													<div><strong>Família:</strong> {data.familia}</div>
													<div><strong>Unidade:</strong> {data.unidade}</div>
												</div>
											</div>
										)
									}} />
									{/* <Column key={7} sortable style={{width: "8em", textAlign: "center"}} header="Família" field="familia" />
									<Column key={8} sortable style={{width: "5em", textAlign: "center"}} header="Unidade" field="unidade" /> */}
									<Column key={9} sortable style={{width: "8em", textAlign: "right"}} name="valorUnitario" field="valorUnitario" header="Valor Unitário" body={this.valorUnitario}/>
									<Column key={10} sortable style={{width: "7em", textAlign: "center"}} header="Quantidade" name="quantidade" field="quantidade" />
									<Column key={11} sortable style={{width: "8em", textAlign: "right"}} header="Valor Total" name="valorTotalItem" field="valorTotalItem" body={(item) => toCurrency(item.valorTotalItem)}/>
									<Column key={12} sortable style={{width: "12em", textAlign: "right"}} header="Variação" field="valorDesconto" body={this.variacao}/>
									<Column key={0} sortable style={{textAlign: "center", width: "4em" }} header="Status" name="status" field="status"  body={(rowData) => DescontoUtils.statusDesconto(rowData.status)} />
									<Column style={{ width: "5em" }} key="acoes" header="Ações" body={(di) => {
										let botoes = [];
										botoes.push(<span key={0} title="Editar Desconto" className="fa fa-edit ui-tzm-icon" onClick={() => this.editar({data: di})} />);
										botoes.push(<span key={1} title="Visualizar Pedido" className="fa fa-desktop ui-tzm-icon" onClick={() => this.visualizarPedido(di.pedidoId)} />);
										return <div style={{ textAlign: "center" }}>{botoes}</div>;
									}} />

								</DataTable>	
							

							</TZMPanel>
						</div>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}	
}
