
import React from "react";
import moment from "moment"
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTable from "../../../components/table/TZMTable";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import { Column } from "primereact/components/column/Column";
import { AlteracaoListaPrecoService } from "../../servicos/AlteracaoListaPrecoService";
import { DetalheAlteracaoListaPreco } from "./DetalheAlteracaoListaPreco";
import TZMUserData from "../../../utilities/TZMUserData";
import "./styles.css"
import TZMCalendar from "../../../components/common/TZMCalendar";
import TZMCombobox from "../../../components/common/TZMCombobox";
import { Growl } from "primereact/components/growl/Growl";
import { toCurrency, toPercentPrecision } from "../../../components/common/TZMFormatter";
import { Panel } from "primereact/components/panel/Panel";
import TZMPopup from "../../../components/dialog/TZMPopup";

const title = "Listas de Preço Agendamento";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class ListaPrecoAgendamento extends React.Component {

	constructor(props) {
		super(props);
		moment.locale('pt-BR');		
		this.state = {	
            alteracaoListaPreco: [],
			alteracaoIncluido: null,
			alteracaoProcessando: null,
			params: {
				inicio: null,
				fim: null,
				forma: null,
				status: null,
			}
		};
		this.intervalId = null;
		this.alteracaoListaPrecoService = new AlteracaoListaPrecoService();	
	}

	componentDidMount() {
		// Configura a função para ser executada a cada 30 segundos
		this.intervalId = setInterval(this.updateIncluido, 30000);
	}

	
	componentWillUnmount() {
		// Limpa o intervalo quando o componente for desmontado
		clearInterval(this.intervalId);
	}
	
	updateIncluido = () => {
		// Verifica o estado antes de executar a função
		if (this.state.alteracaoIncluido && this.state.alteracaoIncluido.id) {
			console.log('executanto função')
			this.alteracaoListaPrecoService.checarProgresso(this.state.alteracaoIncluido.id).then((res) => {
				if(!res){
					this.setState({alteracaoIncluido: null})
					this.listar();
				}
			})
		}

		// Verifica o estado antes de executar a função
		if (this.state.alteracaoProcessando && this.state.alteracaoProcessando.id) {
			console.log('executanto função')
			this.alteracaoListaPrecoService.checarProgresso(this.state.alteracaoProcessando.id).then((res) => {
				if(!res){
					this.setState({alteracaoProcessando: null})
					this.listar();
				}
			})
		}
	};


	listar = () => {

		let params = Object.assign({}, this.state.params);			
		if(params.forma === 'Selecione'){
			params.forma = null;
		}
		if (params.status === 'Selecione'){
			params.status = null;
		}
		this.alteracaoListaPrecoService.listar(params).then((alteracaoListaPreco) => {
			this.setState({alteracaoListaPreco})
			
			const alteracaoIncluido = alteracaoListaPreco.find(alteracao => alteracao.status === 'INCLUIDO');

			if(alteracaoIncluido?.id){
				this.setState({alteracaoIncluido})
			}

			const alteracaoProcessando = alteracaoListaPreco.find(alteracao => alteracao.status === 'PROCESSANDO');

			if(alteracaoProcessando?.id){
				this.setState({alteracaoProcessando})
			}

		});
	}

	detalheAlteracaoListaPreco = (event) => {
		if (event.data != null) {
			if (event.data.id != null) {
				this.alteracaoListaPrecoService.completarAlteracaoListaPrecoItens(event.data).then((alteracaoListaPreco) => {
					ModalRealm.showDialog(
						<DetalheAlteracaoListaPreco 
							key={TZMUserData.getIdGenerator()} 
							alteracaoListaPreco={alteracaoListaPreco}  
							onModalClose={this.listar}  
						/>
					);
				});
			} 
		}	
    }

	confirmarAlteracao = () => {
		ModalRealm.showDialog(<TZMConfirm question={"Deseja alterar o valor dos i do R"} onYes={() => {this.alterar();}} />);
	}

	handleSelect = (event) => {
		let params = this.state.params;
		params[event.name] = event.value;
		this.setState({params});
	}

	handleMultipleSelect = event => {
		const {params} = this.state;
		params[event.name].push(event.value);
		params[event.name] = params[event.name].filter((o, i) => params[event.name].findIndex(e => e.id === o.id) === i);
		this.setState({params});
	}

	handleMultipleUnselect = event => {
		const {params} = this.state;
		params[event.name] = params[event.name].filter(e => e.id !== event.value.id);
		this.setState({params});
	}

	handleUnselect = (event) => {
		let params = this.state.params;
		if (!params[event.name]) {
			params[event.name] = [];
		}
		params[event.name] = params[event.name].filter((e) => e.id !== event.value.id);
		this.setState({params});
	}

	selectAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name] = event.value;
		this.setState({ params });
	}

    handleUpdate = (key, event) => {
		let params = this.state.params;
		params[key] = event.target.value;
		this.setState({params});
    }
	
	handleClear = (event) => {
		let params = this.state.params;
		params[event.name] = null;
		this.setState({params});
	}

	handleUpdateParams = (event, field, index) => {
		let params = this.state.params;
		params[field][index] = event.target.value;
		this.setState({ params });
	}

	templateInclusao = (data) => {
		return (
			<div className="margin-left-5">
				<div className="template-inclusao">
					<i className="tzm-big-icon fa fa-calendar-alt margin-right-5" style={{color: '#098810'}}/>
					<div className="margin-right-5">Inicio:</div>
					{moment(data.inicioInclusao).format("DD/MM/YYYY HH:mm")}
				</div>
				{
					data.terminoInclusao 
					? <div className="template-inclusao">
						<i className="tzm-big-icon fa fa-calendar-check margin-right-5" style={{color: '#098810'}}/>
						<div className="margin-right-5">Fim:</div>
						{moment(data.terminoInclusao).format("DD/MM/YYYY HH:mm")}
					</div>
					: null
				}
			</div>
		)
	}
	templateQuantidadeInclusao = (data) => {
		return (
			<div className="margin-left-5">
				<div className="template-inclusao">
					<i className="tzm-big-icon fa fa-list  margin-right-5" style={{color: '#098810'}}/>
					{/* <div className="margin-right-5">Itens incluídos:</div> */}
					{/* <div> */}
						{`${data.salvosInclusao} de ${data.quantidadeInclusao}`}
					{/* </div> */}
				</div>
			</div>
		)
	}

	templateProcessamento = (data) => {
		return (
			<div className="margin-left-5">
				{
					data.inicioProcessamento 
						? <div className="template-inclusao">
							<i className="tzm-big-icon fa fa-calendar-alt margin-right-5" style={{color: '#8d1e0a'}}/>
							<div className="margin-right-5">Inicio:</div>
							{moment(data.inicioProcessamento).format("DD/MM/YYYY HH:mm")}
						</div>
						: null
				}
				{
					data.terminoProcessamento
						? <div className="template-inclusao">
							<i className="tzm-big-icon fa fa-calendar-check margin-right-5" style={{color: '#8d1e0a'}}/>
							<div className="margin-right-5">Fim:</div>
							{moment(data.terminoProcessamento).format("DD/MM/YYYY HH:mm")}
						</div>
						: null
				}
			</div>
		)
	}

	templateQuantidadeProcessamento = (data) => {
		return (
			<div className="margin-left-5">
				<div className="template-inclusao">
					<i className="tzm-big-icon fa fa-list  margin-right-5" style={{color: '#8d1e0a'}}/>
					{/* <div className="margin-right-5">Itens processados:</div> */}
					{/* <div> */}
						{`${data.salvosProcessamento} de ${data.quantidadeProcessamento}`}
					{/* </div> */}
				</div>
			</div>
		)
	}

	templateVigencia = (data) => {
		return (
			<div className="margin-left-5">
				<div className="template-inclusao tzm-justfy-conntent-center">
					<i className="tzm-big-icon fa fa-calendar-day margin-right-5" style={{color: '#2500fa'}}/>
					{moment(data.vigencia).format("DD/MM/YYYY")}
				</div>
			</div>
		)
	}
	templateFator = (data) => {

		let templateforma;

		switch (data.forma) {
			case "PERCENTUAL":
				templateforma = (
					<>
						<i className="tzm-big-icon fa fa-percentage margin-right-5" title="Percentual" style={{color: '#300286'}}/>
						<div>{toPercentPrecision(data.valor/100)}</div>
					</>
					
				)
				break;
			case "VALOR":
				templateforma = (
					<>
						<i className="tzm-big-icon fa fa-dollar-sign margin-right-5" title="Valor" style={{color: '#d4c70c'}}/>
						<div>{toCurrency(data.valor)}</div>
					</>
						
				)
				break;
			default:
				break;
		}
		
		switch (data.fator) {
			case "AUMENTO":
				return (
					<div className="margin-left-5">
						<div className="template-inclusao tzm-justfy-conntent-center">
							{templateforma}
							<i className="tzm-big-icon fa fa-long-arrow-alt-up margin-left-10" title="Aumento" style={{color: '#1f8d04'}}/>
						</div>
					</div>
				)
			case "REDUCAO":
				return (
					<div className="margin-left-5">
						<div className="template-inclusao tzm-justfy-conntent-center">
							{templateforma}
							<i className="tzm-big-icon fa fa-long-arrow-alt-down margin-left-10" title="Redução" style={{color: '#b61f1f'}}/>
						</div>
					</div>
				)
			default:
				break;
		}
	}
	templateForma = (data) => {
		
		switch (data.forma) {
			case "PERCENTUAL":
				return (
					<div className="margin-left-5">
						<div className="template-inclusao tzm-justfy-conntent-center">
							<i className="tzm-big-icon fa fa-percentage margin-right-5" title="Percentual" style={{color: '#300286'}}/>
							<div>{toPercentPrecision(data.valor/100)}</div>
						</div>
					</div>
				)
			case "VALOR":
				return (
					<div className="margin-left-5">
						<div className="template-inclusao tzm-justfy-conntent-center">
							<i className="tzm-big-icon fa fa-dollar-sign margin-right-5" title="Valor" style={{color: '#d4c70c'}}/>
							<div>{toCurrency(data.valor)}</div>
						</div>
					</div>
				)
			default:
				break;
		}
	}

	templateParametros = (data) => {
		return (
			<div className="margin-left-5">
				{
					data.familias
						? <div className="template-info">
							<i className="tzm-big-icon fa fa-check margin-right-5" style={{color: '#880968'}}/>
							<strong className="margin-right-5">Famílias: </strong>
							{`${data.familias}`}
						</div>
						: null
				}
				{
					data.codigo
						? <div className="template-info">
							<i className="tzm-big-icon fa fa-check margin-right-5" style={{color: '#880968'}}/>
							<strong className="margin-right-5">Código: </strong>
							{`${data.codigo}`}
						</div>
						: null
				}
			</div>
		)
	}

	templateReajustadoPor = (data) => {
		return (
			<div title={data.criadoPor} id={'a' + data.id} className="margin-left-5">
				<div className="template-inclusao tzm-justfy-conntent-center">
					<i className="tzm-big-icon fa fa-user margin-right-5" style={{color: '#022586'}}/>
				</div>
			</div>
		)
	}

	processamentoManual = (alteracaListaPreco) => {
		this.alteracaoListaPrecoService.iniciarProcessamento(alteracaListaPreco.id).then((response) => {
			console.log(response);
			if(response === true){
				this.processamentoIniciado()
				const alteracaoLista = this.state.alteracaoListaPreco.map(alteracao => {
					if(alteracao.id === alteracaListaPreco.id){
						alteracao.status = 'PROCESSANDO'
					}
					return alteracao
				})

				this.setState({alteracaoListaPreco: alteracaoLista, alteracaoProcessando: {...alteracaListaPreco, status: 'PROCESSANDO'} })
			}else{
				this.processamentoErro()
			}
			// this.listar();
		})
	}

	handleProcessamentoManual = (alteracaListaPreco) => {
		if(this.state.alteracaoProcessando){
			return ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()}  header="Advertência" messages={["Já existe um agendamento em processamento. Por favor aguarde esse processamento terminar antes de iniciar outro."]} />);
		}

		ModalRealm.showDialog(<TZMConfirm question={"Deseja iniciar o processemento manual desta alteração? Está é uma operação demorada e pode levar algum tempo até o término."} onYes={() => {this.processamentoManual(alteracaListaPreco);}} />);
	}

	columns = [		
		<Column 
			header="Inclusão"
			key="inclusao"
			body={this.templateInclusao}
			style={{width: "15em"}}
		/>,
		<Column 
			header="Quantidade Inclusão"
			key="qtddinclusao"
			body={this.templateQuantidadeInclusao}
			style={{width: "12em"}}
		/>,
		<Column 
			header="Processamento"
			key="processamento"
			body={this.templateProcessamento}
			style={{width: "15em"}}
		/>,
		<Column 
			header="Quantidade Processamento"
			key="qprocessamento"
			body={this.templateQuantidadeProcessamento}
			style={{width: "12em"}}
		/>,
		<Column 
			header="Vigência"
			key="vigencia"
			body={this.templateVigencia}
			style={{width: "10em"}}
		/>,
		<Column 
			header="Fator/Forma"
			key="fator"
			body={this.templateFator}
			style={{width: "12em"}}
		/>,
		<Column 
			header="Parâmetros"
			key="fator"
			body={this.templateParametros}
			style={{width: "*"}}
		/>,
		// <Column 
		// 	header="Forma"
		// 	key="forma"
		// 	body={this.templateForma}
		// 	style={{width: "10em"}}
		// />,
		<Column 
			header="Criado Por"
			key="reajustadoPor"
			body={this.templateReajustadoPor}
			style={{width: "7em"}}
		/>,
        <Column header="Ações" key="acoes" style={{width: "12em", textAlign: "center"}} body={(alteracaoListaPreco) => { 
			let botoes = [];			

			// if (pedido.id != null && (pedido.status === 'ERRO' || pedido.status === 'RASCUNHO' || pedido.status === 'DESCONTO_REPROVADO' )) {
			// 	botoes.push(<span key={4} title="Cancelar" className="fa fa-ban ui-tzm-icon" onClick={() => this.cancelarAlteracao(pedido)} />);
			// }

			//INCLUIDO, AGENDADO, ERRO
			//painel para confirmar
			//STATUS QUANDO FOR INCLUIDO OU PROCESSANDO, UTILIZAR O PROGRESSO DO CONTROLLER PARA VALIDAR SE O PROCESSANDO FINALIZOU
			switch (alteracaoListaPreco.status) {
				case 'INCLUIDO':
					botoes.push(<i title={`Criando agendamento`} className={`${this.state.alteracaoIncluido ? 'fa-spin' : ''} tzm-big-icon fa fa-hourglass-half `}  style={{color: '#6b068a'}} />);
					botoes.push(<span key={4} title="Cancelar" className="margin-left-10 fa fa-ban tzm-big-icon ui-tzm-icon" onClick={() => this.cancelarAlteracao(alteracaoListaPreco)} />);
					break;
				case 'AGENDADO':
					botoes.push(
						<i 
							title={`Agendado ${alteracaoListaPreco.dataAgendamento ? moment(alteracaoListaPreco.dataAgendamento).format('DD/MM/YYYY HH:mm') : ''}`} 
							className="tzm-big-icon fa fa-calendar-day" 
							style={{color: '#1075e9'}} 
						/>
					);
					botoes.push(<span 
						title="Executar processamento manual" key={0} 
						className="fa fa-play ui-tzm-icon tzm-big-icon margin-left-10" 
						style={{color: '#153801', marginRight: '0'}} 
						onClick={() => this.handleProcessamentoManual(alteracaoListaPreco)} 
					/>);
					botoes.push(<span key={4} title="Cancelar" className="margin-left-10 fa fa-ban tzm-big-icon ui-tzm-icon" onClick={() => this.cancelarAlteracao(alteracaoListaPreco)} />);
					break;
				case 'PROCESSANDO':
					botoes.push(<i title={`Em processamento`} className={`${this.state.alteracaoProcessando ? 'fa-spin' : ''} tzm-big-icon fa fa-sync-alt`} style={{color: '#4d5053'}}/>);
					break;
				case 'ERRO':
					botoes.push(<i title={`Erro`} className="tzm-big-icon fa fa-exclamation-triangle" style={{color: '#dce92a'}}/>);
					botoes.push(<span key={4} title="Cancelar" className="margin-left-10 fa fa-ban tzm-big-icon ui-tzm-icon" onClick={() => this.cancelarAlteracao(alteracaoListaPreco)} />);
					break;
				case 'CANCELADO':
					botoes.push(<i title={`Cancelado`} className="tzm-big-icon fa fa-times" style={{color: '#b10b0b'}}/>);
					break;
				case 'FINALIZADO':
					botoes.push(<i title={`Processamento finalizado`} className="tzm-big-icon fa fa-check" style={{color: '#039723'}}/>);
					break;		
				default:
					break;
			}

			botoes.push(<span 
				title="Alterar" key={0} 
				className="fa fa-edit ui-tzm-icon tzm-big-icon margin-left-10" 
				onClick={() => this.detalheAlteracaoListaPreco({data: alteracaoListaPreco})} 
			/>);
			return <div style={{ textAlign: "center" }}>{botoes}</div>;	
		}} />
	];

	handleChange = event => {
		const {params} = this.state;
		params[event.name] = event.value;
		this.setState({params});
	}

	cancelarAlteracao = (rowData) => {
		ModalRealm.showDialog(<TZMConfirm question="Tem certeza de que deseja cancelar esta alteração? Após cancelá-la não será mais possível desfazer o cancelamento." onYes={() => {
			this.alteracaoListaPrecoService.cancelar(rowData);
			setTimeout(() => {
				this.listar();
			}, 50);
		}} />);
	}

	processamentoIniciado = () => {
		this.growl.show({ closable: true, severity: 'success', sticky: true, summary: 'Processamento iniciado', detail: `Processamento iniciado com sucesso!` });
	}

	processamentoErro = () => {
		this.growl.show({ closable: true, severity: 'error', sticky: true, summary: 'Processamento falhou', detail: `Houve um erro ao iniciar o processamento. Tente novamente mais tarde.` });
	}

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-10">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">				
									<div className="ui-g-2">
										<TZMCalendar 
											label="Inicio da Vigência entre" 
											placeholder="" 
											onChange={(event) => this.handleUpdate("inicio", event)}
											value={this.state.params.inicio}
										/>
									</div>											
									<div className="ui-g-2">
										<TZMCalendar 
											label="&nbsp;" 
											placeholder="" 
											onChange={(event) => this.handleUpdate("fim", event)}
											value={this.state.params.fim}
										/>
									</div>											
									<div className="ui-g-2">
										<TZMCombobox 
											name="forma" 
											onChange={(event) => this.handleUpdate("forma", event)}
											value={this.state.params.forma}
											label="Forma"
										>
											<option value={null}>Selecione</option>
											<option value="PERCENTUAL">Percentual</option>
											<option value="VALOR">Valor</option>
										</TZMCombobox>
									</div>										
									<div className="ui-g-2">
										<TZMCombobox 
											name="status" 
											onChange={(event) => this.handleUpdate("status", event)}
											value={this.state.params.status} 
											label="Status"
										>
											<option value={null}>Selecione</option>
											<option value="INCLUIDO">Incluído</option>
											<option value="AGENDADO">Agendado</option>
											<option value="PROCESSANDO">Processando</option>
											<option value="FINALIZADO">Finalizado</option>
											<option value="ERRO">Erro</option>
											<option value="CANCELADO">Cancelado</option>
										</TZMCombobox>
									</div>		

																		
								</div>
							</TZMForm>	
							<TZMPanelFooter>								
								<TZMButton className="ui-button-primary ui-tzm-button"  icon="fas fa-search" label="Procurar" onClick={this.listar} />
							</TZMPanelFooter>							
						</TZMPanel>
						
					</div>
					<div className="ui-g-2">
						<Panel header="Legenda">
							<div className="tzm-simple-flex"> 
								<div className="ui-g">
									<div style={{ color: '#6b068a', textAlign: "center" }} className="ui-g-2"><i className="fa fa-hourglass-half"/></div><div className="ui-g-10">Incluído</div>
									<div style={{ color: '#1075e9', textAlign: "center" }} className="ui-g-2"><i className="fa fa-calendar-day"/></div><div className="ui-g-10">Agendado</div>
									<div style={{ color: '#4d5053', textAlign: "center" }} className="ui-g-2"><i className="fa fa-sync-alt"/></div><div className="ui-g-10">Processando</div>
								</div>
								<div className="ui-g">
									<div style={{ color: '#039723', textAlign: "center" }} className="ui-g-2"><i className="fa fa-check" /></div><div className="ui-g-10">Finalizado</div>
									<div style={{ color: '#dce92a', textAlign: "center" }} className="ui-g-2"><i className="fa fa-exclamation-triangle" /></div><div className="ui-g-10">Erro</div>
									<div style={{ color: '#b10b0b', textAlign: "center" }} className="ui-g-2"><i className="fa fa-times" /></div><div className="ui-g-10">Cancelado</div>
								</div>

							</div>
						</Panel>
					</div>
					<div className="ui-g-12">
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable 
                                value={this.state.alteracaoListaPreco} 
                                paginator rows={30} rowsPerPageOptions={[30, 45, 60]} 
                                pageLinkSize={10} children={this.columns} 
                            />
						</TZMPanel>
					</div>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
				<div className="content-section implementation p-fluid">
					<Growl ref={(el) => this.growl = el} baseZIndex={10001002}></Growl>
				</div>
			</TZMMainContent>
		);
	}	
}