import classNames from "classnames";
import React from "react";
import "./Common.css";

export default class TZMCheckbox extends React.Component {

	onChange = (event) => {
		if (this.props.onChange) {
			this.props.onChange({name: this.props.name, value: !this.props.checked});
		}
	}

	render() {
		let check = classNames({
			"fa": true,
			"ui-checkbox-check": true,
			"fa-square": !this.props.checked,
			"fa-check-square": this.props.checked
		});
		return (
			<div className={`ui-textfield-group ui-g-${this.props.col}`}>
				<label className="ui-input-label">{this.props.fieldLabel}</label><br />
				<div {...this.props} onClick={this.onChange}>
					<span className={check} />
					<label className="ui-checkbox-label">{this.props.label}</label>
				</div>
			</div>
		);
	}

}
