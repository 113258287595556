import { httpDelete, httpGet, httpPost, httpGetAsync } from "../../utilities/TZMRequest";

export class RegionalService {

    completar(regional) {
        return httpGet(`/regionais/${regional.id}`);
    }

    listar(params) {
        return httpGet("/regionais", params);
    }

    listarSimples(params) {
        return httpGet("/regionais/listar-simples", params);
    }

    salvar(regional) {
        return httpPost("/regionais", regional);
    }

    excluir(regional) {
        return httpDelete("/regionais", regional.id);
    }

    autoCompletar = async (params) => {
        return await httpGetAsync("/regionais/auto-completar", params, false);
    }

}
