import React from "react";
import classNames from "classnames";
import "./Common.css";
import PropTypes from "prop-types";

export default class TZMTextFieldPainel extends React.Component {

	render() {
		let classes = classNames({
			"ui-textfield": true,
			"ui-input-disabled-autocomplete": this.props.forac,
			"ui-input-warning": this.props.warning,
			"ui-input-required": this.props.required && (this.props.value == null || JSON.stringify(this.props.value).trim().length === 0)
		});
		return (
			<div className="ui-textfield-group">
				<label className="ui-input-label" style={{fontSize: "17px"}}>{this.props.label}</label><br />
				<div className={this.props.exclamation ? "ui-input-exclamation" : ""} title={this.props.exclamation}>
					<input type="text" {...this.props} className={classes} {...this.props} />
				</div>
			</div>
		);
	}

}

TZMTextFieldPainel.propTypes = {
	required: PropTypes.bool
};
