const apiUrl = process.env.REACT_APP_API_HOST;

export class Fetch {

  static Get(path) {
    return fetch(`${apiUrl}${path}`, {method: "get", headers: Fetch.buildHeaders(path)});
  }

  static GetAsync = async(path) =>{
    return await fetch(`${apiUrl}${path}`, {method: "get", headers: Fetch.buildHeaders(path)});
  } 

  static GetJSON(path) {
     return Fetch.Get(path).then((response) => {
       return response.text().then((text) => {
         let parse = text ? JSON.parse(text) : {};
         return parse;
       });
     });
   }

   static GetJSONAsync = async (path) => {
    return await Fetch.GetAsync(path).then((response) => {
      return  response.text().then( async (text) => {
        let parse = text ? JSON.parse(text) : {};
        return  parse;
      });
    });
  }

  static Post(path, body) {
    return fetch(`${apiUrl}${path}`, {method: "post", body: JSON.stringify(body), headers: Fetch.buildHeaders(path)});
  }
r
  static async PostAsync(path, body) {
    return await fetch(`${apiUrl}${path}`, {method: "post", body: JSON.stringify(body), headers: Fetch.buildHeaders(path)});
  }

  static PostJSON(path, body) {
    return Fetch.Post(path, body).then((response) => response.json());
  }

  static async PostJSONAsync(path, body) {
    return await Fetch.PostAsync(path, body).then(async (response) => await response.json());
  }

  static Delete(path) {
    return fetch(`${apiUrl}${path}`, {method: "delete", headers: Fetch.buildHeaders(path)});
  }

  static async DeleteAsync(path) {
    return await fetch(`${apiUrl}${path}`, {method: "delete", headers: Fetch.buildHeaders(path)});
  }

  static buildHeaders (path)  {
    return  {
      "Content-Type": "application/json",
      "Authorization": path.includes("/public") ? "" : window.localStorage.getItem("X-Authorization-Key") || ""
    };
  }

}
