import React from "react";
import { Column } from "primereact/components/column/Column";
import { DataTable } from "primereact/components/datatable/DataTable";
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanel from "../../../components/panel/TZMPanel";
import { ListaPrecoService } from "../../servicos/ListaPrecoService";
import { TZMCurrency } from "../../../components/common/TZMCurrency";
import {  toCurrencyFormatterPrecision } from "../../../components/common/TZMFormatter";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMCalendar from "../../../components/common/TZMCalendar";
import TZMPopup from "../../../components/dialog/TZMPopup";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMUserData from "../../../utilities/TZMUserData";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import moment from "moment";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import {Toggle} from "../../../components/common/Toggle";
import TZMConfirmAdvertencia from "../../../components/dialog/TZMConfirmAdvertencia";
import { TZMJustificativa } from "../../../components/dialog/TZMJustificativa";
import { AlteracaoListaPrecoService } from "../../servicos/AlteracaoListaPrecoService";

export class AlterarPrecoNova extends React.Component {

    constructor(props) {
        super(props);
        moment.locale('pt-BR');	
		this.state = {
            itens: props.itens,
			alteracao: props.alteracao,
            params: {
                valor: 0,
                vigencia: null
            },
			sinal: "+",
			forma: "V",
            visible: true
        };
        this.listaPrecoService = new ListaPrecoService();       
		this.alteracaoListaPrecoService = new AlteracaoListaPrecoService();  
    }
    
    handleChange = (event) => {
    	let recalculate = false;
        const {itens, params} = this.state;
        let value =  event.value;
        switch (event.name) {
		case "valor":
			if (!isNaN(value)) {
				params[event.name] = event.value;
				recalculate = true;
			}
			break;
		case "vigencia":
			itens.forEach(item => item.inicio = event.target.value);
			params[event.name] = event.target.value;
			break;
		case "sinal":
		case "forma":
			recalculate = true;
			// eslint-disable-next-line
		default:
			this.setState({[event.name]: event.value});
			break;
        }

		if((this.state.sinal === "-" || event.value === "-" ) && (event.name === "valor" || event.name === "sinal" || event.name === "forma")){
			const valor = this.state.params.valor;

			if((this.state.forma === 'P' || event.value === 'P') && valor >= 100){
				this.setState({params: {
					...this.state.params,
					valor: 0
				}})
				return ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()}  header="Advertência" messages={["O valor não pode ser 100%"]} />);
			}

			const itensComValorMenorOuIgualZero = this.state.itens.filter(item => (item.preco - valor) <= 0);
			const errosMessagens = itensComValorMenorOuIgualZero.map((item) => {
				return `O valor do item ${item.produto.descricao} não pode ser menor ou igual a zero.`;
			})
			if((this.state.forma === 'V' || event.value === 'V') && errosMessagens.length > 0){
				this.setState({params: {
					...this.state.params,
					valor: 0
				}})
				return ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()}  header="Advertência" messages={errosMessagens} />);
			}

		}

        this.setState({itens, params}, () => recalculate && this.recalculateItems());
    }

    onHide = () => this.setState({ visible: false });
    
    toCurrency(rowData, column) {
		return toCurrencyFormatterPrecision(rowData[column.field]);
	}

    handleUpdateItem = (key, index, value) => {
		let itens = this.state.itens;
		itens[index][key] = value;
		this.setState({ itens});
    }

    editorPreco = (rowData, props) => {		
		return (			
			<div style={{ textAlign: "right"}}>
				<TZMCurrency className="ui-textfield-currency-group ui-textfield" name="preco" prefix="R$" 
							value={this.state.itens[props.rowIndex].novoPreco} 
							onChange={(event) => this.handleUpdateItem("novoPreco", props.rowIndex, event.value)} />
			</div>
		);
	}

    editorVigencia = (rowData, props) => {
		return (			
			<div style={{ textAlign: "right"}}>
                <TZMCalendar onChange={(event) => this.handleUpdateItem("inicio", props.rowIndex, event.target.value)} 
                                minDate={moment().toDate()}  readOnlyInput name="inicio" value={this.state.itens[props.rowIndex].inicio} />
			</div>
		);
	}

    confirmar = () => {
		if (this.validar()) {
            let contagem = this.state.itens.length;
            let texto = "Total de registros a serem alterados: " + contagem + ". Tem certeza de que deseja confirmar está Alteração ";			
			ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()}  question={texto} onYes={() => {this.reducao();}} />);			
        }
    }

	finalizar = (alteracao) => {
		ModalRealm.showDialog(<TZMJustificativa key={TZMUserData.getIdGenerator()} question="Informe o motivo da alteração." onYes={(justificativa) => {			
			this.salvar(justificativa,alteracao);
		}} />);
	}

	reducao = async () => {
		let messages = [];
		let erros = []

		let itens = this.state.itens;		
		itens.forEach((item) => {
			if (item.novoPreco < item.preco) {
                messages.push(`O preço do item ${item.produto.descricao} é menor do que o valor atual.`);            
			}			
		});

		let alteracao = Object.assign({}, this.state.alteracao);
		alteracao.itens = this.state.itens;	
		alteracao.vigencia = this.state.params.vigencia;
		alteracao.valor = this.state.params.valor;
		if (this.state.sinal === '+') {
			alteracao.fator = 'AUMENTO';
		} else {
			alteracao.fator = 'REDUCAO';
		}

		if (this.state.forma === 'V') {
			alteracao.forma = 'VALOR';
		} else {
			alteracao.forma = 'PERCENTUAL';
		}	

		await this.alteracaoListaPrecoService.validarDuplicados(alteracao).then((response) => {
			erros = response
		});

		if (erros.length > 0) {
			ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()}  header="Advertência" messages={erros} />);
			return false;
		}


		
		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMConfirmAdvertencia key={TZMUserData.getIdGenerator()}  header="Advertência" messages={messages} onYes={() => {this.finalizar(alteracao);}}/>);
		} else {
			ModalRealm.showDialog(<TZMJustificativa key={TZMUserData.getIdGenerator()}  
									question={"Informe o motivo da alteração."} 
									onYes={(justificativa) => {this.salvar(justificativa,alteracao);}} />);
		}
	}

	validar = () => {
		let messages = [];
        let itens = this.state.itens;
        itens.forEach((item) => {				
            if (!item.novoPreco || item.novoPreco === 0) {
                messages.push(`O preço do item ${item.produto.descricao} não pode ser vazio ou igual a 0(zero).`);
			} else if (item.preco === item.novoPreco) {
				messages.push(`O preço do item ${item.produto.descricao} é igual ao preço anterior.`);
			}
            if (!item.inicio) {
                messages.push(`A vigência do item ${item.produto.descricao} não pode ser vazia.`);
            } else {
				let vigenciaNova = moment(item.inicio).toDate();
				let vigenciaAtual = moment(item.listaPrecoItemAnterior.inicio).toDate();
				if (vigenciaNova < vigenciaAtual) {
					messages.push(`A vigência do item ${item.produto.descricao} não pode ser menor que à atual.`);
				} 
			}            
        });	

		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()}  header="Advertência" messages={messages} />);
			return false;
		}

        return true;        
    }

	salvar = (justificativa, alteracao) => {

		alteracao.motivoReajuste = justificativa;
        this.listaPrecoService.salvarAlteracao(alteracao).then((response) => {
            this.setState({visible: false});   
			if (this.props.onModalClose) {
				this.props.onModalClose(response.ok);
			}
        })
    }

    vigencialAnterior = (rowData, props) => {		
		return moment(this.state.itens[props.rowIndex].listaPrecoItemAnterior.inicio).format("DD/MM/YYYY");
	}
	
	recalculateItems = () => {
		const {itens} = this.state;
    	const valor = Number(this.state.sinal + this.state.params.valor);
		itens.forEach(item => {
			switch (this.state.forma) {
			case "V":
				item.novoPreco = item.preco + valor;
				break;
			case "P":
				item.novoPreco = item.preco + (item.preco * valor / 100);
				break;
			default:
				break;
			}
		});
		this.setState({itens});
	}

	printChange = () => {
    	let oper = this.state.sinal === "+" ? "Adicionar" : "Diminuir";
    	switch (this.state.forma) {
		case "V":
			return `${oper} R$ ${this.state.params.valor} ${this.state.sinal === "+" ? "ao" : "do"} preço`;
		case "P":
			return `${oper} ${this.state.params.valor}% ${this.state.sinal === "+" ? "ao" : "do"} preço`;
		default:
			return null;
		}
	}

    render() {		
        return (
        <TZMDialog style={{width: "1400px"}} autoAlign visible={this.state.visible} modal header="Alterar Preço" onHide={this.onHide} {...this.props}>
			<TZMPanel>
				<TZMForm>
					<div className="ui-g">
						<div className="ui-g-3"/>
						<div className="ui-g-3" style={{display: "flex"}}>
							<Toggle label="&nbsp;" style={{flex: 1}} name="sinal" value={this.state.sinal} left={{value: "+", icon: "plus"}} right={{value: "-", icon: "minus"}} onChange={this.handleChange}/>
							<div style={{flex: 3}}>
								{
									this.state.forma === "V"
										? <TZMCurrency label="Valor" name="valor" value={this.state.params.valor} onChange={this.handleChange} />
										: <TZMTextField label="Valor" name="valor" value={this.state.params.valor} onChange={this.handleChange} />
								}
							</div>
							<Toggle label="&nbsp;" style={{flex: 1}} name="forma" value={this.state.forma} left={{value: "V", icon: "dollar-sign"}} right={{value: "P", icon: "percent"}} onChange={this.handleChange}/>
						</div>
						<div className="ui-g-2">
							<TZMCalendar label="Vigência" onChange={this.handleChange} name="vigencia" value={this.state.params.vigencia} minDate={moment().toDate()} readOnlyInput/>
						</div>
						<div className="ui-g-4"/>
						<div className="ui-g-3"/>
						<div className="ui-g-3" style={{textAlign: "center"}}>
							{this.printChange()}
						</div>
					</div>
				</TZMForm>
			</TZMPanel>
			<div style={{height: "10px"}}/>
			<TZMPanel>
				<TZMForm>
					<div className="ui-g" style={{maxHeight: "500px"}}>
						<DataTable scrollable scrollHeight="432px" ref={(el) => this.dt = el} value={this.state.itens} paginator rows={30} rowsPerPageOptions={[30, 45, 60]} pageLinkSize={30}>
							<Column header="Lista" key="lista" field="listaPreco.codigo" style={{textAlign: "left",width: "10em" }} />
							<Column header="Produto" key="produto" field="produto.pk.produtoId" style={{textAlign: "left",width: "12em" }} />
							<Column header="Descrição do Produto" key="descricao" field="produto.descricao" style={{textAlign: "left",width: "*" }} />
							<Column header="Grupo" key="grupo" field="produto.grupoId" style={{width: "10em" , textAlign: "center"}} />
							<Column field="preco" key="preco" header="Preço Atual"  body={this.toCurrency} style={{width: "12em", textAlign: "center"}}  />
							<Column field="novoPreco" key="novoPreco" header="Novo Preço" body={this.editorPreco}style={{width: "12em", textAlign: "center"}}/>
							<Column name="vigenciaAtual" field="vigenciaAtual" key="vigenciaAtual" header="Vigência Atual" body={this.vigencialAnterior} style={{width: "12em", textAlign: "center"}}/>
							<Column field="inicio" key="inicio" header="Nova Vigência" body={this.editorVigencia} style={{width: "12em", textAlign: "center"}}/>
						</DataTable>
					</div>
				</TZMForm>
			</TZMPanel>
			<TZMPanelFooter>
				<TZMButton className="ui-button-success" label="Confirmar" icon="fas fa-save" onClick={this.confirmar} />
				<TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-close" onClick={this.onHide} />
			</TZMPanelFooter>
        </TZMDialog>
        );
    }
}