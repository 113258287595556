
import React from "react";
import moment from "moment"
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTable from "../../../components/table/TZMTable";
import TZMUserData from "../../../utilities/TZMUserData";
import TZMTextField from "../../../components/common/TZMTextField";
import { ListaPrecoService } from "../../servicos/ListaPrecoService";
import { EditarListaPreco } from "./EditarListaPreco";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import { ClienteService } from "../../servicos/ClienteService";
import { Column } from "primereact/components/column/Column";
import { ClienteUtils } from "../cliente/ClienteUtils";
import {  toCurrencyFormatterPrecision } from "../../../components/common/TZMFormatter";
import { EditarListaPrecoItem } from "./EditarListaPrecoItem";
import { Checkbox } from "primereact/components/checkbox/Checkbox";
import TZMCalendar from "../../../components/common/TZMCalendar";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import { FamiliaService } from "../../servicos/FamiliaService";
import { AlterarPrecoNova } from "./AlterarPrecoNova";
import { Growl } from "primereact/components/growl/Growl";
import TZMProgressbar from "../../../components/menubar/TZMProgressbar";
import { AlteracaoListaPrecoService } from "../../servicos/AlteracaoListaPrecoService";
import TZMPopup from "../../../components/dialog/TZMPopup";

const title = "Listas de Preço";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class ListaPreco extends React.Component {

	constructor(props) {
		super(props);
		moment.locale('pt-BR');		
		this.state = {	
			listasPreco:[],
			desativados: false,
			loading: false,
			alteracao: {
				familias: null,
				cliente: null,
				codigo: null,
				grupo: null
			},
			params: {
				codigo: null,
				versionamento: null,
				produto: null,
				descricaoProduto: null,
				sku: null,
				grupoId: null,
				cliente: null,
				descricao: '',
				ultimaVigencia: true,
				inicio: null,
				fim: null,
				familias: []
			}
		};
		this.listaPrecoService = new ListaPrecoService();	
		this.alteracaoListaPrecoService = new AlteracaoListaPrecoService();
		this.clienteService = new ClienteService();	
		this.familiaService = new FamiliaService();	
	}

	listar = () => {
		let params = Object.assign({}, this.state.params);		
		let alteracao = Object.assign({}, this.state.alteracao);
		alteracao.codigo = params.codigo;
		alteracao.grupo = params.grupoId;

		if (params.cliente) {
			params.cliente = params.cliente.clienteId;
			alteracao.cliente = params.cliente.descricaoAmigavel;
			
		}		
		if (params.familias?.length) {
			//alteracao.familias = params.familias.map(f => f.descricao);
			alteracao.familias = params.familias.map(f => f.descricao).join(',');
			params.familias = params.familias.map(f => f.id);			
		}
		this.setState({alteracao});
		this.listaPrecoService.listarItemSimples(params).then((listasPreco) => this.setState({listasPreco}));
	}

	importar = () => {
		this.listaPrecoService.importar();
	}

	listarClientes = async (event) => {
        await this.clienteService.autoComplete({cliente: event.query, desativados: false, sort: 'razao'}).then((clientes) => this.setState({clientes}));
    }
	
	toggleHidden = () => {
		this.setState({params: {...this.state.params, desativados: !this.state.params.desativados}});
	}

	editarListaPreco = (event) => {		
		this.setState({selectedListaPreco: event.data});
		if (event.data != null) {
			if (event.data.id != null) {
				this.listaPrecoService.completar(event.data.listaPreco).then((listaPreco) => {
					this.listaPrecoService.completarListaPrecoItens(listaPreco).then((itens) =>{
						listaPreco.itens = itens;
						ModalRealm.showDialog(<EditarListaPreco key={TZMUserData.getIdGenerator()} listaPreco={listaPreco}  onModalClose={this.listar}  />);
					});
				});
			} else {
				ModalRealm.showDialog(<EditarListaPreco key={TZMUserData.getIdGenerator()} listaPreco={event.data} onModalClose={this.listar}  />);
			}
		}
    }

	editarListaPrecoItem = (event) => {
		this.setState({selectedListaPreco: event.data});		
		if (event.data != null) {
			if (event.data.id != null) {
				this.listaPrecoService.completarListaPrecoItem(event.data).then((listaPreco) => {
					ModalRealm.showDialog(<EditarListaPrecoItem key={TZMUserData.getIdGenerator()} listaPrecoItem={listaPreco}  onModalClose={this.listar}  />);
				});
			} else {
				ModalRealm.showDialog(<EditarListaPrecoItem key={TZMUserData.getIdGenerator()} listaPrecoItem={event.data} onModalClose={this.listar}  />);
			}
		}
    }

	confirmarAlteracao = () => {
		ModalRealm.showDialog(<TZMConfirm question={"Deseja alterar o valor dos i do R"} onYes={() => {this.alterar();}} />);
	}

	verificarAgendamento = () => {
		this.alteracaoListaPrecoService.isAgendando().then((agendando) => {
			//MUDAR PARA UM PAINEL DE MENSAGEM
			if (agendando) {
				return(ModalRealm.showDialog(
					<TZMPopup 
						key={TZMUserData.getIdGenerator()}  
						header="Advertência" 
						messages={['Existe um agendamento em andamento, aguarde a finalização ou cancele o agendamento e tente novamente.']} 
					/>
				))
				// alert('Existe um agendamento em andamento, aguarde a finalização ou cancele o agendamento e tente novamente.');
			} else {
				this.alterar();
			}
		});
	}

	alterar = (event) => {

		let itens = this.state.listasPreco;	
		let novos = [];
		this.setState({loading: true});
		itens.forEach(item => {	
			if (!item.fim){
				let novo = Object.assign({}, item); 						
				novo.listaPrecoItemAnterior = item; 			
				novo.id = null;
				novo.versao = 0;
				novo.registro = null;				
				novo.inicio = moment().toDate();
				novo.fim = null;
				novo.erro = false;
				novo.novoPreco = novo.preco;
				novo.produtoId = item.produto.pk.produtoId;
				novo.depositoId = item.produto.pk.depositoId;
				novo.estabelecimentoId = item.produto.pk.estabelecimentoId;
				novo.empresaFilialId = item.produto.pk.empresaFilialId;
				novo.produtoDescricao = item.produto.descricao;
				novo.listaPrecoId = item.listaPreco.id;
			
				if (!novos.some(n => n.produto.pk.produtoId === item.produto.pk.produtoId && n.listaPreco.codigo === item.listaPreco.codigo)) {
					novos.push(novo);
				}
			}
		});		
		
		TZMProgressbar.setActive(false);
		ModalRealm.showDialog(
			<AlterarPrecoNova 
				key={TZMUserData.getIdGenerator()} 
				itens={novos}
				alteracao={this.state.alteracao}
				onModalClose={(erro) => {
					if(erro){
						this.alteracaoConfirmada()
					}else{
						this.alteracaoRejeitada()
					}
					this.listar()
				}}
			/>
		);
	}

	listarFamilias = event => {
		this.familiaService.autoCompletarFamiliaDummy({descricao: event.query, sort: "descricao", exclude: this.state.params.familias?.map(f => f.id).join(",")}).then((listFamilias) => this.setState({listFamilias}));
	}

	dateFormat(rowData, column) {
		if (rowData[column.field]) {
			return moment(rowData[column.field]).format("DD/MM/YYYY");
		}
		return null;		
	}

	toCurrency(rowData, column) {
		return toCurrencyFormatterPrecision(rowData[column.field]);
	}
    
	criar = () => {
		this.setState({selectedListaPreco: null});
		this.editarListaPreco({data: {codigo: null, ativado: true}});
	}

	handleSelect = (event) => {
		let params = this.state.params;
		params[event.name] = event.value;
		this.setState({params});
	}

	handleMultipleSelect = event => {
		const {params} = this.state;
		params[event.name].push(event.value);
		params[event.name] = params[event.name].filter((o, i) => params[event.name].findIndex(e => e.id === o.id) === i);
		this.setState({params});
	}

	handleMultipleUnselect = event => {
		const {params} = this.state;
		params[event.name] = params[event.name].filter(e => e.id !== event.value.id);
		this.setState({params});
	}

	handleUnselect = (event) => {
		let params = this.state.params;
		if (!params[event.name]) {
			params[event.name] = [];
		}
		params[event.name] = params[event.name].filter((e) => e.id !== event.value.id);
		this.setState({params});
	}

	selectAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name] = event.value;
		this.setState({ params });
	}

    handleUpdate = (key, event) => {
		let params = this.state.params;
		params[key] = event.target.value;
		this.setState({params});
    }
	
	handleClear = (event) => {
		let params = this.state.params;
		params[event.name] = null;
		this.setState({params});
	}

	handleUpdateParams = (event, field, index) => {
		let params = this.state.params;
		params[field][index] = event.target.value;
		this.setState({ params });
	}

	deleteItem = (event) => {
		ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} onYes={() => {
			this.listaPrecoService.completarListaPrecoItem(event.data).then((listaPrecoItem) => {
				listaPrecoItem.ativado = false;
				this.listaPrecoService.salvarListaPrecoItem(listaPrecoItem).then(() => {
					this.listar();
				});
			});
			
		}} question="Tem certeza de que deseja desativar este item?" />);
		
	}

	columns = [		
		<Column header="Lista" key="lista" field="listaPreco.codigo" style={{textAlign: "left",width: "10em" }} sortable/>,
		<Column header="Produto" key="produto" field="produto.pk.produtoId" style={{textAlign: "left",width: "14em" }} />,		
		<Column header="Descrição do Produto" key="descricao" field="produto.descricao" style={{textAlign: "left",width: "*" }} />,		
		<Column header="Família" key="familia" body={(rowData) => rowData.produto.familia ? rowData.produto.familia.descricao : null }   style={{width: "17em", textAlign: "center"}} />,
		<Column header="Grupo" key="grupo" field="produto.grupoId" style={{width: "10em" , textAlign: "center"}} />,		
		<Column field="inicio" key="inicio" header="Vigência" body={this.dateFormat} style={{width: "10em", textAlign: "center"}} sortable />,
		// <Column field="fim" key="fim" header="Término Vigência" body={this.dateFormat} style={{width: "5em", textAlign: "center"}} sortable />,
		<Column field="preco" key="preco" header="Preço" body={this.toCurrency} style={{width: "10em", textAlign: "center"}}  />,
		<Column header="Ações" key="acoes" style={{width: "8em", textAlign: "center"}} body={(listaPrecoItem) => { 
			let botoes = [];			
			if (TZMUserData.hasRole("____LSTP_E")) {
				botoes.push(<span key={0} title="Editar Lista Preço" className="fa fa-edit ui-tzm-icon" onClick={() => this.editarListaPreco({data: listaPrecoItem})} />);
				botoes.push(<span key={1} title="Editar Item Lista Preço" className="fa fa-box ui-tzm-icon" onClick={() => this.editarListaPrecoItem({data: listaPrecoItem})} />);
				botoes.push(<span key={2} title="Desativar Item Lista Preço" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.deleteItem({data: listaPrecoItem})} />);
			}
			return <div style={{ textAlign: "center" }}>{botoes}</div>;	
		}} />
	];

	handleChange = event => {
		const {params} = this.state;
		params[event.name] = event.value;
		this.setState({params});
	}

	
	alteracaoConfirmada = () => {
		this.growl.show({ closable: true, severity: 'success', sticky: true, summary: 'Alteração Confirmada', detail: `Agendamento para alteração de preço criado com sucesso!` });
	}

	alteracaoRejeitada = () => {
		this.growl.show({ closable: true, severity: 'error', sticky: true, summary: 'Alteração Rejeitada', detail: `Houve um erro ao criar agendamento para alteração de preços. Tente novamente.` });
	}

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">	
									<div className="ui-g-1">									
										<TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("codigo", event)} label="Código" value={this.state.params.codigo} />
									</div>
									<div className="ui-g-1">									
										<TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("grupoId", event)} label="Grupo" value={this.state.params.grupoId} />
									</div>										
									<div className="ui-g-1">
										<TZMTextField  name="sku" placeholder="SKU"  value={this.state.params.sku} label="Produto" onChange={(event) => this.handleUpdate("sku", event)} />		
									</div>
									<div className="ui-g-2">
										<TZMTextField  name="produto" placeholder="Descrição"  value={this.state.params.descricaoProduto} label="Descrição do Produto" onChange={(event) => this.handleUpdate("descricaoProduto", event)} />		
									</div>
									<div className="ui-g-2">
										<TZMAutoComplete  onClear={this.handleClear} itemTemplate={ClienteUtils.templateTipo} onSelect={this.selectAutoComplete} onChange={this.selectAutoComplete}
											suggestions={this.state.clientes} completeMethod={this.listarClientes} name="cliente" field="descricaoAmigavel" 
												label="Cliente"  placeholder="Razão social, fantasia ou CNPJ"  value={this.state.params.cliente} />
									</div>
									<div className="ui-g-2">
										<TZMCalendar label="Vigência" placeholder="Inicial" onChange={(event) => this.handleUpdate("inicio", event)} value={this.state.params.inicio} />
									</div>
									<div className="ui-g-2">
										<TZMCalendar label="&nbsp;" placeholder="Final" onChange={(event) => this.handleUpdate("fim", event)} value={this.state.params.fim} />
									</div>									
									<div className="ui-g-1" style={{textAlign: "left"}} >                                
										<Checkbox inputId="ultimaVigencia" onChange={(event) => this.handleUpdate("ultimaVigencia", {...event, target: {value: event.checked}})} checked={this.state.params.ultimaVigencia} />
										<label htmlFor="ultimaVigencia" className="ui-checkbox-label">Última Vigência </label>
									</div>	
									<div className="ui-g-5 ui-fluid">
										<TZMAutoComplete onSelect={this.handleMultipleSelect} onUnselect={this.handleMultipleUnselect}
														 suggestions={this.state.listFamilias} dropdown multiple completeMethod={this.listarFamilias}
														 name="familias" field="descricao" label="Família" placeholder="Descrição" value={this.state.params.familias}/>
									</div>																	
								</div>
							</TZMForm>	
							<TZMPanelFooter>								
								<TZMButton className="ui-button-secondary" title={this.state.params.desativados ? "Ocultar Desativados" : "Exibir Desativados"} style={{float: "left"}} onClick={this.toggleHidden}  icon={this.state.params.desativados ? "fas fa-eye": "fas fa-low-vision"} />
								{TZMUserData.hasRole("____LSTP_C") 
									? <TZMButton 
										className="ui-button-warning ui-tzm-button" 
										icon="fas fa-hand-holding-usd" 
										disabled={this.state.listasPreco.length === 0} 
										label="Alterar Preço" 
										onClick={() => {
											TZMProgressbar.setActive(true);
											setTimeout(() => {
												this.verificarAgendamento();
											}, 100);
										}}
									/> 
									: null
								}
								{TZMUserData.hasRole("____LSTP_C") ? <TZMButton className="ui-button-success ui-tzm-button" icon="fas fa-plus" label="Nova Lista Preço" onClick={this.criar} /> : null}
								<TZMButton className="ui-button-primary ui-tzm-button"  icon="fas fa-search" label="Procurar" onClick={this.listar} />
							</TZMPanelFooter>							
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable value={this.state.listasPreco} paginator rows={30} rowsPerPageOptions={[30, 45, 60]} pageLinkSize={10} children={this.columns} />
						</TZMPanel>
					</div>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
				<div className="content-section implementation p-fluid">
					<Growl ref={(el) => this.growl = el} baseZIndex={10001002}></Growl>
				</div>
			</TZMMainContent>
		);
	}
	
}
