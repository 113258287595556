import { httpGet, httpGetAsync, httpPost } from "../../utilities/TZMRequest";
import {Fetch} from "../../utilities/Fetch";

export class DescontoService {

    
	salvar(desconto) {
		return httpPost("/descontos", desconto);
	}

    async solicitados(usuario) {
        return await httpGetAsync(`/descontos/solicitados/${usuario.id}`, null, false);
    }

    async minhasSolicitacoes(usuario) {
        return await httpGetAsync(`/descontos/minhas-solicitacoes/${usuario.id}`, null, false);
    }

    async desenvolver(desconto, usuario, status) {
        return await httpPost(`/descontos/desenvolver/${status}/${usuario.id}`, desconto);
    }

    async desenvolverLista(lista, usuario, status) {
        return httpPost(`/descontos/desenvolver-lista/${status}/${usuario.id}`, lista);
    }

	async desenvolverPublic(desconto, usuario, status) {
		return await Fetch.PostAsync(`/public/descontos/desenvolver/${status}/${usuario.id}`, desconto);
	}

    listarSimples = async (params) => {
        return  httpGetAsync("/descontos/listar-simples", params, true);
    }

    completar(desconto) {
		return httpGet(`/descontos/${desconto.descontoId}`);
	}	
	

}
