import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMDialog from "../../../components/dialog/TZMDialog";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMShortcut from "../../../utilities/TZMShortcut";
import TZMUserData from "../../../utilities/TZMUserData";
import AlterarSenha from "./AlterarSenha";




export default class Configuracoes extends React.Component {

	constructor() {
		super();
		this.state = {
			visible: true,
			alterado: false,
			principal: TZMUserData.getPrincipal()
		};
	}

	fecharPainel = (modalResponse) => {
		this.setState({visible: false});
		if (this.props[modalResponse]) {
			this.props[modalResponse](this.state.pedido);
		}
	}

	fecharUsuario = () => {
		if (this.state.alterado) {
			ModalRealm.showDialog(<TZMConfirm onYes={() => this.fecharPainel("onClose")} question="Fechar e descartar as alterações feitas?" />);
		} else {
			this.fecharPainel("onClose");
		}
	}

	alterarSenha = () => {
		ModalRealm.showDialog(<AlterarSenha />);
	}

	render() {
		return (
			<TZMDialog style={{width: "400px"}} visible={this.state.visible} modal header="Configurações" {...this.props}>
				<TZMPanel>
					<div className="ui-g">
						<div className="ui-g-12 ui-tzm-configuracoes-username">{this.state.principal.nome}</div>
						<div className="ui-g-6 ui-tzm-configuracoes-perfil">{this.state.principal.perfil}</div>
						<div className="ui-g-6 ui-tzm-configuracoes-email"><span className="fake-link"href={`mailto:${this.state.principal.email}`}>{this.state.principal.email}</span></div>
					</div>
				</TZMPanel>
				<TZMPanelFooter>
					<TZMButton secondary style={{float: "left"}} label="Alterar Senha" icon="fas fa-key" onClick={this.alterarSenha} />
					<TZMButton label="OK" icon="fas fa-check" onClick={this.fecharUsuario} />
				</TZMPanelFooter>
				<TZMShortcut
					active={this.state.visible}
					onEscape={this.fecharUsuario}
				/>
			</TZMDialog>
		);
	}

}
