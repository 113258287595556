import { AutoComplete } from "primereact/components/autocomplete/AutoComplete";
import React from "react";
import { PerfilService } from "../../pages/servicos/PerfilService";
import {TransportadoraService} from "../../pages/frete/servicos/TransportadoraService";
import {MunicipioService} from "../../pages/frete/servicos/MunicipioService";
import {ClienteService} from "../../pages/servicos/ClienteService";

export class NeoSuggestion extends React.Component {

    static Perfil = class extends React.Component {

        state = {
            perfis: []
        };

        perfilService = new PerfilService();

        render() {
            return <NeoSuggestion label={this.props.label || "Perfil"} {...this.props} completeMethod={this.listarPerfis} field="descricao" suggestions={this.state.perfis} />;
        }

        listarPerfis = (event) => {
            this.perfilService.buscar(`search=descricao~${event.query.replace(/[^0-9a-zA-Z ]/g, " ")};ativado=true&page=0&sort=descricao&size=10`).then((perfis) => this.setState({perfis}));
        }

    }

    static Cliente = class extends React.Component {

        state = {
            clientes: []
        };

        clienteService = new ClienteService();

        render() {
            return <NeoSuggestion label={this.props.label || "Cliente"} {...this.props} completeMethod={this.listarClientes} field="razaoSocial" suggestions={this.state.clientes} />;
        }

        listarClientes = (event) => {
            this.clienteService.listarSimples({cliente: event.query, page: 0, size: 10, sort: "razaoSocial"}).then((clientes) => this.setState({clientes}));
        }

    }

    static Transportadora = class extends React.Component {

        state = {
            transportadoras: []
        };

        transportadoraService = new TransportadoraService();

        render() {
            return <NeoSuggestion label={this.props.label || "Transportadora"} {...this.props} completeMethod={this.listarTransportadoras} field="razaoSocial" suggestions={this.state.transportadoras}/>;
        }

        listarTransportadoras = (event) => {
            this.transportadoraService.listar(event.query.replace(/[^0-9a-zA-Z ]/g, " ")).then(transportadoras => this.setState({transportadoras}));
        }

    }


    static Municipio = class extends React.Component {

        state = {
            municipios: []
        };

        municipioService = new MunicipioService();

        municipioTemplate = (municipio) => `${municipio?.nome}/${municipio?.siglaUf}`;

        render() {
            return <NeoSuggestion label={this.props.label || "Município"} {...this.props} completeMethod={this.listarMunicipios} field="nome" itemTemplate={this.municipioTemplate} suggestions={this.state.municipios}/>;
        }

        listarMunicipios = (event) => {
            this.municipioService.autoCompletar(event.query).then(municipios => this.setState({municipios}));
        }

    }

    state = {
        value: ""
    };

    render() {
        return (
            <div className={`ui-textfield-group ui-g-${this.props.col}`}>
                {this.props.label ? <div><label className="ui-input-label">{this.props.label}</label><br/></div> : null}
                <AutoComplete {...this.props}
                              value={this.state.value.length ? this.state.value : this.props.value}
                              onChange={this.onChange}
                              onSelect={this.onSelect}
                              onUnselect={this.onUnselect}
                              onClear={this.onClear}/>
            </div>
        );
    }

    onChange = (event) => {
        this.setState({value: event.value});
    }

    onSelect = (event) => {
        if (this.props.onChange) {
            const value = this.props.multiple ? [...this.props.value, event.value] : event.value;
            this.props.onChange({name: this.props.name, value, index: this.props.index});
            this.setState({value: ""});
        }
    }

    onUnselect = (event) => {
        if (this.props.onChange && this.props.multiple) {
            const value = this.props.value.filter(v => v.id !== event.value.id);
            this.props.onChange({name: this.props.name, value, index: this.props.index});
        }
    }

    onClear = () => {
        if (this.props.value && this.props.onChange) {
            this.onSelect({value: null});
        }
    }

}
