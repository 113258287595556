import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import {Column} from "primereact/components/column/Column";
import TZMCalendar from "../../../components/common/TZMCalendar";
import {DescontoService} from "../../servicos/DescontoService";
import {toCurrency, toDecimalFixed, toPercent} from "../../../components/common/TZMFormatter";
import {ClienteUtils} from "../cliente/ClienteUtils";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import TZMUserData from "../../../utilities/TZMUserData";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import {JustificativaDesconto} from "../../aap/pedido/JustificativaDesconto";
import {PedidoService} from "../../servicos/PedidoService";
import EditarPedido from "../../aap/pedido/EditarPedido";
import TZMPanel from "../../../components/panel/TZMPanel";
import "./Desconto.css";
import TZMTable from "../../../components/table/TZMTable";
import {DescontoUtils} from "./DescontoUtils";
import {Validator} from "../../../utilities/TZMValidator";
import moment from "moment";
import {AnaliseService} from "../../servicos/AnaliseService";
import {AnalisePedidoItem} from "../../aap/pedido/AnalisePedidoItem";

moment.locale('pt-BR');

export default class EditarDesconto extends React.Component {

	state = {
		desconto: this.props.desconto,
		visible: true,
		alterado: false
	};

	descontoService = new DescontoService();
	pedidoService = new PedidoService();
	analiseService = new AnaliseService();

	onHide = () => 	{
		let alterado = this.state.alterado;		
		if (this.props.onModalClose && alterado) {
			let desconto = this.state.desconto;
			this.props.onModalClose(desconto);
		}		
		this.setState({visible: false});

	}
 
	salvar = () => {
		this.descontoService.salvar(this.state.desconto).then((desconto) => {
			if (this.props.onModalClose) this.props.onModalClose(desconto);
			this.onHide();
		});
	}

	showResponsaveis = (rowData, props) => {		
		return (<div>{rowData.responsaveis.map(responsavel => <div key={responsavel.nome}> {responsavel.nome} </div>)} </div>);
	}

	showUsuario = (rowData, props) => {
		if (rowData.usuario) {
			return (<div>{rowData.usuario.nome}</div>);
		}
		return null;
	}

	showStatus = (rowData, props) => {
		let data = '';
		if (rowData.executadoEm) {
			data = moment(rowData.executadoEm).format("DD/MM/YYYY HH:mm:ss");
		}
		return (<div><div>{rowData.statusExtenso}</div><div>{data}</div></div>);
	}		
	

	modificarDesconto = (descontoItem, acao) => {			
		const usuario = TZMUserData.getUsuario();
		switch (acao) {
			case "R":
				ModalRealm.showDialog(<JustificativaDesconto  acao={"REPROVADO"} itens={this.state.desconto.itemDTO.itens} key={TZMUserData.getIdGenerator()} onModalClose={(justificativa) => {
					this.reprovarDesconto({...descontoItem, justificativa} , usuario)}} />);		
				break;
			case "E":
				ModalRealm.showDialog(<JustificativaDesconto  itens={this.state.desconto.itemDTO.itens} key={TZMUserData.getIdGenerator()} onModalClose={(justificativa) => {
					this.descontoService.desenvolver({...descontoItem, justificativa}, usuario, "APROVADO").then( () =>						
						this.descontoService.completar(descontoItem).then((desconto) => {this.setState({desconto})}))
					 }} />);
				break;
			case "L":	
				ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} 
					question="Tem certeza de que deseja aprovar e transformar em lista de preço está solicitação?" 
					onYes={() => this.aprovarVirarListaDesconto(descontoItem, usuario)} />);
				break;
			default:
				ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} question="Tem certeza de que deseja aprovar está solicitação?" onYes={() => this.aprovarDesconto(descontoItem, usuario)} />);
				break;
		}
	}

	reprovarDesconto = (desconto, usuario) => {
		this.descontoService.desenvolver(desconto, usuario, "REPROVADO").then(() =>  this.descontoService.completar(desconto).then((desc) => {this.setState({desconto:desc, alterado: true})}))
	}

	aprovarDesconto = (desconto, usuario) => {
		this.descontoService.desenvolver(desconto, usuario, "APROVADO").then(() => this.descontoService.completar(desconto).then((desc) => {this.setState({desconto:desc, alterado: true})}))
	}

	aprovarVirarListaDesconto = (desconto, usuario) => {
		this.descontoService.desenvolver(desconto, usuario, "APROVADO_ATUALIZAR_TABELA").then(() => this.descontoService.completar(desconto).then((desc) => {this.setState({desconto:desc, alterado: true})}))
	}

	visualizarPedido = (pedidoID) => {
		this.pedidoService.buscar(pedidoID).then((pedido) => {
			ModalRealm.showDialog(<EditarPedido key={TZMUserData.getIdGenerator()} pedido={pedido} />);
		});
	}

	colorir = (rowData) => {		
		switch (rowData.status) {
			case DescontoUtils.statusSolicitado():				
				return {'solicitado': true};				
			case DescontoUtils.statusEncaminhado():				
				return {'encaminhado': true}				
			case DescontoUtils.statusReprovado():				
				return {'reprovado': true}
			case DescontoUtils.statusAprovado():
				switch (this.state.desconto.status) {
					case DescontoUtils.statusAprovado():
						return {'aprovado': true}	
					case DescontoUtils.statusAprovadoAdicionarTabela():
						return {'aprovado_adicionar_tabela': true}
					case DescontoUtils.statusAprovadoAtualizarTabela():
						return {'aprovado_atualizar_tabela': true}
					default:
						break;
				}	
				break;
			case DescontoUtils.statusEnfileirado():				
				return {'enfileirado': true}				
			default:				
				return false;
		}
	}



	renderDesconto = () => {	
		let desconto = this.state.desconto;	
		if (desconto.status === DescontoUtils.statusAprovadoAdicionarTabela()) {
			return (
				<div className="ui-g-12 ui-g-nopad" style={{  textAlign: "left"}}>
					<span key="variacao" className="fas fa-clipboard-check" style={{  textAlign: "center", fontSize: "18px", color: '#274157'}} />
					<span style={{ fontSize: "15px"}}> Solicitação aprovada e item adicionado na lista de preço.
					</span>
				</div>
			);
		}
		if (desconto.itemDTO.valorUnitarioTabela === 0) {
			return (
				<div className="ui-g-12 ui-g-nopad" style={{  textAlign: "left"}}>
					<span key="variacao" className="fas fa-exclamation-triangle" style={{  textAlign: "center", fontSize: "18px", color: '#ffae00'}} />
					<span style={{ fontSize: "15px"}}> Sem lista de preço definida.
					</span>
				</div>
			);
		}

		let valorDesconto,percentualDesconto, color,titulo,icone;
		if (desconto.percentual > 0) {
			valorDesconto = `-${(desconto.valorDesconto).toCurrency()}`;
			percentualDesconto = `-${(toDecimalFixed(desconto.percentual  ,2))}% `
			color = '#FF0000';
			icone = "arrow-circle-down"
			titulo = "Venda realizada abaixo do valor da lista de preço."
		} else if (desconto.percentual < 0)  {	
			color = '#008000';
			titulo = "Venda realizada acima do valor da lista de preço."
			icone = "arrow-circle-up"
			valorDesconto = `+${(desconto.valorDesconto * (-1)).toCurrency()}`;
			percentualDesconto = `+${(toDecimalFixed(desconto.percentual * (-1)  ,2)) }%`
		}
		return (
			<div className="ui-g-12 ">
				<div className="ui-g-6"/>

				<div className="ui-g-2 ui-g-nopad">	
					<div className="ui-g-1 icone-variacao">						
						<span key="variacao" className={`fas fa-${icone}`} title={titulo} style={{width:"30px", textAlign: "center", fontSize: "20px", color}}  />
					</div>
					<div className="ui-g-11">
						<TZMTextField  disabled value={toCurrency(this.state.desconto.itemDTO.valorUnitarioTabela)} label="Valor Unitário da Lista de Preço" />
					</div>
				</div>
				
				<div className="ui-g-2"  >
					<TZMTextField disabled  style={{color}} label="% de Variação" value={percentualDesconto} />
				</div>
				<div className="ui-g-2"  style={{color}}>
					<TZMTextField  style={{color}} disabled value={valorDesconto} label="Total da Variação" />
				</div>
			</div>
		);
	}

	clienteInscricaoEstadual = (inscricaoEstadual) => {
		return inscricaoEstadual === 'ISENTO' ?
			<>
				<div className="ui-g-1 cliente-tipo" >
					{ClienteUtils.clienteInscricaoEstadual(inscricaoEstadual)}
				</div>
				<div className="ui-g-3" >
					<TZMTextField style={{fontWeight: "bold", backgroundColor: '#77B72A' }} label="Inscrição Estadual" disabled name="inscricaoEstadual" value={inscricaoEstadual} />
				</div>
			</>
				:
			<div className="ui-g-4">
				<TZMTextField label="Inscrição Estadual" disabled name="inscricaoEstadual" value={inscricaoEstadual} />
			</div>;
	}

    render() {	
		let aprovar = false;
		let encaminhar = false;
		let reprovar = false;
		let usuario = TZMUserData.getPrincipal();
		const inscricaoEstadual = this.state.desconto.itemDTO.inscricaoEstadual;
		if (usuario.nivel !== TZMUserData.REPRESENTANTE) {
			let item = this.state.desconto.itens.filter(i => i.status === "SOLICITADO");
			if (!Validator.isEmpty(item)) {			
				if (item[0].limite < this.state.desconto.percentual) {
					encaminhar = true;
				} else {
					aprovar = true;
				}
				reprovar = true;
			}
		}

		return (
			
			<TZMDialog style={{width: "1400px"}} visible={this.state.visible} modal header="Gestão de Preços" onHide={this.onHide} {...this.props}>
				<TZMPanel>
					<div className="ui-g">						
						<div className="ui-g-1">
							<TZMTextField  disabled value={this.state.desconto.itemDTO.pedidoId} label="Pedido" />
						</div>
						<div className="ui-g-1">
							<TZMTextField  disabled value={this.state.desconto.itemDTO.itemId} label="Item" />
						</div>
						<div className="ui-g-2">
							<TZMTextField  disabled value={this.state.desconto.status} label="Status" />
						</div>
						<div className="ui-g-1">
							<TZMCalendar  disabled={true} name="inicio" showTime={true} label="Solicitado Em" value={this.state.desconto.registro} />
						</div>										
						<div className="ui-g-4 ui-g-nopad">	
							<div className="ui-g-1 cliente-tipo">
								{ClienteUtils.clienteTipoPedido(this.state.desconto.itemDTO.tipo)}
							</div>
							<div className="ui-g-7">
								<TZMTextField label="Razão Social" disabled name="razaoSocial" value={this.state.desconto.itemDTO.razaoSocial}  />
							</div>
							{this.clienteInscricaoEstadual(inscricaoEstadual)}
						</div>
						<div className="ui-g-3">
							<TZMTextField  disabled value={this.state.desconto.itemDTO.vendedor} label="Vendedor" />
						</div>

						<div className="ui-g-2">
							<TZMTextField  disabled value={this.state.desconto.itemDTO.produtoSku} label="SKU" />
						</div>
						<div className="ui-g-3">
							<TZMTextField  disabled value={this.state.desconto.itemDTO.produtoDescricao} label="Produto" />
						</div>						
						<div className="ui-g-1">
							<TZMTextField  disabled value={this.state.desconto.itemDTO.unidade} label="Unidade" />
						</div>
						<div className="ui-g-2">
							<TZMTextField  disabled value={toCurrency(this.state.desconto.itemDTO.valorUnitario)} label="Valor Unitário" />
						</div>
						<div className="ui-g-1">
							<TZMTextField  disabled value={toPercent(this.state.desconto.itemDTO.percentualIcms)} label="% ICMS" />
						</div>
						<div className="ui-g-1">
							<TZMTextField  disabled value={this.state.desconto.itemDTO.quantidade} label="Quantidade" />
						</div>						
						<div className="ui-g-2">
							<TZMTextField  disabled value={toCurrency(this.state.desconto.itemDTO.valorTotalItem)} label="Valor Total item" />
						</div>
						{this.renderDesconto()}						
					</div>
				</TZMPanel>
				<div/>
				<TZMTable value={this.state.desconto.itens} rowClassName={this.colorir} >
					{this.state.desconto.status === "EM_ANDAMENTO" ?
					<Column key="alcadaAtual" name="alcadaAtual" style={{textAlign:"center" , width: "2em"}} 
					body={(_item) => {
						let botoes = [];
						if (_item.status === 'SOLICITADO') {										
							botoes.push(<span key="reprovar" title="Alçada Atual" style={{ fontSize: "20px"}} className="fas fa-hand-point-right ui-tzm-icon"/>);
						}
						return <div style={{ textAlign: "center" }}>{botoes}</div>;
					}}/> : <Column key="alcadaAtual" style={{width: "0em"}}/>}
					<Column key="status" name="status" style={{textAlign:"center" , width: "11em"}} field="status" header="Status" body={this.showStatus} />
					<Column key="nivel" name="nivel" style={{textAlign:"center" , width: "6em"}} field="nivel" header="Nível" />
					<Column key="limite" name="limite" style={{textAlign:"center" , width: "6em"}} field="limite" header="Limite" body={((rowData) => <div style={{textAlign: "center"}}>{Number(rowData["limite"])}%</div>)}/>									
					<Column key="justificativa"  name="justificativa" style={{textAlign:"center" , width: "14em"}} field="justificativa" header="Justificativa" />
					<Column key="responsavel" body={this.showResponsaveis} style={{ width: "12em", textAlign:"center" }} header="Alçada" />
					<Column key="usuario"  style={{textAlign:"center" , width: "15em"}} header="Executor" body={this.showUsuario} />
				</TZMTable>
				<TZMPanelFooter>
				<TZMButton className="ui-button-primary ui-tzm-button" title="Visualizar Pedido" icon="fa fa-desktop" style={{ float: "left" }} onClick={() => this.visualizarPedido(this.state.desconto.itemDTO.pedidoId)}/>
				<TZMButton className="ui-button-primary ui-tzm-button" title="Visualizar Análise do Item" icon="fa fa-balance-scale" style={{ float: "left" }} onClick={() => this.visualizarAnalise(this.state.desconto.itemDTO)}/>
				{encaminhar ? <TZMButton style={{ float: "center" }}  className="ui-button-warning" label="Encaminhar" icon="fa fa-arrow-right" onClick={() => this.modificarDesconto(this.state.desconto.itemDTO, "E")} /> : null}
				{aprovar ? <TZMButton style={{ float: "center" }} className="ui-button-success" label="Aprovar" icon="fa fa-check" onClick={() => this.modificarDesconto(this.state.desconto.itemDTO, "A")}/> : null} 
				{aprovar ? <TZMButton style={{ float: "center" }} className="ui-button-primary" label="Aprovar e Atualizar Lista de Preço" icon="fa fa-check-double" onClick={() => this.modificarDesconto(this.state.desconto.itemDTO, "L")}/> : null}
				{reprovar ? <TZMButton style={{ float: "center" }} className="ui-button-danger" label="Reprovar" icon="fa fa-exclamation" onClick={() => this.modificarDesconto(this.state.desconto.itemDTO, "R")}/> : null}				
				<TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-times" onClick={this.onHide} />									
				</TZMPanelFooter>				
			</TZMDialog>
		);
	}

	visualizarAnalise = (itemDTO) => {
		this.analiseService.buscar(itemDTO.analiseId).then(analise => {
			ModalRealm.showDialog(<AnalisePedidoItem key={Math.random()} analise={analise}/>);
		});
	}

}


