import moment from "moment";
import { httpGet, httpDelete, httpPost, httpGetAsync, httpPostVoid } from "../../utilities/TZMRequest";

export class ListaPrecoService {
	
	listarItemSimples(params) {
        return httpGet("/listas-preco/listar-item-simples", params, true);
	}	
	
	completar = async (listaPreco) =>{
		return await httpGetAsync(`/listas-preco/${listaPreco.id}`);
	}

	listar = async (params) => {
		return await httpGetAsync("/listas-preco", params, false);
	}

	completarListaPrecoItens = async (listaPreco) => {
		return await httpGetAsync(`/listas-preco/completar-lista-preco-itens/${listaPreco.id}`);
	}	
	
	completarListaPrecoItem(listaPrecoItem) {
		return httpGet(`/listas-preco/completar-lista-preco-item/${listaPrecoItem.id}`);
	}
	
	salvar(listaPreco) {
		return httpPost("/listas-preco", listaPreco);
	}
	
	salvarListaPrecoItem(listaPrecoItem) {
		return httpPost("/listas-preco/salvar-lista-preco-item", listaPrecoItem);
	} 

	excluir(listaPreco) {
		return httpDelete("/listas-preco", listaPreco.id);
	}

	listarProdutosListaPreco(params) {		
		return httpGet("/listas-preco/listar-produtos-lista-preco", params);
	}

	async alterarListaPrecoItem(alteracao) {
		return await httpPostVoid("/listas-preco/alterar-lista-preco-item", alteracao);
	}

	async alterarListaPrecoItemNova(alteracao) {
		return await httpPostVoid("/alteracao-lista-preco/alterar-lista-preco-item", alteracao);
	}

	async salvarAlteracao(alteracao) {
		return await httpPostVoid("/alteracao-lista-preco/salvar", alteracao);
	}

	async validarDuplicados(alteracao) {
		return await httpPostVoid("/alteracao-lista-preco/validar-duplicados", alteracao);
	}

	importar  = async () => {
        return httpGetAsync("/listas-preco/importar");
    }

	async progress() {
		return await httpGetAsync("/alteracao-lista-preco/progress");
	}

	async alterarItens(itens) {
		let novos = [];
		itens.forEach(item => {	
			if (!item.fim){
				let novo = Object.assign({}, item); 						
				novo.listaPrecoItemAnterior = item; 			
				novo.id = null;
				novo.versao = 0;
				novo.registro = null;				
				novo.inicio = moment().toDate();
				novo.fim = null;
				novo.erro = false;
				novo.novoPreco = novo.preco;
				novo.produtoId = item.produto.pk.produtoId;
				novo.depositoId = item.produto.pk.depositoId;
				novo.estabelecimentoId = item.produto.pk.estabelecimentoId;
				novo.empresaFilialId = item.produto.pk.empresaFilialId;
				novo.produtoDescricao = item.produto.descricao;
				novo.codigo = item.listaPreco.codigo;
				novo.listaPrecoId = item.listaPreco.id;
				if (!novos.some(n => n.produto.pk.produtoId === item.produto.pk.produtoId && n.listaPreco.codigo === item.listaPreco.codigo)) {
					novos.push(novo);
				}
			}
		});
		return Promise.resolve(novos);
	}

}
