import { AutoComplete } from "primereact/components/autocomplete/AutoComplete";
import React from "react";

export class TZMSuggestion extends React.Component {

    state = {
        value: ""
    };

    componentDidMount() {
        if (this.props.value) {
            this.setState({value: this.props.value[this.props.field]});
        }
    }

    onChange = (event) => {
        this.setState({value: event.value});
    }

    onClear = () => {
        if (this.props.onChange) {
            this.props.onChange({name: this.props.name, value: null});
            this.setState({value: ""});
        }
    }

    onSelect = (event) => {
        if (this.props.onChange) {
            this.props.onChange({name: this.props.name, value: event.value});
            if (this.props.autoclear) {
                this.setState({value: ""});
            }
        }
    }

    render() {
        return (
            <div className={`ui-g-${this.props.col}`}>
                <label>{this.props.label}</label>
                <AutoComplete {...this.props} value={this.state.value} onChange={this.onChange} onSelect={this.onSelect} onClear={this.onClear} />
            </div>
        );
    }

}
